import ReactGA from "react-ga4";

interface Event {
  action: string;
  category: string;
  label?: string;
  value?: Number;
  nonInteraction?: Boolean
}

export const useAnalytics = () => {
  return {
    init: (trackingId) => {
      ReactGA.initialize(trackingId);
      // ReactGA.initialize({
      //   trackingId: trackingId
      // })
    },
    trackPageViewed: (path) => {
      let page = window.location.pathname + window.location.search
      if (path) {
        page = path
      }      
      return ReactGA.send( { 
        hitType: 'pageview',
        page: page
      });
    },
    trackEvent: (params) => {
      ReactGA.event(params);
    }
  };
};