import 'core-js'; 
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';
import { getIpGeo } from './libs/ipgeo';

import config from './config';
import './index.css';


Sentry.init({
  dsn: "https://c859570b51f54a6d94a30b090acbe3ae@o436995.ingest.sentry.io/5398898",
  integrations: [
    new Integrations.Tracing(),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_STAGE === 'prod' ? "production" : "development"
});

Amplify.configure({
  /*Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },*/
  API: {
    endpoints: [
      {
        name: "vivelo",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "ipgeo",
        endpoint: config.ipgeolocation.URL,        
        region: config.apiGateway.REGION
      },
      {
        name: "jwplayer",
        endpoint: 'https://cdn.jwplayer.com/',        
        // custom_header: async () => { 
        //   return { 
        //     Host: 'http://viveloonline.com',
        //     Authorization : 'Bearer bA-Ql_FyQewYZ_7TV2KidWInVlc1UFlWRkVka0ZrVVZaWFlsUXhiazVEU0VsdFRGVm4n' 
        //   } 
        //   // Alternatively, with Cognito User Pools use this:
        //   // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        //   // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        // }
      },
      {
        name: "jwplayer-v2",
        endpoint: 'https://api.jwplayer.com/v2',
        custom_header: async () => { 
          return { Authorization : 'Bearer bA-Ql_FyQewYZ_7TV2KidWInVlc1UFlWRkVka0ZrVVZaWFlsUXhiazVEU0VsdFRGVm4n' } 
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
    ]
  }
});

getIpGeo()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>  
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
