import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Container, Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { base64encode } from 'nodejs-base64';

import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

import "./Signup.css";


export default function User() {
  const [fields, handleFieldChange] = useFormFields({
    fullname: "",    
  });
  const history = useHistory();  
  const { getSession, updateUser, handleLogout } = useAppContext();
  const { signup } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [session, setSession] = useState(false);


  
  useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            const sess = await getSession()
            
            setEmail(sess.user.email)
            handleFieldChange({ target: { type: 'text', id:'fullname', value: sess.user.fullname }})

            setSession(sess)

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
  }, []);


  

  function validateForm() {
    return (
      fields.fullname.length > 0
    );
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    try {

        const sess = await updateUser(session.user._id, { fullname: fields.fullname })
        if (sess) {
          console.log(sess)
          setSession(sess)
          Notification('success', 'Tu información fue actualizada')
        } else {
          Notification('error', 'No fue posible actualizar tu información')
        }        
    } catch (e) {
        onError(e);        
        Notification('error', 'No fue posible actualizar tu información')
    }

    setIsLoading(false);
    
  }

  function renderForm() {
    return (<>
      <Container className="">      
        <Row>
          <Col >
            <h1>Mis Datos</h1>
            <div className="description">Revisa y modifica tu información</div>
          </Col>                  
          <Col style={{textAlign:'right'}}>
            <LinkContainer to="/user/orders" style={{marginTop: 10}}><Button variant="outline-light">Mis Compras</Button></LinkContainer >&nbsp;
            <Button variant="danger" onClick={handleLogout} style={{marginTop: 10, marginLeft: 5}}>Cerrar sesión</Button>
          </Col>                 
    </Row>
    <Row style={{ }}>
      <Col md="4">      
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="email" size="lg">
          <FormLabel>Email</FormLabel>
          <FormControl
            readOnly
            type="email"
            value={email} 
            style={{background:'none', color:'white'}}           
          />
        </FormGroup>

        <FormGroup controlId="fullname" size="lg">
          <FormLabel>Nombre</FormLabel>
          <FormControl
            autoFocus
            type="fullname"
            value={fields.fullname}
            onChange={handleFieldChange}
          />
        </FormGroup>
                
        <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Actualizar
        </LoaderButton>
      </form>
      </Col>
      </Row>
    </Container>
    </>);
  }

  return (
    <div className="Signup content content-dark">
      {renderForm()}
    </div>
  );
}