import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle, faMapMarkerAlt, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { faSpotify, faDeezer, faYoutube, faApple, faInstagram, faFacebook, faTwitter, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";



import Loader from 'components/Loader'

import ShowInfo from "components/shows/ShowInfo";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./capitanelectrico.css"
import { render } from "@testing-library/react";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const children = await loadChildShows();            
            setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=ALL&visible=-1`);        
        return children && children.items ? children.items : []
    }

    function openQuickBuyLink(showInfo) {
        window.open(showInfo.settings.quickbuy_link, '_blank')
    }

    function renderShowInfo() {
        const showInfo = childShows[1]

        return <div className="show-info">
            <div className="show-info-border">
            <div className="show-info-header">
                <div className="album-image">
                    <img src={showInfo?.settings?.event_image} />
                </div>
                <div className="album-info">
                    <div className="album-title">{showInfo.fullname}</div>
                    <div className="album-subtitle">Presentación en vivo</div>
                    <div className="show-date">Viernes, 4 de agosto de 2023 • 9:00PM</div>                    
                    <div className="show-venue"><FontAwesomeIcon icon={faMapMarkerAlt} /> Gran Teatro Delirio. A 50m del Trebol de Vista Hermosa</div>

                    <div className="ticket-info" style={{border:'none'}}>
                        <div className="ticket-sales">
                            <div className="ticket-buttons">
                                    <div className="ticket">
                                        <div className="hole-start"></div>
                                        <button onClick={() => openQuickBuyLink(showInfo)} >Preventa<br />Q100<br /><span>Registra tu depósito<br/>o transferencia</span></button>
                                        <div className="hole-end"></div>
                                    </div>
                                    <div className="ticket">
                                        <div className="hole-start"></div>
                                        <button  disabled="disabled">Día del evento<br />Q125<br /><span>Registra tu depósito<br/>o transferencia</span></button>
                                        <div className="hole-end"></div>
                                    </div>
                                </div>                    
                        </div>
                    </div>
                </div>                
            </div>
            
            <div className="show-description">
            <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(showInfo.description)}</Linkify></>            
            </div>            
            <div className="ticket-info">
                <label>Concierto Presencial</label>
                <div className="ticket-info-layout">
                <div className="ticket-prices">
                    <ul>
                        <li>
                            <div>• Preventa</div>
                            <div>Q100</div>
                        </li>
                        <li>
                            <div>• Día del evento</div>
                            <div>Q125</div>
                        </li>
                    </ul>
                </div>
                <div className="ticket-sales">
                    <div className="ticket-buttons">
                            <div className="ticket">
                                <div className="hole-start"></div>
                                <button onClick={() => openQuickBuyLink(showInfo)} >Preventa<br />Q100<br /><span>Registra tu depósito<br/>o transferencia</span></button>
                                <div className="hole-end"></div>
                            </div>
                            <div className="ticket">
                                <div className="hole-start"></div>
                                <button  disabled="disabled">Día del evento<br />Q125<br /><span>Registra tu depósito<br/>o transferencia</span></button>
                                <div className="hole-end"></div>
                            </div>
                        </div>                    
                </div>
                
                </div>
            </div>
            </div>
        </div>
    }

    function renderPlaylist(shows) {
        if (shows) {
            
            return <div className="show-playlist">
                { shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY'].includes(p.status))
                    .map( p => {
                    return (
                    <div key={p._id} className={`show-item`}>
                        <div className="show-item-image">
                            <img src={p.main_image} />
                            { ['ACTIVE','LIVE','REPLAY'].includes(p.status) && p.video_url && (
                            <div className="play-overlay">
                                <a 
                                href={`/shows/${p._id}/player/`} target="_blank"
                                // onClick={() => { setPlaylistSelectedId(p._id); loadVideo(show, p._id); }} 
                                className="d-flex justify-content-center align-items-center" 
                                // onClick={() => { trackEvent({
                                //     action: "Clicked Play Overlay",
                                //     category: "Show"
                                // }); }}
                                ><FontAwesomeIcon icon={faPlayCircle} /></a>
                                {['LIVE'].includes(p.status) &&
                                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                                }
                            </div>
                            ) }
                            { (['SOON'].includes(p.status) /*|| !p.video_url*/) && (
                            <div className="soon-overlay">
                                <div>PRÓXIMAMENTE</div>
                            </div>
                            ) }
                        </div>
                        {/* <div className="show-item-content"> */}
                            <div className="show-item-info">
                                <div className="show-item-title">{p.fullname}</div>
                                <div className="show-item-date">{p.settings.date}</div>
                            </div>
                            <div className="show-item-description">
                                <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(p.description)}</Linkify></>                                
                            </div>                            
                        {/* </div> */}
                    </div>)
    
                })
                }
            </div>
        }
    }

    return (
        !isLoading && show ? <>
        <div className="site-capitanelectrico">
            <div className="header">            
                <div className="background" style={{backgroundImage:`url("${show.settings?.channel_image}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="background-mobile" style={{backgroundImage:`url("${show.settings?.channel_image_mobile}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="channel-info">
                    <div className="show-title">{show.fullname}</div>
                    <div className="show-badges">
                        <div className="show-year">2019</div>
                        <div className="show-rating">Reggae</div>
                        <div className="show-rating">Ska</div>
                        <div className="show-rating">Funk</div>
                    </div>
                    <div className="show-description">
                        <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(show.description)}</Linkify></>
                    </div>
                    <div className="social-links" style={{paddingTop: 20}}>
                        <div><a href="https://open.spotify.com/artist/5a4CjfqcIDFQFUxaQqg2Ka?si=_jmShmPRThipxxNXMWJICQ" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                        <div><a href="https://deezer.page.link/x69koY3EZzLrZB8i8" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                        <div><a href="https://geo.music.apple.com/gt/album/_/1657281717?app=music&at=1000lHKX&ct=linktree_http&itscg=30200&itsct=lt_m&ls=1&mt=1" target="_blank"><FontAwesomeIcon icon={faApple} /> Music</a></div>
                    </div>
                    <div className="social-links" style={{paddingTop: 20}}>
                        <div><a href="https://www.facebook.com/capitanelectricoysuconjuntoelectronico" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Capitán Eléctrico y su Conjunto Electrónico</a></div>
                        <div><a href="https://www.instagram.com/capitan.electrico/" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @capitan.electrico</a></div>
                        <div><a href="https://www.youtube.com/@CapitanElectrico" target="_blank"><FontAwesomeIcon icon={faYoutube} /> @CapitanElectrico</a></div>
                        <div><a href="https://twitter.com/electricocapita" target="_blank"><FontAwesomeIcon icon={faTwitter} /> @electricocapita</a></div>
                    </div>
                        
                </div>
            </div>
            <div className="body">

                {/* { childShows && renderShowInfo() } */}

                <div className="song-container">
                    <div className="song-cover"><img src="https://i.scdn.co/image/ab67616d0000b273af5e2a7d61810fb534293040" /></div>
                    <div className="song-info">
                        <iframe style={{borderRadius:12}} src="https://open.spotify.com/embed/album/2YAIcKtHA7AxzTUujRD4Bp?utm_source=generator" width="300" height="480" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>                        
                    </div>
                    <div className="song-info2">
                    <h1>Siete</h1>
                        <label>ALBUM | LANZAMIENTO: 7 de enero de 2,022</label>                        
                        {/* <dl>
                            <dt>1. She´s From Venezuela</dt><dd></dd>
                            <dt>2. Entre tus dedos</dt><dd></dd>
                            <dt>3. Agua Bendita</dt><dd></dd>
                            <dt>4. Puente</dt><dd></dd>
                            <dt>5. El 10</dt><dd></dd>
                            <dt>6. Fantasmas</dt><dd></dd>
                            <dt>7. Xocomil</dt><dd></dd>
                            <dt>8. Miénteme</dt><dd></dd>
                            <dt>9. Contigo</dt><dd></dd>
                            <dt>10. Por ti</dt><dd></dd>
                            <dt>11. Ya Sabes</dt><dd></dd>
                            <dt>12. El Sueño del Capitán</dt><dd></dd>                            
                            <dt>10. Quiero verte una vez más (Bonus Track)</dt><dd></dd>

                        </dl> */}
                        <dl>
                            <dt>Produccido por:</dt><dd>Matías Gómez</dd>
                            <dt>Grabación:</dt><dd>Alberto Fuentes ( Raulito) Ricardo Ortiz (Cuzuco)</dd>
                            <dt>Estudio:</dt><dd><a href="https://instagram.com/eltungt">EL TUN</a></dd>
                            <dt>Mezcla:</dt><dd>Matías Gómez</dd>
                            <dt>Masterizado por:</dt><dd>Matías Gómez</dd>

                            <dt>Voz:</dt><dd>David Franco (colocho)</dd>
                            <dt>Guitarra:</dt><dd>Pedro Luis Peña</dd>
                            <dt>Bajo:</dt><dd>Alejandro Palma (ale)</dd>
                            <dt>Batería:</dt><dd>Vinicio Molina (vini)</dd>
                            <dt>Percusiones:</dt><dd>Ricardo Palma ( palmita)</dd>
                            <dt>Piano:</dt><dd>Jose Rios ( Joey)</dd>
                            <dt>Trompeta:</dt><dd>Julio oliva</dd>
                            <dt>Saxofón:</dt><dd>Andres Blanco</dd>
                            <dt>Trombón:</dt><dd>Ricardo herrera</dd>
                            
                            
                            
                            
                            
                        </dl>
                        <div className="social-links">
                            <div><a href="https://open.spotify.com/album/2YAIcKtHA7AxzTUujRD4Bp?si=pgGvlPskS0GDTV9_4DVUGA" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                            <div><a href="https://deezer.page.link/JUgHzFMktBTVT7xY6" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                            <div><a href="https://music.apple.com/gt/album/siete/1657281717?l=en" target="_blank"><FontAwesomeIcon icon={faApple} /> Apple Music</a></div>
                        </div>
                    </div>
                </div>

                {/* {childShows && renderPlaylist(childShows)} */}
                
            </div>
            <div className="footer">
                <h2>Acerca de <b>Capitán Eléctrico</b></h2>
                <div className="content">
                    <div>
                    <p>Es una banda guatemalteca de música latinoamericana proveniente de la Ciudad de Guatemala, fundada en el año 2020.  Es considerada una de las propuestas musicales más novedosas del país.  "El Capitán Eléctrico", como se le conoce, surge de un tributo a Los Fabulosos Cadillacs a finales del año 2019.</p>
                    <p>A mediados del año 2020 durante la pandemia, se propone hacer un concierto en línea. Dicho concierto no se realiza y surge la idea de crear música propia, llevando con esto a la creación de la banda. </p>
                    <p>El 4 de enero de 2021 lanzan su primer sencillo, "Entre tus dedos", en todas las plataformas digitales teniendo una gran aceptación en el mercado de México y Centroamérica.  El 20 de enero de 2021 arrancan su primera gira de conciertos "Es hora de despegar Tour", con la cual han realizado presentaciones en diferentes bares y venues de la capital, siendo todo un éxito, teniendo llenos totales aún en época de pandemia, respetando todas las disposiciones y protocolos de seguridad dadas por el Gobierno de la República.</p>
    <p>"Quiero verte una vez más" es el segundo sencillo, lanzado el 2 de Julio del 2021, La canción tuvo un proceso de pre-producción producción, grabación y post-producción, que se dividió en dos partes, una en Guatemala y otra parte en Miami con el productor Rodolfo Castillo.</p>
    <p>El 6 de junio del 2022 entran al estudio a grabar su primer disco  llamado SIETE, de la mano del productor Argentino Matías Gómez grabado en el estudio de grabación “El Tun”  el album tendrá 12 canciones inéditas  de las cuales se desprende el primer sencillo “PUENTE” lanzado el 14 de octubre y el segundo sencillo “El 10” lanzado el 10 de Noviembre del 2022, el 7 de Enero del 2023  Lanzan su primer  disco completo un disco muy variado que incluye sonidos Reggae, Ska, Funk, Rock Latino, y fusiones de Merengue, Flamenco, Vallenato, el 4 de Marzo sera la presentación en vivo de su disco “SIETE” dando inicio a su gira 2023-2024 “El Viaje del Capitán”.</p>
                    </div>
                    <div className="logos">
                        <img src="https://assets.viveloonline.com/profiles/capitan.electrico/capitan-profile.png" />                    
                    </div>
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}