import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";
import "./CarasDelAmor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >


    return (
        !isLoading && show ? <>
        <Container fluid className="Show content content-dark carasdelamor">
            <Row>
                <Col md="4" className="">
                    <img className="main_image" src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/PortadaVertical.png" />
                </Col>    
                <Col md="8" className="Show">
                    <h1>Las Caras del Amor</h1>
                    <ShowInfo info={show.description} />
                    
                    <ShowTicketBox show={show} viewerShow={viewerShow} tickets={tickets} 
                        ticketsButtonVariant="outline-light"
                        loginButtonVariant="outline-light"
                        activateButtonVariant="light"
                        addToCalendarButtonVariant="outline-light"
                    />
                    { show.settings?.registration ? <>
                        <ShowRegistration show={show} tickets={tickets} onSuccess={handlePaymentSuccess} />
                        
                    </> : <>
                        <div className="container show-tickets data-table">
                            <ShowTickets show={show} tickets={tickets} 
                                addToCartButtonVariant="light"
                            />
                        </div>
                    </>
                    }
                    <h1>El Elenco</h1>
                    <div className="biobox">
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio8.png" />
                            </div>
                            <div>
                                <h2>Ana Rosa Orozco</h2>
                                <p>Soprano guatemalteca, con importante trayectoria en la música guatemalteca. Ha interpretado diversos roles operáticos en Italia, Panamá, Guatemala, El Salvador, Nicaragua y Costa Rica, incluyendo operas como El Barbero de Sevilla, Turandot, Rigoletto y El Murcielago. Solista de la agrupación Consorcio Barroco, con quien participa en diversas actividades a lo largo del año. Invitada frecuente de la Orquesta Sinfónica Nacional, con quien ha interpretado gran cantidad de obras, entre las que destacan, Carmina Burana de Carl Orff, Stabat Mater de G. Pergolessi, tambien es conocida por su interpretacion como soprano del Mesias de Handel. Forma parte del elenco permante de “Las Caras del Amor” desde su creación en 2015.
<br /><br />
<div className="social">
<a target="_blank" href="https://www.facebook.com/arteanarosa/"><FontAwesomeIcon icon={faFacebook} /></a>
</div>
</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio7.png" />
                            </div>
                            <div>
                                <h2>David Martínez</h2>
                                <p>Tenor guatemalteco, con una novel carrera en el ámbito operático guatemalteco. Inició cantando en coros operáticos para varias producciones guatemaltecas en las que participó incluso fuera del país. Ha participado como solista en diversos montajes realizados por el colectivo “Opera para Tod@s” en donde ha abordado piezas de compositores como W.A Mozart, F. Cilea. En los últimos años ha tenido destacadas participaciones en los montajes operáticos “La Boheme” producida por “Querido Arte” y en 2019 hizo su primera participación con la Orquesta Sinfónica Nacional en la opera “El Murciélago” de Strauss. 
                                <br /><br />
<div className="social">
<a target="_blank" href="https://www.facebook.com/DavidMartinezTenor/"><FontAwesomeIcon icon={faFacebook} /></a>
<a target="_blank" href="https://www.instagram.com/davidmartineztenor/"><FontAwesomeIcon icon={faInstagram} /></a>
</div>
</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio6.png" />
                            </div>
                            <div>
                                <h2>Leslie González</h2>
                                <p>Soprano spinto guatemalteca, egresada del Conservatorio Nacional de Música, Germán Alcántara.  Su formación vocal ha estado a cargo de maestros como José Reanda, Luis Felipe Girón May, Berrnardo Villalobos, Carmela Altamura, Olivia Gorra, entre otros. En su repertorio cuenta con los siguientes roles: Tosca de la Ópera Tosca de G. Puccini, Cio-Cio-San de la Ópera  Madame Butterfly de G. Puccini, Mimi de la Ópera La Boheme de G. Puccini, Leonora de la Ópera Il Trovatore de G. Verdi, La Condesa de Almaviva de la Ópera Las Bodas de Fígaro de W. A. Mozart, entre otras.
                                <br /><br />
En Guatemala ha participado en diversos escenarios tales como: Festival Paiz, Festival del Centro Histórico, Festival de Junio. Solista en diversas obras que incluyen: Sinfonía 1, Poema de la Ciudad de Guatemala, Opus 14, del compositor guatemalteco Gabriel Yela, Misa en Re Mayor Opus 86 de Antonin Dvorak, Misa de los Niños de John Rutter, Misa a Buenos Aires (Misa Tango) de Martín Palmieri. En 2016 fue parte del Programa de Verano en Altamura Center for the Arts & Culture, en la ciudad de Nueva York; en ese mismo año participó en la “Gira por la Paz”, en diferentes ciudades de Polonia. En 2017 participó en el VIII Festival Internacional de Bel Canto en Hidalgo, México. En 2018 participó como solista con la Orquesta Sinfónica Nacional de Guatemala interpretando La Misa de Réquiem en Re Menor, K. 626 de W. A. Mozart y la Sinfonía No. 9 en Re Menor, Op. 125 de Ludwig Van Beethoven. En 2019 fue solista invitada en diversos conciertos de la Orquesta Sinfónica Nacional de Guatemala. Ese mismo año, interpretó el papel del Príncipe Orlofsky de la Ópera El Murciélago de Strauss como parte de la  Temporada Oficial. En febrero de 2020 participó en el Festival Internacional de Arte y Cultura de Suchitoto, El Salvador, así como también, representó a Guatemala en la gira de conciertos “Arias for Aid” en Sarasota, Florida.
<br /><br />
<div className="social">
<a target="_blank" href="https://www.facebook.com/Leslie-Gonzalez-Soprano-1509281572725521/"><FontAwesomeIcon icon={faFacebook} /></a>
<a target="_blank" href="https://www.instagram.com/leslie_gonzalez_soprano"><FontAwesomeIcon icon={faInstagram} /></a>
</div>
</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio5.png" />
                            </div>
                            <div>
                                <h2>Pedro Pablo Solís</h2>
                                <p>Tenor guatemalteco, con una destacada carrera operática que le ha permitido tener notables participaciones nacionales e internacionales. Inició desde muy joven su formación y participación en múltiples montajes operáticos y recitales. Ha abordado un repertorio variado de compositores tales como: F. Cilea, G. Verdi, WA. Mozart, G.Bizet. Con participaciones internacionales en El Salvador, México, Costa Rica y Estados Unidos, ha dado muestras de su destacada participación en el programa impartido en España por la soprano Montserrat Caballé. Ha tenido roles como solista en óperas tales como: Traviata de G.Verdi, El Empresario de WA Mozart. Invitado frecuente de la Orquesta Sinfónica Nacional de Guatemala, con quien en 2019 interpretó uno de los roles principales en la opereta “El Murcielago” de Johann Strauss. 
<br /><br />
<div className="social">
<a target="_blank" href="https://www.facebook.com/pedropablotenor"><FontAwesomeIcon icon={faFacebook} /></a>
<a target="_blank" href="https://www.instagram.com/pedropablo_solis"><FontAwesomeIcon icon={faInstagram} /></a>
</div>
</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio4.png" />
                            </div>
                            <div>
                                <h2>Sergio Valle</h2>
                                <p>Pianista, compositor y arreglista guatemalteco. Estudió la Licenciatura en música en la Escuela Superior de Arte de la Universidad de San Carlos de Guatemala, especializado en la Licenciatura en Piano, bajo la dirección de la Pianista Julia Solares. También es ejecutante de la marimba, integrando la Marimba Nacional de Concierto y fue cofundador de la Marimba Contemporánea. Con presentaciones en la ciudad capital y varios departamentos de Guatemala, así mismo como en la Universidad de Chiapas, México, la Universidad Nacional de Heredia, Costa Rica, la Universidad Nacional de Honduras y algunos escenarios en los Estados Unidos y Argentina. 
                                <br /><br />
En la edición 2019 del Certamen Permanente Centroamericano 15 de septiembre del Ministerio de Cultura y Deportes de Guatemala, su obra para coro a cuatro voces y piano, “Hunahpú e Ixbalanqué”, fue la ganadora en la disciplina de música. 
<br /><br />
Su pasión por la música de cámara le ha llevado a participar como pianista acompañante de cantantes de ópera nacionales e internacionales, instrumentistas solistas y coros. Desde el año 2016 a la fecha ha participado activamente como pianista con las agrupaciones Capella Cantorum, Estudio Coral y el grupo Ópera para Tod@s. En el 2019 asume el puesto de pianista acompañante del Coro Nacional de Guatemala bajo la dirección de Daniel Ovalle.
<br /><br />
<div className="social">
<a target="_blank" href="https://www.facebook.com/SergioValleGT"><FontAwesomeIcon icon={faFacebook} /></a>
<a target="_blank" href="https://www.instagram.com/SergioValleGT"><FontAwesomeIcon icon={faInstagram} /></a>
<a target="_blank" href="https://www.youtube.com/user/SergioValle92/"><FontAwesomeIcon icon={faYoutube} /></a>
</div>

</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio3.png" />
                            </div>
                            <div>
                                <h2>Henry De Paz</h2>
                                <p>Artista integral que se ha desarrollado en diversas ramas del arte. Actualmente se especializa en la videoescena y la utilización de tecnologías en la escena artística. Con trece años de experiencia en el medio artístico, continúa su ardua labor como incansable autodidacta y creador, es co-fundador y co-director de la Compañía Artística MIDAS y de la Compañía de teatro Drasi.  Posee estudios de dibujo, escultura y pintura en la Academia Ars Artis del escultor Walter Peter y estudios de música con especialización en bajo eléctrico en la academia Poliritmos. Seleccionado para participar en la residencia artística "La videoescena y otras técnicas contemporáneas en las artes escénicas" facilitado por el Centro Cultural de España en Tegucigalpa y la residencia en artes electrónicas en el Centro Cultural de España en Costa Rica -CCECR- . Actualmente se especializa en la creación de videoescena para todo tipo de espectáculos artísticos escénicos y realiza estudios de investigación del diseño escénico con nuevas tecnologías. 
</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio2.png" />
                            </div>
                            <div>
                                <h2>Oscar R. Barrios</h2>
                                <p>Guatemalteco, con estudios en el Conservatorio Nacional de Música, y experiencia en montajes escénicos desde 2007. Desde los once años se ha desempeñado en el ámbito musical como cantante coral y ejecución de marimba y guitarra. Ha formado parte de coros operáticos en producciones desde 2007, así como diversos montajes sinfónicos corales. Con incursiones en proyectos artísticos como anuncios, asistencias a dirección, traducción, subtitulaje, producción, apoyo técnico. Ha sido parte del ensamble de voces acapella Estudio Coral por varios años, y continúa su formación en técnica vocal con la soprano Leslie González, con quien también forma parte del colectivo Ópera para Todos desde hace varios años. Graduado summa cum laude de la universidad Rafael Landívar como ingeniero mecánico, con un posgrado en Taiwán en maquinaria automatizada. Ha laborado en un grupo de empresas de construcción por varios años, así como en audio profesional, asesorías técnicas, y montaje de eventos. Encuentra una convergencia de varios de sus quehaceres en esta asistencia a la producción, y traducción de los diálogos y piezas.
</p>
                            </div>
                        </div>
                        <div className="bio">
                            <div className="biopic">
                                <img src="https://assets.viveloonline.com/shows/5fc968b606ea49f23c9327db/Bio1.png" />
                            </div>
                            <div>
                                <h2>Giovanni Melendez</h2>
                                <p>Realizador Audiovisual y Director Escénico. Guionista y Director de piezas cortas de teatro “Cisne” y “Cuando Nadie Me Ve”. Director escénico de conciertos de las sopranos Karin Rademann y Leslie González. Creador y Director Escénico de los espectáculos líricos y performáticos: “Las Caras del Amor”, “Retorcidos pero Operáticos”, “Cuando nadie me ve, “Voces del Silencio”, Cibeles, “Dans la nuit”, “Lo que he soñado de mí misma”, “No le llores”, “Un empresario en busca de brujas y diosas” , “Ni brujas, ni diosas”. Director Escénico de Conciertos Corales, “Canta Latinoamerica Canta”, Coro Universidad San Carlos de Guatemala, y “Súbito Urbano”, Estudio Coral. Director Escénico de Operas Rigoletto y La Traviata presentadas en Guatemala y El Salvador, Lucia di Lamermoor, Don Pasquale. Con el montaje escénico “El Murciélago” en 2019 realizó su primera colaboración con la Orquesta Sinfónica Nacional. 
</p>
                            </div>
                        </div>
                    </div>

                    <ShowInfo info={show.description2} />
                </Col>
            </Row>
            
        </Container>
        </> : <Loader />
  );
}