import firebase from "firebase/app";
// import "firebase/auth";
import "firebase/database";

import config from "../config"
// console.log(config)
// const config = {
//   apiKey: "AIzaSyBaAZqKn4cdbLlbQw2ags8VPA6F1CLlSGo",
// //   authDomain: "chatty-36be6.firebaseapp.com",
//   databaseURL: "https://vivelo-dev.firebaseio.com/"
// };
const firebaseConfig = {
  apiKey: config.firebase.apiKey,
//   authDomain: "chatty-36be6.firebaseapp.com",
  databaseURL: config.firebase.databaseURL
};
// console.log(firebaseConfig)

firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth;
export const db = firebase.database();