import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../components/Loader"

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

export default function EmailVerify() {  
  const { token } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  
  useEffect(async () => {
    //setIsLoading(true)    
    await verifyEmail();
    setIsLoading(false)
  }, [token]);

  async function verifyEmail() {    
    try {      
        const result = await API.get('vivelo',`/auth/email/verify/${token}`)
        setVerified(true)
        return true;
    } catch(e) {            
      console.log(e)
      setVerified(false)      
      return false
    } finally {
    }
  }

  return ( !isLoading ? <div className="content content-dark">
      <Container className="">
        <Row>
        <Col>
          <h1>Verifica tu Correo</h1>
          <div className="description"></div>
        </Col>
      </Row>
      <Row style={{ }}>
        <Col md="4">
          { verified ? "Gracias, tu correo ha sido verificado exitosamente." : "No fue posible verificar tu correo.  El token probablemente haya expirado." }
        </Col>
        </Row>
      </Container>
      </div>
: <Loader /> );

}