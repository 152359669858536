import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Moment from 'react-moment';
import { API, Storage } from "aws-amplify";
import { Container, Row, Col, Button, Accordion, Card } from "react-bootstrap";
import { Form, FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap/";
import CreditCardInput from 'react-credit-card-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTicketAlt, faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import "./Show.css";

export default function Notes() {
  //const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { isAuthenticated, session } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [extras, setExtras] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    purchaseCode: "",
    purchaseCodeTicket: "",
    ccFirstname: "",
    ccLastname: "",
    ccEmail: "",
    ccPhone: "",
    ccAddress: "",
    ccCity: "",
    ccState: "",
    ccCountry: "GT",
    ccNumber: null,
    ccName: "",
    ccExpiry: null,
    ccCvv2: null,
    ccTicket: "",    
  });

    useEffect(() => {

    async function onLoad() {
        try {            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {
                const vShow = await loadViewerShow(session.viewer.id, show.id)
            }

        } catch (e) {
            onError(e);
        }
    }

    onLoad();
    }, [id]);

    /*function validateForm() {
        return content.length > 0;
    }*/

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets`);
        if (tickets && tickets.length > 0) {
            setTickets(tickets)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    async function processPayment(viewer_id, show_id, payment_type, payment_info, ticket_id) {
        const purchase = await API.post("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`, {
            body: {
                payment_type: payment_type,
                payment_info: payment_info,
                ticket: {
                    ticket_id: ticket_id,
                    fullname: tickets.find(t => t.ticket_id == ticket_id).fullname
                },
                livestream: true,
                replay: true,
                meetngreet: false
            }
        });    
        
        if (purchase) {
            console.log(purchase)
            alert('Compra exitosa')
            await loadViewerShow(viewer_id, show_id)
            return true
        } else {
            alert('No se puedo realizar la compra')
            return false
        }
    }

    function validateCreditCardForm() {
        //return fields.purchaseCode.length > 0 && fields.purchaseCodeTicket;
        const fieldsOk = fields.ccFirstname.length > 0 
            && fields.ccLastname.length > 0
            && fields.ccEmail.length > 0 
            && fields.ccPhone.length > 0 
            && fields.ccAddress.length > 0 
            && fields.ccCity.length > 0 
            && fields.ccState.length > 0 
            && fields.ccCountry.length > 0 
            && fields.ccNumber
            && fields.ccName.length > 0 
            && fields.ccExpiry
            && fields.ccCvv2
            && fields.ccTicket;

        if (fieldsOk) {

        }   
        return fieldsOk
      }
    
    function handleCardInputChange(event) {
        switch(event.target.id) {
            case 'card-number': {
                event.target.id = 'ccNumber'
                break;
            }
            case 'card-expiry': {
                event.target.id = 'ccExpiry'
                break;
            }
            case 'cvc': {
                event.target.id = 'ccCvv2'
                break;
            }

        }
        handleFieldChange(event)
    }

    async function handleSubmitCreditCard(event) {    
        event.preventDefault();        
        setIsLoading(true);
        
        const viewer_id = session.viewer.id
        const payment_info = {                        
                cc_number: fields.ccNumber.replace(" ",""),
                cc_exp: fields.ccExpiry.replace(" ", ""),
                cc_cvv2: fields.ccCvv2
        }
        const purchase = await processPayment(viewer_id, id, 'CREDIT_CARD', payment_info, fields.ccTicket)
    

        setIsLoading(false);
    }    

    function renderCreditCardForm() {
        return <Form onSubmit={handleSubmitCreditCard}>
            <Row>
                <Col md="6">
                    <h2>Datos personales</h2>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccFirstname">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control placeholder="ej: José"  value={fields.ccFirstname} onChange={handleFieldChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccLastname">
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control placeholder="ej: Pérez"  value={fields.ccLastname} onChange={handleFieldChange}  />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.ccEmail} onChange={handleFieldChange}  />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccPhone">
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control type="tel" placeholder="" value={fields.ccPhone} onChange={handleFieldChange} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="ccAddress">
                        <Form.Label>Direcci&oacute;n</Form.Label>
                        <Form.Control value={fields.ccAddress} onChange={handleFieldChange} />
                    </Form.Group>

                    <Form.Group controlId="ccCity">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control value={fields.ccCity} onChange={handleFieldChange} />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="ccState">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control value={fields.ccState} onChange={handleFieldChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccCountry">
                        <Form.Label>Pa&iacute;s</Form.Label>
                        <Form.Control as="select" value={fields.ccCountry} onChange={handleFieldChange} >                            
                            {renderCountries()}
                        </Form.Control>
                        </Form.Group>

                    </Form.Row>
                </Col>
                <Col md="6">
                    <h2>Datos de tarjeta de cr&eacute;dito</h2>
                    <Form.Row>
                    
                        <Form.Group controlId="ccName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control autoComplete="off" placeholder="ej: José Perez" value={fields.ccName} onChange={handleFieldChange} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group>                    
                        <Form.Label style={{width:'100%'}}>Informaci&oacute;n de pago</Form.Label>
                        <CreditCardInput
                            cardNumberInputProps={{ value: fields.ccNumber, onChange: handleCardInputChange }}
                            cardExpiryInputProps={{ value: fields.ccExpiry, onChange: handleCardInputChange }}
                            cardCVCInputProps={{ value: fields.ccCvv2, onChange: handleCardInputChange }}
                            fieldClassName="form-control vivelo-field" inputClassName="vivelo-input"
                            customTextLabels={{
                                invalidCardNumber: 'El número de la tarjeta es inválido',
                                expiryError: {
                                invalidExpiryDate: 'La fecha de expiración es inválida',
                                monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
                                yearOutOfRange: 'El año de expiración no puede estar en el pasado',
                                dateOutOfRange: 'La fecha de expiración no puede estar en el pasado'
                                },
                                invalidCvc: 'El código de seguridad es inválido',
                                invalidZipCode: 'El código postal es inválido',
                                cardNumberPlaceholder: 'Número de tarjeta',
                                expiryPlaceholder: 'MM/AA',
                                cvcPlaceholder: 'COD',
                                zipPlaceholder: 'C.P.'
                            }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <div className="data-table">
                    <Row >
                        <Col md="9">Total</Col>
                        <Col md="3" className="price">Q600</Col>                            
                    </Row>
                    </div>

                    <Form.Row>
                        <LoaderButton
                            block
                            type="submit"
                            size="lg"
                            isLoading={isLoading}
                            disabled={!validateCreditCardForm()}
                        >
                        Pagar
                        </LoaderButton> 
                    </Form.Row>
                    <Form.Row style={{'text-align:':'center', 'border-top': 'solid 1px #0191e2', 'margin-top': 20}}>
                        <img src="/img/branding-QPayPro-invert-muestra.png" alt="QPayPro" style={{width:300, 'margin-left':'auto', 'margin-right':'auto'}} />
                    </Form.Row>
                </Col>
            </Row>

                <Form.Group id="formGridCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group>

                
            </Form>       
    }

    function renderCountries() {
        return <>
        <option value="GT">Guatemala</option>
        <option value="AF">Afghanistan</option>
        <option value="AX">&Aring;land Islands</option>
        <option value="AL">Albania</option>
        <option value="DZ">Algeria</option>
        <option value="AS">American Samoa</option>
        <option value="AD">Andorra</option>
        <option value="AO">Angola</option>
        <option value="AI">Anguilla</option>
        <option value="AQ">Antarctica</option>
        <option value="AG">Antigua and Barbuda</option>
        <option value="AR">Argentina</option>
        <option value="AU">Australia</option>
        <option value="AT">Austria</option>
        <option value="AZ">Azerbaijan</option>
        <option value="BS">Bahamas</option>
        <option value="BH">Bahrain</option>
        <option value="BD">Bangladesh</option>
        <option value="BB">Barbados</option>
        <option value="BY">Belarus</option>
        <option value="BE">Belgium</option>
        <option value="BZ">Belize</option>
        <option value="BJ">Benin</option>
        <option value="BM">Bermuda</option>
        <option value="BT">Bhutan</option>
        <option value="BO">Bolivia</option>
        <option value="BA">Bosnia and Herzegovina</option>
        <option value="BW">Botswana</option>
        <option value="BV">Bouvet Island</option>
        <option value="BR">Brazil</option>
        <option value="IO">British Indian Ocean Territory</option>
        <option value="BN">Brunei Darussalam</option>
        <option value="BG">Bulgaria</option>
        <option value="BF">Burkina Faso</option>
        <option value="BI">Burundi</option>
        <option value="KH">Cambodia</option>
        <option value="CM">Cameroon</option>
        <option value="CA">Canada</option>
        <option value="CV">Cape Verde</option>
        <option value="KY">Cayman Islands</option>
        <option value="CF">Central African Republic</option>
        <option value="TD">Chad</option>
        <option value="CL">Chile</option>
        <option value="CN">China</option>
        <option value="CX">Christmas Island</option>
        <option value="CC">Cocos (Keeling) Islands</option>
        <option value="CO">Colombia</option>
        <option value="KM">Comoros</option>
        <option value="CG">Congo</option>
        <option value="CD">Zaire</option>
        <option value="CK">Cook Islands</option>
        <option value="CR">Costa Rica</option>
        <option value="CI">C&ocirc;te D&#039;Ivoire</option>
        <option value="HR">Croatia</option>
        <option value="CU">Cuba</option>
        <option value="CY">Cyprus</option>
        <option value="CZ">Czech Republic</option>
        <option value="DK">Denmark</option>
        <option value="DJ">Djibouti</option>
        <option value="DM">Dominica</option>
        <option value="DO">Dominican Republic</option>
        <option value="EC">Ecuador</option>
        <option value="EG">Egypt</option>
        <option value="SV">El Salvador</option>
        <option value="GQ">Equatorial Guinea</option>
        <option value="ER">Eritrea</option>
        <option value="EE">Estonia</option>
        <option value="ET">Ethiopia</option>
        <option value="FK">Falkland Islands (Malvinas)</option>
        <option value="FO">Faroe Islands</option>
        <option value="FJ">Fiji</option>
        <option value="FI">Finland</option>
        <option value="FR">France</option>
        <option value="GF">French Guiana</option>
        <option value="PF">French Polynesia</option>
        <option value="TF">French Southern Territories</option>
        <option value="GA">Gabon</option>
        <option value="GM">Gambia</option>
        <option value="GE">Georgia</option>
        <option value="DE">Germany</option>
        <option value="GH">Ghana</option>
        <option value="GI">Gibraltar</option>
        <option value="GR">Greece</option>
        <option value="GL">Greenland</option>
        <option value="GD">Grenada</option>
        <option value="GP">Guadeloupe</option>
        <option value="GU">Guam</option>
        <option value="GG">Guernsey</option>
        <option value="GN">Guinea</option>
        <option value="GW">Guinea-Bissau</option>
        <option value="GY">Guyana</option>
        <option value="HT">Haiti</option>
        <option value="HM">Heard Island and Mcdonald Islands</option>
        <option value="VA">Vatican City State</option>
        <option value="HN">Honduras</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hungary</option>
        <option value="IS">Iceland</option>
        <option value="IN">India</option>
        <option value="ID">Indonesia</option>
        <option value="IR">Iran, Islamic Republic of</option>
        <option value="IQ">Iraq</option>
        <option value="IE">Ireland</option>
        <option value="IM">Isle of Man</option>
        <option value="IL">Israel</option>
        <option value="IT">Italy</option>
        <option value="JM">Jamaica</option>
        <option value="JP">Japan</option>
        <option value="JE">Jersey</option>
        <option value="JO">Jordan</option>
        <option value="KZ">Kazakhstan</option>
        <option value="KE">KENYA</option>
        <option value="KI">Kiribati</option>
        <option value="KP">Korea, Democratic People&#039;s Republic of</option>
        <option value="KR">Korea, Republic of</option>
        <option value="KW">Kuwait</option>
        <option value="KG">Kyrgyzstan</option>
        <option value="LA">Lao People&#039;s Democratic Republic</option>
        <option value="LV">Latvia</option>
        <option value="LB">Lebanon</option>
        <option value="LS">Lesotho</option>
        <option value="LR">Liberia</option>
        <option value="LY">Libyan Arab Jamahiriya</option>
        <option value="LI">Liechtenstein</option>
        <option value="LT">Lithuania</option>
        <option value="LU">Luxembourg</option>
        <option value="MO">Macao</option>
        <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
        <option value="MG">Madagascar</option>
        <option value="MW">Malawi</option>
        <option value="MY">Malaysia</option>
        <option value="MV">Maldives</option>
        <option value="ML">Mali</option>
        <option value="MT">Malta</option>
        <option value="MH">Marshall Islands</option>
        <option value="MQ">Martinique</option>
        <option value="MR">Mauritania</option>
        <option value="MU">Mauritius</option>
        <option value="YT">Mayotte</option>
        <option value="MX">Mexico</option>
        <option value="FM">Micronesia, Federated States of</option>
        <option value="MD">Moldova, Republic of</option>
        <option value="MC">Monaco</option>
        <option value="MN">Mongolia</option>
        <option value="ME">Montenegro</option>
        <option value="MS">Montserrat</option>
        <option value="MA">Morocco</option>
        <option value="MZ">Mozambique</option>
        <option value="MM">Myanmar</option>
        <option value="NA">Namibia</option>
        <option value="NR">Nauru</option>
        <option value="NP">Nepal</option>
        <option value="NL">Netherlands</option>
        <option value="AN">Netherlands Antilles</option>
        <option value="NC">New Caledonia</option>
        <option value="NZ">New Zealand</option>
        <option value="NI">Nicaragua</option>
        <option value="NE">Niger</option>
        <option value="NG">Nigeria</option>
        <option value="NU">Niue</option>
        <option value="NF">Norfolk Island</option>
        <option value="MP">Northern Mariana Islands</option>
        <option value="NO">Norway</option>
        <option value="OM">Oman</option>
        <option value="PK">Pakistan</option>
        <option value="PW">Palau</option>
        <option value="PS">Palestinian Territory, Occupied</option>
        <option value="PA">Panama</option>
        <option value="PG">Papua New Guinea</option>
        <option value="PY">Paraguay</option>
        <option value="PE">Peru</option>
        <option value="PH">Philippines</option>
        <option value="PN">Pitcairn</option>
        <option value="PL">Poland</option>
        <option value="PT">Portugal</option>
        <option value="PR">Puerto Rico</option>
        <option value="QA">Qatar</option>
        <option value="RE">R&eacute;union</option>
        <option value="RO">Romania</option>
        <option value="RU">Russian Federation</option>
        <option value="RW">Rwanda</option>
        <option value="SH">Saint Helena</option>
        <option value="KN">Saint Kitts and Nevis</option>
        <option value="LC">Saint Lucia</option>
        <option value="PM">Saint Pierre and Miquelon</option>
        <option value="VC">Saint Vincent and the Grenadines</option>
        <option value="WS">Samoa</option>
        <option value="SM">San Marino</option>
        <option value="ST">Sao Tome and Principe</option>
        <option value="SA">Saudi Arabia</option>
        <option value="SN">Senegal</option>
        <option value="RS">Serbia</option>
        <option value="SC">Seychelles</option>
        <option value="SL">Sierra Leone</option>
        <option value="SG">Singapore</option>
        <option value="SK">Slovakia</option>
        <option value="SI">Slovenia</option>
        <option value="SB">Solomon Islands</option>
        <option value="SO">Somalia</option>
        <option value="ZA">South Africa</option>
        <option value="GS">South Georgia and the South Sandwich Islands</option>
        <option value="ES">Spain</option>
        <option value="LK">Sri Lanka</option>
        <option value="SD">Sudan</option>
        <option value="SR">Suriname</option>
        <option value="SJ">Svalbard and Jan Mayen</option>
        <option value="SZ">Swaziland</option>
        <option value="SE">Sweden</option>
        <option value="CH">Switzerland</option>
        <option value="SY">Syrian Arab Republic</option>
        <option value="TW">Taiwan, Province of China</option>
        <option value="TJ">Tajikistan</option>
        <option value="TZ">Tanzania, United Republic of</option>
        <option value="TH">Thailand</option>
        <option value="TL">Timor-Leste</option>
        <option value="TG">Togo</option>
        <option value="TK">Tokelau</option>
        <option value="TO">Tonga</option>
        <option value="TT">Trinidad and Tobago</option>
        <option value="TN">Tunisia</option>
        <option value="TR">Turkey</option>
        <option value="TM">Turkmenistan</option>
        <option value="TC">Turks and Caicos Islands</option>
        <option value="TV">Tuvalu</option>
        <option value="UG">Uganda</option>
        <option value="UA">Ukraine</option>
        <option value="AE">United Arab Emirates</option>
        <option value="GB">United Kingdom</option>
        <option value="US">United States</option>
        <option value="UM">United States Minor Outlying Islands</option>
        <option value="UY">Uruguay</option>
        <option value="UZ">Uzbekistan</option>
        <option value="VU">Vanuatu</option>
        <option value="VE">Venezuela</option>
        <option value="VN">Viet Nam</option>
        <option value="VG">Virgin Islands, British</option>
        <option value="VI">Virgin Islands, U.S.</option>
        <option value="WF">Wallis and Futuna</option>
        <option value="EH">Western Sahara</option>
        <option value="YE">Yemen</option>
        <option value="ZM">Zambia</option>
        <option value="ZW">Zimbabwe</option>
        </>
    }

    return (
        show ?    
        <Container className="Show content">
            <Row>                
                <Col md="12" className="show-title primary">
                    
                            <>{show.fullname}</>
                    
                </Col>
            </Row>

            <Row>
                <Col md="12">
                {renderCreditCardForm()}
                </Col>                
            </Row>
        </Container>
        : null
  );
}