import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Container, Row, Col, Button, Badge, Carousel } from "react-bootstrap/";
import { LinkContainer } from "react-router-bootstrap";
import Moment from 'react-moment';
import { API } from "aws-amplify";
import Loader from '../components/Loader'

import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { useCart } from "libs/cartLib"

import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faTheaterMasks, faMusic, faFilm, faShoppingCart, faGlobe, faWarehouse, faPlay, faMicrophoneAlt, faInfoCircle, faInfo, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faPlayCircle, faUserCircle } from "@fortawesome/free-regular-svg-icons";

import "./Home.css";

export default function Home() {
  const history = useHistory();
  const [shows, setShows] = useState([]);
  const [pastShows, setPastShows] = useState([]);
  const [vodShows, setVodShows] = useState([]);
  const [billboardShows, setBillboardShows] = useState([]);
  const {isAuthenticated, setIsAuthenticating, logout } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { getCart, getTotalItems } = useCart();
  const [cart, setCart] = useState(null)
  const [cartTotalItems, setCartTotalItems] = useState(null)  

  useEffect(() => {
    async function onLoad() {
     /* if (!isAuthenticated) {
        return;
      }*/
  
      try {
        const bshows = await loadBillboardShows();
        setBillboardShows(bshows)

        const shows = await loadShows();
        setShows(shows)        

        loadVodShows();
        loadPastShows();        

        await updateCartCounter()

      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadShows() {

    let shows = API.get("vivelo", "/shows?sort=1");
    return shows || { items: [] }   
    // return { items: []}  
  }

  function loadBillboardShows() {
    let bShows = API.get("vivelo", "/shows?sort=1&billboard=1");
    return bShows || { items: [] }    
  }

  async function loadVodShows() {
    let vodShows = await API.get("vivelo", "/shows?filter=ALL&sort=-1&vod=1&status=ACTIVE,REPLAY,SOON");
    setVodShows(vodShows || { items: [] } )
  }

  async function loadPastShows() {
    let pastShows = await API.get("vivelo", "/shows?filter=PAST&sort=-1");
    setPastShows( pastShows || { items: [] })

  }

  async function updateCartCounter() {
    let mycart;
      try {
        mycart = await getCart(true)
        setCart(mycart)
      } catch (e) {
        // no cart
      }

      const total = await getTotalItems(mycart)
      setCartTotalItems(total)
  }

  async function handleLogout() {    
    setIsAuthenticating(true);

    try {
      await logout()
      history.push("/");
    } catch (e) {
      onError(e)      
    } finally {
      setIsAuthenticating(false);
    }
  }

  function renderShowInfo(show) {      
    return <Row className="show" noGutters>
      <div className="background" style={{backgroundImage: `url(${show.main_image})`}}>
      <LinkContainer key={`name-${show._id}`} to={`/shows/${show._id}`}>
        <div className="layer">
          <div className={`show-title primary`}>
                  
                      <>{show.fullname}</>
          </div>
          <div className={`show-date primary`}>
                    <Moment local format="ddd, LL - LT">{new Date(show.show_date)}</Moment>
            </div>
            <div className={`show-actions`}>
                  { show.status == "SOON" && 
                    <Badge variant="info" >Pr&oacute;ximamente</Badge> 
                  }
                  { ['ACTIVE','STARTED','LIVE','TEST'].includes(show.status) && 
                    show.paid == "true" ? <Button variant="danger" style={{marginLeft: 'auto'}} >Comprar boletos</Button> : <Badge variant="info" >Ingresar</Badge> 
                  }
                
            </div>
            {show.status == "LIVE" &&
              <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
            }
        </div>
        </LinkContainer>
      </div>

    </Row>
  }

  function renderShowsList(shows) {    

    let items = shows ? shows.items : []

    let primaryShow = null;
    let secondaryShows = [];
    items.map((show, i) => {
        if (i === 0) {
            primaryShow = renderShowInfo(show)
        } else {
            secondaryShows.push(renderShowInfo(show))
        }
    })

    return <>
      <div className="home-menu">
        
          <Row className="nav-logo justify-content-center" >            
            <img src="/img/vivelo-logo.png"/>
          </Row>
          <Row className="nav justify-content-center">                      
            <Link to={`/activate`}  className="nav-item">Activar Boleto</Link>
            <Link to={`/help`}  className="nav-item">C&oacute;mo Funciona</Link>
            {isAuthenticated
              ? <Link onClick={handleLogout}  className="nav-item highlight1">Salir</Link> 
              : <Link to={`/login`}  className="nav-item highlight1">Iniciar Sesión</Link>}
            
          </Row>
   
      </div>   
        <div className="content">
            <div>                                    
              {primaryShow}
              {secondaryShows}
              <Row className="soon" noGutters></Row>
            </div>
        </div>
        <div className="home-menu">      
          <Row className="nav justify-content-center">                      
            <Link to={`/shows/past`}  className="nav-item">Shows Pasados</Link>            
          </Row>   
        </div>         
        </>      
  }

  function renderShows() {
    return (
      <div className="shows">        
        {!isLoading ? renderShowsList(shows) : <Loader />}
      </div>
    );
  }

  function renderCarrousel(show) {
    // let items = shows ? shows.items : []

    // const carrousel = items.filter(show => show.settings?.billboard ).map((show, i) => {
        // return <Carousel.Item>
        //   <LinkContainer key={`name-${show._id}`} to={`/shows/${show._id}`}>
        //     <img className="" src={`${show.main_image}`} alt={show.fullname} />
        //   </LinkContainer>
        // </Carousel.Item>
        const info_classname = `billboard-info-${show?.settings?.billboard_info_position || 'top'}`

        const carrousel = show && <div className="billboard">
          <div className={`billboard-info ${info_classname}`}>
            <div className="billboard-info-title">{`${show.fullname}`}</div>
            <div className="billboard-info-summary">{`${show.settings?.billboard_summary || ''}`}</div>
            <div className="billboard-info-actions">
              {show.settings.vod &&
                <Link to={`/shows/${show._id}`} ><Button variant="light" ><FontAwesomeIcon icon={faPlayCircle} /> Reproducir</Button></Link>
              }
              <Link to={`/shows/${show._id}`} ><Button variant="dark" className="more-info"  ><FontAwesomeIcon icon={faInfoCircle} /> Más Información</Button></Link>
            </div>
          </div>
        </div>
    // })

      return <div className="show-carousel">
          {/* <Carousel> */}
          {carrousel}
          {/* </Carousel> */}
          {/* <div className="overlay"></div> */}
      </div>
  }

  function renderGenreIcon(show) {
    switch(show.icon) {
      case 'theater':
        return <FontAwesomeIcon icon={faTheaterMasks} />
      break;

      case 'movie':
        return <FontAwesomeIcon icon={faFilm} />
      break;

      case 'talk':
        return <FontAwesomeIcon icon={faMicrophoneAlt} />
      break;

      case 'music':
      default:
        return <FontAwesomeIcon icon={faMusic} />
      break;
    }
  }

  function renderVenue(show) {
    switch(show.venue) {
      case 'Online':
        return <div class="show-venue"><label className="virtual"><FontAwesomeIcon icon={faGlobe} /> virtual</label></div>
      break;

      default:
        return <div class="show-venue"><span><label className="presencial"><FontAwesomeIcon icon={faMapMarkerAlt} /> presencial</label> {show.venue}</span></div>
      break;
    }
  }

  function renderStatus(show) {
    switch(show.status) {
      case 'LIVE':
        return <div className="live"><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div>
      break;
      case 'REPLAY':
        return <div className="vod"><FontAwesomeIcon icon={faPlayCircle} />&nbsp;&nbsp;POR DEMANDA</div>
      break;
      case 'SOON':
        return <div className="live">PRóximamente</div>
      break;
      default:
        if (show?.settings?.vod) {          
          return <div className="vod"><FontAwesomeIcon icon={faPlayCircle} />&nbsp;&nbsp;PRóximamente</div>
        } else {
          return <></>
        }
      break;
    }
  }

  function renderShowCards() {
    let items = shows ? shows.items : []
    if (!items) return

    const cards = items.filter(s => !s.settings?.vod ).map((show, i) => {
        return <Link key={`name-${show._id}`} to={`/shows/${show._id}`}>
        <div className="show-card">
          <div className="show-card-image"><img src={show.main_image} /></div>
          <div className="show-card-overlay">
            <div className="show-card-info">
              <div className="show-card-left">
                <div className="show-card-day"><Moment local format="DD">{new Date(show.show_date)}</Moment></div>
                <div className="show-card-month"><Moment local format="MMM">{new Date(show.show_date)}</Moment></div>
                <div className="show-card-hour"><Moment local format="LT">{new Date(show.show_date)}</Moment></div>
              </div>
              <div className="show-card-right">
                <div className="show-card-title">{show.fullname}</div>
              </div>
            </div>
            <div className="show-card-footer">
              <div className="show-card-genre">{renderGenreIcon(show)}</div>
              {renderStatus(show)}
              <div className="show-card-venue">{renderVenue(show)}</div>            
            </div>
          </div>
        </div>
        </Link>
    })    

    return cards && cards.length > 0 && <section id="events" ><div className="show-card-group" >
      <div className="show-card-group-title">Próximos Eventos</div>
    <div className="show-card-list">        
        {cards}
    </div>
    </div></section>
  }
  
  function renderVodShowCards() {
    let items = vodShows ? vodShows.items : []
    if (!items) return

    const cards = items.filter(s => s.settings?.vod ).map((show, i) => {
        return <Link key={`name-${show._id}`} to={`/shows/${show._id}`}>
        <div className="show-card">
          <div className="show-card-image"><img src={show.main_image} /></div>
          <div className="show-card-overlay">
            <div className="show-card-info">
              <div className="show-card-left">
                {show.status != 'REPLAY' && <>
                <div className="show-card-day"><Moment local format="DD">{new Date(show.show_date)}</Moment></div>
                <div className="show-card-month"><Moment local format="MMM">{new Date(show.show_date)}</Moment></div>
                <div className="show-card-hour"><Moment local format="LT">{new Date(show.show_date)}</Moment></div>
                </> }
              </div>
              <div className="show-card-right">
                <div className="show-card-title">{show.fullname}</div>
              </div>
            </div>
            <div className="show-card-footer">
              <div className="show-card-genre">{renderGenreIcon(show)}</div>
              {renderStatus(show)}
              <div className="show-card-venue">{renderVenue(show)}</div>            
            </div>
          </div>
        </div>
        </Link>
    })
      return cards && cards.length > 0 && <section id="vod" ><div className="show-card-group">
      <div className="show-card-group-title">Por Demanda</div>
      <div className="show-card-list">
      {cards}
      </div>
      </div></section>
  }

  function renderPastShowCards() {
    let items = pastShows ? pastShows.items : []    
    if (!items) return

    const cards = items.map((show, i) => {
        return <Link key={`name-${show._id}`} to={`/shows/${show._id}`}>
        <div className="show-card">
          <div className="show-card-image"><img src={show.main_image} /></div>
          <div className="show-card-overlay">
            <div className="show-card-info">
              <div className="show-card-left">
                <div className="show-card-day"><Moment local format="DD">{new Date(show.show_date)}</Moment></div>
                <div className="show-card-month"><Moment local format="MMM">{new Date(show.show_date)}</Moment></div>
                <div className="show-card-hour"><Moment local format="LT">{new Date(show.show_date)}</Moment></div>
              </div>
              <div className="show-card-right">
                <div className="show-card-title">{show.fullname}</div>
              </div>
            </div>
            <div className="show-card-footer">
              <div className="show-card-genre">{renderGenreIcon(show)}</div>
              {renderStatus(show)}
              <div className="show-card-venue">{renderVenue(show)}</div>            
            </div>
          </div>
        </div>
        </Link>
    })
      return cards && cards.length > 0 && <section id="past" ><div className="show-card-group">
      <div className="show-card-group-title">Eventos Pasados</div>
      <div className="show-card-list">
      {cards}
      </div>
      </div></section>
  }


  function renderShowsBillboard(shows) {    

    let items = shows ? shows.items : []

    let primaryShow = null;
    let secondaryShows = [];
    items.map((show, i) => {
        if (i === 0) {
            primaryShow = renderShowInfo(show)
        } else {
            secondaryShows.push(renderShowInfo(show))
        }
    })

    let billboardList = billboardShows ? billboardShows.items : []
    const billboard = billboardList.length > 0 ? billboardList[0] : null


    return ( billboardShows && <>      
        <div className="billboard-background" style={{backgroundImage:`url("${billboard?.settings.billboard_image}")`}}>
          <div className="overlay"></div>
        </div>
        <div className="content">
            <div>
              { renderCarrousel(billboard) }
              { shows && renderShowCards() }
              { vodShows && renderVodShowCards() }
              { pastShows && renderPastShowCards() }
              {/* <Row className="soon" noGutters></Row> */}
            </div>
        </div>        
        </>      
    )
  }

  function renderHome() {
    return  (
      <div className="billboardk">        
        {!isLoading ? renderShowsBillboard(billboardShows) : <Loader />}
      </div>
    )

  }

  return (
    <div className="Home">
      {renderHome()}      
    </div>
  );
}