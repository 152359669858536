import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, Container } from "react-bootstrap";

import Loader from 'components/Loader'
import CartSummaryBox from "components/cart/CartSummaryBox"
import RegistrationForm from "components/cart/RegistrationForm"

import { onError } from "libs/errorLib";
import { useCart } from "libs/cartLib"
import { useAppContext } from "libs/contextLib";
import { LinkContainer } from "react-router-bootstrap";
// import './Loader.scss'

export default function CartRegistration(props) {
    const { info, ...otherProps } = props
    
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { getSession, updateUser, updateCartCounter } = useAppContext()
    const [user, setUser] = useState(null)

    useEffect(() => {

        async function onLoad() {
            const session = await getSession()
            setUser(session.user)

        }

        onLoad()

    }, [])


    function handlePaymentSuccess(payment_info, purchase) {
        updateCartCounter()

        const userInfo = {
            firstname: payment_info.firstname,
            lastname: payment_info.lastname,
            email: payment_info.email,
            country: payment_info.country,
        }

        updateUser(user._id, userInfo)
        history.push(`/orders/${purchase.id}`)
    }

    return user && <div class="content content-dark">
        <Container>
        <Row><Col>
        <h1 className="title">Completar Registro</h1>
        <CartSummaryBox readOnly={true} totalsOnly={false} />
        <div className="cart-summary-actions" style={{marginBottom:20}}>
            <LinkContainer to="/cart">
                <Button variant="light" size="sm"  
                    ><FontAwesomeIcon icon={faChevronLeft} /> Modificar órden</Button>
            </LinkContainer>
        </div>
        
        <RegistrationForm user={user} onSuccess={handlePaymentSuccess} />
        </Col></Row>
        </Container>
    </div>
}