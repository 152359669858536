import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle, faFilm, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { faSpotify, faDeezer, faYoutube, faApple, faInstagram, faFacebook, faTwitter, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { Button, Badge, Modal } from "react-bootstrap";


import Loader from 'components/Loader'

import ShowInfo from "components/shows/ShowInfo";
import ShowTicketBox from "components/shows/ShowTicketBox";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import Activate from 'containers/Activate'
import Login from 'containers/Login'

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./leyendasgt.css"
import { render } from "@testing-library/react";
import moment from "moment";
import { setExtra } from "@sentry/minimal";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory();
  const { trackEvent } = useAnalytics();
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const { getCart, addCartItems, removeCartItems } = useCart()  

  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);  
  const [extraShows, setExtraShows] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);    
  
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            let children = await loadChildShows();            
            await Promise.all(children.map(async c => {
                let tickets = await loadShowTickets(c._id)
                c.tickets = tickets
    
                if (isAuthenticated) {
                    let vShow = await loadViewerShow(session.viewer._id, c._id)
                    c.viewerShow = vShow
                }

            }))
            setChildShows(children)
            
            let extra = await loadExtraShows()
            setExtraShows(extra)


            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?producer=5f73e9c21ecaeb1193bbeef4&visible=1`);
        return children && children.items ? children.items : []
    }
    async function loadViewerShow(viewer_id, show_id) {
        try {
            const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);
            return vShow
        } catch (e) {            
        }
        return null
    }
    async function loadShowTickets(show_id) {
        const tickets = await API.get("vivelo", `/shows/${show_id}/tickets?paymentType=CREDIT_CARD`);
        if (tickets && tickets.items && tickets.items.length > 0) {            
            return tickets.items
        }
        return null
    }
    async function loadExtraShows() {
        const extra1 = await API.get("vivelo", `/shows/6180739cbe6a8e0b7f298891`);
        const extra2 = await API.get("vivelo", `/shows/61807949be6a8e0b7f298895`);
        return [extra1, extra2]
    }

    async function registerCartItem(selectedShow, ticket) {
        trackEvent({
            action: "Clicked Add to Cart Button",
            category: "PurchaseFunnel"
        });

        const currency = selectedShow?.settings?.currency || 'GTQ'

        const date = moment(selectedShow.show_date).format('ddd, LL - LT')

        const cartItem = {
            ticket_uid: ticket._id,
            type: 'TICKET',                
            show_uid: selectedShow._id,
            show_name: selectedShow.fullname,
            show_date: selectedShow.show_date,
            show_type: selectedShow.type,
            venue: selectedShow.venue,                
            title: `${selectedShow.fullname} (${date})`,
            description: ticket.fullname,
            note: ticket.note,
            ticket_type: ticket.type,
            image: `${selectedShow.main_image}`,
            quantity: 1
        }
        if (ticket.location) {
            cartItem.location = ticket.location
        }

        await addCartItems({ currency: currency, items: [cartItem] })
        await updateCartCounter()
        history.push('/cart',{})
        
    }

    function handleModalClose() {
        setShowActivationModal(false)
        setShowLoginModal(false)
    }

    async function handleActivationModalSuccess() {
        setShowActivationModal(false);
        window.location.reload(false);

    }

    function renderActivationModal() {
        
        return <Modal size="md" centered show={showActivationModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body><Activate modal handleModalSuccess={async () => await handleActivationModalSuccess() } /></Modal.Body>        
      </Modal>
    }

    function renderLoginModal() {        
        return <Modal size="md" centered show={showLoginModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ingresa a tu cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body><Login modal /></Modal.Body>        
      </Modal>
    }

    function renderPlaylist(shows) {
        if (shows) {
            
            return <div className="show-playlist">
                { shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY'].includes(p.status))
                    .map( p => {
                    return (
                    <div key={p._id} className={`show-item`}>
                        <div className="show-item-image">
                            <img src={p.main_image} />
                            { ['ACTIVE','LIVE','REPLAY'].includes(p.status) && p.video_url && (
                            <div className="play-overlay">
                                <a 
                                href={`/shows/${p._id}/`} target="_blank"
                                // onClick={() => { setPlaylistSelectedId(p._id); loadVideo(show, p._id); }} 
                                className="d-flex justify-content-center align-items-center" 
                                // onClick={() => { trackEvent({
                                //     action: "Clicked Play Overlay",
                                //     category: "Show"
                                // }); }}
                                ><FontAwesomeIcon icon={faPlayCircle} /></a>
                                {['LIVE'].includes(p.status) &&
                                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                                }
                            </div>
                            ) }
                            { (['SOON'].includes(p.status) /*|| !p.video_url*/) && (
                            <div className="soon-overlay">
                                <div>PRÓXIMAMENTE</div>
                            </div>
                            ) }
                        </div>
                        {/* <div className="show-item-content"> */}
                            <div className="show-item-info">
                                <div className="show-item-title">{p.fullname}</div>
                                <div className="show-item-date">{p.settings.date}</div>
                            </div>
                            <div className="show-item-description">
                                <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(p.description)}</Linkify></>
                                <div>
                                    <div className="price">Míralo por demanda por {p.settings.price}</div>
                                    <a 
                                        href={`/shows/${p._id}/`} target="_blank"
                                    >
                                    <Button variant="outline-light">Más información</Button>
                                    </a>
                                </div>
                                {/* <ShowTicketBox /> */}
                            </div>
                        {/* </div> */}
                    </div>)
    
                })
                }
            </div>
        }
    }

    function renderShowTickets(shows) {
        if (shows) {
            
            return <div className="show-tickets">
                { shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY'].includes(p.status))
                    .map( p => {


                    return (
                    <div key={p._id} className={`show-ticket`}>
                        <div className="date">{moment(p.show_date).format("dddd DD MMM")}</div>
                        <div className="time">{moment(p.show_date).format("hh:mm A")}</div>
                        <div className="actions">
                            <a 
                                onClick={() => registerCartItem(p, p.tickets[0])}
                                >
                            <Button>Comprar (Q125 / $17)</Button>
                            </a>
                        </div>
                    </div>)
    
                })
                }
            </div>
        }
    }

    function renderTicketBoxes(shows) {
        if (shows) {
            
            let ticketCount = 0
            let tickets = 
                shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY'].includes(p.status))
                    .map( p => {

                        // get show ticket
                        let viewerShow = p.viewerShow

                        if (!viewerShow) {
                            return null
                        } else {
                            ticketCount++;
                            return (
                            <div key={p._id} className={`show-item`}>                                                                
                                <div className="date">{moment(p.show_date).format("dddd DD MMM hh:mm A")}</div>
                                <div>
                                    {viewerShow.ticket.fullname}<br />
                                    Boleto: {viewerShow.payment_info.request_code} <Badge variant="success">ACTIVADO</Badge>
                                </div>
                                <div>
                                    <a href={`/shows/${p._id}/player`} target="_blank" onClick={(e) => {
                                        trackEvent({
                                            action: "Clicked Play Button",
                                            category: "Show"
                                        });
                                        if (!viewerShow) {
                                            alert('Solicita tu boleto para poder ingresar. Si ya tienes tu boleto activado, por favor inicia sesión para continuar.')
                                        }
                                        
                                        if (!['STARTED','LIVE','REPLAY'].includes(p.status)) {
                                            alert('Aún no es posible el ingreso al evento.  Por favor, recarga la página 10 minutos antes del evento y vuelve a intentar.')
                                            e.preventDefault()
                                            return false
                                        }
                                        return true                            
                                    }} >
                                    <Button variant="success"><FontAwesomeIcon icon={faFilm} /> Ver show</Button>
                                    </a>
                                </div>
                            </div>
                            )
                        }
    
                    })                     
            
            if (ticketCount == 0) {
                tickets = (<div>Aún no tienes ningún boleto activo para alguna función.<br /><br />
                    Si ya hiciste tu compra, revisa que hayas activado tu boleto.<br />
                    <Button variant="info" onClick={() => { setShowActivationModal(true); trackEvent({
                            action: "Clicked Start Activate Button",
                            category: "Show"
                        }); }} ><FontAwesomeIcon icon={faUnlock} /> Activar código</Button>
                </div>)
            }

            return <div className="show-tickets">{tickets}</div>
        }
    }

    function renderShows(shows) {
        if (shows) {
            
            return <div className="show-tickets">
                { shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY'].includes(p.status))
                    .map( p => {


                    return (
                    <div key={p._id} className={`show-ticket`}>
                        <div className="date">{moment(p.show_date).format("dddd DD MMM")}</div>
                        {/* <div className="time">{moment(p.show_date).format("hh:mm A")}</div> */}
                        <div className="actions">
                            <Link to={`/shows/${p._id}`}                                
                                >
                            <Button>Seleccionar</Button>
                            </Link>
                        </div>
                    </div>)
    
                })
                }
            </div>
        }
    }

    return (
        !isLoading && show ? <>
        { renderLoginModal() }
        { renderActivationModal() }
        <div className="site-leyendasgt">
            <div className="header">            
                <div className="background" style={{backgroundImage:`url("${show.settings?.channel_image}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="background-mobile" style={{backgroundImage:`url("${show.settings?.channel_image_mobile}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="show-info">
                    <div className="show-title">{show.fullname}</div>
                    {/* <div className="show-badges">
                        <div className="show-year">2021</div>
                        <div className="show-rating">13+</div>
                        <div className="show-quality">HD</div>
                    </div> */}
                    <div className="show-description">
                        <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(show.description)}</Linkify></>
                    </div>
                    <div className="social-links" style={{paddingTop: 20}}>
                        <div><a href="https://www.facebook.com/RecorridodeleyendasGT" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Recorrido de Leyendas GT</a></div>
                        <div><a href="https://www.instagram.com/recorrido_de_leyendas_gt/" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @recorrido_de_leyendas_gt</a></div>
                        <div><a href="https://www.youtube.com/@recorridodeleyendasgt1293" target="_blank"><FontAwesomeIcon icon={faYoutube} /> Recorrido de Leyendas GT</a></div>
                        {/* <div><a href="https://twitter.com/electricocapita" target="_blank"><FontAwesomeIcon icon={faTwitter} /> @electricocapita</a></div> */}
                    </div>
                </div>
                
            </div>
            <div className="body">

                {/* <div className="show-ticketbox">
                    <h1>Mis Boletos</h1>
                    {!isAuthenticated && (<div>
                        <div>Ingresa a tu cuenta para ver tu boleto para este evento</div>
                        <div>
                            <Button variant="info" onClick={() => { setShowLoginModal(true); trackEvent({
                                        action: "Login",
                                        category: "Show"
                                    }); }} >Iniciar Sesión</Button>
                        </div>
                    </div> )}
                    {isAuthenticated && (<div>
                        { childShows && renderTicketBoxes(childShows) }
                    </div> )}
                </div>

                <div className="show-howto">
                    <h1>¿Cómo funciona?</h1>
                    <div>
                        <ol>
                            <li>Presiona el botón “Comprar” de la fecha y hora de la función que deseas ver para agregarla al carrito.</li>
                            <li>Continúa a “Finalizar comprar”</li>
                            <li>Inicia sesión, o crea tu usuario si aún no lo tienes</li>
                            <li>Completa el formulario de pago. Revisa que todos los datos esten ingresados correctamente.</li>
                            <li>Activa el código que te muestra en la órden o que recibirás al correo electrónico ingresado.</li>
                            <li>El día del evento inicia sesión con el mismo usuario con el que activaste el código.</li>
                            <li>El acceso al evento estará abierto desde 15 minutos antes de la hora anunciada</li>
                            <li>La transmisión del teatrometraje se hará en esta misma plataforma</li>
                        </ol>
                    </div>
                </div>

                <div className="show-buy-tickets">
                    <h1>Elije una función:</h1>
                    <div>
                        {childShows && renderShowTickets(childShows)}
                    </div>
                </div> */}

                <div className="show-buy-tickets">
                    <h1>Elije una fecha:</h1>
                    <div>
                        {childShows && renderShows(childShows)}
                    </div>
                </div>

                {/* { extraShows &&  (<div className="show-ticketbox">
                    <h1>Videos Por Demanda</h1>
                    <div>
                        { renderPlaylist(extraShows) }
                    </div>
                </div> ) } */}

                
                
            </div>
            <div className="footer">
                <h2>Acerca de <b>Recorrido de Leyendas de Guatemala</b></h2>
                <div>
                    <br />
                    Síguenos en nuestro <a href="https://www.youtube.com/@recorridodeleyendasgt1293/" target="_blank">canal de YouTube</a>
                    <br />
                </div>
                <div className="logos">
                    <img src="https://assets.viveloonline.com/shows/614a661c49525af84cdd0bd5/Leyendas Logo.png" />
                    <img src="https://assets.viveloonline.com/shows/614a661c49525af84cdd0bd5/Duende del Atico Logo.png" />
                    <img src="https://assets.viveloonline.com/shows/614a661c49525af84cdd0bd5/Fundacion Teoxche Logo.png" />
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}