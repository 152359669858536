import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'

import Moment from 'react-moment';
import 'moment/locale/es';
import ReactJWPlayer from 'react-jw-player';

import { API } from "aws-amplify";
import { v1 as uuidv1 } from 'uuid'
import config from '../config';

import './JwPlayer.css'
/**
 * The code of the error which the player may raise if the livestream goes offline or finishes.
 */
 const LIVESTREAM_COMPLETE_ERROR = 230001;

 const PROTECTED_CONTENT_ERROR = 232403;
 /**
  * The code of the error emitted by JW Player's HLS implementation if buffering stalls.
  * This may happen when a livestream (suddenly) finishes and no more segments are available to buffer.
  * In this case we'll switch back to VOD playback.
  */
 const HLS_BUFFER_STALL_WARNING = 334001;
 
 const CHECK_STATUS_INTERVAL = 10 * 1000;


 function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


const JwPlayer = ({channelId, mediaId, playerIds, image, className, style, show, seek,
    playerRef, autoPlay,
    onReady, onPlay, onEnded, onCast, onError
}) => {

    const [playerId, setPlayerId] = useState(uuidv1())

    const [status, setStatus] = useState(null)
    const [media, setMedia] = useState(mediaId)
    const [active, setActive] = useState(false)
    const [ended, setEnded] = useState(false)
    const timer = useRef(null)
    const player = useRef(null)

    const prevMedia = usePrevious(mediaId)
    const [reload, setReload] = useState(false)
    

    // useEffect(() => {        
    // })

    useEffect(() => {       
        return () => () => { console.log('unmount'); stopTimer(); }
    }, [])

    useEffect(() => {
        // console.debug('channel change', channelId)
        
        if (!channelId) {
            stopTimer()
            return
        } else {
            if (player.current) {
                // console.log('stop')
                player.current.stop()
            }
        }

        checkStatus()
        startTimer()
        return () => function() {
            stopTimer()            
        }
    }, [channelId])

    useEffect((last) => {
        if (!mediaId) {
            return
        } else {
            stopTimer()

            // fetch signed media url

        }

        setMedia(mediaId)
    }, [mediaId])

    useEffect(() => {
        if (ended && onEnded) {

            onEnded()
        }

    }, [ended])

    function startTimer() {        
        timer.current = setInterval(checkStatus, CHECK_STATUS_INTERVAL)
        // console.debug('start timer', timer.current)
    }

    function stopTimer() {
        // console.debug('stop timer', timer.current)
        clearInterval(timer.current);
        // setTimer(null)
    }

    async function checkStatus() {                        
        const status = await getLiveChannelStatus(channelId);
        //console.debug(status);
        setStatus(status)

        if (status.status == 'active') {            
            setMedia(status.current_event)
        }

        if (status.status != 'active') {
            setActive(false)                        
        }

    }


    async function getLiveChannelStatus(channelId) {
        try {
            const s = await API.get("jwplayer", `/live/channels/${channelId}.json`, {
                headers: {
                    // Authorization: 'Bearer bA-Ql_FyQewYZ_7TV2KidWInVlc1UFlWRkVka0ZrVVZaWFlsUXhiazVEU0VsdFRGVm4n'
                }
            });
            return s
        } catch(err) {
            console.log(err)
            return { status: 'idle', current_event: null }
        }
    }

    function handleOnVideoLoad(e) {
        setActive(true)
        stopTimer()
        setEnded(false)        

    }

    function handleOnError(e) {
        console.log(e)
        if (e.code === LIVESTREAM_COMPLETE_ERROR) {
            setActive(false)
            setStatus({ status: 'error', media: null })
            player.current.stop()
            setEnded(true)            

            startTimer()            
        }
        if (e.code === PROTECTED_CONTENT_ERROR) {

        }
    }

    function handleOnAutoStart(e) {

    }

    /*
    {
        oldstate: buffering|playing
        viewable: 0|1
        playReason: autostart|external|interaction|playlist|related-auto|related-interaction|viewable
    }
    */
    function handleOnPlay(e) {
        if (onPlay) {
            onPlay(e)
        }
    }

    /*
    {
        oldstate: buffering|playing
        viewable: 0|1
        pauseReason: autostart|external|interaction|playlist|related-auto|related-interaction|viewable
    }
    */
    function handleOnPause(e) {

    }

    /*
    {
        setupTime: number millis
        viewable: 0|1
    }
    */
    function handleOnReady(e) {
        if  (window.jwplayer && window.jwplayer(playerId)) {
            player.current = window.jwplayer(playerId)
        }
        
        if (player.current) {
            if (playerRef) {
                playerRef.current = player.current
            }
        }
    
        if (onReady) {
            onReady(e)
        }
    }

    /*
    {
        code: number
        message: string,
        sourceError:
        type: string
    }
    */
    function handleOnWarning(e) {

    }

    /*
    */
    function handleOnComplete(e) {
        console.info('completed', e)
        setEnded(true)
        stopTimer()
    }

    /*
    {
        loadTime: millis
        viewable: 0|1
    }
    */
    function handleOnFirstFrame(e) {
        console.log('firstframe', player.current)
        if (player.current && seek) {
            player.current.seek(seek)
        }
    }

    /*
    {
        fullscreen: true|false
    }
    */
    function handleOnFullscreen(e) {

    }
    /*
    {
        "available": true,
        "active": true,
        "deviceName": "ViewerTV",
        "type": "cast"
    }
    */
    function handleOnCast(e) {

    }
    


    function renderJwVideoPlayer2() {
        
        // console.log('player media', media)

        let playlist = `https://cdn.jwplayer.com/v2/media/${media}`
        //console.log('playlist', playlist)
        // let playlist = "https://devcdn.viveloonline.com/v2/media/PvmFPLjC?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6Ii92Mi9tZWRpYS9Qdm1GUExqQyIsImV4cCI6MTYyNzAyNjMwMCwiaWF0IjoxNjI3MDI1OTYzfQ.DGklCkpTy-oCL559q2gPFYDaiXH7nzNeEtH1TKAgpMI"

        return media && <div className="player"  style={!active ? { display:'none'} : {}}>
          <ReactJWPlayer 
              playerId={playerId}
            // playerId="player01"
              playerScript={`https://cdn.jwplayer.com/libraries/BYv09MZg.js`}
            // playerScript={"https://devcdn.viveloonline.com/libraries/MWsO6zfO.js?exp=1627026300&sig=1c7174ac17bc43bc9936f72d228b9869"}              
            // playerScript="https://content.jwplatform.com/libraries/jvJ1Gu3c.js"
            //   file={`https://content.jwplatform.com/videos/BglojqY3-mY297ZqO.mp4`}
            //   image={`https://content.jwplatform.com/thumbs/BglojqY3-1920.jpg`}              
              image={image}
              isAutoPlay={autoPlay || false}
            //   customProps={{
            //       autostart
            //   }}
            //   file={playlist}
            //   playlist={[]}
              playlist={playlist}
            //   channelId={`lqnkpaUe`}
              onVideoLoad={handleOnVideoLoad}
              onError={handleOnError}
              onAutoStart={handleOnAutoStart}
              onPlay={handleOnPlay}
              onPause={handleOnPause}
              onReady={handleOnReady}
              onWarning={handleOnWarning}
              onComplete={handleOnComplete}
              onFullscreen={handleOnFullscreen}
              onCast={handleOnCast}
              onFirstFrame={handleOnFirstFrame}
            //   onDisplayClick={() => {
            //     document.body.style.backgroundColor = "red";
            //     setMedia('cvLuwFIf')
            //     // gaCRFWjn
            //     //window.jwplayer('jw-player').load('https://cdn.jwplayer.com/v2/media/cvLuwFIf');
            //   }}
          />
        </div>
    }

    function renderImage(show) {
        if (show && show.main_image) {
            return show.main_image
        } else {
            return 'https://assets.viveloonline.com/vivelo-video-cover2.png'
        }
    }

    function renderMessage(show) {
        let title = show?.fullname
        let time
        let message
        let footnote
        let icon

        if (ended) {
            icon = <img src="/img/isotipo200.png" />
            title = "La transmisión ha terminado"
            message = "Esperamos hayas disfrutado esta nueva experiencia de espectáculos online"
            footnote = "Si crees que es un error, por favor recarga la página"
        } else {
            switch(show?.status) {
                case 'NEW':
                case 'ACTIVE':
                case 'LIVE':
                case 'TEST': {
                    time = <Moment local format="ddd, LL - LT">{new Date(show.show_date)}</Moment>
                    message = "El evento aún no ha iniciado"    
                    break
                }
                case 'REPLAY': {
                    message = "El evento aún no ha iniciado"    
                    break
                }
                case 'CLOSED':
                case 'CANCELLED': {
                    message = "Este evento ya no está disponible"    
                    break
                }
                default: {
                    title = ""
                    message = "La transmisión de este evento no está activa"
                }
            }
        }

        return <>
            <div className="cover-icon">{icon}</div>
            <div className="cover-title">{title}</div>
            <div className="cover-time">{time}</div>
            <div className="cover-message">{message}</div>
            <div className="cover-footnote">{footnote}</div>
            </>
    }

    function renderWaitScreen() {
        return <div className="wait" style={active ? { display:'none'} : {}}>
                <div className="cover-image" style={{backgroundImage:`url('${renderImage(show)}')`}}></div>
                <div className="cover-block">
                    <div className="cover-card">
                        {renderMessage(show)}
                    </div>
                    
                </div>
            
            </div>
    }

    function render() {
        
        let player = null
        let waitVisible = 'block'
        let playerVisible = 'none'
        switch(status?.status) {
            case 'idle': 
                waitVisible = 'block'
                playerVisible = 'none'
                break;
            case 'active': 
                waitVisible = 'none'
                playerVisible = 'block'
                break;
        }

        const waitScreen = renderWaitScreen();
        player = renderJwVideoPlayer2()
        
        return <div className={`jwplayer ${className}`} style={style}>            
            {waitScreen}
            {player}
        </div>
    }

    return render()
}


JwPlayer.propTypes = {
	channelId: PropTypes.string,
	mediaId: PropTypes.string,
	image: PropTypes.string,
    className: 	PropTypes.string,
    style: PropTypes.string,
    show: PropTypes.any,
    seek: PropTypes.number,
    playerRef: PropTypes.any,

    onReady: PropTypes.func,
    onPlay: PropTypes.func,
    onEnded: PropTypes.func,
    onCast: PropTypes.func,

}

JwPlayer.defaultProps = {
    // image: false
  }
  

export default JwPlayer