import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import 'moment/locale/es';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faMoneyBillAlt, faInfoCircle, faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";

import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";
import { useAppContext } from "libs/contextLib";
import { Notification } from "libs/errorLib";
import Loader from 'components/Loader'
import Moment from "react-moment";
import { faSlideshare } from "@fortawesome/free-brands-svg-icons";
// import { useOrder } from "libs/ordersLib";
import './ShowMapTickets.css'

function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

const row_names = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V',/*'W',*/'X','Y','Z','AA','BB','CC','DD','EE','FF','GG','HH','II','JJ','KK','LL','MM','NN','OO','PP','QQ','RR','SS','TT','UU','VV','XX','YY','ZZ'];


export default function ShowMapTickets({ show, showTickets, showMap, readOnly=false, paymentType, showViewCart = true,  ...props}) {
    // const { show, viewerShow, notifyTicketChange, showAddToCart: showAddToCart = true, 
    //     quickBuyLink: quickBuyLink = false, ...otherProps } = props

    const history = useHistory();
    const [tickets, setTickets] = useState(null)
    const [totalTickets, setTotalTickets] = useState(0)
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderDiscountTotal, setOrderDiscountTotal] = useState(0);
    const [currency, setCurrency] = useState('GTQ')

    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedTicket, setSelectedTicket] = useState(null)
    const [selectedSection, setSelectedSection] = useState(null)
    const [showMapSeats, setShowMapSeats] = useState();
    const [selectedSeats, setSelectedSeats] = useState([])
    const [openTicketQuantity, setOpenTicketQuantity] = useState(0)

    const [mapLoading, setMapLoading] = useState(false)

    const { trackEvent } = useAnalytics();
    // const [order, setOrder] = useOrder(show._id)
    const { getCart, addCartItems, removeCartItems, removeCartSeats } = useCart()
    const { updateCartCounter } = useAppContext()

    

    useEffect( () => {
        if (!readOnly) {
            calculateTotals()
        }
        // setTickets(tickets)
        // setOrderTotal(0)
        // setTotalTickets(0)
        
        // if (notifyTicketChange) {
        //     notifyTicketChange(null, 0, 0, currency)
        // }
    }, [selectedSeats, openTicketQuantity])

    useEffect( () => {
        try {
            setMapLoading(true)
            setSelectedSeats([])
            setOpenTicketQuantity(0)

            if (show && selectedLocation && selectedSection) {
                loadShowMapSeats(show._id, selectedLocation._id, selectedSection._id)
            } else {
                setShowMapSeats([])
            }            
        } finally {
            setMapLoading(false)
        }

    }, [selectedLocation, selectedSection])


    
    async function loadShowMapSeats(show_uid, location_uid, section_uid) {
        let cart_uid = null
        try {
            const cart = await getCart()
            if (cart) cart_uid = cart._id
        } catch(e) {} finally {}

        let payload = {
            location: location_uid,
            section: section_uid,
            cart: cart_uid
        }
        const seats = await API.post("vivelo", `/shows/${show_uid}/map/seats`, 
            { body: payload }
        );
        setShowMapSeats(seats || [])
        const selected = []
        seats.forEach(s => {
            if (s.reserved) {
                selected.push(s)
            }
        })
        setSelectedSeats(selected)
    }

    async function registerCartItem(ticket, seat, addToCartOnly) {
        trackEvent({
            action: "Clicked Seat Reservation",
            category: "PurchaseFunnel"
        });
        
        const currency = show?.settings?.currency || 'GTQ'        
        const date = moment(show.show_date).format('ddd, LL - LT')
        let payment_type = 'CREDIT_CARD'

        const cartItem = {
            ticket_uid: ticket._id,
            type: 'TICKET',                
            show_uid: show._id,
            show_name: show.fullname,
            show_date: show.show_date,
            show_type: show.type,
            venue: show.venue,                
            title: `${show.fullname} (${date})`,
            description: ticket.fullname,
            note: ticket.note,
            ticket_type: ticket.type,
            image: `${show.main_image}`,
            quantity: seat ? 1 : parseInt(openTicketQuantity),
            location: {
                location_uid: selectedLocation._id,
                location: selectedLocation.name,
                section_uid: selectedSection._id,
                section: selectedSection.name,
                door: selectedSection.door || 'Unica'
            },
            
        }
        if (seat) {
            cartItem.location.row = seat.row_name
            cartItem.location.seat_uid = seat._id
            cartItem.location.seat = seat.name
            
            cartItem.seat = {
                _id: seat._id,
                location_uid: selectedLocation._id,
                section_uid: selectedSection._id
            }
        }
        console.log('cartItem', cartItem)
        console.log('items', ticket.bundle)
        if (ticket.bundle && ticket.bundle.length > 0) {
            cartItem.bundle_items = ticket.bundle.map(item => {
                const it = {
                    ticket_uid: item._id,
                    code: item.code,
                    type: 'TICKET',
                    show_uid: show._id,
                    show_name: show.fullname,
                    show_date: show.show_date,
                    show_type: show.type,
                    venue: show.venue,
                    description: item.fullname,
                    note: item.note,
                    ticket_type: item.type,
                    image: `${show.main_image}`,
                    quantity: item.ammount * 1
                }
                if (item.location) {
                    it.location = item.location
                }
                return it
            })
        }

        const items = [cartItem]
        
        try {
            if (seat) {
                seat.reserved = true
                seat.available = false
                seat.show_ticket_uid = ticket._id
            }

            if (paymentType == 'DEPOSIT') {
                if (ticket.payment_type.includes('ACCOUNT_BANK_DEPOSIT')) {
                    payment_type = 'ACCOUNT_BANK_DEPOSIT'
                } else if (ticket.payment_type.includes('BANK_DEPOSIT')) {
                    payment_type = 'BANK_DEPOSIT'
                }
            }

            const cart = await addCartItems({payment_type: payment_type, currency: currency, items: items })
            updateCartCounter()
            if (addToCartOnly) {
                if (seat) {
                    Notification('success', `El asiento ${seat.name} fue agregado a tu carrito`)
                }
            } else {
                history.push('/cart',{})
            }

            

        } catch(e) {
            if (seat) {
                seat.reserved = false
                seat.available = false
            }

            if (e?.response?.status == 400) {                
                Notification('error', 'No fue posible agregar los boletos al carrito')
                // history.push('/cart',{})
            } else {
                Notification('error', 'No fue posible agregar los boletos al carrito')
                // history.push('/cart',{})
            }
        }
        
    }
    

    async function removeCartSeat(seat) {
        try {
            const cart = await removeCartSeats({ seats: [seat._id] })
            updateCartCounter()

            seat.reserved = false
            seat.available = true

            if (seat) {
                Notification('success', `El asiento ${seat.name} fue removido de tu carrito`)
            }
                       
        } catch(e) {
            if (e?.response?.status == 400) {                
                Notification('error', 'No fue posible remover los boletos del carrito')
                // history.push('/cart',{})
            } else {
                Notification('error', 'No fue posible remover los boletos del carrito')
                // history.push('/cart',{})
            }
        }
    }
 
    function applyPriceRule(t) {
        console.log('RULE',t)
        if (t.price_rules && t.price_rules.length > 0 && t.price_rules[0].rule_type == 'quantity_discount') {
            const rule = t.price_rules[0]
            if (t.selected >= rule.quantity_min) {
                const discount_price = rule.item_discount_price
                const subtotal_price = discount_price > 0 ? t.selected * discount_price : 0
                return subtotal_price
            }
        }
        return 0
    }

    function calculateTotals() {
        let total = 0
        let discount_total = 0
        let count = 0
        let currency = show?.settings?.currency || 'GTQ'

        
        let updated = selectedSeats.map(s => {
            // const ticket = showTickets.find(t => t._id == selectedSection.show_ticket_uid)
            const  ticket = selectedTicket
            if (ticket.currency )
            total += ticket.currency == 'USD' ? ticket.price_usd : ticket.price 
            count += ticket.selected
            currency = ticket.currency || show?.settings?.currency || 'GTQ'

            // total += ticket.fee

            discount_total = applyPriceRule(ticket)
            
            return ticket
        })
        if (selectedSection?.ticket_type == 'VENUE_OPEN' && parseInt(openTicketQuantity) > 0) {
            // const ticket = showTickets.find(t => t._id == selectedSection.show_ticket_uid)
            const  ticket = selectedTicket
            total += (ticket.currency == 'USD' ? ticket.price_usd : ticket.price ) * parseInt(openTicketQuantity)
            count += parseInt(openTicketQuantity)
            currency = ticket.currency || show?.settings?.currency || 'GTQ'

            // total += ticket.fee

            discount_total = applyPriceRule(ticket)
        }
        setOrderTotal(total)
        setOrderDiscountTotal(discount_total)        
        setCurrency(currency)
        
    } 

    
    async function onSeatClick(seat, selected) {
        try {
            if (readOnly) return;

            if (seat.waiting) {
                console.log('double click')
                return
            }

            seat.waiting =  true

            if (selected) {
                // const ticket = showTickets.find(t => t._id == selectedTicket._id)
                const ticket = selectedTicket
                console.log('Seat',seat)
                await registerCartItem(ticket, seat, true)

                setSelectedSeats(prevState => {
                    return [...prevState, seat]
                })
            } else {
                // removeItem
                await removeCartSeat(seat)

                setSelectedSeats(prevState => {
                    console.log(seat, selected, prevState)
                    const items = [...prevState]
                    const r = items.filter(s => { return s.row != seat.row || s.col != seat.col })
                        return r
                    })
            }
        } finally {            
            seat.waiting = false
        }
    }

    function renderSelected() {
        const items = selectedSeats.map(s => {
            return <div>{s.name}</div>
        })
        return <div>
            {items}
        </div>
    }

    function renderSection() {
        if (selectedLocation && selectedSection) {
            switch (selectedSection.type) {
                case 'SEATS': {
                    if (showMapSeats && showMapSeats.length > 0) {
                        return renderSectionSeatsMap(selectedSection)
                    } else {
                        return <Loader />
                    }
                }
                case 'TABLE': {
                    if (showMapSeats && showMapSeats.length > 0) {
                        return renderSectionTableMap(selectedLocation, selectedSection)
                    } else {
                        return <Loader />
                    }
                }

                case 'OPEN': {
                    return renderSectionOpenSeats(selectedSection) 
                }
            }
        }
        
        
    }

    function handleOpenTicketSelection(e, ticket) {
        setOpenTicketQuantity(e.target.value)
    }
    async function handleCheckoutButton() {        
        history.push('/cart')
    }
    async function handleAddToCartButton() {
        
        const ticket = showTickets.find(t => t._id == selectedTicket._id)
        registerCartItem(ticket, null, false)
    }
    function validateOpenTicketSelection() {
        return openTicketQuantity > 0
    }
    function renderSectionOpenSeats(section) {

        const t = showTickets.find(t => t._id == selectedTicket._id)

        const now = moment.utc()
        const since = moment.utc(t.valid_since);
        const thru = t.valid_thru ? moment.utc(t.valid_thru) : now

        t.since = since
        t.thru = thru
        t.expired = now.isAfter(thru)
        t.can_buy = now.isSameOrAfter(since)
        let options = []
        for(let i = 0; i <= 10 && i <= t.real_available; i++) {
            options.push(<option value={`${i}`} key={`${t.ticket_id}-${i}`}>{`${i}`}</option>)
        }

        let buy = ''
        if (t.expired) {
            buy = <span><Badge variant="secondary">No Disponible</Badge></span>
        } else if (t.can_buy) {
            buy = t.real_available > 0 
                ? <div className="selection">Cantidad: <Form.Control as="select" value={openTicketQuantity} onChange={(e) => handleOpenTicketSelection(e, t)} >
                    { options }                              
                </Form.Control></div>
                : <Badge variant="secondary">AGOTADOS</Badge>                        
        } else {
            buy = <span className="presale-date">A partir del <Moment local format="lll">{t.since}</Moment></span>
            // buy = <span><Badge variant="success">Próximamente</Badge></span>
        }
        return <div className="venue_map">
            <div className="venue_section open">
                <div style={{textAlign:'center'}}>** Asientos no numerados **</div>
            {!readOnly && <>{buy}
                <Button
                    disabled={!validateOpenTicketSelection()}
                    onClick={handleAddToCartButton}
                ><FontAwesomeIcon icon={faPlus} /> Agregar al Carrito</Button>
                </>}
            </div>
        </div>
    }

    function renderSectionSeatsMap(section) {
        try {        
            const col_headers = []
            const rows = []
            const seat_padding = 2;

            const grid_style = { gridTemplateColumns: `repeat(${section.map_columns + 1} 25px` }

            col_headers.push(<div className="numbering">&nbsp;</div>)
            for(var row = 0, row_name_index = section.row_start; row < section.map_rows; row++, row_name_index += section.row_step ) {
                const row_name = row_names[row_name_index]            

                
                const table_row = [] 
                table_row.push(<div className="numbering" >{row_name}</div>)

                for(var col = 0, col_number_index = section.col_start; col < section.map_columns; col++, col_number_index += section.col_step ) {
                    const number = col_number_index + 1
                    const col_name = pad(number, seat_padding)
                    const name = row_name + col_name
                    
                    
                        if (row == 0) {                            
                            col_headers.push(<div className="numbering">{section.show_col_names ? col_name : ''}</div>)
                        }                    

                    let tableSeat = null
                    let seat = showMapSeats.find(s => {
                        return (s.row == row && s.col == col);
                    })
                    let seat_input = ''
                    if (seat) {
                        if (seat.waiting) {
                            seat_input = <div className="seat loading" title={seat.name} ></div>
                        } else if (seat.available) {
                            seat_input = <div className={`seat available ${readOnly && 'readonly'}`} title={`${seat.name}: Disponible`} onClick={(e) => onSeatClick(seat, true)} ></div>
                        } else {
                            if (seat.reserved) {
                                seat_input = <div className="seat reserved" title={`${seat.name}: Reservado`} onClick={(e) => onSeatClick(seat, false)} ></div>
                            } else {
                                seat_input = <div className="seat sold" title={`${seat.name}: No disponible`} ></div>
                            }
                        }                    

                        tableSeat = seat_input
                    } else {
                        tableSeat = <div></div>
                    }
                    
                    table_row.push(tableSeat)
                }

                rows.push(table_row)
            }            

            return <div className="venue_map">
                    <div className="venue_section seats" style={{gridTemplateColumns: `repeat(${section.map_columns + 1}, 20px`}}>
                        {col_headers}
                        {rows}
                    </div>
                    {/* <div className="">
                    {renderSelected()}
                    </div> */}
                    

                </div>

        } finally {
        }
    }

    function renderSectionTableMap(location, section) {
        try {        
            const col_headers = []
            const rows = []
            const seat_padding = 2;

            const grid_style = { gridTemplateColumns: `repeat(${section.map_columns + 1} 25px` }

            col_headers.push(<div className="numbering">&nbsp;</div>)
            for(var row = 0, row_name_index = section.row_start; row < section.map_rows; row++, row_name_index += section.row_step ) {
                const row_name = row_names[row_name_index]            
                const table_row = []                 

                for(var col = 0, col_number_index = section.col_start; col < section.map_columns; col++, col_number_index += section.col_step ) {
                    const number = col_number_index                
                    const col_name = pad(number, seat_padding)
                    const name = row_name + col_name
                    const is_last_row = (row == (section.map_rows - 1))
                    const is_odd_seats = (section.total_seats % 2 == 1)
                    
                    let tableSeat = null
                    let seat = showMapSeats.find(s => {
                        return (s.row == row && s.col == col);
                    })
                    let seat_input = ''
                    let seat_label_left
                    let seat_label_right
                    let seat_single = ''
                    if (seat) {

                        // left label
                        if (col == 0) {
                            seat_label_left = <div className="numbering">{seat.name}</div>
                        }

                        if (col == 0 && is_last_row && is_odd_seats) {
                            seat_single = 'single'
                        }

                        if (seat.waiting) {
                            seat_input = <div className={`seat loading ${seat_single}`} title={seat.name} ></div>
                        } else if (seat.available) {
                            seat_input = <div className={`seat available ${readOnly && 'readonly'} ${seat_single}`} title={seat.name} onClick={(e) => onSeatClick(seat, true)} ></div>
                        } else {
                            if (seat.reserved) {
                                seat_input = <div className={`seat reserved ${seat_single}`} title={seat.name} onClick={(e) => onSeatClick(seat, false)} ></div>
                            } else {
                                seat_input = <div className={`seat sold ${seat_single}`}></div>
                            }
                        }
                        if (col == 0) {
                            if (is_last_row && is_odd_seats) {

                            } else {
                            seat_label_right = <>
                                <div className="" style={{background:'gray', width:40}}></div>                               
                                </>
                            }
                        }

                        // right label
                        if (col == 1) {
                            seat_label_right = <div className="numbering">{seat.name}</div>
                        }
                        

                        tableSeat = <>
                            {seat_label_left}
                            {seat_input}
                            {seat_label_right}
                        </>
                        console.log(tableSeat)
                    } else {
                        tableSeat = <><div></div><div></div></>
                    }
                    
                    
                    table_row.push(tableSeat)
                }

                rows.push(table_row)
            }            

            return <div className="venue_map">
                    <div className="venue_section table" 
                    // style={{gridTemplateColumns: `repeat(${section.map_columns + 4}, 20px`}}
                    >
                        {rows}
                    </div>
                    {/* <div className="">
                    {renderSelected()}
                    </div> */}
                    

                </div>

        } finally {
        }
    }

    function onLocationSelected(e) {        
        // console.log(showMap.locations, e.target.value)
        
        const [location_uid, ticket_uid] = e.target.value.split('-')
        const location = showMap.locations.find(l => l._id == location_uid)
        const ticket = showTickets.find(t => t._id == ticket_uid)
        
        setSelectedSeats([])
        setSelectedSection(null)
        setSelectedLocation(location)
        setSelectedTicket(ticket)

        if (location && location.sections.length == 1) {
            setSelectedSection(location.sections[0])
        } else {
            setSelectedSection(null)
        }
    }

    function renderLocationSelect() {
        const locationOptions = [
            <option key="location_select">-- Selecciona una localidad --</option>
        ]
        
        showMap.locations
            .filter(l => l.available )
            .map(l => {  
                // console.log('location tickets', l.show_tickets)
                if (readOnly) {
                    locationOptions.push(<option key={`${l._id}`} value={`${l._id}`}>{l.name}</option>)
                } else {
                    l.show_tickets.map(lt => {
                        
                        const ticket = showTickets.find(t => {                
                            return t._id == lt
                        })
                        if (ticket) {
                            const price = ticket.currency == 'USD' ? ticket.price_usd_text : ticket.price_text
                            const name = `${l.name} - (${ticket.fullname} ${price})`
                            locationOptions.push(<option key={`${l._id}-${ticket._id}`} value={`${l._id}-${ticket._id}`}>{name}</option>)
                        }
                    })          
                }
                
            })

        const selectedValue = readOnly ? selectedLocation?._id : `${selectedLocation?._id}-${selectedTicket?._id}`
        return <div>
                {/* <select onChange={onLocationSelected}> */}
                <Form.Control as="select" value={selectedValue} onChange={onLocationSelected}>
                    {locationOptions}
                </Form.Control>
                    
                {/* </select> */}
            </div>
    }

    function onSectionSelected(e) {
        const section = selectedLocation.sections.find(s => s._id == e.target.value)
        setSelectedSeats([])
        setSelectedSection(section)
    }

    function renderSectionsSelect() {
        const sectionOptions = [
            <option key="section_select" value="">-- Selecciona una seccion --</option>
        ]
        
        selectedLocation.sections
            .filter(s => s.available )
            .map(s => {
                sectionOptions.push(<option key={s._id} value={s._id}>{s.name}</option>)
            })

        return <div>                
                <Form.Control as="select" value={selectedSection ? selectedSection._id : ''} onChange={onSectionSelected}>
                    {sectionOptions}
                </Form.Control>
            </div>
    }

    function validateTickets() {
        return selectedSeats.length > 0
    }

    function renderTotal() {
        if (orderDiscountTotal > 0) {
            return <><span style={{textDecoration:'line-through'}}>{currency} {orderTotal}</span> {currency} {orderDiscountTotal}</>
        } else {
            return <>{currency} {orderTotal}</>
        }

    }
    
    function renderMapImage() {
        let imgsrc = showMap.map_image
        if (selectedLocation && selectedLocation.map_image) {
            imgsrc = selectedLocation.map_image 
        }

        return <div className="venue_map_image">
            <img style={{width:'100%'}} src={imgsrc} />
        </div>
    }

    function render() {            
        console.log('showTickets', showTickets)

        return showMap && showTickets && <div className="venue_map_selection">
            {renderLocationSelect()}
            {selectedLocation && selectedLocation.sections.length > 1 && showMapSeats &&
                renderSectionsSelect()
            }
            {renderSection()}            

            <div className="tickets-total" style={{marginTop: 20}}>
                <div style={{textAlign: 'center'}}>TOTAL {renderTotal()}</div>
                { showViewCart && 
                    <div style={{textAlign: 'center'}}><Button variant={props.addToCartButtonVariant || "info"} style={{width:200}}                          
                        // disabled={!validateTickets()}
                        onClick={handleCheckoutButton}><FontAwesomeIcon icon={faShoppingCart} /> Ver Carrito</Button></div>                    
                }
            {/* <Row className="tickets-footer"><Col className="note">**Recibirás un correo por cada boleto en el correo electrónico registrado en el siguiente formulario</Col></Row> */}
            
            </div>
            { renderMapImage() }
        </div>
    }
    return render()
}