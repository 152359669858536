import React, { useState, useEffect } from "react";
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, Container } from "react-bootstrap";

import Loader from 'components/Loader'
import CartSummaryBox from "components/cart/CartSummaryBox"  
import CartTimer from "components/cart/CartTimer"
import Timer from "components/Timer"

import { onError } from "libs/errorLib";
import { useCart } from "libs/cartLib"
import { useAppContext } from "../libs/contextLib";
import { LinkContainer } from "react-router-bootstrap";


import 'components/cart/Cart.css'

export default function CartSummary(props) {
    const { info, ...otherProps } = props
    
    const [isLoading, setIsLoading] = useState(false);
    const [cart, setCart] = useState(null)

    const { getCart, getTotalItems, removeCartItems } = useCart()

    useEffect(() => {        
        onLoad()         
        
    }, [cart]);

    async function onLoad() {
        let cart
        try {
            cart = await getCart()
            setCart(cart)
        } catch (e) {
            // no cart
        }
    }


    function render() {
        return !isLoading ? <div class="content content-dark">
        <Container>
        <Row><Col>
        <h1 className="title">Carrito de Compras</h1>
        <CartTimer />
            
        <CartSummaryBox />
        <div className="cart-summary-actions">
            <LinkContainer to="/">
                <Button variant="light" style={{width:200}}                     
                    ><FontAwesomeIcon icon={faChevronLeft} /> Seguir comprando</Button>
            </LinkContainer>
            { getTotalItems(cart) > 0 && <LinkContainer to="/cart/checkout">
                <Button variant="primary" style={{width:200}}                     
                    > Finalizar la Compra <FontAwesomeIcon icon={faChevronRight} /></Button>
            </LinkContainer> }
        </div>
        </Col></Row>
        </Container>
    </div>
    : <Loader />
    }

    return render()
}