import { useState } from "react";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { base64encode, base64decode } from 'nodejs-base64';


import { onError } from "../libs/errorLib";

async function validateUser(fullname, email, password, roles) {
    try {
      let validUser = await API.post("vivelo", "/auth/users/exists", {
          body: {
              email: email,
          }
      });
      if (validUser != null) {
        return validUser.userExists
      } else {
        throw new Error("Error checking user exists")
      }      

    } catch (e) {
      onError(e)      
      throw e
    }

  }

export function useAuth(initSession, initIsAuthenticating, initIsAuthenticated) {
    const [session, setSession] = useState(initSession);
    const [isAuthenticating, setIsAuthenticating] = useState(initIsAuthenticating);
    const [isAuthenticated, userHasAuthenticated] = useState(initIsAuthenticated);    

    const sessionCookie = 'session'

    async function validateSession() {        
        const sess = Cookie.get(sessionCookie)
        // console.log('session timer', sess)
        if (!sess) return true
        
        let sessObj = JSON.parse(base64decode(sess))
        if (!sessObj) return true

        let sessionExpired = false
        try {
            let touched = await API.put("vivelo", `/auth/sessions/${sessObj.session._id}/touch`, {})            
        } catch(e) {
            if (e.response && e.response.status === 440) { // session expired
                sessionExpired = true
            } else {
                onError(e)
            }
        }

        if (sessionExpired) {
            console.log('session expired')
            Cookie.remove(sessionCookie);
            userHasAuthenticated(false);
            setSession(null);
            return false
        } else {
            return true
        }
        
    }

    async function getSession() {
        try {
            setIsAuthenticating(true)

            if (session)
                return session
            
            const sess = Cookie.get(sessionCookie)
            // console.log("sess",sess)
            if (sess != null) {
                let sessionExpired = false
                let sessObj = JSON.parse(base64decode(sess))
                // console.log("sessObj",sessObj)
                if (sessObj) {
                    try {
                        console.info("getting touch status", sessObj)
                        let touched = await API.put("vivelo", `/auth/sessions/${sessObj.session._id}/touch`, {})
                        sessObj.session = touched
                        Cookie.set(sessionCookie, base64encode(JSON.stringify(sessObj)), { expires: 1 })                  
                    } catch(e) {                        
                        console.error("touch status error", e.response, e)
                        if (e.response && e.response.status === 440) { // session expired
                            sessionExpired = true
                        } else {
                            console.error(e)
                            //sessionExpired = true
                        }                        
                    }
                } else {
                    sessionExpired = true
                }

                if (sessionExpired) {
                    console.info('session expired')
                    Cookie.remove(sessionCookie);
                    userHasAuthenticated(false);
                    setSession(null);
                } else {
                    setSession(sessObj)
                    userHasAuthenticated(true);
                    return sessObj
                }
            }
            return null
        } finally {
            setIsAuthenticating(false)
        }
    }

    async function login(email, password) {
        try {
            setIsAuthenticating(true)        
    
            let result = await API.post("vivelo", "/auth/login", {
                body: {
                    username: email,
                    password: password,
                    role: 'Viewer',
                    forceLogin: true
                }
            });
            console.info('Login successful')
    
            let viewer = await API.get("vivelo", `/viewers/user/${result.user._id}`);
        
            const session = {
                user: result.user,
                viewer: viewer,
                session: result.session
            }
            Cookie.set(sessionCookie, base64encode(JSON.stringify(session)), { expires: 1 })
            setSession(session)
            userHasAuthenticated(true);  
            setIsAuthenticating(false)
    
            return session   
      
          } catch (e) {
              onError(e);
              setIsAuthenticating(false)
              throw e
          }
    }

    async function logout() {
        if (isAuthenticated && session) {
            try {
                const id = session.session._id
                const result = await API.put('vivelo',`/auth/sessions/${id}/logout`, )
                console.log("result", result)

                Cookie.remove(sessionCookie);
                setSession(null);
                userHasAuthenticated(false);
                return true;
            } catch(e) {
                if (e.response.status === 400) { // session expired
                    console.log('session expired')
                    Cookie.remove(sessionCookie);
                    userHasAuthenticated(false);
                    setSession(null);                                
                } else {
                    console.log(e.response)                    
                }
                return false
            }
        }
    }

    async function signup(fullname, email, password, roles) {
        try {
            const userExists = await validateUser(fullname, email, password, roles);
            if (userExists) {
                return false    
            }

            let user = await API.post("vivelo", "/auth/users", {
                body: {
                    username: email,
                    password: password,
                    email: email,
                    fullname: fullname,
                    roles: roles
                }
            });
            console.log(user)
    
            let viewer = await API.post("vivelo", "/viewers/viewers", {
                body: {
                    user_uid: user._id
                }
            });
            console.log(viewer)
    
            let userSession = await API.post("vivelo", "/auth/sessions", {
                body: {
                    user_uid: user._id,
                    username: user.username,
                    role: 'Viewer'
                }
            });
            console.log(userSession)
    
            const session = {
                user: user,
                viewer: viewer,
                session: userSession
            }
            Cookie.set(sessionCookie, base64encode(JSON.stringify(session)), { expires: 1 })
            setSession(session)
            userHasAuthenticated(true);     
    
            return session; 
        } catch (e) {
            onError(e);
            throw e
        }  
    }

    async function updateUser(user_uid, body) {
        try {
            setIsAuthenticating(true)        
    
            const result = await API.put('vivelo',`/auth/users/${user_uid}`, {
                body: body
            })

            session.user = result;

            Cookie.set(sessionCookie, base64encode(JSON.stringify(session)), { expires: 1 })
            setSession(session)
            setIsAuthenticating(false)
    
            return session   
      
          } catch (e) {
              onError(e);
              setIsAuthenticating(false)
              throw e
          }
    }

    
    return [isAuthenticating, setIsAuthenticating, isAuthenticated, userHasAuthenticated, getSession, setSession, signup, login, logout, updateUser, validateSession ];    
}