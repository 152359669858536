import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import ReactPlayer from 'react-player'


import Loader from 'components/Loader'

import ShowInfo from "components/shows/ShowInfo";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./India.css"

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const children = await loadChildShows();            
            setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=ALL&visible=-1`);        
        return children && children.items ? children.items : []
    }

    function renderVideoPlayer(videoUrl) {
        return <>
            <div className="video-player">
                <ReactPlayer
                    className="react-player"
                    url={videoUrl}
                    controls
                    playsinline                    
                    volume={0.8}
                    width='100%'
                    height='100%'
                    // autoplay={true}
                    //pip={true}
                    // muted={true}
                    playing={true}
                    
                />
            </div>        
            {/* { renderPlayerControls() } */}
            </>
             
      }

    return (
        !isLoading && show ? <>
        <div className="site-india2023">
            <div className="header">
                <div className="title-container">
                    <div className="title-container-orange"><div className="title-container-yellow">
                    
                    <div className="title" style={{marginTop:-40}}>
                        <img className="india-logo" src="https://assets.viveloonline.com/shows/semanacineindia202301/logo2.png" />
                        <div className="india-title">
                            <h1>Semana de cine <br />de la India 2023</h1>
                            <h2>Del 1 al 7 de febrero • 8pm</h2>
                        </div>                        
                    </div>
                    <div className="movie-collage">
                            <img className="desktop" src="https://assets.viveloonline.com/shows/semanacineindia202301/movie collage horizontal.png" />
                            <img className="mobile" src="https://assets.viveloonline.com/shows/semanacineindia202301/movie collage vertical.png" />
                    </div>                        

                    </div>
                    </div>
                </div>                
            </div>            
            <div className="body">
            <div className="description">
                <ShowInfo info={show.description} />                
                <div className="logos">
                    <div><img src="https://assets.viveloonline.com/shows/semanacineindia202301/vivelo.png" /></div>
                    <div><img src="https://assets.viveloonline.com/shows/semanacineindia202301/shemaroo.png" /></div>
                    <div><img src="https://assets.viveloonline.com/shows/semanacineindia202301/banner75.png" /></div>
                    <div><img src="https://assets.viveloonline.com/shows/semanacineindia202301/banner50.png" /></div>
                </div>
                {<div className="trailer">
                <label>Mensaje del Embajador de India</label>
                {renderVideoPlayer("https://vimeo.com/794900844")}
                {/* {ReactHtmlParser('<iframe src="https://player.vimeo.com/video/452880459" width="350" height="196" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>')} */}
            </div> }
            </div>
                <h1>Cartelera</h1>
                <div className="events">
                    { childShows && childShows.map(s => {                        
                        return <Link key={`name-${s._id}`} to={`/semanacineindia2023/${s.settings.path}`}>
                        <div className="movie">
                        <div className="poster" ><img src={s.settings.poster_image} /></div>
                        <div className="info">
                            <div>
                                <div className="title"><b>{s.settings.title}</b> | {s.settings.year}</div>
                                <div className="genre">{s.settings.genre}</div>
                            </div>
                            <div>
                                <div className="month">Feb</div>
                                <div className="date">{s.settings.date}</div>
                            </div>
                        </div>
                    </div></Link>
                    })}
                    
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}