import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";


import Loader from 'components/Loader'

import ShowInfo from "components/shows/ShowInfo";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./India.css"

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const children = await loadChildShows();            
            setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=ALL`);        
        return children && children.items ? children.items : []
    }

    return (
        !isLoading && show ? <>
        <div className="site-india2021">
            <div className="header">
                <div className="title-container">                    
                    <div className="title">
                        <img className="india-logo" src="https://assets.viveloonline.com/shows/festivalindia2021/logo2.png" />
                        <div className="india-title">
                            <h1>Festival de cine <br />de la India 2021</h1>
                            <h2>Del 8 al 15 de noviembre, 8pm</h2>
                        </div>                        
                    </div>
                    <div className="movie-collage">
                            <img className="desktop" src="https://assets.viveloonline.com/shows/festivalindia2021/movie collage horizontal.png" />
                            <img className="mobile" src="https://assets.viveloonline.com/shows/festivalindia2021/movie collage vertical.png" />
                        </div>                        
                </div>                
            </div>            
            <div className="body">
            <div className="description"><ShowInfo info={show.description} /></div>
                <h1>Cartelera</h1>
                <div className="events">
                    { childShows && childShows.map(s => {                        
                        return <Link key={`name-${s._id}`} to={`/festivalcineindia2021/${s.settings.path}`}>
                        <div className="movie">
                        <div className="poster" ><img src={s.settings.poster_image} /></div>
                        <div className="info">
                            <div>
                                <div className="title"><b>{s.settings.title}</b> | {s.settings.year}</div>
                                <div className="genre">{s.settings.genre}</div>
                            </div>
                            <div>
                                <div className="month">Nov</div>
                                <div className="date">{s.settings.date}</div>
                            </div>
                        </div>
                    </div></Link>
                    })}
                    
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}