import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import 'moment/locale/es';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";

import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";
import { useAppContext } from "libs/contextLib";
// import { useOrder } from "libs/ordersLib";
// import './Loader.scss'

export default function ShowRegistration(props) {
    const { show, viewerShow, ...otherProps } = props

    const history = useHistory();
    const [tickets, setTickets] = useState(null)
    const [totalTickets, setTotalTickets] = useState(0)
    const [orderTotal, setOrderTotal] = useState(0);

    const { trackEvent } = useAnalytics();
    // const [order, setOrder] = useOrder(show._id)
    const { getCart, addCartItems, removeCartItems, resetCart } = useCart()
    const { updateCartCounter } = useAppContext()

    useEffect( () => {
        setTickets(props.tickets)

    }, [props.tickets])
            
    
    async function handleRegisterButton() {
        trackEvent({
            action: "Clicked Register Now Button",
            category: "PurchaseFunnel"
        });

        await resetCart()
        
        const currency = 'GTQ'

        const items = tickets.filter(t => t.payment_type.includes('REGISTRATION') ).map(t => {
            const date = moment(show.show_date).format('YYYY/MM/DD LT')

            const cartItem = {
                ticket_uid: t._id,
                type: 'TICKET',
                show_uid: show._id,
                show_name: show.fullname,
                show_date: show.show_date,
                show_type: show.type,
                title: `${show.fullname} (${date})`,
                description: t.fullname,
                note: t.note,
                ticket_type: t.type,
                image: `${show.main_image}`,
                quantity: 1
            }
            if (t.location) {
                cartItem.location = t.location
            } else {
                cartItem.location = {}
            }

            return cartItem
        })
        await addCartItems({ currency: currency, items: items })
        updateCartCounter()
        history.push('/cart/register')
        
        // setOrder(show._id, reservations)

        // setIsOrderView(false)
        // setIsPaymentView(true)
        
    }

    function renderTickets() {
        
        const showTickets = ['ACTIVE','STARTED','LIVE','REPLAY','HIDDEN','TEST'].includes(show.status);

        if (['NEW','CLOSED'].includes(show.status)) {
            return null;
        }

        return showTickets && tickets && tickets.length > 0 && <>                
            <Button variant={props.variant || 'danger'} style={props.style || {width:200}} className={props.className || ''}
            onClick={handleRegisterButton}>{props.icon || <><FontAwesomeIcon icon={faTicketAlt} /> </>}{props.buttonText || 'Registrarme'}</Button>
            </>

    }

    return renderTickets()
}