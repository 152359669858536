import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faExpandArrowsAlt, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import Chat from 'components/FirebaseChat'
import * as Sentry from '@sentry/react';
import { deviceDetect } from "react-device-detect";

import { onError } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { getIpGeo } from 'libs/ipgeo';
import { useShowStats } from 'libs/statsLib'
import { db } from 'libs/firebase'
import { useAnalytics } from "libs/analytics";
import Cookies from "js-cookie";
import config from "config"
// import { useWindowSize } from "../libs/windowSize";


import "./Fafidess2021.css";
import { ToggleButton } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";

export default function ShowPlayer(props) {
  //const file = useRef(null);
  const { isAuthenticated, getSession } = useAppContext();
  const { id } = props;
// const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const [session, setSession] = useState(null);
  const [chatSession, setChatSession] = useState(null);
  const [show, setShow] = useState(null);
  const [code, setCode] = useState(null);
  const [showCode, setShowCode] = useState(null);  
  const [viewerShow, setViewerShow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [playerSettings, setPlayerSettings] = useState({
    videoUrl: null,
    playsInline: true,
    played: 0,
    loaded: 0,
    muted: false,
    controls: true,
    loop: false,
    playing: false,  
    light: false
  });
  const [playlistSelectedId, setPlaylistSelectedId] = useState(null);
  const [loaded, setLoaded] = useState(0);
  const [ended, setEnded] = useState(false);
  const [curtain, setCurtain] = useState(null);
  const [muted, setMuted] = useState(true);
  const [ipgeo, setIpgeo] = useState(null)
  const [mobileChatUrl, setMobileChatUrl] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [codeError, setCodeError] = useState(false);
  
  const [ initShowStat, registerShowStat ] = useShowStats() 
  

  const containerRef = useRef(null)
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const interactionsRef = useRef(null)
  const codeInputRef = useRef(null)

//   const size = useWindowSize()
//   const childRef = useRef(null)

  const [chatHeight, setChatHeight] = useState(null);
  const [messageHeight, setMessageHeight] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [contentHeight, setContentHeight] = useState(null);  
  const [playerHeight, setPlayerHeight] = useState(null);
  const [interactionsMargin, setInteractionsMargin] = useState(0);

//   let session = null
  const stats = {
    show_uid: id,
    device: deviceDetect()            
  }

    useEffect(() => {

        

        async function loadShow() {        
            const show = await API.get("vivelo", `/shows/${id}`);
            if (show) {
                setShow(show)
                // console.log(['LIVE','REPLAY'].includes(show.status), show)            
                if (['ACTIVE','LIVE','REPLAY','TEST'].includes(show.status)) {
                    setEnded(false)
                }
                if (['NEW','SOON','CLOSED','CANCELLED'].includes(show.status)) {
                    history.push(`/shows/${id}`)
                }
            }
            return show
        }
        async function loadCode(show, code) {
            try {
                // console.log('load code ', code)
                // setShowCode(code)
                // return code
                const vcode = await API.post("vivelo", `/viewers/codes/validate`, {
                    body: {
                        code: code,
                        show_uid: show._id
                    }
                });        
                if (vcode) {
                    setShowCode(vcode)
                }
                return vcode
            } catch (error) {
                console.log(error)
                return null
            }
        }
        async function loadViewerShow(viewer_id, show_id) {
            try {
                const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
                if (vShow) {
                    setViewerShow(vShow)                
                }
                return vShow
            } catch (error) {
                console.log(error)
                return null
            }
        }
    
        function registerAccessStats(sess, show_uid, code) {
            const timestamp = new Date();            
    
            const stat = {
                user_uid: sess?.session?.user_uid,
                viewer_uid: sess?.viewer?._id,
                username: sess?.user?.fullname,
                show_uid: show_uid,
                code: code,
                timestamp: timestamp.toISOString()
            }
    
            db.ref(`show-access/${show_uid}/stats`).push(stat)
        }
    
        let accessInterval = null

    async function onLoad() {        
        const searchParams = new URLSearchParams(location.search);        
        let code = searchParams.get('code')
        if (code) {
            code = code.toUpperCase()
        }          
        // setCode(pcode)

        // if (searchParams.get('code')) {
        //     code = 
        //     //setCode(code)
        // }
        
        // const stats = await initShowStat(id)
        // const stats = initShowStat(id)
        try {               
            setIsLoading(true)

            Sentry.addBreadcrumb({category: "player", message: "Loading session", level: Sentry.Severity.Info });
            let sess = await getSession()
            setSession(sess)        
            Sentry.addBreadcrumb({category: "player", message: "Session Loaded", data: sess, level: Sentry.Severity.Info, }); 

            Sentry.addBreadcrumb({ category: "player", message: "Loading Show", level: Sentry.Severity.Info, });
            let show = await loadShow();            
            if (!show || (show && ['CLOSED','CANCELLED'].includes(show.status) )) {
                history.push(`/shows/${id}`)
            }
            if (show?.settings?.playlist) {
                const p = show.settings.playlist.find(p=>p.default == true)
                setPlaylistSelectedId(p._id)
            }
            Sentry.addBreadcrumb({ category: "player", message: "Show Loaded", level: Sentry.Severity.Info, });


            console.log('loading ipgeo')
            const ipgeo = await getIpGeo()
            if (ipgeo) {
                setIpgeo(ipgeo)
                stats.location = { 
                    country_code: ipgeo ? ipgeo.country_code2 : null,
                    country_name: ipgeo ? ipgeo.country_name : null,
                    city: ipgeo ? ipgeo.city : null,
                    ip: ipgeo ? ipgeo.ip : null,
                }
            }

            if (sess) {
                stats.session_uid = sess.session._id
                if (sess.user) {
                    stats.user_uid = sess.user._id
                }
            } else {
                stats.session = Cookies.get('sessionID')
            }

            if (show?.settings?.nologin) {

                console.log('loading code',code)
                let vcode = await loadCode(show, code)
                if (vcode) {
                    stats.code = code
                    
                    // register for events
                    db.ref(`show-events/${id}`).on('value', (snapshot) => {
                        handleShowEvents(show, snapshot.val())
                    })

                } else {
                    // redirect
                    console.log('no code')
                    if (code) {
                        setCodeError(true)
                    }                    
                    code = null
                    return
                }

            } else {
                let vShow = null

                if (isAuthenticated) {
                    // console.log(show)
                    // console.log(sess)

                    Sentry.addBreadcrumb({ category: "player", message: "Loading Viewer Show", data: { viewer: sess.viewer._id, show: show._id }, level: Sentry.Severity.Info,});                    
                    stats.viewer_uid = sess.viewer._id
                    vShow = await loadViewerShow(sess.viewer._id, show._id)
                    if (vShow) {
                        code = vShow.payment_info.code
                        stats.viewer_show_uid = vShow._id
                    }
                    stats.code = code

                    Sentry.addBreadcrumb({ category: "player", message: "Viewer Show Loaded", level: Sentry.Severity.Info, });

                    db.ref(`show-events/${id}`).on('value', (snapshot) => {
                        handleShowEvents(show, snapshot.val())
                    })
                } 

                if (!vShow) {
                    if (code) {                        
                        history.push(`/activate?code=${code}`)
                    } else {
                        history.push(`/shows/${id}`)
                    }
                    return;
                }
                
            }


            const unique = Math.random()
            db.ref(`show-access/${id}/codes/${code}`).on('value', (snapshot) => {
                console.log(snapshot.val(), unique)
                if (snapshot.val() != unique) {
                    // alert('Busted!')
                    setShowMessage(true)
                }
            })
            db.ref(`show-access/${id}/codes/${code}`).set(unique)


            const accessRef = db.ref(`show-access/${id}/connected/${code}`);
            accessRef.onDisconnect().remove()
            accessRef.set(true)


            Sentry.addBreadcrumb({ category: "player", message: "Setting ChatSession", level: Sentry.Severity.Info, });

            const chatSessionInfo = {
                user_uid: sess?.session?.user_uid,
                username: sess?.user?.fullname,
                show_uid: id,
            }
            if (sess?.viewer?._id) {
                chatSessionInfo.viewer_uid = sess.viewer._id
            }
            setChatSession(chatSessionInfo)

            accessInterval = setInterval(function() {
                console.log('pinging')
                registerAccessStats(sess, id, code)
            }, 3 * 60 * 1000);

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
            console.log('stats', id, location, stats)

            if (stats.session_uid || stats.session) {
                stats.type = 'player'
                stats.action = 'loaded'
                registerShowStat(id, stats)

                // console.log(stats)
            }
        }
    }

    onLoad();

    }, [id, location]);

    useEffect(() => {
        function handleResize() {
            if ( containerRef.current && headerRef.current && interactionsRef.current ) {
                // console.log(window.innerWidth)
                //             
                if (window.innerWidth >= 900) {
                    let playerH = (window.innerWidth - 350) * 446/758 + 10
                    let chatH = playerH - 50
                    setChatHeight(chatH)
                    setInteractionsMargin(0)                    
                } else {
                    let playerH = window.innerWidth * 1080/1920 //446/758 
                    // let chatH = containerRef.current.offsetHeight - playerH - headerRef.current.offsetHeight - 100;// - 46;                    
                    let chatH = 445
                    setChatHeight(chatH)
                    setInteractionsMargin(playerH+headerRef.current.offsetHeight)
                    
                }
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => window.removeEventListener('resize', handleResize);
    })


    function loadCurtainVideo(curtain) {
        //    setVideoUrl('https://vimeo.com/516520939')
        setPlayerSettings(settings => {
            settings.videoUrl = curtain.video_url
            settings.played = 0
            settings.loaded = 0
            settings.muted = false
            settings.controls = true
            settings.loop = true
            settings.playing = true
            settings.light = false
            return settings
        })
    }

    function loadVideo(show, playlistSelectedId) {

        let video_url = show.video_url
        let playing = false
        if (show?.settings?.playlist) {            
            if (playlistSelectedId) {
                const p = show.settings.playlist.find(p => p._id == playlistSelectedId)
                video_url = p.video_url
                console.log('selected', playlistSelectedId, video_url)
            } else {
                const p = show.settings.playlist.find(p => p.default == true)
                video_url = p.video_url
                console.log('default', p, video_url)
            }
            playing = true
        }

        console.log(`loading video player. url: ${video_url}`)
          //setVideoUrl(`https://vimeo.com/${show.video_id}`)
          //setVideoUrl("https://vimeo.com/417491036")
          
          setPlayerSettings(settings => {
            settings.videoUrl = `${video_url}`
            settings.played = 0
            settings.loaded = 0
            settings.muted = false
            settings.controls = true
            settings.loop = false
            settings.playing = playing
            settings.light = false
            return settings
        })

      }
    function unloadVideo() {
        setEnded(true)
        setPlayerSettings(settings => {
            settings.videoUrl = null
            settings.played = 0
            settings.loaded = 0        
            return settings
        })
        
    }

    function handleShowEvents(show, event) {
        console.log('showEvent',event, show)    
        
        if (!event || !show) return
        
        if (event.event == 'statusChange') {        
            if (event.data.type == 'status') {
                switch (event.data.status) {
                    case 'start': {
                        loadVideo(show, playlistSelectedId)
                        setCurtain(false)
                        setEnded(false)
                        return;
                    }
                    case 'restart': {
                        setPlayerSettings(settings => {
                            settings.videoUrl = null
                            settings.played = 0
                            settings.loaded = 0        
                            return settings
                        })                    
                        loadVideo(show, playlistSelectedId)
                        setCurtain(false)
                        setEnded(false)
                        return;
                    }
                    case 'issues': {
                        return;
                    }
                    case 'curtain': {
                        loadCurtainVideo(event.data.metadata)
                        setCurtain(event.data.metadata)
                        return;
                    }
                    case 'end': {
                        setCurtain(false)
                        setEnded(true)
                        unloadVideo()
                        return;
                    }
                }
            }
        } else {
            loadVideo(show)
            setCurtain(false)
            setEnded(false)
        }

    }

  function renderPlayerControls() {
      return true && <div>
          <Button size="lg"><FontAwesomeIcon icon={faPlayCircle} /></Button>
          <Button size="lg" style={{float:'right'}} onClick={() => { setMuted(!muted) }}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
          <Button size="lg" style={{float:'right'}}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
      </div>
  }

  function handleOnEnded() {
      console.log("Ended")
      unloadVideo()
  }

  function handleOnPlay() {
      setMuted(false)
  }
  function handleOnPause() { console.log("pause")};
  function handleOnReady() { 
    console.log("ready")
    if (session) {
        stats.session_uid = session.session._id
        stats.user_uid = session.user._id
        stats.viewer_uid = session.viewer._id
        stats.viewer_show_uid = viewerShow._id    
        stats.code = viewerShow.payment_info.code
    } else {
        stats.session = Cookies.get('sessionID')
        stats.code = code
    }
    stats.location = { 
        country_code: ipgeo ? ipgeo.country_code2 : null,
        country_name: ipgeo ? ipgeo.country_name : null,
        city: ipgeo ? ipgeo.city : null,
        ip: ipgeo ? ipgeo.ip : null,
    }
    stats.code = viewerShow ? viewerShow.payment_info.code : code
    stats.type = 'player'
    stats.action = 'ready'
    registerShowStat(id, stats)
  };
  function handleOnLoad() { console.log("load")};
  function handleOnBuffer() { console.log("buffer start")};
  function handleOnBufferEnd() { console.log("buffer end")};
  function handleOnStart() { console.log("start")};

  function renderVideoPlayer() {
    return <>
        <div className="video-player" ref={ playerRef }>
            <ReactPlayer
                className="react-player"                
                // config={{
                //     vimeo: {
                //         playerOptions: {
                //             controls: false,
                //             loop: true
                //         }
                //     }
                // }}
                //url="https://vimeo.com/417491036" 
                //url="https://vimeo.com/event/133164"
                // url={videoUrl}
                // played={played}
                // loaded={loaded}
                // controls={controls}
                // playsinline={playsInline}
                // playing={playing}
                // loop={loop}
                url={playerSettings.videoUrl}
                played={playerSettings.played}
                loaded={playerSettings.loaded}
                controls={playerSettings.controls}
                playsinline={playerSettings.playsInline}
                // playing={playerSettings.playing}
                playing={true}
                loop={playerSettings.loop}
                // light={playerSettings.light}
                volume={0.8}
                width='100%'
                height='100%'                
                //pip={true}
                muted={playerSettings.muted}
                //onEnded={handleOnEnded}
                onPlay={handleOnPlay}
                onPause={handleOnPause}
                onReady={handleOnReady}
                onLoad={handleOnLoad}
                onBuffer={handleOnBuffer}
                onBufferEnd={handleOnBufferEnd}
                onStart={handleOnStart}
            />
        </div>        
        {/* { renderPlayerControls() } */}
        </>
         
  }

  function renderVideoEnded() {
      //width:'100%', height:'100%'
    return <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: contentHeight}}>
            <div className="content">
            <img className="show-ended-isotipo" src="/img/isotipo200.png" />
            <div className="show-ended-title">La transmisión del evento ha terminado</div>
            <div className="show-ended-comment">Esperamos hayas disfrutado esta nueva experiencia de espectáculos online</div>
            </div>
        </div>
  }

  function renderMessageCurtain(curtain) {
    //width:'100%', height:'100%'
  return <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: contentHeight}}>
          <div className="content">
          <img className="show-ended-isotipo" src="/img/isotipo200.png" />
          <div className="show-ended-title">{curtain.message1}</div>
          <div className="show-ended-comment">{curtain.message2}</div>
          </div>
      </div>
  }

  function renderCurtain() {
    // loadCurtainVideo(curtain)
    // return renderVideoPlayer()
    //   return renderMessageCurtain(curtain)
    //   return <div>Cortina</div>
      switch(curtain.type) {
          case 'static': 
          return renderMessageCurtain(curtain)
            
          case 'video':
            // loadCurtainVideo(curtain)
            const player = renderVideoPlayer()
            
            return player
            
      }
  }

    function isShowChat() {
        let chat_visible = ['LIVE','TEST'].includes(show.status)
        if (show.settings?.chat_visible !== undefined) {
            chat_visible = chat_visible && show.settings.chat_visible
        }
        return chat_visible
    }  

    function renderVideoContent() {
        return <div className={!isShowChat() ? 'show-content vod': 'show-content'} ref={ contentRef } style={{height: contentHeight}} >
        <div className="show-player" ref={ videoRef } style={{height: playerHeight}} >
        { ended ? renderVideoEnded() : curtain ? renderCurtain() : renderVideoPlayer() }
        </div>
        {isShowChat() && <div className="show-interactions" ref={ interactionsRef }>
        
                <div className="chat-box">                        
                    {chatHeight && <>
                        <Chat channel={id} session={chatSession} style={{height:chatHeight}} 
                            chatVisible={true}
                            // socketRef={(socket) => { /*console.log("socket ref", socket)*/ } } socketOnMessage={ async (data) => { return handleSocketOnMessage(data) }}
                        />
                    
                    </>
                    }
                </div> 
            </div> }
        </div> 
  }

  function renderMessage() {
    return <div className='show-content vod' ref={ contentRef } style={{height: contentHeight}} >
            <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: contentHeight}}>
                <div className="content">
                    <img className="show-ended-isotipo" src="/img/isotipo200.png" />
                    <div className="show-ended-title">Hemos detectado que has ingresado a este contenido desde otro dispositivo o navegador.</div>
                    <div className="show-ended-comment">Asegúrate de ingresar solamente en un navegador y en un dispositivo a la vez. Recarga la página para cargar el contenido de nuevo.</div>
                </div>
            </div>
        </div> 
  }

  function onCodeSubmit(e) {
    e.preventDefault()
    // console.log('code',codeInputRef.current.value)
    history.push(`/shows/${id}/player/?code=${codeInputRef.current.value}`, { code: codeInputRef.current.value.toUpperCase() })
    //setCode(codeInputRef.current.value)
    }

    function renderCodeForm() {
        return <div className='show-content vod' ref={ contentRef } style={{height: contentHeight}} >
                <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: contentHeight}}>
                    <div className="content">
                        <img className="show-ended-isotipo" src="/img/isotipo200.png" />
                        { codeError && <div className="show-ended-error">Código Inválido</div> }
                        <div className="show-ended-title">Ingresa tu código de acceso para entrar:</div>
                        <div className="show-ended-comment">
                            <form onSubmit={onCodeSubmit} >
                            <input type="text" placeholder="código" ref={codeInputRef} />
                            <button type="submit">Entrar</button>
                            </form>
                        </div>
                        <duv>Aún no tienes un código, <a href="/fafidess2021/">Regístrate</a></duv>
                    </div>
                </div>
            </div> 
    }


    function renderContent() {      
        //   console.log(viewerShow, showCode)
          if (viewerShow || showCode) {
            if (showMessage) {
                return renderMessage()
            } else {
                return renderVideoContent()
            }
          } else {
              return renderCodeForm()
          }
        }    
    
      function renderPlaylist() {
        if (show?.settings?.playlist) {
            
            return <div className="show-playlist">
                { show.settings.playlist
                    .filter( p => ['ACTIVE','SOON','LIVE'].includes(p.status))
                    .map( p => {
                    return (
                    <div key={p._id} className={`show-item ${p._id == playlistSelectedId ? 'active' : ''}`}>
                        <div className="show-item-image">
                            <img src={p.image_url} />
                            { ['ACTIVE','LIVE'].includes(p.status) && p.video_url && (
                            <div className="play-overlay">
                                <a onClick={() => { setPlaylistSelectedId(p._id); loadVideo(show, p._id); }} className="d-flex justify-content-center align-items-center" 
                                // onClick={() => { trackEvent({
                                //     action: "Clicked Play Overlay",
                                //     category: "Show"
                                // }); }}
                                ><FontAwesomeIcon icon={faPlayCircle} /></a>
                                {p.status == "LIVE" &&
                                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                                }
                            </div>
                            ) }
                            { (['SOON'].includes(p.status) || !p.video_url) && (
                            <div className="soon-overlay">                            
                            </div>
                            ) }
                        </div>
                        {/* <div className="show-item-content"> */}
                            <div className="show-item-info">
                                <div className="show-item-title">{p.title}</div>
                                <div className="show-item-date">{p.date}</div>
                            </div>
                            <div className="show-item-description">{p.description}</div>
                        {/* </div> */}
                    </div>)
    
                })
                }
            </div>
        }
      }

  return !isLoading && show && <div className="show-container fafidess2021 player" ref={ containerRef} style={{height: containerHeight}}>
      <div className="show-header" ref={ headerRef }>      
        <div className="sparkBottom">
        <div className="gold-border">
                        <div className="header-content">
            <div className="tema">
                <img className="desktop" src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" />
                <img className="mobile"  src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" />
            </div>
            <div className="header-legend">
                <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/player header legend.png" />
            </div>
            <div className="contacto">                                    
                                    <div>
                                        <a href="http://www.fafidess.org" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-www.png" /></a>
                                        <a href="http://facebook.com/fundacionfafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-fb.png" /></a>
                                        <a href="http://twitter.com/fafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-twitter.png" /></a>
                                        <a href="http://instagram.com/fundacionfafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-ig.png" /></a>
                                    </div>
                                    <div>
                                    <a href="http://www.fafidess.org">www.fafidess.org</a><br />
                                    PBX: 2294 0000<br />
                                    </div>
                                </div>
            </div></div>
            </div>
      </div>

      { renderContent() }
      { renderPlaylist() }


      { (showCode || viewerShow) && <div className="show-footer">
        <div className="sparkBottom">
        <div className="gold-border">
                        <div className="footer-content">
                        <div className="footer-logo"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" /></div>
                            <div className="footer-legend">Te ofrecemos el crédito que necesitas para hacer la inversión de tus sueños.</div>
                            <div className="servicios-financieros">
                                <div className="grid-financieros">
                                    <div className="grid-item">
                                        <label>Bancos Comunales</label>
                                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 31.png" />
                                    </div>
                                    <div className="grid-item">
                                        <label>Grupos Solidarios</label>
                                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 30.png" />
                                    </div>
                                    <div className="grid-item">
                                        <label>Crédito Individual</label>
                                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 29.png" />
                                    </div>
                                    <div className="grid-item">
                                        <label>Crédito Vivienda</label>
                                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 28.png" />
                                    </div>
                                    <div className="grid-item">
                                        <label>Crédito Educativo</label>
                                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 27.png" />
                                    </div>
                                    <div className="grid-item">
                                        <label>Eco Crédito</label>
                                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 26.png" />
                                    </div>
                                </div>
                                <div className="contacto">                                    
                                    <div>
                                        <a href="http://www.fafidess.org" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-www.png" /></a>
                                        <a href="http://facebook.com/fundacionfafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-fb.png" /></a>
                                        <a href="http://twitter.com/fafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-twitter.png" /></a>
                                        <a href="http://instagram.com/fundacionfafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-ig.png" /></a>
                                    </div>
                                    <a href="http://www.fafidess.org">www.fafidess.org</a><br />
                                    PBX: 2294 0000<br />
                                </div>
                        
                            </div>
                        </div>
        </div>
        </div>
        
      </div> }

      { (showCode || viewerShow) && <div className="contact-box">
                <div><img src="http://viveloonline.com/img/vivelo-logo-blanco.png" /></div>
                <div>
                <b>¿Deseas realizar un evento virtual? Contáctanos!</b><br/>
                Tel/Whatsapp <a href="http://wa.me/50258793055" target="_blank">+502 5879 3055</a> • <a href="https://facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} />/vivelo.online</a> • <a href="https://instagram.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faInstagram} />@vivelo.online</a> • <a href="http://servicios.viveloonline.com" target="_blank">Portal de Servicios</a>            
                </div>
        </div> }
        
        { (showCode || viewerShow) && <div className="help-box">
                <h3>Tips y Preguntas Frecuentes</h3>              
                <div className="faq-box">
                    <div>
                        <p>
                            <b>Tips</b><br />
                            <ul>
                                <li>Asegúrate de tener suficiente batería o conectar tu dispositivo a la corriente</li>
                                <li>Conecta tu dispositivo a unas bocinas o audífonos para disfrutar una mejor calidad de audio</li>
                            </ul>
                        </p>
                        <p>
                        <b>Tengo problemas en la transmisión,  se "congela" o se para seguido, cómo lo arreglo?</b><br />                    
Se puede ver en la parte de abajo del video player que hay un icono de una herramienta, le das click ahí y cambia la resolución. Te recomendamos bajar la resolución a 720 o 480 sí el video se está parando mucho. Este cambio de resolución ayudará a que tu internet funcione mejor con la transmisión.
<br /><img src="/img/player-quality.png" />
</p>
<p>
<b>Se puede ver el video pero no tiene sonido, cómo lo arreglo?</b><br />
Asegurate que presiones en la parte de arriba del video el ícono de "UNMUTE". También en la parte inferior del video se pueden ver unas barritas rosadas, donde puedes subir el volumen desde ahí. Debes asegurate que el volumen de tu computador o teléfono esté con el volumen correcto.
<br /><img src="/img/player-volume.png" width="320" />
</p>
                    </div>
                    <div>
                    <b>Cómo puedo ver el concierto en mi televisión?</b><br />
                    <ul>
                        <li>Si tienes <b>ChromeCast</b> y una Laptop: Usa el browser CHROME en tu laptop entra a settings y click en CAST. Ahí podrás mostrar la pantalla de tu laptop en tu televisor.</li>
                        <li>Si tienes un <b>SMART TV</b> con navegador: solamente tienes que ir a http://viveloonline.com, inicia sesión con tu usuario y dirígete a la página del show.</li>
                        <li>Si tienes <b>APPLE TV</b> y un teléfono: Entra al show desde tu teléfono y usa la opción "Duplicar pantalla" o "Screen Mirroring", y selecciona tu APPLE TV</li>
                        <li>Si tu laptop/televisor tiene <b>HDMI</b>: Conecta tu laptop al televisor usando HDMI con un cable de HDMI.</li>
                    </ul>
 
                    </div>
                </div>


            </div> }
      {/* <div className="show-footer">
        <footer className="social-footer">
            <div className="social-footer-left">
            </div>
            <div className="social-footer-icons">
              <ul className="">
                <li><a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> facebook</a></li>
                <li><a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> instagram</a></li>          
              </ul>
            </div>
          </footer>
      </div> */}
  </div>

//   return (
// /*
// <div class="video-player" style="padding:56.25% 0 0 0;position:relative;">
//                             <Iframe src="https://player.vimeo.com/video/415993914" frameborder="0" allow="autoplay; fullscreen" allowFullScreen style="{{position:absolute;top:0;left:0;width:100%;height:100%;}}"></Iframe>
//                         </div>
// */


// !isLoading && viewerShow && <div className="">
//         <Container className="show-container">            
//             <Row className="show-header">
//                 <Col>
//                 <div className="show-title primary">
//                     {show.fullname}
//                 </div>
//                 {false && <div className="live">
//                     <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
//                 </div>}
//                 <div className="small-logo">
//                     <img src="/img/vivelo-logo.png" className="small-logo" />
//                 </div>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col md="12">
//                 <div className="show-player">
//                     { !ended ? renderVideoPlayer() : renderVideoEnded() }
//                     <div className="chat-box">                        
//                         <Chat channel={id} session={chatSession} socketRef={(socket) => { console.log("socket ref", socket) } } socketOnMessage={ async (data) => { return handleSocketOnMessage(data) }} />
//                     </div> 
//                     <div className="title">
//                         {show.fullname}
//                     </div>
//                 </div>
//                 <div>
//             <label>Recomendaciones</label>
//             <ul style={{listStyleType:'circle'}}>
//             <li>Si no te carga el video, intenta recargar la página</li>
//             <li>Recuerda revisar los niveles de volumen</li>
//             </ul>
//         </div>
//                 </Col>
//             </Row>            
//         </Container>
//     </div>
//   );
}