import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form"
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { LinkContainer } from "react-router-bootstrap";

export default function Login(props) {  
  const { login } = useAppContext();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showForceLogout, setShowForceLogout] = useState(true);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    forceLogout: true
  });
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('pwdreset') == "1") {
        Notification('success','Tu contraseña fue reestablecida')
    }
    if  (searchParams.redirect) {

    }
    
  }, []);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);

    try {
      const session = await login(fields.email, fields.password) 
      if (session) {
        console.log(session)
        if (props.modal && props.handleModalSuccess) {
          props.handleModalSuccess()
        }
        
      } else {
        Notification('error', 'Usuario o contraseña inválidos')
        setIsLoading(false);
        //setShowForceLogout(false);
      }

    } catch (e) {
      console.log("Login submit error")
        onError(e);
        if (e.response.status == 409) {
          // if (window.confirm('Ya existe una sesión con este usuario.  Deseas cerrar la sesión en el otro dispositivo?'))
          Notification('error', 'Ya existe una sesión activa con este usuario.')
          // setTimeout( () => {
          // handleFieldChange({ target: { id: "forceLogout", name: "forceLogout", value: true }})
          // setShowForceLogout(true)
          // }, 200)
        } else {
          Notification('error', 'Usuario o contraseña inválidos')          
          //setShowForceLogout(false);
        }
        setIsLoading(false);
    }
  }

  return (
    <div className="content content-dark">
    <Container className="">
      <Row>
      <Col>
        { !props.modal && <h1>Ingresa</h1> }
        <div className="description">Ingresa a tu cuenta para poder ver tu shows</div>
      </Col>
    </Row>
    <Row style={{ }}>
      <Col md={ props.modal ? "12" : "4" }>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            value={fields.password}
            onChange={handleFieldChange}
            type="password"
          />
          <div>
            <a href={`/password/recover`} className="password-recover" >Olvid&eacute; mi contrase&ntilde;a</a>
          </div>
        </Form.Group>
        {/* { showForceLogout && <Form.Group controlId="forceLogout" size="lg">
          <Form.Check 
            type="checkbox"            
            id={`forceLogout`}
            name="forceLogout"
            value={true}
            checked={true}
            // onChange={handleFieldChange}
            label={`Cerrar sesiones abiertas en otros dispositivos`}
            readOnly
          /> 
        </Form.Group> } */}
        <LoaderButton
            block
            type="submit"
            size="lg"
            isLoading={isLoading}
            disabled={!validateForm()}
        >
            Login
        </LoaderButton>
      </Form>      
      </Col>
      </Row>
    </Container>
    </div>
  );
}