import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button } from "react-bootstrap";

import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";
import { useAppContext } from "libs/contextLib";

import './Cart.css'

export default function CartSummaryBox(props) {
    const { info, ...otherProps } = props

    const [ticketsTotal, setTicketsTotal] = useState(0)
    const [cartTotal, setCartTotal] = useState(0)
    // const [cart, setCart] = useState(null)

    const { trackEvent } = useAnalytics();

    const { cart } = useAppContext();
    // const cart = {}
    
    const { getCart, getTotalItems, removeCartItems } = useCart()

    useEffect(() => {        
        // onLoad()         
        
    }, [cart]);

    async function onLoad() {
        // let mycart
        // try {
        //     mycart = await getCart()
        //     setCart(mycart || {})
        // } catch (e) {
        //     // no cart
        // }
    }

    async function handleRemoveFromCartButton(item_id) {
        trackEvent({
            action: "Clicked Remove from Cart Button",
            category: "PurchaseFunnel"
        });

        const items = [item_id]
        await removeCartItems({ items: items })
        window.location.reload()
        
        // setOrder(show._id, reservations)

        // setIsOrderView(false)
        // setIsPaymentView(true)

    }

    function getCurrencyValue(cart, item, property) {
        const suffix = cart?.currency == 'USD' ? '_usd' : ''
        return item[`${property}${suffix}`]
    }

    function renderPrice(cart, price) {
        const currency = cart?.currency == 'USD' ? 'US$ ' : 'Q '
        const sanitizedPrice = price || 0
        return `${currency}${sanitizedPrice.toFixed(2)}`
    }

    function renderVenue(ticket) {        
        switch(ticket.show_type) {
          case 'VENUE':
            return <><FontAwesomeIcon icon={faWarehouse} /> {ticket.venue}</>
          break;
    
          default:
            return <><FontAwesomeIcon icon={faGlobe} /> Online</>
          break;
        }
      }

    function renderItemPriceDetail(cart, ticket) {

        const itemPrice = <>{ticket.quantity} x {renderPrice(cart, getCurrencyValue(cart, ticket, 'price'))}</>
        const itemDiscountPrice = <>{ticket.quantity} x {renderPrice(cart, getCurrencyValue(cart, ticket, 'discount_price'))}</>

        if (ticket.discount_price > 0) {
            return <><span style={{textDecoration:'line-through'}}>{itemPrice}</span><br />{itemDiscountPrice}</>
        } else {
            return itemPrice
        }
    }

    function renderSeatInfo(t) {
        const info = []
        info.push(t.description)
        switch(t.ticket_type) {
            case 'VENUE_SEAT': {
                info.push(`Sección: ${t.location.section}`)
                info.push(`Fila: ${t.location.row}`)
                // info.push(t.location.table)
                info.push(`Asiento: ${t.location.seat}`)
                break;
            }
            case 'VENUE_TABLE_SEAT': {
                info.push(`Sección: ${t.location.section}`)
                // info.push(`Mesa: ${t.location.table}`)
                // info.push(t.location.table)
                info.push(`Asiento: ${t.location.seat}`)
                break;
            }
            case 'VENUE_OPEN': {
                info.push(`Sección: ${t.location.section}`)
                break;
            }
        }
        return info.join(' / ')
    }
    
    function renderTicketsSummary(cart) {
        // const cart = getCart()
        let items = cart ? cart.items || [] : [];
        let ticketsTotal = 0
        let feeTotal = 0
        let discountTotal = 0
        const tickets = items.filter(i => i.type == 'TICKET').map(t => {
            ticketsTotal += getCurrencyValue(cart, t, 'total_price')
            feeTotal += getCurrencyValue(cart, t, 'total_fee')
            discountTotal += getCurrencyValue(cart, t, 'discount')
            return !props.totalsOnly ? <>
                <div className="cart-item cart-item-ticket" key={t._id}>                    
                    <div className="cart-item-info">
                        <div className="cart-item-image">
                            <img src={`${t.image}`} />
                        </div>
                        <div>
                            <div className="cart-item-title">
                                {`${t.title}`}
                            </div>
                            <div className="cart-item-description">
                                {renderVenue(t)}
                            </div>
                            <div className="cart-item-description">
                                {renderSeatInfo(t)}
                            </div>
                            <div className="cart-item-note">
                                {`${t.note}`}
                            </div>
                            { t.bundle_items && <div className="cart-item-note">
                                { t.bundle_items.map(item => {
                                    return `• ${item.quantity} x ${item.description}`
                                }).join('<br />')}                                
                            </div> }
                        </div>
                    </div>
                    <div className="cart-item-numbers">
                        <div className="cart-item-price">
                            {renderItemPriceDetail(cart, t)}
                            {/* {t.quantity} x {renderPrice(cart, getCurrencyValue(cart, t, 'price'))} {renderPrice(cart, getCurrencyValue(cart, t, 'discount_price'))} */}
                        </div>
                        { !t.fee_included && <div className="cart-item-fee">
                            Servicio: {t.quantity} x +{renderPrice(cart, getCurrencyValue(cart, t, 'fee'))}
                        </div> }
                        <div className="cart-item-actions">
                            {!props.readOnly &&
                                <a href="#" onClick={() => handleRemoveFromCartButton(t._id)}>Eliminar</a>
                            }
                        </div>
                    </div>
                </div>                
                </> : null
        })
        // setTicketsTotal(ticketsTotal)
        return <div className="cart-item-group">
            { <>{tickets}

                <div className="cart-subtotal">
                    {/* <div className="cart-subtotal-info">Descuentos</div>
                    <div className="cart-subtotal-numbers">{renderPrice(cart, discountTotal)}</div> */}
                    <div className="cart-subtotal-info">Subtotal Boletos</div>
                    <div className="cart-subtotal-numbers">{renderPrice(cart, ticketsTotal)}</div>
                    <div className="cart-subtotal-info">Cargo por servicio de boletería</div>
                    <div className="cart-subtotal-numbers">+ {renderPrice(cart, feeTotal)}</div>
                </div>
                </>
                }
            </div>
    }

    function renderCartSummary(cart) {
        // <Col md="2">{!viewerShow ? <Button variant="primary" data-ticket-id={t.id} onClick="">Comprar</Button> : null }</Col>

        // let items = props.cart.items || [];
        // let orderTotal = 0
        // if (tickets) {
        //     items = tickets.filter(t => t.selected ).map( t => {
        //         orderTotal = 
        //         return <>
        //         <Row key={t.ticket_id}>
        //             <Col md="6">{t.fullname}</Col>
        //             <Col md="6" className="price">{t.selected} x {t.price_text}</Col>
        //         </Row>           
        //         </>
        //     })
        // }
//         const cart = await getCart()
        // console.log(cart)
        const tickets = renderTicketsSummary(cart)

        let items = cart ? cart.items || [] : [];
        const cartTotal = items.reduce((total, current) => {
            return total + getCurrencyValue(cart, current, 'total_price')  + getCurrencyValue(cart, current, 'total_fee')
        }, 0)
        // setCartTotal(ticketsTotal)         
        // console.log(getTotalItems(cart))
        return <div className="cart-summary-box">            
            { getTotalItems(cart) > 0 ? <>
                {tickets}
                <div className="cart-total">
                    <div className="cart-total-info">TOTAL</div>
                    <div className="cart-total-numbers">{renderPrice(cart, cartTotal)}</div>
                </div>                
                </>
                : <div className="cart-empty">El carrito está vacío :(</div>
            }</div>
    }    

    return renderCartSummary(cart)
}