import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle } from "@fortawesome/free-solid-svg-icons";


import Loader from 'components/Loader'

import ShowInfo from "components/shows/ShowInfo";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./astrodeoro.css"
import { render } from "@testing-library/react";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const children = await loadChildShows();            
            setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=ALL&visible=-1&sort=1`);        
        return children && children.items ? children.items : []
    }

    function renderPlaylist(shows) {
        if (shows) {
            
            return <div className="show-playlist">
                { shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY','HIDDEN'].includes(p.status))
                    .map( p => {
                    return (
                    <div key={p._id} className={`show-item`}>
                        <div className="show-item-image">
                            <img src={p.main_image} />
                            { ['ACTIVE','LIVE','REPLAY'].includes(p.status) && p.video_url && (
                            <div className="play-overlay">
                                <a 
                                href={`/shows/${p._id}/player/`} target="_blank"
                                // onClick={() => { setPlaylistSelectedId(p._id); loadVideo(show, p._id); }} 
                                className="d-flex justify-content-center align-items-center" 
                                // onClick={() => { trackEvent({
                                //     action: "Clicked Play Overlay",
                                //     category: "Show"
                                // }); }}
                                ><FontAwesomeIcon icon={faPlayCircle} /></a>
                                {['LIVE'].includes(p.status) &&
                                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                                }
                            </div>
                            ) }
                            { (['SOON'].includes(p.status) /*|| !p.video_url*/) && (
                            <div className="soon-overlay">
                                <div>PRÓXIMAMENTE</div>
                            </div>
                            ) }
                        </div>
                        {/* <div className="show-item-content"> */}
                            <div className="show-item-info">
                                <div className="show-item-title">{p.fullname}</div>
                                <div className="show-item-date">{p.settings.date}</div>
                            </div>
                            <div className="show-item-description">
                                <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(p.description)}</Linkify></>                                
                            </div>
                        {/* </div> */}
                    </div>)
    
                })
                }
            </div>
        }
    }

    return (
        !isLoading && show ? <>
        <div className="site-astrodeoro">
            <div className="header">            
                <div className="background" style={{backgroundImage:`url("${show.settings?.channel_image}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="show-info">
                    <div className="show-title">{show.fullname}</div>
                    <div className="show-badges">
                        <div className="show-year">2024</div>
                        <div className="show-rating">13+</div>
                        <div className="show-quality">HD</div>
                    </div>
                    <div className="show-description">
                        <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(show.description)}</Linkify></>
                    </div>
                </div>
            </div>
            <div className="body">

                {childShows && renderPlaylist(childShows)}
                
            </div>
            <div className="footer">
                
            </div>
        </div>
        </> : <Loader />
  );
}