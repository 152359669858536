import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons";

import Loader from 'components/Loader'
import CartTimer from "components/cart/CartTimer"

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowMapTickets from "components/shows/ShowMapTickets";
import ShowDates from "components/shows/ShowDates";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";


export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);  
  const [tickets, setTickets] = useState(null);
  const [showDates, setShowDates] = useState(null);
  const [showMap, setShowMap] = useState(null);
  const [selectedShowDate, setSelectedShowDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            if (show?.settings?.is_show_date) {
                history.push(`/shows/${show.parent_show_uid}`)
                return;
            }
            setShow(show)
            // console.log(show)

            if (show?.settings?.has_show_dates) {
                loadChildShows();                
            } else {
                setSelectedShowDate(show)
                //loadShowTickets(show._id)
            }
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    useEffect(() => {
        async function loadShowDateTickets() {
            if (selectedShowDate) { 
                await loadShowTickets(selectedShowDate._id)
                if (selectedShowDate?.settings?.has_map) {
                    loadShowMap(selectedShowDate._id)
                }
            } else {
                setTickets(null)
            }
        }
        loadShowDateTickets();
    }, [selectedShowDate]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets(show_uid) {
        const tickets = await API.get("vivelo", `/shows/${show_uid}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=CURRENT&visible=-1`);
        const dates = children && children.items ? children.items : []
        setShowDates(dates)
    }

    async function loadShowMap(show_uid) {
        const map = await API.get("vivelo", `/shows/${show_uid}/map`);        
        setShowMap(map)
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >

    function onShowDateChange(show_id) {
        const selected = showDates.find(s => s._id == show_id)
        setSelectedShowDate(selected)
    }

    function renderQuickBuyLink() {
        return <div class="quickbuy_link">
            <a href={show.settings.quickbuy_link} target="_blank">                
                <FontAwesomeIcon icon={faMoneyBillAlt} />
                <span>Registra aquí tu pago con depósito o transferencia bancaria<br />{show.settings.quickbuy_link}</span>                
            </a>
        </div>
    }

    return (
        !isLoading && show ? <>
        <div className="Show content content-dark">
            <CartTimer />
            <Container>
            <Row>
                <Col md="4" className="">
                    <ShowImage show={show} viewerShow={viewerShow} />

                    <ShowTicketBox show={show} viewerShow={viewerShow} tickets={tickets} />

                    {/* <ShowSocialBox show={show} />                                                         */}
                    
                </Col>    
                <Col md="8" className="Show">
                    <ShowTitle show={show} />

                    <ShowInfo info={show.description} />

                    {!['NEW','CLOSED'].includes(show.status) && show.settings.deposit && show.settings.quickbuy_link && <div className="container show-tickets">
                        <h3>Pago con depósito o transferencia</h3>
                        {renderQuickBuyLink()}
                        </div> }
                    {!['NEW','CLOSED'].includes(show.status) && (!show.settings.deposit || (show.settings.deposit && !show.settings?.deposit_only)) && <>
                        
                        <div className="container show-tickets data-table">
                            {show.settings.deposit && <><h3>Pago con tarjeta</h3></> }
                            <ShowDates show={show} showDates={showDates} onChange={onShowDateChange} />
                        </div>
                        { selectedShowDate && <>
                            { show.settings?.registration ? <>
                                <ShowRegistration show={selectedShowDate} tickets={tickets} onSuccess={handlePaymentSuccess} />
                                
                            </> : <>
                                <div className="container show-tickets data-table">
                                {/* quickBuyLink={!!selectedShowDate.settings.quickbuy_link} */}
                                    {selectedShowDate?.settings?.has_map ?
                                        showMap ? <ShowMapTickets show={selectedShowDate} showTickets={tickets} showMap={showMap}  /> : <Loader />                                         
                                        : <ShowTickets show={selectedShowDate} tickets={tickets}  />
                                    }
                                </div>
                            </>
                            } </>
                        }
                    </> }

                    
                    
                    
                    

                    <ShowInfo info={show.description2} />
                </Col>
            </Row>
            </Container>
        </div>
        </> : <Loader />
  );
}