import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Container, Row, Col, Button, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';

import { base64encode } from 'nodejs-base64';

import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

import "./Order.css";



export default function UserOrderList() {
  const [fields, handleFieldChange] = useFormFields({
    fullname: "",    
  });
  const history = useHistory();  
  const { getSession, updateUser, handleLogout} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState(null);
  const [session, setSession] = useState(false);


  
  useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            const sess = await getSession()
            setSession(sess)

            const orders = await getOrderList(sess.user._id, sess.user.email)
            setOrders(orders)

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
  }, []);

  async function getOrderList(user_uid, email) {
    let orders = API.get("vivelo", `/cart/orders?user=${user_uid}|${email}`);
    return orders || { items: [] }
  }
  

  function validateForm() {
    return (
      fields.fullname.length > 0
    );
  }

  function renderPrice(order, price) {
    const currency = order.currency == 'USD' ? 'US$ ' : 'Q '
    const sanitizedPrice = price || 0
    return `${currency}${sanitizedPrice.toFixed(2)}`
  }

  function renderOrderStatus(o) {
    switch(o.status) {
      case 'APPROVED': return <Badge variant="success" >APROBADA</Badge>;
      case 'REJECTED': return <Badge variant="danger" >DENEGADA</Badge>;
      case 'CANCELLED': return <Badge variant="warning" >ANULADA</Badge>;
      case 'REFUND': return <Badge variant="secondary" >REEMBOLSO</Badge>;
      case 'WAITING_CONFIRMATION': return <Badge variant="info" >ESPERANDO CONFIRMACIÓN</Badge>;
      default: return <Badge variant="primary" >{o.status}</Badge>;
    }
  }

  function renderOrdersList() {
    const orderList = orders.items.map(o => 
      <a href={`/orders/${o._id}`}>
        <div className="order">        
          <div className="column"><label>Fecha</label> <Moment local format="YYYY-MM-DD HH:mm:ss" locale="es">{new Date(o.createdAt)}</Moment></div>        
          <div className="column"><label>Total</label> {renderPrice(o, o.total)}</div>
          <div className="column"><label>{ o.tickets.reduce((total, ticket) => total + ticket.quantity, 0) } Item(s)</label> 
            <div>
              {o.tickets.map(t => <div>{t.show_name} - {t.description}</div>)}
            </div>
          </div>
          <div className="column">
            <span style={{fontSize:'0.8rem'}}>ID: {o._id}</span>
            <span style={{fontSize:'0.8rem'}}>{renderOrderStatus(o)}</span>
            <Button size="sm" variant="outline-light">Ver</Button>
          </div>        
        </div>
      </a>
    )

    return orderList.length > 0 ? <div className="order-list">
          {orderList}
        </div> 
        : <div className="order-list">
            Aún no has realizado ninguna compra.
        </div> 
  }

  function renderForm() {
    return (orders && <>
      <Container className="">
        <Row>
          <Col >
            <h1>Mis Compras</h1>
            <div className="description">Revisa tus compras</div>
          </Col>                  
          <Col style={{textAlign:'right'}}>
            <LinkContainer to="/user/"  style={{marginTop: 10}}><Button variant="outline-light">Mis Datos</Button></LinkContainer >
            <Button variant="danger" onClick={handleLogout} style={{marginTop: 10, marginLeft: 5}}>Cerrar sesión</Button>
          </Col>
        </Row>
      <Row>
      
    </Row>
    <Row style={{ }}>
      <Col md="12">      
      { renderOrdersList() }
      </Col>
      </Row>
    </Container>
    </>);
  }

  return (
    <div className="Signup content content-dark">
      {renderForm()}
    </div>
  );
}