import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import Form from "react-bootstrap/Form"
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { LinkContainer } from "react-router-bootstrap";

export default function PasswordReset() {  
  const { token } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({    
    password: "",
    confirmPassword: ""
  });

  async function resetPassword(password) {
    
    try {
        const result = await API.post('vivelo',`/auth/password/reset`, {
          body: { token: token, password: password }
        })        
        return true;
    } catch(e) {            
      console.log(e)                      
      return false
    }    
  }

  function validateForm() {
    return fields.password.length > 0 && fields.password === fields.confirmPassword;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);

    try {
      const result = await resetPassword(fields.password) 
      if (result) {
        history.push(`/login`)
        
      } else {
        Notification('error', 'No fue posible reestabler la contrase&ntilde;a')
        setIsLoading(false);
      }

    } catch (e) {
        onError(e);
        Notification('error', 'No fue posible reestabler la contrase&ntilde;a')        
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="content content-dark">
    <Container className="">
      <Row>
      <Col>
        <h1>Reestablecer contrase&ntilde;a</h1>
        <div className="description">Ingresa tu nueva contrase&ntilde;a</div>
      </Col>
    </Row>
    <Row style={{ }}>
      <Col md="4">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Nueva contrase&ntilde;a</Form.Label>
          <Form.Control
            autoFocus
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirma tu contrase&ntilde;a</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </Form.Group>
        <LoaderButton
            block
            type="submit"
            size="lg"
            isLoading={isLoading}
            disabled={!validateForm()}
        >
            Reestablecer Contrase&ntilde;a
        </LoaderButton>
      </Form>
      </Col>
      </Row>
    </Container>
    </div>
  );
}