import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";
import CreditCardInput from 'react-credit-card-input';
import { LinkContainer } from "react-router-bootstrap";

import LoaderButton from "components/LoaderButton";

import { useCart } from "libs/cartLib";
import { useAnalytics } from "libs/analytics";
import { useFormFields } from "libs/hooksLib";
import { useAppContext } from "libs/contextLib";
import { renderCountryOptions } from "libs/formsLib";
import { onError, Notification } from "libs/errorLib";
// import { useRecaptcha } from "libs/recaptchLib";
import config from 'config';

// import './Loader.scss'

export default function RegistrationForm(props) {
    const { user, ...otherProps } = props

    const [tickets, setTickets] = useState(null)
    const [show, setShow] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)

    const { trackEvent } = useAnalytics();
    const { getSession } = useAppContext();
    const { getCart, addCartItems, resetCart, createOrder } = useCart();

    // const { initRecaptcha, executeRecaptcha } = useRecaptcha();
    
    const [fields, handleFieldChange] = useFormFields({
        ccSaveInfo: true,
        ccFirstname: user.firstname || "",
        ccLastname: user.lastname || "",
        ccEmail: user.email || "",
        ccEmailConfirm: "",
        ccCountry: user.country || "GT",
      });

    useEffect( () => {
        setShow(props.show)
        setTickets(props.tickets)
        console.log(props)

    }, [props.tickets])

    // useEffect(() => {
    //     initRecaptcha()
    // }, []);

    // useEffect(() => {

    //     // const searchParams = new URLSearchParams(location.search);
    //     // if (searchParams.get('activated') == "1") {
    //     //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     //     setShowActivationModal(false)        
    //     // }
    
    //     async function onLoad() {
    //         try {           
    //             setIsLoading(true)
    //             session = await getSession()
                                
    //             // if (isAuthenticated) {    
    //             //     console.log(session, show)            
    //             //     const vShow = await loadViewerShow(session.viewer._id, show._id)
    //             // }
    
    //         } catch (e) {
    //             onError(e);
    //         } finally {
    //             setIsLoading(false)
    //         }
    //     }
    
    //     onLoad();
    // }, []);  

    async function processPayment(payment_type, payment_info) {
        const session = await getSession()

        const order = {
            user_uid: session.user._id,
            payment_type: payment_type,
            currency: 'GTQ',
            payment_info: payment_info,                
        }
        const purchase = await createOrder(order)

        // const purchase = await API.post("vivelo", `/viewers/orders/`, {
        //     body: {
        //         user_uid: session.user._id,
        //         payment_type: payment_type,
        //         currency: 'GTQ',
        //         payment_info: payment_info,                
        //     }
        // });
        
        return purchase
    }



    /* VALIDATORS */
    function validateRegistrationForm() {
        //return fields.purchaseCode.length > 0 && fields.purchaseCodeTicket;
        return fields.ccFirstname.length > 0 
            && fields.ccLastname.length > 0
            && fields.ccEmail.length > 0
            && fields.ccEmail == fields.ccEmailConfirm
            && fields.ccCountry.length > 0 ;
            // && orderTotal > 0;

    }

    /* HANDLERS */
    function handleChangeOrderButton() {
        trackEvent({
            action: "Clicked ChangeOrder Button",
            category: "PurchaseFunnel"
        });
    }    
    

    async function handleSubmitRegistration(event) {    
        event.preventDefault();        
        setIsLoading(true);
        trackEvent({
            action: "Clicked Pay Button",
            category: "PurchaseFunnel"
        });
        
        try {

            // const captchaResult = await executeRecaptcha('registration')
            // if (!captchaResult.success) {
            //     Notification('error', 'Error de verificación.  Por favor vuelve a intentar.')                
            //     setIsLoading(false);
            //     return false
            // }
            
            // await resetCart()
            
            const currency = 'GTQ'
            // console.log(tickets)
            // const items = tickets.filter(t => t.payment_type.includes('REGISTRATION') ).map(t => {            
            //     return {
            //         ticket_uid: t._id,
            //         type: 'TICKET',
            //         show_uid: show._id,
            //         show_name: show.fullname,
            //         title: `${show.fullname} (${show.show_date})`,
            //         description: t.fullname,
            //         note: t.note,
            //         image: `${show.main_image}`,
            //         quantity: 1
            //     }
            // })
            // await addCartItems({ currency: currency, items: items })
            

            const payment_info = {
                    firstname: fields.ccFirstname,
                    lastname: fields.ccLastname,
                    email: fields.ccEmail,
                    country: fields.ccCountry,
                    currency: 'GTQ',
                    total: orderTotal
            }
                        
            const purchase = await processPayment('REGISTRATION', payment_info)
            if (purchase) {
                // console.log(purchase)
                setIsLoading(false);
                payment_info.save_info = fields.ccSaveInfo
                props.onSuccess && props.onSuccess(payment_info, purchase)
                // history.push(`/orders/${purchase.id}`)
                return true
            } else {
                Notification('error', 'No fue posible realizar la compra', null, 'top')
                props.onFailure && props.onFailure(purchase)
                setIsLoading(false);
                return false
            }        
            
        } catch(error) {
            Notification('error', 'No fue posible realizar la compra', null, 'top')
            setIsLoading(false);
            return false
        } finally {
            
        }

        
    }  

    function renderRegistrationForm() {
        return <Form onSubmit={handleSubmitRegistration}>
            <Row>
                <Col md="8">                    
                    <h2>Datos personales</h2>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccSaveInfo">                        
                        <Form.Check type="checkbox" label="Guardar los datos en mi perfil" checked={fields.ccSaveInfo} onChange={handleFieldChange} />                        
                        </Form.Group>
                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccFirstname">
                        <Form.Label>Nombre*</Form.Label>
                        <Form.Control required placeholder="ej: José"  value={fields.ccFirstname} onChange={handleFieldChange} className={fields.ccFirstname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccLastname">
                        <Form.Label>Apellido*</Form.Label>
                        <Form.Control placeholder="ej: Pérez"  value={fields.ccLastname} onChange={handleFieldChange}  className={fields.ccLastname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccEmail">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.ccEmail} onChange={handleFieldChange}  className={fields.ccEmail == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccEmailConfirm">
                        <Form.Label>Confirmar Email*</Form.Label>
                        <Form.Control type="email" placeholder="" value={fields.ccEmailConfirm} onChange={handleFieldChange}  className={fields.ccEmailConfirm != fields.ccEmail ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccCountry">
                        <Form.Label>Pa&iacute;s*</Form.Label>
                        <Form.Control as="select" value={fields.ccCountry} onChange={handleFieldChange} >                            
                            {renderCountryOptions()}
                        </Form.Control>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <LoaderButton
                            block
                            type="submit"
                            size="lg"
                            isLoading={isLoading}
                            disabled={!validateRegistrationForm()}
                            variant={validateRegistrationForm() ? 'primary' : 'secondary'}                            
                        >
                        Completar Registro
                        </LoaderButton>
                        
                    </Form.Row>
                    
                </Col>
                <Col md="4">
                

                    
                    
                </Col>
            </Row>                

                
            </Form>       
    }

    return renderRegistrationForm()
}