import React from "react";
import Moment from 'react-moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'


// import './Loader.scss'

export default function ShowTitle(props) {
    const { show, ...otherProps } = props

    const dateFormat = (show?.settings?.has_show_dates) ? 'ddd, LL' : 'ddd, LL- LT'

    return <div>
            <div className="show-title primary">
                <>{show.fullname}</>
            </div>
            { !show?.settings?.vod && <div className="show-date">
                <Moment local format={dateFormat}>{new Date(show.show_date)}</Moment>
            </div> }
            <div className="show-venue">
                {show.venue == 'Online' ? 
                <label className="virtual"><FontAwesomeIcon icon={faGlobe} /> evento virtual</label> : 
                <span><label className="presencial"><FontAwesomeIcon icon={faMapMarkerAlt} /> evento presencial</label> {`${show.venue}`}</span>
                }
            </div>
        </div>    
}