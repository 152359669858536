import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle, faMusic } from "@fortawesome/free-solid-svg-icons";
import { faSpotify, faDeezer, faYoutube, faApple, faInstagram, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Loader from 'components/Loader'
import ReactPlayer from 'react-player'

import ShowInfo from "components/shows/ShowInfo";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./jandir.css"
import { render } from "@testing-library/react";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const children = await loadChildShows();            
            setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=ALL`);        
        return children && children.items ? children.items : []
    }

    function renderPlaylist(shows) {
        if (shows) {
            
            return <div className="show-playlist">
                { shows
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY'].includes(p.status))
                    .map( p => {
                    return (
                    <div key={p._id} className={`show-item`}>
                        <div className="show-item-image">
                            <img src={p.main_image} />
                            { ['ACTIVE','LIVE','REPLAY'].includes(p.status) && p.video_url && (
                            <div className="play-overlay">
                                <a 
                                href={`/shows/${p._id}/player/`} target="_blank"
                                // onClick={() => { setPlaylistSelectedId(p._id); loadVideo(show, p._id); }} 
                                className="d-flex justify-content-center align-items-center" 
                                // onClick={() => { trackEvent({
                                //     action: "Clicked Play Overlay",
                                //     category: "Show"
                                // }); }}
                                ><FontAwesomeIcon icon={faPlayCircle} /></a>
                                {['LIVE'].includes(p.status) &&
                                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                                }
                            </div>
                            ) }
                            { (['SOON'].includes(p.status) /*|| !p.video_url*/) && (
                            <div className="soon-overlay">
                                <div>PRÓXIMAMENTE</div>
                            </div>
                            ) }
                        </div>
                        {/* <div className="show-item-content"> */}
                            <div className="show-item-info">
                                <div className="show-item-title">{p.fullname}</div>
                                <div className="show-item-date">{p.settings.date}</div>
                            </div>
                            <div className="show-item-description">
                                <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(p.description)}</Linkify></>                                
                            </div>
                        {/* </div> */}
                    </div>)
    
                })
                }
            </div>
        }
    }

    return (
        !isLoading && show ? <>
        <div className="site-jandir">
            <div className="header">            
                <div className="background" style={{backgroundImage:`url("${show.settings?.channel_image}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="show-info">
                    <div className="show-title">{show.fullname}</div>
                    <div className="show-badges">
                        <div className="show-year">2021</div>
                        <div className="show-rating">0+</div>
                        <div className="show-quality">HD</div>
                    </div>
                    <div className="show-description">
                        <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(show.description)}</Linkify></>
                    </div>
                    <div className="social-links" style={{paddingTop: 20}}>
                        <div><a href="https://www.facebook.com/profile.php?id=100044402140392" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Jandir Rodríguez (Cantautor)</a></div>
                        <div><a href="https://instagram.com/jandir_music" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @jandir_music</a></div>
                        <div><a href="https://www.youtube.com/channel/UCiyN5I3h6gc0Q0n4bWTkwtg" target="_blank"><FontAwesomeIcon icon={faYoutube} /> Jandir Rodríguez</a></div>
                        <div><a href="https://twitter.com/_Fibonacci5" target="_blank"><FontAwesomeIcon icon={faTwitter} /> Jandir Rodríguez</a></div>
                    </div>
                    <div className="social-links">
                        <div><a href="https://open.spotify.com/artist/0d2zrtmfvyNsvosb7JXZ2V?si=mR3Hay_CTk6HtNY8GX3SNw&dl_branch=1" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                        <div><a href="https://deezer.page.link/7Fq5es1KZgJFpS5R6" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                        <div><a href="https://music.apple.com/gt/artist/jandir-rodr%C3%ADguez/1436377872?l=en" target="_blank"><FontAwesomeIcon icon={faApple} /> Music</a></div>
                    </div>
                </div>
            </div>
            <div className="body">

                <div className="song-container">
                    <div className="song-cover"><img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/cover-manana.png" /></div>
                    <div className="song-info">
                        <h1>Mañana</h1>
                        <label>LANZAMIENTO: 26 de septiembre de 2,021</label>
                        <br />
                        <dl>
                            <dt>Letra y música:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Arreglos:</dt><dd>Jandir Rodríguez y Sergio Zepeda</dd>
                            <dt>Voz:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Coros:</dt><dd>Meli Dónis</dd>
                            <dt>Guitarra:</dt><dd>Sergio Zepeda</dd>
                            <dt>Bajo:</dt><dd>Sergei Walter</dd>
                            <dt>Percusiones:</dt><dd>Antonio Gómez Darce</dd>
                            <dt>Grabación y Mezcla:</dt><dd>Pepo Meza</dd>
                            <dt>Producción:</dt><dd>Jandir Rodríguez, Sergio Zepeda, Pepo Meza</dd>
                            <dt>Fotografía y Producción Audiovisual:</dt><dd>Douglas Cruz</dd>
                        </dl>
                        <div className="social-links">
                            <div><a href="https://open.spotify.com/album/6qrU3Jb3OLwyJpa2WEGwUm?si=5niRPN2PTlWp9cq5_X2szw" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                            <div><a href="https://deezer.page.link/QLSC2SixceYo25QB9" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                            <div><a href="https://music.apple.com/gt/album/ma%C3%B1ana-feat-meli-donis-sergio-zepeda/1585369596?i=1585369597" target="_blank"><FontAwesomeIcon icon={faApple} /> Apple Music</a></div>
                        </div>
                    </div>
                    <div className="song-info2"></div>
                </div>
                <div className="song-video">
                    <h1>Video Musical</h1>
                    <div className="song-video-container">
                        {/* <img className="placeholder" src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/video-placeholder.png" /> */}
                        <div className="vimeo">
                        <ReactPlayer
                            className=""
                            url={`https://vimeo.com/614808773`}
                            controls
                            playsinline
                            volume={0.8}
                            width='100%'
                            height='100%'
                        />

                        </div>
                    </div>
                </div>
                <div className="song-gallery">
                    <h1>Galería</h1>
                    <div className="gallery-container">

                    
                    <Carousel className="carousel" showArrows={true}>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery1.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery2.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery3.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery4.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery5.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery6.png" />
                        </div>                        
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery8.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery9.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery10.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery11.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery12.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery13.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery14.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery15.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery16.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery17.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery18.png" />
                        </div>
                    </Carousel>
                    </div>
                </div>



                <div className="song-container">
                    <div className="song-cover"><img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/cover-quiero.png" /></div>
                    <div className="song-info">
                        <h1>Quiero</h1>                        
                        <br />
                        <dl>
                            <dt>Letra y música:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Arreglos:</dt><dd>Jandir Rodríguez y Sergio Zepeda</dd>
                            <dt>Voz:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Coros:</dt><dd>Meli Dónis</dd>
                            <dt>Guitarra:</dt><dd>Sergio Zepeda</dd>
                            <dt>Piano:</dt><dd>Selvin López</dd>
                            <dt>Percusiones:</dt><dd>Antonio Gómez Darce</dd>
                            <dt>Grabación y Mezcla:</dt><dd>Pepo Meza</dd>
                            <dt>Producción:</dt><dd>Jandir Rodríguez, Sergio Zepeda, Pepo Meza</dd>
                        </dl>
                        <div className="social-links">
                            <div><a href="https://open.spotify.com/album/7Co3Q8e8vsEUm4NrpR9eyp?si=JV5YGxfhRqGuukOwg1N6DA" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                            <div><a href="https://deezer.page.link/DAVUxCrUdptTLPyy9" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                            <div><a href="https://music.apple.com/gt/album/quiero/1563348445?i=1563348446&l=en" target="_blank"><FontAwesomeIcon icon={faApple} /> Music</a></div>
                        </div>
                    </div>
                    <div className="song-info2"></div>
                </div>
                <div className="song-video">
                    <h1>Video Musical</h1>
                    <div className="song-video-container">
                        <iframe className="youtube"  src="https://www.youtube.com/embed/7Cb3g1znIZ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                
            </div>
            <div className="footer">
            <h2>Acerca de <b>Jandir Rodríguez</b></h2>
            <div className="content">
                <div>
                <p>Jaime Jandir Rodríguez Centeno es un músico y cantautor nicaragüense. Nació el 5 de septiembre de 1993 en San Rafael del Norte, Jinotega, Nicaragua. Su música abarca temas desde la música testimonial, hasta la trova romántica. Con fuerte influencia del son nica, rock en español, ritmos latinos y de los máximos exponentes de la música latinoamericana, la música de Jandir está comprometida con los cambios sociales, así como con las cotidianidades de la vida y el amor, en los que se inspira para exponer su punto de vista sobre los mismos.</p>

<p>Co-autor del disco “Mi voz por los derechos humanos” en el Campamento Internacional de Cantautores “Mi voz” con el tema “Somos”. Este campamento fue organizado en Guatemala en 2018. El disco fue producido por KC Porter, Iker Gastaminza y Rodolfo Castillo, tres de los mejores productores de la música latina.</p>

<p>Participó en el X Festival de Cantautores “El que la hace la canta” en El Salvador en el año 2019.
Ha realizado conciertos en Guatemala, El Salvador, España, Alemania, Dinamarca, Suecia, Francia y Países Bajos.
Ha compartido escenario con artistas como: Luis Enrique Mejía Godoy, Perrozompopo, Sara Curruchich, Ceshia Ubau, Gaby Baca y Mario Ruiz de la banda Garcín.</p>

<p>En 2020 fue nominado al Premio Vaclav Havel para la Disidencia Creativa, que otorga The Human Rights Foundation.</p>

<p>En 2021 se ha embarcado en su nueva producción musical, de la mano de Sergio Zepeda y Pepo Meza, dos productores guatemaltecos, para grabar su segundo álbum que se llamará “Volar”, del cual se desprende el sencillo “Quiero” y “Mañana” estrenados el 02 de Mayo y 26 de septiembre, respectivamente, en Vívelo Online, Spotify y demás plataformas musicales.</p>
                </div>
                <div className="logos">
                    <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/about.png" />                    
                </div>
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}