import React, { useState } from "react";
import Form from "react-bootstrap/Form"
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { Table } from "react-bootstrap";

export default function Login() {
  const history = useHistory();
  const { login } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);

    try {
      const session = await login(fields.email, fields.password) 
      if (session) {
        console.log(session)
        history.push("/");
      } else {
        Notification('error', 'Usuario o contraseña inválidos')
      }

    } catch (e) {
        onError(e);
        Notification('error', 'Usuario o contraseña inválidos')
        setIsLoading(false);
    }
  }

  return (
    <div className="content content-dark">  
    <Container>
<h1>Política de Cookies</h1>

    <p>
    Este sitio web, al igual que la mayoría de los sitios en Internet, usa <b>Cookies</b> para mejorar y optimizar la experiencia del usuario.</p>

<p>A continuación encontrará información detallada sobre qué son las <b>Cookies</b>, qué tipo de <b>Cookies</b> utiliza este sitio web, cómo puede desactivarlas en su navegador y cómo bloquear específicamente la instalación de <b>Cookies</b> de terceros.</p>




<h2>¿Qué son las Cookies?</h2>
<p>Las <b>Cookies</b> son archivos que los sitios web o las aplicaciones instalan en el navegador o en el dispositivo (smartphone, tablet o televisión conectada) del usuario durante su recorrido por las páginas del sitio o por la aplicación, y sirven para almacenar información sobre su visita.</p>

<p>Como la mayoría de los sitios en internet, este sitio web utiliza <b>Cookies</b> para:
<ul>
<li>Asegurar que las páginas web pueden funcionar correctamente.</li>
<li>Almacenar las preferencias, como el idioma que ha seleccionado o el tamaño de letra.</li>
<li>Conocer la experiencia de navegación del usuario.</li>
<li>Recopilar información estadística anónima, como qué páginas ha visitado el usuario o cuánto tiempo ha permanecido en el sitio web.</li>
</ul>
</p>

<p>El uso de <b>Cookies</b> permite optimizar la navegación, adaptando la información y los servicios ofrecidos a los intereses del usuario, para proporcionarle una mejor experiencia siempre que visita el sitio web.</p>

<p>Este sitio web utiliza <b>Cookies</b> para funcionar, adaptar y facilitar al máximo la navegación del usuario.</p>



<h2>Tipología, finalidad y funcionamiento</h2>
<p>Las <b>Cookies</b>, en función de su permanencia, pueden dividirse en <b>cookies de sesión</b> o <b>permanentes</b>. Las primeras expiran cuando el usuario cierra el navegador. Las segundas expiran cuando se cumple el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado en el sitio web) o bien cuando se borran manualmente.</p>

<p>Adicionalmente, <b>en función de su objetivo</b>, las <b>Cookies</b> puedes clasificarse de la siguiente forma:
<ul>
  <li><b>Estrictamente necesarias (de registro)</b>: Son aquellas imprescindibles para el correcto funcionamiento de la página. Normalmente se generan cuando el usuario accede al sitio web o inicia sesión en el mismo y se utilizan para identificarle en el sitio web con los siguientes objetivos:
    <ul>
      <li>Mantener al usuario identificado de forma que, si abandona el sitio web, el navegador o el dispositivo y en otro momento vuelve a acceder a dicho sitio web, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse.</li>
      <li>Comprobar si el usuario está autorizado para acceder a ciertos servicios o zonas del sitio web.</li>
    </ul>
  </li>
  <li><b>De rendimiento</b> Se utilizan para mejorar la experiencia de navegación y optimizar el funcionamiento del sitio web, como por ejemplo, almacenar las configuraciones de servicios, o almacenar la compra realizada en un carrito de la compra.</li>
  <li><b>De publicidad</b>: Son aquellas que recaban información sobre los anuncios mostrados a los usuarios del sitio web. Pueden ser de dos tipos:
    <ul>
      <li>Anónimas: Solo recopilan información sobre los espacios publicitarios mostrados en el sitio web, con independencia del usuario que accede al sitio web, es decir, sin identificarle expresamente.</li>
      <li>Personalizadas: Recopilan información personal del usuario del sitio web por parte de un tercero, para la personalización de dichos espacios publicitarios.</li>
    </ul>
  </li>
  <li><b>De geo-localización</b>: Estas <b>Cookies</b> se utilizan para averiguar en qué país o región se encuentra el usuario cuando accede a un servicio del sitio web con el fin de ofrecerle contenidos o servicios adecuados a su localización.</li>
  <li><b>De analíticas</b>: Recopilan información sobre la experiencia de navegación del usuario en el sitio web, normalmente de forma anónima, aunque en ocasiones también permiten identificar de manera única e inequívoca al usuario con el fin de obtener informes sobre los intereses de los usuarios en los servicios que ofrece el sitio web.</li>
</ul>
</p>



<h2>Deshabilitar el uso de Cookies</h2>
<p>Si el usuario así lo desea, es posible dejar de aceptar las <b>Cookies</b> del navegador, o dejar de aceptar las <b>Cookies</b> de un servicio en particular.</p>

<p>Todos los navegadores modernos permiten cambiar la configuración de <b>Cookies</b>. Estos ajustes normalmente se encuentra en las "Opciones" o "Preferencias" del menú del navegador.</p>

<p>El usuario podrá, en cualquier momento, deshabilitar el uso de <b>Cookies</b> en este sitio web mediante:
    <ul>
      <li>La configuración del navegador, por ejemplo:
        <ul>
          <li><b>Google Chrome</b>: <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">Ver link</a></li>
          <li><b>Internet Explorer</b>: <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11">Ver link</a></li>
          <li><b>Mozilla Firefox</b>: <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Ver link</a></li>
          <li><b>Apple Safari</b>: <a href=" https://support.apple.com/kb/PH17191?locale=es_ES">Ver link</a></li>
        </ul>
        {/* <ul>
          <li><b>Google Chrome</b>: http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</li>
          <li><b>Internet Explorer</b>: http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11</li>
          <li><b>Mozilla Firefox</b>: http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</li>
          <li><b>Apple Safari</b>: https://support.apple.com/kb/PH17191?locale=es_ES</li>
        </ul> */}
      </li>
      <li>Otras <b>herramientas de terceros</b>, disponibles on-line, que permiten a los usuarios detectar las <b>Cookies</b> en cada sitio web que visita y gestionar su desactivación (por ejemplo, Ghostery: http://www.ghostery.com/privacy-statement).</li>
  </ul>
</p>



<h2>¿Qué ocurre al deshabilitar las Cookies?</h2>
<p>Algunas funcionalidades y servicios pueden quedar deshabilitados o tener un comportamiento diferente al esperado como, por ejemplo, permanecer identificado, mantener las compras en el “carrito de la compra” en un servicio de comercio electrónico o recibir información dirigida a su localización, entre otros.</p>

<p>Si deshabilita el uso de <b>Cookies</b> en este sitio web es probable que deje de poder acceder a ciertas zonas del mismo o que se degrade notablemente la experiencia de navegación en el mismo.</p>




<h2>Cookies utilizadas en este sitio web</h2>
<p>A continuación se muestra una tabla con las <b>Cookies</b> utilizadas en este sitio web, incorporando un criterio de “nivel de intrusividad” apoyado en una escala del 1 al 3, en la que:
    <ul>
    <li><b>Nivel 1</b>: se corresponde con <b>Cookies estrictamente necesarias</b> para la prestación del propio servicio solicitado por el usuario.</li>
    <li><b>Nivel 2</b>: se corresponde con <b>Cookies de rendimiento</b> (anónimas) necesarias para el mantenimiento de contenidos y navegación, de las que solo es necesario informar sobre su existencia.</li>
    <li><b>Nivel 3</b>: se corresponde con <b>Cookies gestionadas por terceros</b> que permiten el seguimiento del usuario a través de webs de las que Tech Ideas, S.A. no es titular. Normalmente se corresponden con <b>Cookies de publicidad o de analíticas</b> donde se identifica al usuario de manera única y evidente.</li>
  </ul>
</p>

<h2>Tabla de cookies utilizadas</h2>
<Table variant="dark" responsive style={{}}>
  <thead>
    <th>Cookie</th>
    <th>Duración</th>
    <th>Propósito</th>
    <th>Intrusividad</th>
  </thead>
  <tr>
    <td>geo</td>
    <td>10 minutos</td>
    <td>Identificador de país y región para el contenido del sitio</td>
    <td>Nivel 1</td>
  </tr>
  <tr>
    <td>sessionID</td>
    <td>10 minutos</td>
    <td>Identificador único de sesión para control del carrito de compras y otras funcinoes</td>
    <td>Nivel 1</td>
  </tr>
  <tr>
    <td>session</td>
    <td>10 minutos</td>
    <td>Información del usuario y sesión actual</td>
    <td>Nivel 1</td>
  </tr>
  <tr>
    <td>CookiesConsent</td>
    <td>10 minutos</td>
    <td>Identificar si el usuario acepta el uso de cookies en este sitio web</td>
    <td>Nivel 2</td>
  </tr>
  <tr>
    <td>_ga</td>
    <td>2 años</td>
    <td>Medición interna con Google Analytics para contar las visitas al sitio</td>
    <td>Nivel 2</td>
  </tr> 
  <tr>
    <td>_gat</td>
    <td>1 minuto</td>
    <td>Medición interna con Google Analytics para filtrar bots</td>
    <td>Nivel 2</td>
  </tr> 
  <tr>
    <td>_gid</td>
    <td>1 día</td>
    <td>Medición interna con Google Analytics para contar las visitas al sitio</td>
    <td>Nivel 2</td>
  </tr> 
  <tr>
    <td>_fbc</td>
    <td>10 minutos</td>
    <td>Medición externa de marketing de Facebook</td>
    <td>Nivel 3</td>
  </tr>
  <tr>
    <td>_fbp</td>
    <td>10 minutos</td>
    <td>Identificador de usuario único para Facebook</td>
    <td>Nivel 3</td>
  </tr>  
  <tr>
    <td>fpestid</td>
    <td>10 minutos</td>
    <td>Medición externa de ShareThis</td>
    <td>Nivel 3</td>
  </tr>
  <tr>
    <td>_gat</td>
    <td>10 minutos</td>
    <td>Medición interna con Google Analytics</td>
    <td>Nivel 2</td>
  </tr>

  <tr>
    <td>_</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
</Table>

<h2>Garantías adicionales y revocación de la aceptación</h2>
<p>Tech Ideas, S.A. no se hace responsable del contenido y veracidad de las políticas de privacidad de los componentes de terceros incluidos en este sitio web.</p>

<p>Como garantía adicional, el registro de Cookies en este sitio web podrá estar sujeto a la Aceptación de Cookies por parte del usuario durante la visita al sitio web, la instalación o puesta al día del navegador usado, y esta aceptación puede en todo momento ser revocada mediante las opciones de configuración de contenidos y privacidad disponibles ya comentadas en el punto 3 de esta Política de cookies.</p>

<h2>Actualización de la Política de cookies</h2>
<p>Tech Ideas, S.A. puede modificar esta Política de cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los usuarios que la visiten periódicamente.</p>

<p>Cuando se produzcan cambios significativos en esta Política de cookies, se comunicará a los usuarios bien mediante la web o a través de correo electrónico a los usuarios registrados.</p>

    </Container>
    </div>
  );
}