import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle, faMusic, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faSpotify, faDeezer, faYoutube, faApple, faInstagram, faFacebook, faTwitter, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { Modal } from "react-bootstrap";
import moment from "moment";

import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Loader from 'components/Loader'
import ReactPlayer from 'react-player'

import Activate from 'containers/Activate'
import ShowInfo from "components/shows/ShowInfo";
import ShowTicketBox from "components/shows/ShowTicketBox";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./jandirvolar.css"
import { render } from "@testing-library/react";

export default function Site(props) {
  const { id, ...otherProps } = props

  const { trackEvent } = useAnalytics();
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const { getCart, addCartItems, removeCartItems } = useCart()  

  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }
    function handleBuyTicket() {
        registerCartItem(show, tickets[1]);
    }

    function handleOpenShow() {
        window.open(`/shows/${show._id}/player`, "_blank");
    }

    async function registerCartItem(selectedShow, ticket) {
        trackEvent({
            action: "Clicked Add to Cart Button",
            category: "PurchaseFunnel"
        });

        const currency = selectedShow?.settings?.currency || 'GTQ'

        const date = moment(selectedShow.show_date).format('ddd, LL - LT')

        const cartItem = {
            ticket_uid: ticket._id,
            type: 'TICKET',                
            show_uid: selectedShow._id,
            show_name: selectedShow.fullname,
            show_date: selectedShow.show_date,
            show_type: selectedShow.type,
            venue: selectedShow.venue,                
            title: `${selectedShow.fullname} (${date})`,
            description: ticket.fullname,
            note: ticket.note,
            ticket_type: ticket.type,
            image: `${selectedShow.main_image}`,
            quantity: 1
        }
        if (ticket.location) {
            cartItem.location = ticket.location
        }

        await addCartItems({ currency: currency, items: [cartItem] })
        await updateCartCounter()
        history.push('/cart',{})
        
    }    

    function handleModalClose() {
        setShowActivationModal(false)        
    }

    async function handleActivationModalSuccess() {
        setShowActivationModal(false);
        window.location.reload(false);

    }

    function renderActivationModal() {
        
        return <Modal size="md" centered show={showActivationModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body><Activate modal handleModalSuccess={async () => await handleActivationModalSuccess() } sessionType="login" /></Modal.Body>        
      </Modal>
    }
    

    return (
        !isLoading && show ? <>
        {renderActivationModal()}
        <div className="site-jandirvolar">
            <div className="header">            
                <div className="background" style={{backgroundImage:`url("${show.settings?.channel_image}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="background-mobile" style={{backgroundImage:`url("${show.settings?.channel_image_mobile}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="channel-info">
                    <div className="show-title">{show.fullname}</div>
                    <div className="show-badges">
                        <div className="show-year">2022</div>
                        <div className="show-rating">13+</div>
                        <div className="show-quality">HD</div>
                    </div>
                    <div className="show-description">
                        <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(show.description)}</Linkify></>
                    </div>
                    <div className="social-links" style={{paddingTop: 20}}>
                        <div><a href="https://www.facebook.com/profile.php?id=100044402140392" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Jandir Rodríguez (Cantautor)</a></div>
                        <div><a href="https://instagram.com/jandir_music" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @jandir_music</a></div>
                        <div><a href="https://www.youtube.com/channel/UCiyN5I3h6gc0Q0n4bWTkwtg" target="_blank"><FontAwesomeIcon icon={faYoutube} /> Jandir Rodríguez</a></div>
                        <div><a href="https://twitter.com/_Fibonacci5" target="_blank"><FontAwesomeIcon icon={faTwitter} /> Jandir Rodríguez</a></div>
                    </div>
                    <div className="social-links">
                        <div><a href="https://open.spotify.com/artist/0d2zrtmfvyNsvosb7JXZ2V?si=mR3Hay_CTk6HtNY8GX3SNw&dl_branch=1" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                        <div><a href="https://deezer.page.link/7Fq5es1KZgJFpS5R6" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                        <div><a href="https://music.apple.com/gt/artist/jandir-rodr%C3%ADguez/1436377872?l=en" target="_blank"><FontAwesomeIcon icon={faApple} /> Music</a></div>
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="show-info">
                    <div className="show-info-border">
                    <div className="show-info-header">
                        <div className="album-image">
                            <img src="https://s3.amazonaws.com/assets.viveloonline.com/shows/621a4b43138d4b20ad89e9fc/Volar+-+Jandir+Rodriguez.png" />
                        </div>
                        <div className="album-info">
                            <div className="album-title">Volar</div>
                            <div className="album-subtitle">Presentación en vivo</div>
                            <div className="show-date">Sábado, 19 de marzo de 2022 • 8:00PM</div>
                            <div className="show-date-alternate">8:00PM Centroamérica • 6:00PM Los Ángeles • 3:00AM Madrid</div>
                            <div className="show-venue"><FontAwesomeIcon icon={faMapMarkerAlt} /> TrovaJazz. Vía 6, 3-55 zona 4, Guatemala</div>
                            <div className="ticket-box">                                                            
                                {viewerShow ? <>
                                    <div className="show-play">
                                        <button onClick={handleOpenShow}>Ver Show</button>
                                    </div>
                                    <div className="show-ticket-info">
                                    <strong>Boleto:</strong> {viewerShow.ticket.fullname}
                                    <br /><strong>Código:</strong> {viewerShow.payment_info.request_code}
                                    </div>
                                    </>
                                    : <>
                                        <div className="show-play">
                                        <button onClick={() => { setShowActivationModal(true); trackEvent({
                            action: "Clicked Start Activate Button",
                            category: "Show"
                        }); }} style={{width:'100%'}} >ACTIVA TU CÓDIGO</button>
                                        </div>
                                    </>
                                }
                                
                            
                            </div>
                        </div>
                    </div>
                    
                    <div className="show-description">
                    Concierto de presentación del disco “Volar” de Jandir Rodríguez, desde el escenario de TrovaJazz.  La presentación se hará en simultáneo de manera presencial para el público local, y virtual para el resto del mundo.   Jandir interpretará todos los temas de esta nueva producción, acompañado de los músicos que le acompañan en el disco, temas de su producción anterior y temas inéditos que no te puedes perder.                    
                    </div>
                    <div className="ticket-info">
                        <label>Concierto Virtual</label>
                        <div className="ticket-info-layout">
                        <div className="ticket-prices">
                            <ul>
                                <li>
                                    <div>• Preventa</div>
                                    <div>Q100 / US$14</div>
                                </li>
                                <li>
                                    <div>• Día del evento</div>
                                    <div>Q120 / US$16</div>
                                </li>
                                <li>                                
                    <b>** El concierto virtual estará disponible por 48hrs luego de la transmisión en vivo.</b>
                                </li>
                            </ul>
                        </div>
                        <div className="ticket-buttons">
                            <div className="ticket">
                                <div className="hole-start"></div>
                                <button onClick={handleBuyTicket} disabled="disabled">Preventa<br />Q100 / US$14<br /><span>Acceso por dispositivo</span></button>
                                <div className="hole-end"></div>
                            </div>
                            <div className="ticket">
                                <div className="hole-start"></div>
                                <button onClick={handleBuyTicket}>Día del evento<br />Q120 / US$16<br /><span>Acceso por dispositivo</span></button>
                                <div className="hole-end"></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="ticket-info">
                        <label>Concierto Presencial</label>
                        <div className="ticket-info-layout">
                        <div className="ticket-prices">
                            <ul>
                                <li>
                                    <div>• Preventa</div>
                                    <div>Q100 / US$14</div>
                                </li>
                                <li>
                                    <div>• Día del evento</div>
                                    <div>Q120 / US$16</div>
                                </li>
                            </ul>
                        </div>
                        <div className="ticket-sales">
                            <div className="ticket-sales-info">Entradas a la venta en</div>
                            <div className="ticket-sales-place">
                                <div className="place-icon"><img src="https://assets.viveloonline.com/shows/621a4b43138d4b20ad89e9fc/trovajazz.png" /></div>
                                <dl>
                                    <dt>Trovajazz</dt>
                                    <dd><FontAwesomeIcon icon={faFacebookMessenger} /> <a href="https://m.me/trovajazz.trovajazz">Escribe ahora</a> o llama al <a href="phone:+50223341241">+502 2334-1241</a></dd>
                                </dl>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="song-container">
                    <div className="song-cover"><img src="https://s3.amazonaws.com/assets.viveloonline.com/shows/621a4b43138d4b20ad89e9fc/Volar+-+Jandir+Rodriguez.png" /></div>
                    <div className="song-info">
                        <h1>Volar</h1>
                        <label>ALBUM | LANZAMIENTO: 19 de marzo de 2,022</label>                        
                        <dl>
                            <dt>1. Amarte</dt><dd></dd>
                            <dt>2. Érase</dt><dd></dd>
                            <dt>3. Mariposa</dt><dd></dd>
                            <dt>4. Luna</dt><dd></dd>
                            <dt>5. Clochard</dt><dd></dd>
                            <dt>6. Libre</dt><dd></dd>
                            <dt>7. Quiero</dt><dd></dd>
                            <dt>8. Nostalgia</dt><dd></dd>
                            <dt>9. Mañana</dt><dd></dd>
                            <dt>10. Cósmosis</dt><dd></dd>
                        </dl>
                        <dl>
                            <dt>Produccido por:</dt><dd>Sergio Zepeda, Eberth "Pepo" Meza, Jandir Rodríguez en Ciudad de Guatemala, Guatemala</dd>
                            <dt>Grabación y Mezcla:</dt><dd>Eberth "Pepo" Meza</dd>
                            <dt>Masterizado por:</dt><dd>José Monzón JJ (JJSound)</dd>

                            <dt>Voz:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Coros y Voz en Luna:</dt><dd>Meli Dónis</dd>
                            <dt>Guitarra:</dt><dd>Sergio Zepeda</dd>
                            <dt>Bajo:</dt><dd>Sergei Walter</dd>
                            <dt>Percusiones:</dt><dd>Antonio Gómez Darce</dd>
                            <dt>Piano:</dt><dd>Roberto Estrada en Érase y Libre. Selvin López en Quiero</dd>
                            
                        </dl>
                        <div className="social-links">
                            <div><a href="https://open.spotify.com/album/1UzevkCQbaBJInxodlXSjy?si=rxHp3KRQTHSPtRMeZhrnaw" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                            <div><a href="https://deezer.page.link/u82Tn1avfsDddMgq8" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                            <div><a href="https://music.apple.com/gt/album/volar/1612711893?l=en" target="_blank"><FontAwesomeIcon icon={faApple} /> Apple Music</a></div>
                        </div>
                    </div>
                    <div className="song-info2"></div>
                </div>

                <div className="song-container">
                    <div className="song-cover"><img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/cover-manana.png" /></div>
                    <div className="song-info">
                        <h1>Mañana</h1>
                        <label>Sencillo | LANZAMIENTO: 26 de septiembre de 2,021</label>
                        <br />
                        <dl>
                            <dt>Letra y música:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Arreglos:</dt><dd>Jandir Rodríguez y Sergio Zepeda</dd>
                            <dt>Voz:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Coros:</dt><dd>Meli Dónis</dd>
                            <dt>Guitarra:</dt><dd>Sergio Zepeda</dd>
                            <dt>Bajo:</dt><dd>Sergei Walter</dd>
                            <dt>Percusiones:</dt><dd>Antonio Gómez Darce</dd>
                            <dt>Grabación y Mezcla:</dt><dd>Pepo Meza</dd>
                            <dt>Producción:</dt><dd>Jandir Rodríguez, Sergio Zepeda, Pepo Meza</dd>
                            <dt>Fotografía y Producción Audiovisual:</dt><dd>Douglas Cruz</dd>
                        </dl>
                        <div className="social-links">
                            <div><a href="https://open.spotify.com/album/6qrU3Jb3OLwyJpa2WEGwUm?si=5niRPN2PTlWp9cq5_X2szw" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                            <div><a href="https://deezer.page.link/QLSC2SixceYo25QB9" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                            <div><a href="https://music.apple.com/gt/album/ma%C3%B1ana-feat-meli-donis-sergio-zepeda/1585369596?i=1585369597" target="_blank"><FontAwesomeIcon icon={faApple} /> Apple Music</a></div>
                        </div>
                    </div>
                    <div className="song-info2"></div>
                </div>
                <div className="song-video">
                    <h1>Video Musical</h1>
                    <div className="song-video-container">
                        {/* <img className="placeholder" src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/video-placeholder.png" /> */}
                        <div className="vimeo">
                        <ReactPlayer
                            className=""
                            url={`https://vimeo.com/614808773`}
                            controls
                            playsinline
                            volume={0.8}
                            width='100%'
                            height='100%'
                        />

                        </div>
                    </div>
                </div>
                <div className="song-gallery">
                    <h1>Galería</h1>
                    <div className="gallery-container">

                    
                    <Carousel className="carousel" showArrows={true}>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery1.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery2.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery3.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery4.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery5.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery6.png" />
                        </div>                        
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery8.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery9.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery10.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery11.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery12.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery13.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery14.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery15.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery16.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery17.png" />
                        </div>
                        <div>
                        <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/gallery18.png" />
                        </div>
                    </Carousel>
                    </div>
                </div>



                <div className="song-container">
                    <div className="song-cover"><img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/cover-quiero.png" /></div>
                    <div className="song-info">
                        <h1>Quiero</h1>  
                        <label>Sencillo | LANZAMIENTO: 2 de mayo de 2,021</label>
                        <br />
                        <dl>
                            <dt>Letra y música:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Arreglos:</dt><dd>Jandir Rodríguez y Sergio Zepeda</dd>
                            <dt>Voz:</dt><dd>Jandir Rodríguez</dd>
                            <dt>Coros:</dt><dd>Meli Dónis</dd>
                            <dt>Guitarra:</dt><dd>Sergio Zepeda</dd>
                            <dt>Piano:</dt><dd>Selvin López</dd>
                            <dt>Percusiones:</dt><dd>Antonio Gómez Darce</dd>
                            <dt>Grabación y Mezcla:</dt><dd>Pepo Meza</dd>
                            <dt>Producción:</dt><dd>Jandir Rodríguez, Sergio Zepeda, Pepo Meza</dd>
                        </dl>
                        <div className="social-links">
                            <div><a href="https://open.spotify.com/album/7Co3Q8e8vsEUm4NrpR9eyp?si=JV5YGxfhRqGuukOwg1N6DA" target="_blank"><FontAwesomeIcon icon={faSpotify} /> Spotify</a></div>
                            <div><a href="https://deezer.page.link/DAVUxCrUdptTLPyy9" target="_blank"><FontAwesomeIcon icon={faDeezer} /> Deezer</a></div>
                            <div><a href="https://music.apple.com/gt/album/quiero/1563348445?i=1563348446&l=en" target="_blank"><FontAwesomeIcon icon={faApple} /> Music</a></div>
                        </div>
                    </div>
                    <div className="song-info2"></div>
                </div>
                <div className="song-video">
                    <h1>Video Musical</h1>
                    <div className="song-video-container">
                        <iframe className="youtube"  src="https://www.youtube.com/embed/7Cb3g1znIZ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                
            </div>
            <div className="footer">
            <h2>Acerca de <b>Jandir Rodríguez</b></h2>
            <div className="content">
                <div>
                <p>Jaime Jandir Rodríguez Centeno es un músico y cantautor nicaragüense. Nació el 5 de septiembre de 1993 en San Rafael del Norte, Jinotega, Nicaragua. Su música abarca temas desde la música testimonial, hasta la trova romántica. Con fuerte influencia del son nica, rock en español, ritmos latinos y de los máximos exponentes de la música latinoamericana, la música de Jandir está comprometida con los cambios sociales, así como con las cotidianidades de la vida y el amor, en los que se inspira para exponer su punto de vista sobre los mismos.</p>

<p>Co-autor del disco “Mi voz por los derechos humanos” en el Campamento Internacional de Cantautores “Mi voz” con el tema “Somos”. Este campamento fue organizado en Guatemala en 2018. El disco fue producido por KC Porter, Iker Gastaminza y Rodolfo Castillo, tres de los mejores productores de la música latina.</p>

<p>Participó en el X Festival de Cantautores “El que la hace la canta” en El Salvador en el año 2019.
Ha realizado conciertos en Guatemala, El Salvador, España, Alemania, Dinamarca, Suecia, Francia y Países Bajos.
Ha compartido escenario con artistas como: Luis Enrique Mejía Godoy, Perrozompopo, Sara Curruchich, Ceshia Ubau, Gaby Baca y Mario Ruiz de la banda Garcín.</p>

<p>En 2020 fue nominado al Premio Vaclav Havel para la Disidencia Creativa, que otorga The Human Rights Foundation.</p>

<p>En 2021 se ha embarcado en su nueva producción musical, de la mano de Sergio Zepeda y Pepo Meza, dos productores guatemaltecos, para grabar su segundo álbum que se llamará “Volar”, del cual se desprende el sencillo “Quiero” y “Mañana” estrenados el 02 de Mayo y 26 de septiembre, respectivamente, en Vívelo Online, Spotify y demás plataformas musicales.</p>
                </div>
                <div className="logos">
                    <img src="https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/about.png" />                    
                </div>
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}