import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import 'moment/locale/es';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Button, Badge, Modal } from "react-bootstrap";

import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";
import { useAppContext } from "libs/contextLib";
import Moment from "react-moment";
// import { useOrder } from "libs/ordersLib";
// import './Loader.scss'

export default function ShowTickets(props) {
    const { show, showDates, onChange, ...otherProps } = props

    const history = useHistory();
    
    useEffect( () => {    

    }, [props.tickets])
    
    function onSelected(event) {
        var selectElement = event.target;
        var value = selectElement.value;
        if (!value) {
            value = null
        }
        onChange ? onChange(value) : console.log('No handler for show date select')
    }

    function renderShowDates() {
        if (show?.settings?.has_show_dates && showDates) {

            const options = [];
            options.push(<option value="" key="--">-- Selecciona una función --</option>)
            showDates.map(s => {
                const name = moment(s.show_date).format('LL - h:mm A')
                //<Moment local format="LLLL">{new Date(s.show_date)}</Moment><
                options.push(<option value={s._id} key={s._id}>{name}</option>)
            })

            return <>
                <section id="dates" ><label>Funciones</label></section>
                <div>
                    <Form.Control as="select" onChange={onSelected}>
                        {options}
                    </Form.Control>
                </div>
           </>
        } else {
            return <></>
        }
    }

    

    return renderShowDates()
}