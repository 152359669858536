import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilm, faUnlock } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import Activate from 'containers/Activate'
import Login from 'containers/Login'


import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { useAnalytics } from "libs/analytics";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./India.css"
import { LinkContainer } from "react-router-bootstrap";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
  const { trackEvent } = useAnalytics();
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showActivationModal, setShowActivationModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);    
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const tickets = await loadShowTickets()            

            const children = await loadChildShows(show);            
            setChildShows(children)
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            } else {
                setViewerShow(null)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadChildShows(show) {
        const children = await API.get("vivelo", `/shows/?parent=${show.parent_show_uid}&&filter=ALL`);        
        return children && children.items ? children.items : []
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        try {
            const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);
            setViewerShow(vShow)
            
            return vShow
        } catch (e) {
            setViewerShow(null)
        }
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }

    function renderVideoPlayer(videoUrl) {
        return <>
            <div className="video-player">
                <ReactPlayer
                    className="react-player"
                    url={videoUrl}
                    controls
                    playsinline                    
                    volume={0.8}
                    width='100%'
                    height='100%'
                    //pip={true}
                    // muted={muted}
                    
                />
            </div>        
            {/* { renderPlayerControls() } */}
            </>
             
      }
    
      function handleModalClose() {
        setShowActivationModal(false)
        setShowLoginModal(false)
    }

    async function handleActivationModalSuccess() {
        setShowActivationModal(false);
        window.location.reload(false);

    }

    function renderActivationModal() {
        
        return <Modal size="md" centered show={showActivationModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body><Activate modal handleModalSuccess={async () => await handleActivationModalSuccess() } /></Modal.Body>        
      </Modal>
    }

    function renderLoginModal() {        
        return <Modal size="md" centered show={showLoginModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ingresa a tu cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body><Login modal /></Modal.Body>        
      </Modal>
    }
    

    return (        
        !isLoading && show ? <>
        { renderLoginModal() }
        { renderActivationModal() }
        <div className="site-india">
            <div className="show-header">
                <LinkContainer to="/festivalcineindia2020" >
                <div className="festival-title">
                    
                    <div className="india-logo"><img src="/img/sites/india/logo.png" /></div>
                    <div className="title">
                        <h1>Festival de cine <span>de la India 2020</span></h1>
                        <h2>Del 9 al 15 de noviembre, 8pm</h2>
                        <h3>Embajada de la India en Guatemala</h3>
                    </div>                    
                </div>
                </LinkContainer>
                <div className="date-box">
                    <div className="month">Nov</div>
                    <div className="date">{show.settings.date}</div>
                </div>
                
            </div>
            <div className="show">
                <div className="poster" ><img src={show.settings.poster_image} /></div>
                <div className="show-info">
                    <div className="info">
                            <div className="title"><b>{show.settings.title}</b> | {show.settings.year}</div>
                            <div className="description">{show.description}</div>                        
                        </div>
                    <div className="movie-info1">
                        <div>
                            <label>Duración</label>
                            <div>{show.settings.duration}</div>
                        </div>
                        <div>
                            <label>Clasificación</label>
                            <div>{show.settings.rated}</div>
                        </div>
                        <div>
                            <label>Publicación</label>
                            <div>{show.settings.year}</div>
                        </div>
                        <div>
                            <label>Idioma Original</label>
                            <div>{show.settings.language}</div>
                        </div>
                        <div>
                            <label>Subtítulos</label>
                            <div>{show.settings.subtitles}</div>
                        </div>
                        <div>
                            <label>Género</label>
                            <div>{show.settings.genre}</div>
                        </div>
                    </div>
                    <div className="movie-info2">
                        <div><label>Director:</label><span>{show.settings.director}</span></div>                        
                        <div><label>Escritor:</label><span>{show.settings.writer}</span></div>
                        <div><label>Productor:</label><span>{show.settings.producer}</span></div>
                        <div><label>Elenco:</label><span>{show.settings.cast}</span></div>
                        <div><label></label><span></span></div>
                    </div>                    
                    { viewerShow ? <div className="ticket">
                        <div>
                            {viewerShow.ticket.fullname}
                        </div>
                        <div>
                            Boleto: {viewerShow.payment_info.request_code} <Badge variant="success">ACTIVADO</Badge>
                        </div> 
                        
                    </div> : 
                    <div className="instructions">
                        * Acceso gratuito: Solicita tu boleto para obtener acceso.
                    </div> }
                        { !['CLOSED'].includes(show.status) && <div className="actions">                         
                        {!viewerShow ? <>
                        <ShowRegistration  show={show} tickets={tickets} onSuccess={handlePaymentSuccess}
                            buttonText="Obtener boleto" style={{}} className="india-tickets"
                        /> 
                        <Button variant="outline-dark" onClick={() => { setShowActivationModal(true); trackEvent({
                            action: "Clicked Start Activate Button",
                            category: "Show"
                        }); }} ><FontAwesomeIcon icon={faUnlock} /> Activar código</Button>
                        </> : <>
                        <a href={`/shows/${show._id}/full`} target="_blank" onClick={(e) => {
                            trackEvent({
                                action: "Clicked Play Button",
                                category: "Show"
                            });
                            if (!viewerShow) {
                                alert('Solicita tu boleto para poder ingresar. Si ya tienes tu boleto activado, por favor inicia sesión para continuar.')
                            }
                            
                            if (!['STARTED','LIVE','REPLAY'].includes(show.status)) {
                                alert('Aún no es posible el ingreso al evento.  Por favor, recarga la página 5 minutos antes del evento y vuelve a intentar.')
                                e.preventDefault()
                                return false
                            }
                            return true                            
                        }} >
                        <Button variant="outline-dark"><FontAwesomeIcon icon={faFilm} /> Ver película</Button>
                        </a>
                        </> }                    
                    </div> }
                </div>
                <div style={{clear:'both'}}></div>
            </div>
            <div className="trailer">
                <label>Trailer</label>
                {renderVideoPlayer(show.settings.trailer)}
                {/* {ReactHtmlParser('<iframe src="https://player.vimeo.com/video/452880459" width="350" height="196" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>')} */}
            </div>
            <div className="body">
                <h1>Cartelera</h1>
                <div className="events">
                    { childShows && childShows.filter(s => s._id != id).map(s => {                        
                        return <Link key={`name-${s._id}`} to={`/festivalcineindia2020/${s.settings.path}`}>
                        <div className="movie">
                        <div className="poster" ><img src={s.settings.poster_image} /></div>
                        <div className="info">
                            <div>
                                <div className="title"><b>{s.settings.title}</b> | {s.settings.year}</div>
                                <div className="genre">{s.settings.genre}</div>
                            </div>
                            <div>
                                <div className="month">Nov</div>
                                <div className="date">{s.settings.date}</div>
                            </div>
                        </div>
                    </div></Link>
                    })}
                    
                </div>
            </div>                
                            
        </div>
        </> : <Loader />
  );
}