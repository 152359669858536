import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { onError, Notification } from "libs/errorLib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'


import QuickBuyForm from "components/cart/QuickBuyForm";

import { useAppContext } from "libs/contextLib";

export default function ShowQuickBuy(props) {
    const history = useHistory(); 
    const { id } = useParams();
    const { getSession, updateUser, updateCartCounter } = useAppContext()
    const [user, setUser] = useState(null)
    const [show, setShow] = useState(null);
    const [tickets, setTickets] = useState(null);
    const [showMap, setShowMap] = useState(null);
    const [showDates, setShowDates] = useState(null);
    const [selectedShowDate, setSelectedShowDate] = useState(null);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);


    useEffect(() => {

        // const searchParams = new URLSearchParams(location.search);
        // if (searchParams.get('activated') == "1") {
        //     //Notification('success','Tu boleto ha sido activado exitosamente')
        //     setShowActivationModal(false)        
        // }
    
        async function onLoad() {
            try {           
                setIsLoading(true)
                const session = await getSession()
                if (session) {
                    setUser(session?.user)
                }
                
                const show = await loadShow();
                if (show?.settings?.is_show_date) {
                    history.push(`/shows/${show.parent_show_uid}/quicklink`)
                    return;
                }
                setShow(show)                
    
                if (show?.settings?.has_show_dates) {
                    loadChildShows();                
                } else {
                    setSelectedShowDate(show)
                }
                                
    
            } catch (e) {
                onError(e);
            } finally {
                setIsLoading(false)
            }
        }
    
        onLoad();
    }, [id]);
    
    useEffect(() => {
        async function loadShowDateTickets() {
            try {
                setIsLoadingData(true)
                if (selectedShowDate) {
                    await loadShowTickets(selectedShowDate._id)
                    if (selectedShowDate?.settings?.has_map) {
                        loadShowMap(selectedShowDate._id)
                    }
                } else {
                    setTickets(null)
                }
            } finally {
                setIsLoadingData(false)
            }
        }
        loadShowDateTickets();
    }, [selectedShowDate]);

    useEffect(() => {        
    }, [isLoadingData]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets(show_uid) {
        const tickets = await API.get("vivelo", `/shows/${show_uid}/tickets?paymentType=BANK_DEPOSIT|ACCOUNT_BANK_DEPOSIT`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        } else {
            setTickets(null)
        }
        return tickets
    }    
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=CURRENT&visible=-1`);
        const dates = children && children.items ? children.items : []
        setShowDates(dates)
    }

    async function loadShowMap(show_uid) {
        const map = await API.get("vivelo", `/shows/${show_uid}/map`);        
        setShowMap(map)
    }

    function onShowDateChange(show_id) {
        const selected = showDates.find(s => s._id == show_id)
        setSelectedShowDate(selected)
    }


    function onSuccess(payment_info, purchase) {
        history.push(`/shows/${show._id}/quicklink/success/${purchase.id}`)
    }

    function render() {
        return !isLoading && show && <div className="show-container quicklink" >
            <div className="show-header" style={{background:'black'}} >
                <div><b>Link de compra</b></div>
                <div className="social-icons">
                    <a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Vívelo Online</a>
                    <a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @vivelo.online</a>
                </div>
                <div className="small-logo">
                    <img src="/img/vivelo-logo.png" className="small-logo" />
                </div>
            </div>
            <QuickBuyForm user={user} sessionId={Cookie.get('sessionID')} show={show} tickets={tickets} 
                showDates={showDates} showMap={showMap} onShowDateChange={onShowDateChange} 
                selectedShowDate={selectedShowDate}
                bankDepositInfo={selectedShowDate?.settings?.deposit_info}
                isLoadingData={isLoadingData}
                onSuccess={onSuccess}
                />
        </div>
    }

    return render()
}