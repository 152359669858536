import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Container, Row, Col, Button } from "react-bootstrap/";
import { LinkContainer } from "react-router-bootstrap";
import Moment from 'react-moment';
import { API } from "aws-amplify";

import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


export default function ShowsList() {
  const history = useHistory();
  const [shows, setShows] = useState([]);
  const {isAuthenticated, setIsAuthenticating, logout } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function onLoad() {
      try {
        const shows = await loadShows();
        setShows(shows)        
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadShows() {

    let shows = API.get("vivelo", "/shows?filter=PAST");
    return shows || { items: [] }    
  }

  function renderShowInfo2(show) {      
    return <Row className="show" noGutters>
      <div className="background" style={{backgroundImage: `url(${show.main_image})`}}>
      <LinkContainer key={`name-${show._id}`} to={`/shows/${show._id}`}>
        <div className="layer">
          <div className={`show-title primary`}>                  
            <>{show.fullname}</>
          </div>
          <div className={`show-date primary`}>
            <Moment local format="ddd, LL - LT">{new Date(show.show_date)}</Moment>
          </div>
        </div>
        </LinkContainer>
      </div>

    </Row>
  }

  function renderShowsList(shows) {    

    let items = shows ? shows.items : []

    let primaryShow = null;
    let secondaryShows = [];
    items.map((show, i) => {
        if (i === 0) {
            primaryShow = renderShowInfo2(show)
        } else {
            secondaryShows.push(renderShowInfo2(show))
        }
    })

    return <>
        <div className="content">
            <Container>                                    
                        {primaryShow}
                        {secondaryShows}                                        
            </Container>
        </div>
        </>      
  }

  function renderShows() {
    return (
      <div className="shows">        
        {!isLoading && renderShowsList(shows)}
      </div>
    );
  }

  return (
    <div className="Home">
      {renderShows()}      
    </div>
  );
}