import React, { useState } from "react";
import 'moment/locale/es';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';

import Activate from 'containers/Activate'
import Login from 'containers/Login'

import { useAnalytics } from "libs/analytics";
import { useAppContext } from "libs/contextLib";
// import './Loader.scss'

export default function ShowTicketBox(props) {
    const { show, viewerShow, tickets, ...otherProps } = props

    const { trackEvent } = useAnalytics();
    const {isAuthenticated, getSession } = useAppContext();

    const [showActivationModal, setShowActivationModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);    

    function renderShowStatusMessage() {
        switch (show.status) {
            case 'CLOSED': return 'Este evento ha terminado';
            default: return 'Este evento aún no ha iniciado. Revisa la página 5 minutos antes de la hora del evento para ver la transmisión.'
        }
    }

    function generateGoogleCalendarLink(show) {
        const text = encodeURIComponent(show.fullname)
        const details = encodeURIComponent(`${show.fullname}\nVívelo Online en https://viveloonline.com/shows/${show._id}/`);
        const showDate = moment.utc(show.show_date)        
        const dates = `${showDate.format('YYYYMMDDTHHmmss')}Z/${showDate.add(show?.settings?.duration || 2,'hours').format('YYYYMMDDTHHmmss')}Z`;
        return `https://calendar.google.com/calendar/r/eventedit?text=${text}&details=${details}&dates=${dates}`
    }

    function handleModalClose() {
        setShowActivationModal(false)
        setShowLoginModal(false)
    }

    async function handleActivationModalSuccess() {
        setShowActivationModal(false);
        window.location.reload(false);

    }

    function renderActivationModal() {
        
        return <Modal size="md" centered show={showActivationModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body><Activate modal handleModalSuccess={async () => await handleActivationModalSuccess() } /></Modal.Body>        
      </Modal>
    }

    function renderLoginModal() {        
        return <Modal size="md" centered show={showLoginModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ingresa a tu cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body><Login modal /></Modal.Body>        
      </Modal>
    }

    function render() {
        const renderPlayButton = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || ((viewerShow || !show.paid) && ['STARTED','LIVE','REPLAY'].includes(show.status) )
        
        const renderTicketInfo = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || (viewerShow && ['STARTED','LIVE','REPLAY'].includes(show.status) )
        
        const renderCalendar = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || (viewerShow && ['STARTED','LIVE','REPLAY'].includes(show.status) )

        const renderGetTickets = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || (viewerShow && ['STARTED','LIVE','REPLAY'].includes(show.status) )

        const renderLogin = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || (viewerShow && ['STARTED','LIVE','REPLAY'].includes(show.status) )

        const renderActivate = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || (viewerShow && ['STARTED','LIVE','REPLAY'].includes(show.status) )

        return (<div>
            { renderLoginModal() }
            { renderActivationModal() }

            { renderPlayButton ? 
                <Container className="ticket-box">
                <Row>
                    <Col>
                    <a href={`/shows/${show._id}/player`} target="_blank" onClick={() => {trackEvent({
                        action: "Clicked Play Button",
                        category: "Show"
                    });}} ><Button style={{width:'100%', marginBottom:10}} variant="success"><FontAwesomeIcon icon={faPlayCircle} /> Ver Show</Button></a>                            
                    </Col> 
                </Row>
                </Container>
            : viewerShow 
            ? <Container className="ticket-box">
                { renderPlayButton 
                ? <Row>
                    <Col>
                    <a href={`/shows/${show._id}/player`} target="_blank" onClick={() => {trackEvent({
                        action: "Clicked Play Button",
                        category: "Show"
                    });}} ><Button style={{width:'100%', marginBottom:10}} variant="success"><FontAwesomeIcon icon={faPlayCircle} /> Ver Show</Button></a>                            
                    </Col> 
                </Row>
                : <Row>
                        <Col style={{paddingBottom:10, fontFamily: 'Montserrat', fontSize: '0.8rem', fontWeight: 'bold', color: '#0ecbb2'}}>
                            { renderShowStatusMessage() }
                        </Col>
                </Row> }
            <Row style={{marginBottom:40, paddingBottom:10, borderBottom:'solid 1px', fontSize:'1.2rem'}}>
                <Col md="12" className="col-12">
                <div>
                Boleto: {viewerShow.payment_info.request_code} <Badge variant="success">ACTIVADO</Badge>
            </div>
            <div>
                {viewerShow.ticket.fullname}
            </div>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <a href={generateGoogleCalendarLink(show)} target="_blank" onClick={() => {
                    trackEvent({
                        action: "Clicked Add to Calendar",
                        category: "Goodies"
                    });
                }}  >
                    <Button style={{width:'100%'}} variant={props.addToCalendarButtonVariant || "primary"}>Agregar a Google Calendar</Button>
                </a>
                </Col>
            </Row>                        
        </Container>
        : <Container className="ticket-box">        
            <Row style={{paddingBottom:10}}>
                <Col>
                { tickets && tickets.length && ['ACTIVE','STARTED','LIVE','REPLAY'].includes(show.status) &&
                    <HashLink smooth to={`#tickets`} style={{width:'100%'}} className="tickets-button" >
                        <Button onClick={() => { trackEvent({ action: "Clicked Tickets Button", category: "PurchaseFunnel" }); }} v variant={props.ticketsButtonVariant || "success"} style={{width:'100%'}} >OBTENER BOLETOS</Button>
                    </HashLink>
                }
                </Col>
            </Row>
            { !isAuthenticated && <>
            <Row style={{paddingBottom:10}}>
                <Col><Button variant={props.loginButtonVariant || "info"} onClick={() => { setShowLoginModal(true); trackEvent({
                        action: "Login",
                        category: "Show"
                    }); }} style={{width:'100%'}} >INICIAR SESIÓN</Button></Col>
            </Row>                        
            </> }
            { show.type != 'VENUE' && ['ACTIVE','STARTED','LIVE','REPLAY','TEST'].includes(show.status) && (show.paid || show?.settings?.registration) && <>                        
            <Row>
                <Col><Button variant={props.activateButtonVariant || "primary"} onClick={() => { setShowActivationModal(true); trackEvent({
                        action: "Clicked Start Activate Button",
                        category: "Show"
                    }); }} style={{width:'100%'}} >ACTIVA TU CÓDIGO</Button></Col>
            </Row> 
            </> }                        
        </Container>
        }
        </div> )
    }

    return render()
}