import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faPlay, faTicketAlt, faUnlock, faWarehouse, faQrcode, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Row, Col, Button } from "react-bootstrap";

import { useAnalytics } from "libs/analytics";

import config from 'config'
import './Cart.css'

export default function OrderSummaryBox({ order, totalsOnly, hideCodes: hideCodes = false, ...otherProps }) {    

    const { trackEvent } = useAnalytics();
    
    useEffect(() => {
        onLoad()
        
    }, []);

    async function onLoad() {
        
    }

    function getCurrencyValue(order, item, property) {
        const suffix = order.currency == 'USD' ? '_usd' : ''
        return item[`${property}${suffix}`]
    }

    function renderPrice(order, price) {
        const currency = order.currency == 'USD' ? 'US$ ' : 'Q '
        const sanitizedPrice = price || 0
        return `${currency}${sanitizedPrice.toFixed(2)}`
    }

    function renderCode(code) {
        return code.split('-')[1]
    }

    function renderTicketIcon(code) {
        switch(code.ticket_type) {
            case 'VENUE_OPEN':
            case 'VENUE_SEAT':
            case 'VENUE_TABLE_SEAT':
                return <><FontAwesomeIcon icon={faQrcode} /> </>
                break;
            
            default:
                return <><FontAwesomeIcon icon={faTicketAlt} /> </>
        }
    }

    function renderQrCode(code) {
        const qrcode = code.qrcode
        const darkColor = darkColor || '000000'
        switch(code.ticket_type) {
            case 'VENUE_OPEN':
            case 'VENUE_SEAT':
            case 'VENUE_TABLE_SEAT':
                return <><img src={`https://devapi.viveloonline.com/etickets/qr?str=${qrcode}&d=${darkColor}`} width="200" height="200" /><br/><span className="code" style={{width:200,display:'inline-block',marginRight:10,padding:0,textAlign:'center'}}>{renderCode(code.code)}</span></>                
            
            default:
                return <>{renderTicketIcon(code)}Código: <span className="code">{renderCode(code.code)}</span> </>
        }
    }

    function renderCodeList(order, ticket) {
        // const codes = order.codes.filter(c => c.ticket_uid == ticket.ticket_uid)                
        const codes = order.codes.filter(c => c.order_ticket_uid == ticket._id)
        
        if (codes.length > 0) {            

            return codes.map(c => {
                const code = renderCode(c.code)
                const activateLink = `${config.baseUrl}/activate?code=${code}`
                const playLink = `${config.baseUrl}/shows/${ticket.show_uid}/player?code=${code}`
                const whatsappLink = `https://wa.me?text=Tu boleto para "${ticket.show_name}": ${code}.   Actívalo en ${playLink}`

                return <div className="ticket">
                    {renderQrCode(c)}                                        
                { ticket.show_type != 'VENUE' && <>
                <Button size="sm" variant="outline-light"
                    onClick={() => {
                        window.open(activateLink,'_blank')
                    }}
                    ><FontAwesomeIcon icon={faUnlock} /> ACTIVAR</Button> 
                &nbsp;<Button size="sm" variant="outline-light"
                    onClick={() => {
                        window.open(playLink,'_blank')
                    }}
                    ><FontAwesomeIcon icon={faPlay} /> VER SHOW</Button>
                &nbsp;| <Button size="sm" variant="outline-light"
                    onClick={() => {
                        window.open(whatsappLink,'_blank')
                    }}
                    ><FontAwesomeIcon icon={faWhatsapp} /> ENVIAR POR WHATSAPP</Button>
                    </> }
                </div>
            })
        } else {
            if (ticket.quantity > 1) {
                return <div className="ticket">Tus códigos aún se están generando.  <a href="#" className="code" onClick={() => window.location.reload()}>RECARGAR</a></div>
            } else {
                return <div className="ticket">Tu código aún se está generando.  <a href="#" className="code" onClick={() => window.location.reload()}>RECARGAR</a></div>
            }
        }
    }

    function renderVenue(ticket) {        
        switch(ticket.show_type) {
          case 'VENUE':
            return <div class="show-venue"><span><label className="presencial"><FontAwesomeIcon icon={faMapMarkerAlt} /> evento presencial</label> {ticket.venue}</span></div>
          break;
    
          default:
            return <div class="show-venue"><label className="virtual"><FontAwesomeIcon icon={faGlobe} /> evento virtual</label></div>
          break;
        }
      }

    function renderItemPriceDetail(order, ticket) {
        
        const itemPrice = <>{ticket.quantity} x {renderPrice(order, getCurrencyValue(order, ticket, 'price'))}</>
        const itemDiscountPrice = <>{ticket.quantity} x {renderPrice(order, getCurrencyValue(order, ticket, 'discount_price'))}</>

        if (ticket.discount_price > 0) {
            return <><span style={{textDecoration:'line-through'}}>{itemPrice}</span><br />{itemDiscountPrice}</>
        } else {
            return itemPrice
        }
    }

    function renderSeatInfo(t) {
        const info = []
        info.push(t.description)
        switch(t.ticket_type) {
            case 'VENUE_SEAT': {
                info.push(`Sección: ${t.location.section}`)
                info.push(`Fila: ${t.location.row}`)
                // info.push(t.location.table)
                info.push(`Asiento: ${t.location.seat}`)
                break;
            }
            case 'VENUE_TABLE_SEAT': {
                info.push(`Sección: ${t.location.section}`)
                // info.push(`Mesa: ${t.location.table}`)
                // info.push(t.location.table)
                info.push(`Asiento: ${t.location.seat}`)
                break;
            }
            case 'VENUE_OPEN': {
                info.push(`Sección: ${t.location.section}`)
                break;
            }
        }
        return info.join(' / ')
    }
    
    function renderTicketsSummary(order) {
        // const cart = getCart()
        let items = order ? order.tickets || [] : [];
        let ticketsTotal = 0
        let feeTotal = 0
        const tickets = items.map(t => {
            ticketsTotal += getCurrencyValue(order, t, 'total_price')
            feeTotal += getCurrencyValue(order, t, 'total_fee')
            return !totalsOnly ? <>
                <div className="cart-item cart-item-ticket" key={t._id}>                    
                    <div className="cart-item-info">
                        <div className="cart-item-image">
                            <img src={`${t.image}`} />
                        </div>
                        <div>
                            <div className="cart-item-title">
                                <a href={`/shows/${t.show_uid}`}>{`${t.title}`}</a>
                            </div>
                            <div className="cart-item-description">
                                {renderVenue(t)} • {renderSeatInfo(t)}
                            </div>                            
                            <div className="cart-item-note">
                                {`${t.note}`}
                            </div>
                            { t.bundle_items && <div className="cart-item-note">
                                { t.bundle_items.map(item => {
                                    return `• ${item.quantity} x ${item.description}`
                                }).join('<br />')}                                
                            </div> }
                            { t.collect_only && <div className="cart-item-note">
                                {`**${t.collect_message}`}
                            </div> }
                        </div>
                        <div></div>
                        <div className="cart-item-ticketbox">                            
                            {/* <Button >{t.quantity > 1 ? 'Ver boletos' : 'Ver boleto'}</Button> */}
                            <div className="cart-item-ticketlist">
                                { !hideCodes && order.status == 'APPROVED' && renderCodeList(order, t) }
                            </div>
                        </div>
                    </div>
                    <div className="cart-item-numbers">
                        <div className="cart-item-price">
                            {renderItemPriceDetail(order, t)}
                        </div>
                        {!t.fee_included && <div className="cart-item-fee">
                            Servicio: {t.quantity} x +{renderPrice(order, getCurrencyValue(order, t, 'fee'))}
                        </div> }
                    </div>
                </div>
                </> : null
        })
        // setTicketsTotal(ticketsTotal)
        return <div className="cart-item-group">
            { <>{tickets}

                <div className="cart-subtotal">
                    <div className="cart-subtotal-info">Subtotal Boletos</div>
                    <div className="cart-subtotal-numbers">{renderPrice(order, ticketsTotal)}</div>
                    <div className="cart-subtotal-info">Cargo por servicio de boletería</div>
                    <div className="cart-subtotal-numbers">+ {renderPrice(order, feeTotal)}</div>
                </div>
                </>
                }
            </div>
    }

    function renderOrderSummary(order) {

        const tickets = renderTicketsSummary(order)
        
        
        return <div className="cart-summary-box">
                {tickets}
                <div className="cart-total">
                    <div className="cart-total-info">TOTAL</div>
                    <div className="cart-total-numbers">{renderPrice(order, order.total)}</div>
                </div>
            </div>
    }    

    return order && renderOrderSummary(order)
}