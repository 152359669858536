import React from "react";
import { Component } from "react";
import { Button } from "react-bootstrap/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle as farCheckCircle, faSmile as farSmile } from '@fortawesome/free-regular-svg-icons'
import { faPaperPlane, faComment, faTimes, faPollH, faHandPointer, faMobileAlt, faChartPie } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import Linkify from 'react-linkify';
import { Picker } from 'emoji-mart';
import QRCode from 'react-qr-code';
import { base64encode } from "nodejs-base64";
import ReactHtmlParser from 'react-html-parser';

import 'moment/locale/es';
import { API } from "aws-amplify";
import config from '../config';

import "./Chat.css";
import 'emoji-mart/css/emoji-mart.css'

import { db } from '../libs/firebase';



class Chat extends Component {
    messagesRef = React.createRef()

    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            socket: null,
            messageText: "",
            messages: [],
            chatVisible: props.chatVisible == undefined ? false : props.chatVisible,
            pollVisible: props.pollVisible == undefined ? false : props.pollVisible,
            chatEnabled: props.chatEnabled == undefined ? true : props.chatEnabled,
            pollEnabled: props.pollEnabled == undefined ? true : props.pollEnabled,
            chatButtonVisible: props.chatButtonVisible == undefined ? true : props.chatButtonVisible,
            pollButtonVisible: props.pollButtonVisible == undefined ? true : props.pollButtonVisible,

            activePoll: null,
            pollAnswered: false,
            pollNotification: false,

            emojiPickerVisible: false,
            emojiPickerStyle: { display: 'none' },
            
            activeSession: true,
            sessionUsername: '',

            mobileChatUrl: ''
        };
        //setTimeout(() => this.setState({ pollNotification: true }), 5000)

        
    }
    linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
    );

    customEmojis = [        
        {
          id: 'vivelo1',
          name: 'Vivelo1',
          short_names: ['vivelo1'],
          text: '\\o/',
          emoticons: ['\\o/'],
          keywords: ['vivelo'],
          imageUrl: 'https://viveloonline.com/img/isotipo200.png',
          customCategory: 'Vívelo',
        },
      ]
    
    emoji_i18n = {
        search: 'Buscar',
        clear: 'Limpiar', // Accessible label on "clear" button
        notfound: 'Emoji no encontrado',
        skintext: 'Choose your default skin tone',
        categories: {
        search: 'Buscar',
        recent: 'Populares',
        smileys: 'Emociones',
        people: 'Emociones y Personas',
        nature: 'Animales y Naturaleza',
        foods: 'Comida y Bebida',
        activity: 'Actividades',
        places: 'Lugares',
        objects: 'Objectos',
        symbols: 'Símbolos',
        flags: 'Banderas',
        custom: 'Custom',
        },
        categorieslabel: 'Emoji categories', // Accessible title for the list of categories
        skintones: {
        1: 'Default Skin Tone',
        2: 'Light Skin Tone',
        3: 'Medium-Light Skin Tone',
        4: 'Medium Skin Tone',
        5: 'Medium-Dark Skin Tone',
        6: 'Dark Skin Tone',
        },
    }
    

    componentDidMount() {

        this.setChannel()
    
        if (this.state.chatVisible && this.state.activeSession) {            

            try {
                db.ref(`chats-${this.props.channel}/messages`).limitToLast(100).on("child_added", snapshot => {                
                    const data = snapshot.val()                
                    this.onMessage(data)
                });
                db.ref(`polls-${this.props.channel}`).limitToLast(100).on("child_changed", snapshot => {                
                    const data = snapshot.val()
                    data.key = snapshot.key
                    this.onPoll(data)
                });
                db.ref(`polls-${this.props.channel}`).once("value", snapshot => {
                    console.log('snapshot', snapshot)
                    if (snapshot) {
                        let data = null
                        snapshot.forEach(s => {
                            const val = s.val()
                            if (val.config.status == 'ACTIVE') {
                                data = val
                                data.key = s.key
                            }
                        })
                        if (data) {
                            console.log(data)
                            this.onPoll(data)
                        }
                    }
                });
            } catch (error) {
                this.setState({ readError: error.message, loadingChats: false });
            }
        }
    }

    setSessionUsername(event) {
        let sessionUsername = this.state.sessionUsername
        this.setState({ sessionUsername: event.target.value })
        // console.log(this.state)
    }

    startChatSession() {
        // this.props.session.user_uid = '5edc79d60ee327132db0d30d';//this.state.sessionUsername;
        this.props.session.username = this.state.sessionUsername;
        this.setState({ activeSession: true })

        this.setChannel()
        // this.setChannel(this.state.socket)
    }

    validateSessionForm() {
        return this.state.sessionUsername.length > 0;
    }

    loadPollAnswer = async (show_uid, poll_uid, user_uid) => {
        try {
            const answer = await API.get("vivelo", `/shows/${show_uid}/polls/${poll_uid}/answers/${user_uid}`);        
            return answer
        } catch(err) {
            console.log(err)
            return null
        }
    }

    // setState(socket) {
    //     this.state.socket = socket
    // }
    scrollToMyRef = () => {
        if (this.messagesRef && this.messagesRef.current) {
            const scroll = this.messagesRef.current.scrollHeight - this.messagesRef.current.clientHeight;
            this.messagesRef.current.scrollTop = scroll
            // this.messagesRef.current.scrollTo(0, scroll);
        }
      };

    onOpen = ev => {
        // console.log("> Connected!", ev);
        this.setChannel(this.state.socket)

        // setTimeout(() => this.onMessage({
        //     data: JSON.stringify({
        //         type: 'poll-notification',
        //         status: 'ACTIVE',
        //         poll: {
        //             _id: "5ee6929325558bb028317447",
        //             show_uid: "5edc79d60ee327132db0d30d",
        //             question: 'Qué canción prefieres escuchar primero?',
        //             options: [
        //                 { _id: "5ee6925c25558bb028317444", text: 'Contigo' },
        //                 { _id: "5ee6925d25558bb028317445", text: 'Sin Embargo' },
        //                 { _id: "5ee6925f25558bb028317446", text: 'Ojala' },
        //             ],
        //             results: [
        //                 { _id: '5ee6925c25558bb028317444', text: 'Contigo', percent: 13, count: 3 },
        //                 { _id: '5ee6925d25558bb028317445', text: 'Sin Embargo', percent: 77, count: 17  },
        //                 { _id: '5ee6925f25558bb028317446', text: 'Ojala', percent: 9, count: 2  },
        //             ],
        //             status: 'ACTIVE'
        //         }
        //     })
        // }), 5000)
    };

    onMessage = async data => {
        // console.log("> Received:", ev.data);
        // const data = JSON.parse(ev.data)

        let handled = false;
        if (this.props.socketOnMessage) {
            handled = await this.props.socketOnMessage(data)
        }
        if (handled) {
            console.info('socket message already handled')
            return;
        }

        if (data.type == 'init') {
            // this.setState({ 
            //     messages: data.messages.reverse(),
            //     activePoll: data.poll,
            //     pollAnswered: data.pollAnswered || this.props.pollReadOnly
            //  }, () => this.scrollToMyRef())
        }
        else if (['message','notification'].includes(data.type)) {
            this.setState(state => {
                let msgs = [...state.messages, data]
                // console.log(msgs)
                return {
                    messages: msgs
                }            
            }, () => this.scrollToMyRef());
        }
        else if (data.type == 'poll-notification') {
            if (data.status == 'ACTIVE') {
                let answered = true
                if (!this.props.pollReadOnly) {
                    const answer = await this.loadPollAnswer(data.poll.show_uid, data.poll._id, this.props.session.user_uid)
                    console.log("ANSWER", answer)
                    if (!answer) {
                        answered = false
                    }
                }
                this.setState({ activePoll: data.poll, pollNotification: true, pollAnswered: answered })
            } else if (data.status == 'CLOSED') {
                this.setState({ activePoll: null, pollNotification: false })
            }
        }
        else if (data.type == 'poll-results') {
            if (this.state.pollAnswered) {
                this.setState({ activePoll: data.poll })
            } else {
                if (this.state.activePoll._id != data.poll._id) {
                    const answer = await this.loadPollAnswer(data.poll.show_uid, data.poll._id, this.props.session.user_uid)
                    if (answer) {
                        this.setState({ pollAnswered: true })
                    }
                }
                this.setState({ activePoll: data.poll })
            }
        }

        
    };

    onPoll = async data => {
        console.log('poll', data)
        // else if (data.type == 'poll-notification') {
            if (data.config.status  == 'ACTIVE') {
                let answered = true
                if (!this.props.pollReadOnly && this.props?.session?.user_uid) {
                    await db.ref(`polls-${this.props.channel}-answers/${data.key}/${this.props?.session?.user_uid}`).once('value')
                    .then(snapshot => {
                        if (!snapshot.exists()) {
                            answered = false
                        }
                        this.setState({ activePoll: data, pollNotification: true, pollAnswered: answered })
                    })
                } else {
                    this.setState({ activePoll: data, pollNotification: true, pollAnswered: answered })
                }
            } else if (data.config.status  == 'CLOSED') {
                this.setState({ activePoll: null, pollNotification: false })
            }
        // }
        // else if (data.type == 'poll-results') {
        //     if (this.state.pollAnswered) {
        //         this.setState({ activePoll: data.poll })
        //     } else {
        //         if (this.state.activePoll._id != data.poll._id) {
        //             const answer = await this.loadPollAnswer(data.poll.show_uid, data.poll._id, this.props.session.user_uid)
        //             if (answer) {
        //                 this.setState({ pollAnswered: true })
        //             }
        //         }
        //         this.setState({ activePoll: data.poll })
        //     }
        // }
    }

    sendMessage = async _ => {
        this.toggleEmojiPickerVisible(false)

        if (!this.state.messageText) 
            return;

        const data = {            
            show_uid: this.props.session.show_uid,
            username: this.props.session.username,
            type: this.props.admin ? 'notification': 'message',
            message: this.state.messageText,
            time: new Date().toISOString()
        }
        if (this.props.session?.user_uid)
            data.user_uid = this.props.session.user_uid
        if (this.props.session?.viewer_uid)
            data.viewer_uid = this.props.session.viewer_uid

        db.ref(`chats-${this.props.channel}/messages`).push( data )

        const messageCountRef = db.ref(`chats-${this.props.channel}/stats/message_count`);
        messageCountRef.transaction( (currentMessageCount) => {
            return (currentMessageCount || 0) + 1
        })

        this.setState({ messageText: '' })
        
    };
    
    sendPollAnswer = (poll_key, index, answer) => {
        const data = {      
            poll_key: poll_key,
            show_uid: this.props.session.show_uid,
            username: this.props.session.username,
            option_uid: answer.id,
            option_text: answer.text,
            createdAt: new Date().toISOString()
        };
        if (this.props.session?.user_uid)
            data.user_uid = this.props.session.user_uid
        if (this.props.session?.viewer_uid)
            data.viewer_uid = this.props.session.viewer_uid

        db.ref(`polls-${this.props.channel}-answers/${poll_key}/${this.props.session.user_uid}`).set( data )

        const messageCountRef = db.ref(`polls-${this.props.channel}/${poll_key}/results/${index}/count`);
        messageCountRef.transaction( (currentAnswerCount) => {
            return (currentAnswerCount || 0) + 1
        })
    }

    setChannel = () => {
        if (this.props.session?.username) {
            console.log("Setting channel:" + this.props.channel)

            const data = { 
                channel_id: this.props.channel,
                show_uid: this.props.session.show_uid,                
                username: this.props.session.username
            };
            if (this.props.session?.user_uid)
                data.user_uid = this.props.session.user_uid

            db.ref(`chats-${this.props.channel}/sessions`).push( data )

            const sessionCountRef = db.ref(`chats-${this.props.channel}/stats/session_count`);
            sessionCountRef.transaction( (currentSessionCount) => {
                return (currentSessionCount || 0) + 1
            })
            
            const sessionKey = base64encode(`${this.props.session.user_uid || ''}|${this.props.session.viewer_uid || ''}|${this.props.session.username}`)
            const mobileChatUrl = `${config.baseUrl}/shows/${this.props.session.show_uid}/chat?s=${sessionKey}`

            this.setState({ activeSession: true, mobileChatUrl: mobileChatUrl })
        } else {
            this.setState({ activeSession: false })
        }
    }

    handleMessageText = e => {
        this.setState({ messageText: e.target.value })
        this.toggleEmojiPickerVisible(false)
    }

    handleEmojiPickerVisible = () => {        
        this.toggleEmojiPickerVisible(!this.state.emojiPickerVisible)        
    } 

    toggleEmojiPickerVisible(visible) {
        if (!visible) {
            this.setState({ 
                emojiPickerStyle: { display: 'none' }, 
                emojiPickerVisible: false 
            })
        } else {
            this.setState({ 
                emojiPickerStyle: { 
                    position: 'absolute',
                    bottom: 50,
                    right: 0
                }, 
                emojiPickerVisible: true
            })
        }
    }

    handleEmojiPick = emoji => {
        // console.log(emoji)
        let emojiText = emoji.native;
        if (emoji.custom) {
            emojiText = emoji.emoticons[0]
        }

        let message = this.state.messageText
        if (!message) {
            message = emojiText + ' '
        } else {
            message +=  ' ' + emojiText + ' '
        }
        this.setState({ messageText: message })
    }

    handleViveloEmojiPick = emoji => {
        // console.log(emoji)
        let emojiText = "\\o/";
        
        let message = this.state.messageText
        if (!message) {
            message = emojiText + ' '
        } else {
            message +=  ' ' + emojiText + ' '
        }
        this.setState({ messageText: message })
    }

    handleChatVisible = e => {
        this.setState({ chatVisible: !this.state.chatVisible })
        if (this.state.pollVisible) {
            this.setState({ pollVisible: false })
        }
    }
    handlePollVisible = e => {
        this.setState({ pollVisible: !this.state.pollVisible, pollNotification: false })
        if (this.state.chatVisible) {
            this.setState({ chatVisible: false })
        }        
    }
    handlePollAnswer = (poll_uid, index, option) => {
        this.sendPollAnswer(poll_uid, index, option)
        this.setState({ pollAnswered: true })
    }

    renderPollOptions() {
        console.log('options',this.state.activePoll)
        if (!this.state.pollAnswered) {
            return this.state.activePoll 
                ? <div className="poll-ask">
                    <div className="poll-question">{this.state.activePoll.config.question}</div>
                    <ul className="poll-options">
                        { this.state.activePoll.config.options.map( (opt, index) => {
                            return <li key={opt.id}><Button variant="link" onClick={() => this.handlePollAnswer(this.state.activePoll.key, index, opt)}><FontAwesomeIcon icon={farCheckCircle} /> {opt.text}</Button></li>
                        })}                    
                    </ul>
                </div>
            : <div>Lo sentimos, no hay ninguna encuesta activa en este momento</div>
        } else {
            return null
        }
        
    } 

    renderPollResults() {
        console.log('results',this.state.activePoll)
        if (this.state.pollAnswered) {
            const total = this.state.activePoll?.results.reduce((total, current) => total + current.count, 0)

            return this.state.activePoll && this.state.activePoll?.results ? <div className="poll-results">
                <div className="poll-question">{this.state.activePoll.config.question}</div>
                <ul className="poll-options">
                    { this.state.activePoll.results.map( opt => {
                        const percent = total > 0 ? (opt.count/total*100).toFixed(2) : 0
                        return <li key={opt.id}>
                            <div className="poll-result-labels">
                                <span className="poll-result-labels-title">{opt.text}</span>
                                <span className="poll-result-labels-percent">{opt.count} | {percent}%</span>
                            </div>
                            <div className="poll-result-graph">
                                <span className="poll-result-graph-label">{opt.count} votos</span>
                                <div className="poll-result-graph-fill" style={{width:`${percent}%`}}>&nbsp;</div>
                            </div>

                        </li>
                    })}
                </ul>
            </div>
            : <div>Lo sentimos, no hay ninguna encuesta activa en este momento</div>
        } else{
           return null 
        }
    }

    preprocessMessage(message) {
        return ReactHtmlParser(message.replaceAll("\\o/",'<span class="icon-vivelo">&nbsp;</span>'))
    }

    render() {
        return (
        <div className={`chat ${this.props.className}`} style={this.props.style}>
            { this.state.chatButtonVisible && <button onClick={this.handleChatVisible} className="chat-visibility" ><FontAwesomeIcon icon={this.state.chatVisible ? faTimes : faComment } /></button> }
            { this.state.pollButtonVisible && <button onClick={this.handlePollVisible} className="poll-visibility" ><FontAwesomeIcon icon={this.state.pollVisible ? faTimes : faChartPie } />
                { this.state.pollNotification && <FontAwesomeIcon style={{animation: 'colorpulse 2s infinite alternate-reverse'}} icon={faHandPointer} /> }</button> 
            }
            
            {this.state.chatVisible && !this.state.activeSession && <div className={`chat-session ${this.props.className}`} style={this.props.style}>
                <div className="form" style={{width:'80%'}}>                    
                    <input type="text" placeholder="Cuál es tu nombre..." value={this.state.sessionUsername} onChange={(e) => this.setSessionUsername(e)} onKeyDown={e=>{ if (e.key==='Enter') this.startChatSession()}} 
                               />
                    {/* <button onClick={() => this.startChatSession()} disabled={!this.validateSessionForm()}>Iniciar chat</button> */}
                    <Button                        
                        type="submit"
                        size=""                    
                        disabled={!this.validateSessionForm()}
                        onClick={() => this.startChatSession()} 
                    >Iniciar chat
                    </Button>
                    
                </div>
            </div>}


            {this.state.chatVisible && this.state.activeSession && <>
                <div className={`show-messages ${this.props.className}`} style={this.props.style}>
                    <div className="chat-title"><b>Chat:</b> {this.props.session.username}</div>
                    <div className="messages-list" ref={this.messagesRef} onClick={() => { this.toggleEmojiPickerVisible(false)  }}>
                        { this.state.messages.map(m => {
                            return <div className={m.type == "notification" ? 'notification' : ""}  key={m.time.toString()} >                                    
                                    <span className="username">{m.username}</span>
                                    <span className="message"><Linkify componentDecorator={this.linkifyComponentDecorator}>{this.preprocessMessage(m.message)}</Linkify></span>
                                    <span className="date">
                                        <Moment local format="HH:mm">{new Date(m.time)}</Moment>
                                        {(m.type == 'notification') && <span className="icon"></span> }
                                    </span>
                                </div>    
                        })}                        
                    </div>
                    { !this.props.chatReadOnly && <div className="messages-footer">
                        <div className="messages-input">
                            <input type="text" placeholder="Escribe algo..." value={this.state.messageText} onChange={this.handleMessageText} onKeyDown={e=>{ if (e.key==='Enter') this.sendMessage()}} 
                             onFocus={() => { this.toggleEmojiPickerVisible(false)  }}  />
                        </div>
                        <div className="messages-send">
                            <button onClick={this.sendMessage}><FontAwesomeIcon icon={faPaperPlane} /></button>
                        </div>
                        <div className="messages-emoji">
                            <button onClick={this.handleEmojiPickerVisible} className={this.state.emojiPickerVisible ? 'active' : ''}  ><FontAwesomeIcon icon={farSmile} /></button>
                            <Picker set='apple' theme="dark" style={this.state.emojiPickerStyle} title="Vívelo" sheet="16" perLine={6} emoji="" 
                            showPreview={false} showSkinTones={false} useButton={false}
                            onSelect={this.handleEmojiPick} 
                            custom={this.customEmojis} 
                            recent={['vivelo1','clap','heart','heart_eyes','grinning_face_with_star_eyes','joy','clap','wave','ok_hand','i_love_you_hand_sign','the_horns','+1','beer','beers','wine_glass','tumbler_glass','microphone','guitar']}
                            i18n={this.emoji_i18n} />
                        </div>
                        <div className="messages-vivelo">
                            <button onClick={this.handleViveloEmojiPick}><span className="icon-vivelo" >&nbsp;</span></button>
                        </div>
                    </div> }
                    <div className="chat-mobile">
                        <div className="chat-qrcode"><QRCode value={`${this.state.mobileChatUrl}`} size={100} fgColor="white" bgColor="black"  /></div>
                        <div className="chat-label">Escanea el QR con tu cámara para chatear desde tu móvil</div>                         
                        <div className="chat-phone"><FontAwesomeIcon icon={faMobileAlt} /></div>
                     </div>
                </div>
            </> }
                {this.state.pollVisible && <div className={`show-messages ${this.props.className}`} style={this.props.style}>
                        <div className="chat-title">Encuesta</div>
                        <div className="poll-container">
                            {!this.props.pollReadOnly && this.renderPollOptions()}
                            {this.renderPollResults()}
                        </div>                    
                    </div>
                }

                
            

            

            
        </div>
        );
    }
}

export default Chat

