import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";
import "./Titanes2021.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >


    return (
        !isLoading && show ? <>
        <Container fluid className="Show content content-dark titanes2021">
        <img height="1" width="1" style={{display:'none'}} src="https://www.facebook.com/tr?id=517268906113862&ev=PageView&noscript=1"/>
        <img height="1" width="1" style={{display:'none'}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2785794&conversionId=3626258&fmt=gif" />
            <div className="fullscreen">
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/Main2.jpg" />
            </div>
            <div className="dark-bg buttons">
                <div className="centered titledate" >3 de marzo de 2021 • De 8:30am a 5:30pm CST</div>
                <div className="centered">Conéctate desde las 8:00 y sé parte de esta experiencia con charlas increíbles desde las 8:30am hasta las 5:30pm.</div>
                <div className="centered">** Cada conferencia tendrá traducción simultánea **</div>
                <div className="centered" style={{fontWeight:'bold'}}>Soporte Técnico: José Javier 4149-9731, Natalia Orué 5524-2630, Diego Casado 5510-8423</div>
                <div className="centered">
                <ShowTicketBox show={show} viewerShow={viewerShow} tickets={tickets} 
                        ticketsButtonVariant="outline-light"
                        loginButtonVariant="outline-light"
                        activateButtonVariant="light"
                        addToCalendarButtonVariant="outline-light"

                    />

                </div>
            </div>
            <div className="dark-bg info video">
                <iframe src="https://player.vimeo.com/video/510078112" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="two-columns">
                <div>
                    <h1>Summit 2021</h1>
                    <p>La forma de hacer negocios en las diferentes regiones del mundo evolucionan constantemente a una velocidad creciente. Esto requiere que los equipos de liderazgo de las organizaciones estén actualizados y tengan acceso a las mejores metodologías para definir estrategias de crecimiento que les permita aprovechar las oportunidades presentes y futuras. 
                        <br /><br />
                        TITANES SUMMIT es la plataforma para acercar este conocimiento a los empresarios latinoamericanos.                                                
                    </p>
                </div>
                <div>
                    <h1>Thrive</h1>
                    <p>El contexto en donde se operan los negocios está en constante cambio. Este cambio puede ser progresivo o repentino como lo vivimos en el 2020 y puede ser una amenaza para algunos y una oportunidad para otros.
                        <br /><br />
                        Prepara tu empresa para triunfar y prosperar en cualquier contexto. THRIVE te dará herramientas para aprovechar las oportunidades que se presentan en la nueva economía.
                    </p>
                </div>
            </div>
            <div className="info">
                <div className="hosts">
                    <img className="allagis" src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/Allagis.png" />
                    <img className="cargo" src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/cargoexpreso-big.png" />
                </div>
                Un evento de <b>Allagis</b> y <b>Cargo Expreso</b><br />
                Más información <a href="maito:info@allagis.com">info@allagis.com</a><br />
                <a href="http://www.allagis.com" target="_blank">http://www.allagis.com</a>< br />
                <a href="https://www.facebook.com/allagiscoaching" target="_blank"><FontAwesomeIcon icon={faFacebook} /> /allagiscoaching</a>< br />
            </div>
            <div className="fullscreen">
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/Agenda4.jpg" />
            </div>
            <div className="dark-bg">
                
                    { show.settings?.registration ? <>
                        <ShowRegistration show={show} tickets={tickets} onSuccess={handlePaymentSuccess} />
                        
                    </> : <>
                        <div className="container show-tickets data-table">
                            <ShowTickets show={show} tickets={tickets} 
                                addToCartButtonVariant="light"
                            />
                        </div>
                    </>
                    }
            </div>
            <div className="title">
                Speakers
            </div>
            <div className="speaker">
                <div className="image"><img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/foto1a.jpg" /></div>
                <div className="content">
                    <h1>Verne Harnish</h1>
                    <h2>4 P's que le darán a tu empresa una ventaja competitiva</h2>
                    <p>Verne se centrará en las cuatro prioridades que cualquier organización debe dominar para escalar exitosamente.
                    <br /><br />
                    Para cada prioridad, le guiará a través de la teoría y las herramientas de Scaling Up, proporcionará los últimos estudios de casos y luego desafiará a su equipo a trabajar de forma conjunta.
                    <br /><br />
                    Verne es el autor del bestseller Mastering the Rockefeller Habits, que está traducido a 9 idiomas; y junto con los editores de Fortune, fue autor de The Greatest Business Decisions of All Times, del cual Jim Collins escribió el prólogo. Su último libro Scaling Up (Rockefeller Habits 2.0) ha sido traducido a 19 idiomas y ha ganado ocho premios internacionales importantes, incluido el prestigioso International Book Award al Mejor Libro de Negocios en General.</p>
                </div>
                
            </div>
            <div className="dark-bg speaker">
                <div className="image"><img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/foto2a.jpg" /></div>
                <div className="content">
                    <h1>Alan Miltz</h1>
                    <h2>Optimiza tu Cash Flow para Impulsar tu Negocio</h2>
                    <p>Nunca ha sido más importante entender y gestionar su flujo de efectivo para que pueda proteger su dinero en efectivo. En cualquier negocio, existen 7 palancas críticas que impactan su efectivo. Alan le llama: “El poder de uno”.
                    <br /><br />
                    Alan Miltz es el fundador de Cash Flow Story y co-autor del capítulo de efectivo de Scaling Up junto con Verne Harnish. Alan desarrolló una técnica utilizada por más de 500 bancos globales y 30.000 empresas en todo el mundo para medir el desempeño de la empresa. Ha sido Key Note Speaker en Forbes Growth Sumit, MIT, Cambridge, nombrado Mejor Orador en Australia por TEC, el foro de CEOs más grande del mundo, Entrepreneurs Organization. Además de ser parte de la junta directiva de 15 corporaciones multinacionales.                    
                    </p>
                </div>
                
            </div>
            <div className="speaker">
                <div className="image"><img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/foto3b.jpg" /></div>
                <div className="content">
                    <h1>Oleg Kondrashov y Rod Robertson</h1>
                    <h2>The Human Vector</h2>
                    <p>El Vector es un enfoque sistemático, gestionado científicamente y diseñado para optimizar el rendimiento de los colaboradores y el momentum de la organización. En el enfoque vectorial, el fundador establece una visión clara para que le la empresa siga el Vector.
                    <br /><br />
                    Oleg es un empresario líder en Europa que ha desarrollado un enfoque científico único para llevar sus empresas de tecnología al mercado. A sus 35 años, es el CEO de su organización que cuenta con una cartera de cincuenta empresas que se encuentran en distintas etapas de desarrollo. Rod es actualmente Managing Partner de Briggs Capital, una firma internacional de Mergers & Acquisition que presta servicios al mercado bajo y medio. Ha supervisado más de 70 transacciones y personalmente ha vendido exitosamente dos de sus empresas. Juntos son co-autores de The Human Vector.
                    </p>
                </div>
                
            </div>
            <div className="dark-bg speaker">
                <div className="image"><img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/foto4d.jpg" /></div>
                <div className="content">
                    <h1>Mari Soler y Jose Muñoz</h1>
                    <h2>Thrive</h2>
                    <p>3 enfoques son fundamentales para que una organización pueda triunfar y prosperar en cualquier contexto. Los enfoques de Empresa, Sistema e Individuo darán a la organización la dirección y capacidades necesarias para lograr la visión de la empresa y disfrutar el proceso.
                    <br /><br />
                    Mari y Jose son Coaches Empresariales certificados en Scaling Up y co-fundadores de TITANES. Han asesorado exitosamente empresas en toda la región latinoamericana en una gran variedad de industrias como construcción, manufactura y distribución, marketing, retail, etc, en temas de estrategia empresarial, cultura organizacional y desarrollo de equipos y ejecutivos de alto rendimiento.
                    </p>
                </div>
                
            </div>
            <div className="title" style={{marginBottom: 0}}>
                Patrocinadores
            </div>
            <div className="white-bg partners">            
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/logoTritos.png" />                     
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/LOGO HPBC.png" />
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/expande.png" />                     
            </div>
            <div className="title" style={{marginBottom: 0}}>
                Partners
            </div>
            <div className="white-bg partners">
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/scalingup.jpeg" />
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/vector.jpeg" />            
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/eo1.jpeg" />
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/LogoCIG_Color-1.png" />            
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/logo-amcham-1-1.png" style={{padding:60}} />            
                <img src="https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/ergo.jpeg"  style={{padding:60}} />            
            </div>
            
        </Container>
        </> : <Loader />
  );
}