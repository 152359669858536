import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";
import moment from "moment";

import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { useAnalytics } from "libs/analytics";
import { useCart } from "libs/cartLib";
import { useFormFields } from "libs/hooksLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";
import "./Teleton2021.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { trackEvent } = useAnalytics();

  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const { getCart, addCartItems, removeCartItems } = useCart()  

  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    dpi: "",    
  });
  const [codes, setCodes] = useState(null)
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    useEffect(() => {
        console.log('codes ', codes)
    }, [codes]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    async function searchCodes() {        
        const codes = await API.get("vivelo", `/cart/orders/teleton/?dpi=${fields.dpi}`);
        if (codes.total > 0) {
            setCodes(codes.items)
        } else {
            setCodes([])
        }
      }

    function handleBuyTicket() {
        registerCartItem(show, tickets[0]);
    }

    async function registerCartItem(selectedShow, ticket) {
        trackEvent({
            action: "Clicked Add to Cart Button",
            category: "PurchaseFunnel"
        });

        const currency = selectedShow?.settings?.currency || 'GTQ'

        const date = moment(selectedShow.show_date).format('ddd, LL - LT')

        const cartItem = {
            ticket_uid: ticket._id,
            type: 'TICKET',                
            show_uid: selectedShow._id,
            show_name: selectedShow.fullname,
            show_date: selectedShow.show_date,
            show_type: selectedShow.type,
            venue: selectedShow.venue,                
            title: `${selectedShow.fullname} (${date})`,
            description: ticket.fullname,
            note: ticket.note,
            ticket_type: ticket.type,
            image: `${selectedShow.main_image}`,
            quantity: 1
        }
        if (ticket.location) {
            cartItem.location = ticket.location
        }

        await addCartItems({ currency: currency, items: [cartItem] })
        await updateCartCounter()
        history.push('/cart',{})
        
    }

    function handleSearchCodes() {
        if (fields.dpi && fields.dpi.length == 13) {
            searchCodes()
        }
    }

    function renderSearchResults() {
        if (codes) {
            if (codes.length > 0) {
                const res = codes.map(c => {
                    return <div className="code">Código: {c}</div>
                })
                return <div className="codes">
                    <div>{res}</div>
                    <div>Copia el código y úsalo para entrar a ver la transmisión</div>
                </div>
            } else {
                return <>No hemos encontrado códigos para este DPI. Es posible que tu aporte aún no haya sido cargado al sistema.  Por favor vuelve a internar más tarde.</>
            }
        } else {
            return null
        }
    }

    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >


    return (
        !isLoading && show ? <>
        <Container fluid className="Show content content-dark teleton2021">        
            <div className="header">
                <div className="desktop">
                    <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/teleton-bg-desktop.jpg" />
                </div>
                <div className="mobile">
                    <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/teleton-bg-mobile.jpg" />
                </div>
            </div>
            <div className="body">
                <div className="compra">
                    <div className="online">
                        <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/compra-en-linea.png" />
                        <button onClick={handleBuyTicket}>Comprar</button>
                    </div>
                    <div className="agencia">
                        <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/compra-en-agencia.png" />
                    </div>
                    <div className="descarga">
                        <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/descarga-codigo.png" />
                        <div className="search">
                            <label>DPI:</label>
                            <input type="number" maxLength="13" min="1111111111111" max="9999999999999"
                                name="dpi"
                                id="dpi"
                                value={fields.dpi}
                                onChange={handleFieldChange}
                             />
                            <button onClick={handleSearchCodes}>Mostrar</button>
                        </div>
                        {codes && <div>
                            <div className="results">
                                {renderSearchResults()}
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="player">
                    <div>
                    <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/Disfruta.png" />
                    </div>
                    <div>
                    <a className="button" href={`/shows/${show._id}/player`} target="_blank"><FontAwesomeIcon icon={faPlayCircle} /> Ingresar</a>
                    </div>
                </div>                
            </div>
            <div className="footer">
                <div className="logos">
                <img src="https://assets.viveloonline.com/shows/61ba599d23b9823169747054/logo-banrural.png" />
                </div>
            </div>
            
        </Container>
        </> : <Loader />
  );
}