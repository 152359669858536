import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle, faMapMarkerAlt, faMoneyBillAlt, faDownload, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { faSpotify, faDeezer, faYoutube, faApple, faInstagram, faFacebook, faTwitter, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";



import Loader from 'components/Loader'

import ShowInfo from "components/shows/ShowInfo";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./despuesdelsilencio.css"
import { render } from "@testing-library/react";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            // session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            // const children = await loadChildShows();            
            // setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */
    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }

    async function redeemDownload(filename) {
        try {
            API.get("vivelo", `/etickets/downloads/redeem?filename=${filename}`);
        } catch (e) {
            console.error(e)
        }
        
    }

    function renderDownloadTable() {
        const playlist = [
            { 
                name: '1. Después del silencio',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/01+Despues+del+silencio+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/01+Despues+del+silencio+-+Armando+Pineda.mp3',
                time: '3:57'
            },
            { 
                name: '2. Guitarra 1',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/02+Guitarra+1+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/02+Guitarra+1+-+Armando+Pineda.mp3',
                time: '2:05'
                
            },
            { 
                name: '3. Guitarra 2',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/03+Guitarra+2+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/03+Guitarra+2+-+Armando+Pineda.mp3',
                time: '3:13'
            },
            { 
                name: '4. Guitarra 3',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/04+Guitarra+3+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/04+Guitarra+3+-+Armando+Pineda.mp3',
                time: '3:29'
            },
            { 
                name: '5. Aquí está mi palabra',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/05+Aqui+esta+mi+palabra+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/05+Aqui+esta+mi+palabra+-+Armando+Pineda.mp3',
                time: '5:18'
            },
            { 
                name: '6. Equis equis',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/06+Equis+equis+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/06+Equis+equis+-+Armando+Pineda.mp3',
                time: '3:17'
            },
            { 
                name: '7. Tú',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/07+Tu+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/07+Tu+-+Armando+Pineda.mp3',
                time: '4:38'
            },
            { 
                name: '8. Si sólo pudiera verte',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/08+Si+solo+pudiera+Verte+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/08+Si+solo+pudiera+Verte+-+Armando+Pineda.mp3',
                time: '3:59'
            },
            { 
                name: '9. Si fueras aire',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/09+Si+fueras+Aire+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/09+Si+fueras+Aire+-+Armando+Pineda.mp3',
                time: '3:40'
            },
            { 
                name: '10. Me están doliendo los días',
                file: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/10+Me+estan+doliendo+lo+dias+-+Armando+Pineda.mp3',
                download: 'https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/10+Me+estan+doliendo+lo+dias+-+Armando+Pineda.mp3',
                time: '2:51'
            }
        ]

        const songs = playlist.map(s => 
            <div className="song">
                <div className="download"><a href={s.download} 
                onClick={() => redeemDownload(s.download)}
                download><FontAwesomeIcon icon={faCloudDownloadAlt} /></a></div>
                <div className="song-name">{s.name}</div>
                <div className="song-duration">{s.time}</div>
            </div>
        )

        return <div className="playlist">
            <div className="album-name">Después del Silencio</div>
            <div className="subtitle">Los poemas de Manuel José Arce hechos canción</div>
            <div className="artist-name">Armando Pineda</div>
            {songs}
            <div className="song album-download">
            <div className="download"><a href="https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/Despues+del+Silencio+-+Armando+Pineda.zip" 
            onClick={() => redeemDownload("https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/Despues+del+Silencio+-+Armando+Pineda.zip")}
            download><FontAwesomeIcon icon={faCloudDownloadAlt} /></a></div>
                <div className="song-name"><a href="https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/Despues+del+Silencio+-+Armando+Pineda.zip" 
                onClick={() => redeemDownload("https://s3.amazonaws.com/assets.viveloonline.com/sites/armandopineda/Despues+del+Silencio/download/Despues+del+Silencio+-+Armando+Pineda.zip")}
                download>Descargar disco completo</a></div>
                <div></div>            
            </div>
        </div>
    }

    return (
        !isLoading && show ? <>
        <div className="site-despuesdelsilencio">
            <div className="body">
                <div className="cover">
                    <img src="https://assets.viveloonline.com/sites/armandopineda/Despues del Silencio/Despues del Silencio Portada.jpeg" />
                </div>
                <div className="content">
                    {/* <div className="artist">
                        Armando Pineda
                    </div> */}
                    <div className="album">
                    <div className="album-art">
                        {/* <img src="https://assets.viveloonline.com/sites/armandopineda/Despues del Silencio/album-art.png" /> */}
                    </div>
                    {renderDownloadTable()}                    
                </div>
            </div>
        </div>

            
                            
            {/* <div className="footer">
                <h2>Acerca de <b>Armando Pineda</b></h2>
                <div className="content">
                    <div>                    
                    </div>
                    <div className="logos">
                        <img src="https://assets.viveloonline.com/profiles/capitan.electrico/capitan-profile.pn" />
                    </div>
                </div>
            </div> */}
        </div>
        </> : <Loader />
  );
}