import React, { useState, useEffect } from "react";
import Chat from '../components/FirebaseChat'


export default function ChatTest() {  
    // const [isConnected, setConnected] = useState(false);
    // const [ws, setWs] = useState(null);
    // // const [retries, setRetries] = useState(3);

    // let retries = 3
    // useEffect(() => {
    //     async function onLoad() {
    //      //Init WebSockets with Cognito Access Token
    //      if (retries-- > 0) {
    //         let wsock = new Sockette(
    //             "wss://x75aacsh9k.execute-api.us-east-1.amazonaws.com/dev"
    //             //+ props.authData.signInUserSession.accessToken.jwtToken
    //             ,{
    //             timeout: 5e3,
    //             maxAttempts: 1,
    //             onopen: onOpen,
    //             onmessage: e => console.log("Message Received:", e),
    //             onreconnect: e => console.log("Reconnecting...", e),
    //             onmaximum: e => console.log("Stop Attempting!", e),
    //             onclose: onClose,
    //             onerror: onError
    //             }
    //         );
    //         setWs(wsock)
    //         console.log(wsock)

    //         //Set Channel
    //         wsock.json({
    //             action: "setChannel",
    //             channel_id: "test"
    //             });  
    //      }         
    //     }
      
    //     onLoad();
    //   }, []);


    // function onOpen(e) {
    //     console.log("connected:", e)
    //     setConnected(true)             
    // }

    
    // function onClose(e) {
    //     console.log("Closed!", e)
    //     setConnected(false)
    //     setWs(null)
    // }
    // function onError(e) {
    //     console.log("Error!", e)
    //     setConnected(false)
    //     setWs(null)
    // }
    

    // function sendMessage(e) {
    //     //Send Message
    //     ws.json({
    //         action: "sendMessage",
    //         channel_id: "test",
    //         message: "Hello World"
    //         });
    // }

    

    const chatSession = {
        user_uid: '5edd7ae34194514a4b473c01',
        viewer_uid: '5edf215b8b1a50000897503b',
        username: 'Test User',
        show_uid: '5edc79d60ee327132db0d30d',
    }

    
    // return <div>Chat Test <button onClick={sendMessage}>Send</button></div>
    return <div>Chat Test 
        <Chat channel='test' session={chatSession} chatVisible={true} style={{top: 60, height:600, width: 300}} />
      </div>
}