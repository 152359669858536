import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle } from '@fortawesome/free-solid-svg-icons'

import { useAnalytics } from "libs/analytics";

// import './Loader.scss'

export default function ShowImage(props) {
    const { show, viewerShow, ...otherProps } = props

    const { trackEvent } = useAnalytics();

    function onClickHandler() {
        const action = props.analyticsTrackAction || 'Clicked Play Overlay'
        const category = props.analyticsTrackCategory || 'Show'

        trackEvent({
            action: action,
            category: category
        });
    }

    function render() {
        const renderPlayButton = (show?.settings?.nologin && !['CLOSED','CANCELLED'].includes(show.status) ) 
            || ((viewerShow || !show.paid ) && ['STARTED','LIVE','REPLAY'].includes(show.status) )

        const playerUrl = viewerShow
            ? `/shows/${show._id}/player/?code=${viewerShow.payment_info.request_code}`
            : `/shows/${show._id}/player/`

        return <div className="show-image">                    
            <img src={`${show.main_image}`} />
            {renderPlayButton && <>
            <div className="play-overlay">
                <a href={playerUrl} target="_blank" className="d-flex justify-content-center align-items-center" onClick={onClickHandler}><FontAwesomeIcon icon={faPlayCircle} /></a>
                {show.status == "LIVE" &&
                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                }
            </div>                            
            </>}
            
        </div>
    }

    return render()
}