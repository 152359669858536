import React, { useState, useEffect } from "react";
import JwPlayer from '../components/JwPlayer'


export default function JwPlayerTest() {  
   
    
    // return <div>Chat Test <button onClick={sendMessage}>Send</button></div>
    return <div>JW Player
       {/* <JwPlayer channelId="fyPPmo6R"  /> */}
        <JwPlayer channelId="MKpr2802" autoPlay={true}
          image={`http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png`}  
          // style={{width: 1280, height:720, marginTop:200 }} 
          style={{width: '50%', marginTop: 200}}
          />
        {/* <JwPlayer mediaId="iyWy4xcW"   />  */}

        {/* <JwPlayer mediaId="cvLuwFIf"   />  */}
        <JwPlayer mediaId="INRmz3R1"  autoPlay={false}  /> 
        
       
      </div>
}