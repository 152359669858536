import React from 'react';
import { useTimer } from 'react-timer-hook';

export default function Timer({ expiryTimestamp, onExpire, className }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: onExpire });


  function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

  return (
    <div className={`${className} timer`} style={{textAlign: 'center'}}>
        { days > 0 && <><span>{pad(days,2)}</span>:</> }
        { hours > 0 && <><span>{pad(hours,2)}</span>:</> }
        <span>{pad(minutes,2)}</span>:<span>{pad(seconds,2)}</span>
    </div>
  );
}