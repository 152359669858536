import React, { useState } from "react";
import Form from "react-bootstrap/Form"
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

export default function Login() {
  const history = useHistory();
  const { login } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);

    try {
      const session = await login(fields.email, fields.password) 
      if (session) {
        console.log(session)
        history.push("/");
      } else {
        Notification('error', 'Usuario o contraseña inválidos')
      }

    } catch (e) {
        onError(e);
        Notification('error', 'Usuario o contraseña inválidos')
        setIsLoading(false);
    }
  }

  return (
    <div className="content content-dark">
    <Container>
      <Row>
      <Col>
        <h1>¿Cómo Funciona?</h1>
        <div className="description">Vívelo Online es una plataforma digital para eventos y espectáculos de entretenimiento.</div>
      </Col>
      </Row>

      <Row>
      <Col>
        <h3>Compra tu Boleto</h3>
        <div className="description">
          <ul>            
            <li>Busca el evento que deseas. Un evento puede tener diferentes opciones de boletos.</li>
            <li>Selecciona la cantidad de boletos que deseas comprar. Puedes comprar boletos para ti o para otras personas.</li>
            <li>Luego de completar el pago te enviaremos un correo por cada boleto que hayas comprado al correo registrado</li>
          </ul>  
        </div>
      </Col>
      </Row>

      <Row>
      <Col>
        <h3>Activa tu Boleto</h3>
        <div className="description">
          Para poder disfrutar del show debes activar tu boleto en tu cuenta personal.  Si compraste más de un boleto, reenviales el boleto para que lo puedan activar en su propia cuenta.
          <ul>            
            <li>Puedes activar tu boleto desde la página de confirmación de la compra, desde la página del show, o en la página de Activar Boleto</li>
            <li>Ingresa el código de boleto en el formulario</li>            
            <li>Debes activar tu boleto en tu cuenta personal.  Si no tienes una cuenta aún, debes crearla.</li>
            <li>El show del boleto mostrará tu código de boleto activado.</li>
          </ul>
        </div>
      </Col>
      </Row>

      <Row>
      <Col>
        <h3>Disfruta del Show</h3>
        <div className="description">
          <ul>
            <li>El día del evento, dirígete al show que deseas ver.</li>
            <li>Inicia sesión con tu cuenta personal donde activaste el boleto</li>
            <li>Haz click en el botón de Ver Show para disfrutar del espectáculo.  Usualmente la transmisión se activa 5 minutos antes de la hora del evento.  
              Si aún no está activa la opción de Ver Show, regresa más tarde y recarga la página.</li>
          </ul>
        </div>
      </Col>
      </Row>
    
      <Row>
      <Col>
        <h1>Preguntas Frecuentes</h1>
      </Col></Row>
      
      <Row>
        <Col>
        <h4>No recibí mi boleto en el correo, ¿Cómo puedo obtenerlos?</h4>
        <div className="description">Si recibiste el correo de confirmación de compra puedes hacer click en el botón de <i>Ver Orden</i>.  En esa página puedes presionar el botón de <i>Reenviar Boletos</i>, o puedes activarlo directamente ahí.  Si tienes alguna otra duda, escríbenos en Facebook Messenger</div>
      </Col>
      </Row>
      <Row>
        <Col>
        <h4>El correo que coloqué en la orden no es el correcto, ¿Cómo lo puedo corregir?</h4>
        <div className="description">Escríbenos en Facebook Messenger para que podamos ayudarte a corregir el correo y puedas recibir tus boletos</div>
      </Col>
      </Row>


      <Row>
      <Col>
        <h1>¿Necesitas Ayuda?</h1>
        <div className="description">Si tiene alguna pregunta sobre el proceso o tus boletos puedes escribirnos en el chat de Messenger haciendo click en el ícono que aparecer abajo a la derecha de la página</div>
      </Col>
      </Row>

      </Container>
    </div>
  );
}