import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { onError } from "./errorLib";
import { base64encode, base64decode } from 'nodejs-base64';

import config from "../config";

export const getIpGeo = async () => {

  const geoCookie = 'geo'
  const geo = Cookie.get(geoCookie)
  let geoInfo = null

  try {
    if (geo) {
      geoInfo = JSON.parse(base64decode(geo))
    }
    if (!geoInfo) {
        let params = "apiKey="+config.ipgeolocation.API_KEY
        // params += "&ip=8.8.8.8"

        geoInfo = await API.get("ipgeo", `ipgeo?${params}`)
        // console.log(geoInfo)
        
        Cookie.set(geoCookie, base64encode(JSON.stringify(geoInfo)), { expires: 45 })
    }
  } catch(error) {
    onError(error)
  }
  return geoInfo || {}

};