import React from "react";
import { Component, createElement } from "react";
import  createSocket from "sockette-component";
import { Button } from "react-bootstrap/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle as farCheckCircle, faSmile as farSmile } from '@fortawesome/free-regular-svg-icons'
import { faPaperPlane, faComment, faTimes, faPollH, faHandPointer, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import Linkify from 'react-linkify';
import { Picker } from 'emoji-mart';
import QRCode from 'react-qr-code';
import { base64encode } from "nodejs-base64";
import ReactHtmlParser from 'react-html-parser';

import 'moment/locale/es';
import { API } from "aws-amplify";
import config from '../config';

import "./Chat.css";
import 'emoji-mart/css/emoji-mart.css'

const Sockette = createSocket({
    Component,
    createElement
});


class Chat extends Component {
    messagesRef = React.createRef()

    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            socket: null,
            messageText: "",
            messages: [],
            chatVisible: props.chatVisible == undefined ? false : props.chatVisible,
            pollVisible: props.pollVisible == undefined ? false : props.pollVisible,
            chatEnabled: props.chatEnabled == undefined ? true : props.chatEnabled,
            pollEnabled: props.pollEnabled == undefined ? true : props.pollEnabled,
            chatButtonVisible: props.chatButtonVisible == undefined ? true : props.chatButtonVisible,
            pollButtonVisible: props.chatButtonVisible == undefined ? true : props.chatButtonVisible,

            activePoll: null,
            pollAnswered: false,
            pollNotification: false,

            emojiPickerVisible: false,
            emojiPickerStyle: { display: 'none' },
            
            activeSession: true,
            sessionUsername: '',

            mobileChatUrl: ''
        };
        //setTimeout(() => this.setState({ pollNotification: true }), 5000)

        
    }
    linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
    );

    customEmojis = [        
        {
          id: 'vivelo1',
          name: 'Vivelo1',
          short_names: ['vivelo1'],
          text: '\\o/',
          emoticons: ['\\o/'],
          keywords: ['vivelo'],
          imageUrl: 'https://viveloonline.com/img/isotipo200.png',
          customCategory: 'Vívelo',
        },
      ]
    
    emoji_i18n = {
        search: 'Buscar',
        clear: 'Limpiar', // Accessible label on "clear" button
        notfound: 'Emoji no encontrado',
        skintext: 'Choose your default skin tone',
        categories: {
        search: 'Buscar',
        recent: 'Populares',
        smileys: 'Emociones',
        people: 'Emociones y Personas',
        nature: 'Animales y Naturaleza',
        foods: 'Comida y Bebida',
        activity: 'Actividades',
        places: 'Lugares',
        objects: 'Objectos',
        symbols: 'Símbolos',
        flags: 'Banderas',
        custom: 'Custom',
        },
        categorieslabel: 'Emoji categories', // Accessible title for the list of categories
        skintones: {
        1: 'Default Skin Tone',
        2: 'Light Skin Tone',
        3: 'Medium-Light Skin Tone',
        4: 'Medium Skin Tone',
        5: 'Medium-Dark Skin Tone',
        6: 'Dark Skin Tone',
        },
    }
    

    componentDidMount() {

    }

    setSessionUsername(event) {
        let sessionUsername = this.state.sessionUsername
        this.setState({ sessionUsername: event.target.value })
        console.log(this.state)
    }

    startChatSession() {
        // this.props.session.user_uid = '5edc79d60ee327132db0d30d';//this.state.sessionUsername;
        this.props.session.username = this.state.sessionUsername;
        this.setState({ activeSession: true })
        // this.setChannel(this.state.socket)
    }

    validateSessionForm() {
        return this.state.sessionUsername.length > 0;
    }

    loadPollAnswer = async (show_uid, poll_uid, user_uid) => {
        try {
            const answer = await API.get("vivelo", `/shows/${show_uid}/polls/${poll_uid}/answers/${user_uid}`);        
            return answer
        } catch(err) {
            console.log(err)
            return null
        }
    }

    // setState(socket) {
    //     this.state.socket = socket
    // }
    scrollToMyRef = () => {
        if (this.messagesRef && this.messagesRef.current) {
            const scroll = this.messagesRef.current.scrollHeight - this.messagesRef.current.clientHeight;
            this.messagesRef.current.scrollTop = scroll
            // this.messagesRef.current.scrollTo(0, scroll);
        }
      };

    onOpen = ev => {
        // console.log("> Connected!", ev);
        this.setChannel(this.state.socket)

        // setTimeout(() => this.onMessage({
        //     data: JSON.stringify({
        //         type: 'poll-notification',
        //         status: 'ACTIVE',
        //         poll: {
        //             _id: "5ee6929325558bb028317447",
        //             show_uid: "5edc79d60ee327132db0d30d",
        //             question: 'Qué canción prefieres escuchar primero?',
        //             options: [
        //                 { _id: "5ee6925c25558bb028317444", text: 'Contigo' },
        //                 { _id: "5ee6925d25558bb028317445", text: 'Sin Embargo' },
        //                 { _id: "5ee6925f25558bb028317446", text: 'Ojala' },
        //             ],
        //             results: [
        //                 { _id: '5ee6925c25558bb028317444', text: 'Contigo', percent: 13, count: 3 },
        //                 { _id: '5ee6925d25558bb028317445', text: 'Sin Embargo', percent: 77, count: 17  },
        //                 { _id: '5ee6925f25558bb028317446', text: 'Ojala', percent: 9, count: 2  },
        //             ],
        //             status: 'ACTIVE'
        //         }
        //     })
        // }), 5000)
    };

    onMessage = async ev => {
        // console.log("> Received:", ev.data);
        const data = JSON.parse(ev.data)

        let handled = false;
        if (this.props.socketOnMessage) {
            handled = await this.props.socketOnMessage(data)
        }
        if (handled) {
            console.info('socket message already handled')
            return;
        }

        if (data.type == 'init') {
            this.setState({ 
                messages: data.messages.reverse(),
                activePoll: data.poll,
                pollAnswered: data.pollAnswered || this.props.pollReadOnly
             }, () => this.scrollToMyRef())
        }
        else if (['message','notification'].includes(data.type)) {
            this.setState(state => {
                let msgs = [...state.messages, data]
                console.log(msgs)
                return {
                messages: msgs
                }            
            }, () => this.scrollToMyRef());
        }
        else if (data.type == 'poll-notification') {
            if (data.status == 'ACTIVE') {
                let answered = true
                if (!this.props.pollReadOnly) {
                    const answer = await this.loadPollAnswer(data.poll.show_uid, data.poll._id, this.props.session.user_uid)
                    console.log("ANSWER", answer)
                    if (!answer) {
                        answered = false
                    }
                }
                this.setState({ activePoll: data.poll, pollNotification: true, pollAnswered: answered })
            } else if (data.status == 'CLOSED') {
                this.setState({ activePoll: null, pollNotification: false })
            }
        }
        else if (data.type == 'poll-results') {
            if (this.state.pollAnswered) {
                this.setState({ activePoll: data.poll })
            } else {
                if (this.state.activePoll._id != data.poll._id) {
                    const answer = await this.loadPollAnswer(data.poll.show_uid, data.poll._id, this.props.session.user_uid)
                    if (answer) {
                        this.setState({ pollAnswered: true })
                    }
                }
                this.setState({ activePoll: data.poll })
            }
        }

        
    };

    onReconnect = ev => {
        console.log("> Reconnecting...", ev);
        // console.log(this.state)
    };

    sendMessage = _ => {
        this.toggleEmojiPickerVisible(false)

        if (!this.state.messageText) 
            return;

        this.state.socket.json({
                    action: "sendMessage",
                    channel_id: this.props.channel,
                    data: {                                                
                        user_uid: this.props.session.user_uid,
                        viewer_uid: this.props.session.viewer_uid,
                        show_uid: this.props.session.show_uid,
                        username: this.props.session.username,
                        type: this.props.admin ? 'notification': 'message',
                        message: this.state.messageText
                    }});
        this.setState({ messageText: '' })
        
    };
    
    sendPollAnswer = (poll_uid, answer) => {
        this.state.socket.json({
            action: "sendPollAnswer",
            channel_id: this.props.channel,
            data: {      
                poll_uid: poll_uid,
                show_uid: this.props.session.show_uid,
                user_uid: this.props.session.user_uid,
                viewer_uid: this.props.session.viewer_uid,                
                username: this.props.session.username,
                option_uid: answer._id,
                option_text: answer.text
            }});
    }

    setChannel = socket => {
        if (this.props.session?.username) {
            console.log("Setting channel:" + this.props.channel)
            socket.json({
                action: "setChannel",
                channel_id: this.props.channel,
                show_uid: this.props.session.show_uid,
                user_uid: this.props.session.user_uid,
                username: this.props.session.username
            });

            const sessionKey = base64encode(`${this.props.session.user_uid || ''}|${this.props.session.viewer_uid || ''}|${this.props.session.username}`)
            const mobileChatUrl = `${config.baseUrl}/shows/${this.props.session.show_uid}/chat?s=${sessionKey}`

            this.setState({ activeSession: true, mobileChatUrl: mobileChatUrl })
        } else {
            this.setState({ activeSession: false })
        }
    }

    handleMessageText = e => {
        this.setState({ messageText: e.target.value })
        this.toggleEmojiPickerVisible(false)
    }

    handleEmojiPickerVisible = () => {        
        this.toggleEmojiPickerVisible(!this.state.emojiPickerVisible)        
    } 

    toggleEmojiPickerVisible(visible) {
        if (!visible) {
            this.setState({ 
                emojiPickerStyle: { display: 'none' }, 
                emojiPickerVisible: false 
            })
        } else {
            this.setState({ 
                emojiPickerStyle: { 
                    position: 'absolute',
                    bottom: 50,
                    right: 0
                }, 
                emojiPickerVisible: true
            })
        }
    }

    handleEmojiPick = emoji => {
        // console.log(emoji)
        let emojiText = emoji.native;
        if (emoji.custom) {
            emojiText = emoji.emoticons[0]
        }

        let message = this.state.messageText
        if (!message) {
            message = emojiText + ' '
        } else {
            message +=  ' ' + emojiText + ' '
        }
        this.setState({ messageText: message })
    }

    handleViveloEmojiPick = emoji => {
        // console.log(emoji)
        let emojiText = "\\o/";
        
        let message = this.state.messageText
        if (!message) {
            message = emojiText + ' '
        } else {
            message +=  ' ' + emojiText + ' '
        }
        this.setState({ messageText: message })
    }

    handleChatVisible = e => {
        this.setState({ chatVisible: !this.state.chatVisible })
        if (this.state.pollVisible) {
            this.setState({ pollVisible: false })
        }
    }
    handlePollVisible = e => {
        this.setState({ pollVisible: !this.state.pollVisible, pollNotification: false })
        if (this.state.chatVisible) {
            this.setState({ chatVisible: false })
        }        
    }
    handlePollAnswer = (poll_uid, option) => {
        this.sendPollAnswer(poll_uid, option)
        this.setState({ pollAnswered: true })
    }

    renderPollOptions() {
        if (!this.state.pollAnswered) {
            return this.state.activePoll 
                ? <div className="poll-ask">
                    <div className="poll-question">{this.state.activePoll.question}</div>
                    <ul className="poll-options">
                        { this.state.activePoll.options.map( opt => {
                            return <li key={opt._id}><Button variant="link" onClick={() => this.handlePollAnswer(this.state.activePoll._id, opt)}><FontAwesomeIcon icon={farCheckCircle} /> {opt.text}</Button></li>
                        })}                    
                    </ul>
                </div>
            : <div>Lo sentimos, no hay ninguna encuesta activa en este momento</div>
        } else {
            return null
        }
        
    } 

    renderPollResults() {
        if (this.state.pollAnswered) {
            return this.state.activePoll && this.state.activePoll.results ? <div className="poll-results">
                <div className="poll-question">{this.state.activePoll.question}</div>
                <ul className="poll-options">
                    { this.state.activePoll.results.map( opt => {
                        return <li key={opt._id}>
                            <div className="poll-result-labels">
                                <span className="poll-result-labels-title">{opt.text}</span>
                                <span className="poll-result-labels-percent">{opt.count} | {opt.percent}%</span>
                            </div>
                            <div className="poll-result-graph">
                                <span className="poll-result-graph-label">{opt.count} votos</span>
                                <div className="poll-result-graph-fill" style={{width:`${opt.percent}%`}}>&nbsp;</div>
                            </div>

                        </li>
                    })}
                </ul>
            </div>
            : <div>Lo sentimos, no hay ninguna encuesta activa en este momento</div>
        } else{
           return null 
        }
    }

    preprocessMessage(message) {
        return ReactHtmlParser(message.replaceAll("\\o/",'<span class="icon-vivelo">&nbsp;</span>'))
    }

    render() {
        return (
        <div className={`chat ${this.props.className}`} style={this.props.style}>
            { this.state.chatButtonVisible && <button onClick={this.handleChatVisible} className="chat-visibility" ><FontAwesomeIcon icon={this.state.chatVisible ? faTimes : faComment } /></button> }
            { this.state.pollButtonVisible && <button onClick={this.handlePollVisible} className="poll-visibility" ><FontAwesomeIcon icon={this.state.pollVisible ? faTimes : faPollH } />
                { this.state.pollNotification && <FontAwesomeIcon style={{animation: 'colorpulse 2s infinite alternate-reverse'}} icon={faHandPointer} /> }</button> 
            }
            
            {this.state.chatVisible && !this.state.activeSession && <div className={`chat-session ${this.props.className}`} style={this.props.style}>
                <div className="form" style={{width:'80%'}}>                    
                    <input type="text" placeholder="Cuál es tu nombre..." value={this.state.sessionUsername} onChange={(e) => this.setSessionUsername(e)} onKeyDown={e=>{ if (e.key==='Enter') this.startChatSession()}} 
                               />
                    {/* <button onClick={() => this.startChatSession()} disabled={!this.validateSessionForm()}>Iniciar chat</button> */}
                    <Button                        
                        type="submit"
                        size=""                    
                        disabled={!this.validateSessionForm()}
                        onClick={() => this.startChatSession()} 
                    >Iniciar chat
                    </Button>
                    
                </div>
            </div>}


            {this.state.chatVisible && this.state.activeSession && <>
                <div className={`show-messages ${this.props.className}`} style={this.props.style}>
                    <div className="chat-title">Mensajes</div>
                    <div className="messages-list" ref={this.messagesRef} onClick={() => { this.toggleEmojiPickerVisible(false)  }}>
                        { this.state.messages.map(m => {
                            return <div className={m.type == "notification" ? 'notification' : ""}  key={m.time.toString()} >                                    
                                    <span className="username">{m.username}</span>
                                    <span className="message"><Linkify componentDecorator={this.linkifyComponentDecorator}>{this.preprocessMessage(m.message)}</Linkify></span>
                                    <span className="date">
                                        <Moment local format="HH:mm">{new Date(m.time)}</Moment>
                                        {(m.type == 'notification') && <span className="icon"></span> }
                                    </span>
                                </div>    
                        })}                        
                    </div>
                    { !this.props.chatReadOnly && <div className="messages-footer">
                        <div className="messages-input">
                            <input type="text" placeholder="Escribe algo..." value={this.state.messageText} onChange={this.handleMessageText} onKeyDown={e=>{ if (e.key==='Enter') this.sendMessage()}} 
                             onFocus={() => { this.toggleEmojiPickerVisible(false)  }}  />
                        </div>
                        <div className="messages-send">
                            <button onClick={this.sendMessage}><FontAwesomeIcon icon={faPaperPlane} /></button>
                        </div>
                        <div className="messages-emoji">
                            <button onClick={this.handleEmojiPickerVisible} className={this.state.emojiPickerVisible ? 'active' : ''}  ><FontAwesomeIcon icon={farSmile} /></button>
                            <Picker set='apple' theme="dark" style={this.state.emojiPickerStyle} title="Vívelo" sheet="16" perLine={6} emoji="" 
                            showPreview={false} showSkinTones={false} useButton={false}
                            onSelect={this.handleEmojiPick} 
                            custom={this.customEmojis} 
                            recent={['vivelo1','clap','heart','heart_eyes','grinning_face_with_star_eyes','joy','clap','wave','ok_hand','i_love_you_hand_sign','the_horns','+1','beer','beers','wine_glass','tumbler_glass','microphone','guitar']}
                            i18n={this.emoji_i18n} />
                        </div>
                        <div className="messages-vivelo">
                            <button onClick={this.handleViveloEmojiPick}><span className="icon-vivelo" >&nbsp;</span></button>
                        </div>
                    </div> }
                    <div className="chat-mobile">
                        <div className="chat-qrcode"><QRCode value={`${this.state.mobileChatUrl}`} size={100} fgColor="white" bgColor="black"  /></div>
                        <div className="chat-label">Escanea el QR con tu cámara para chatear desde tu móvil</div>                         
                        <div className="chat-phone"><FontAwesomeIcon icon={faMobileAlt} /></div>
                     </div>
                </div>
                <Sockette                     
                    url={config.wsService.url}
                    getSocket={socket => {
                        this.setState({ socket: socket });

                        if (this.props.socketRef) {
                            this.props.socketRef(socket)
                        }

                        // console.log(socket)
                        // setTimeout(() => {
                        //     this.setChannel(socket)
                        // }, 3000);
                    }}            
                    //maxAttempts={20000}
                    onopen={this.onOpen}
                    onmessage={this.onMessage}
                    onreconnect={this.onReconnect}
                    
                    />
                
                {this.state.pollVisible && <div className={`show-messages ${this.props.className}`} style={this.props.style}>
                        <div className="chat-title">Encuesta</div>
                        <div className="poll-container">
                            {!this.props.pollReadOnly && this.renderPollOptions()}
                            {this.renderPollResults()}
                        </div>                    
                    </div>
                }

                </>
            }

            

            
        </div>
        );
    }
}

export default Chat

