import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button } from "react-bootstrap";
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrownOpen, faPlayCircle, faExpandArrowsAlt, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Chat from '../components/FirebaseChat'
import { base64decode } from 'nodejs-base64';
import { ConsoleView, deviceDetect } from "react-device-detect";

import { onError } from "../libs/errorLib";
// import { useAppContext } from "../libs/contextLib";
import { getIpGeo } from '../libs/ipgeo';
import { useShowStats } from '../libs/statsLib'
// import { useWindowSize } from "../libs/windowSize";


import "./ShowViewFullscreen.css";

export default function ShowMobileChat() {

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [chatSession, setChatSession] = useState(null);
  const [show, setShow] = useState(null);
  const [invalidSession, setInvalidSession] = useState(false);
  const [ipgeo, setIpgeo] = useState(null)
  const [iframe, setIframe] = useState(false)

  const containerRef = useRef(null)
  const headerRef = useRef(null)

  const [chatHeight, setChatHeight] = useState(null);
  const [messageHeight, setMessageHeight] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);

  const [ initShowStat, registerShowStat ] = useShowStats()

  const stats = {
    show_uid: id,
    device: deviceDetect(),
    type: 'player',
    action: 'mobilechat'
  }

  useEffect(() => {

    async function loadShow() {        
        const show = await API.get("vivelo", `/shows/${id}`);
        if (show) {
            setShow(show)
            // console.log(['LIVE','REPLAY'].includes(show.status), show)            
            if (['LIVE','REPLAY','TEST'].includes(show.status)) {
                
            }
            if (['NEW','SOON','ACTIVE','CLOSED','CANCELLED'].includes(show.status)) {
                history.push(`/shows/${id}`)
            }
        }
        return show
    }

    async function onLoad() {
        console.log('onload')
        try {          
            setIsLoading(true)
            
            const searchParams = new URLSearchParams(location.search);            
            let s = searchParams.get('s')
            // console.log(s)
            if (!s) {
                setInvalidSession(true)
                return
            }

            const iframe = searchParams.get('iframe')
            // console.log('iframe', iframe)
            if (iframe == '1') {
                setIframe(true)
                document.getElementById('helpme').style.display = 'none'                
            }
            
            // const s = 'NWVkZDdhYTA5NWNkMDM0YTI3NzVhNjI2fDVlZGYwNjJlN2UxMzc3M2U2MzQzYmYzNnxTZXJnZWlX';
            const session = base64decode(s)
            // console.log('session', session)
            if (!session) {
                setInvalidSession(true)
                return
            }
            const sessionParts = session.split('\|')
            console.log(sessionParts.length)
            if (!Array.isArray(sessionParts) || sessionParts.length != 3) {
                setInvalidSession(true)
                return
            }

            const show = await loadShow();          

            setChatSession({
                user_uid: sessionParts[0],
                viewer_uid: sessionParts[1],
                show_uid: id,
                username: sessionParts[2]
            })

            stats.user_uid = sessionParts[0]
            stats.viewer_uid = sessionParts[1]

            const ipgeo = await getIpGeo()
            setIpgeo(ipgeo)
            stats.location = { 
                country_code: ipgeo ? ipgeo.country_code2 : null,
                country_name: ipgeo ? ipgeo.country_name : null,
                city: ipgeo ? ipgeo.city : null,
                ip: ipgeo ? ipgeo.ip : null,
            }
            
            if (stats.user_uid) {
                console.log(stats)
                registerShowStat(id, stats)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)

            
        }
    }

    onLoad();
  }, [id]);

  useEffect(() => {
        
        function handleResize() {
            console.info('resize')
            setContainerHeight(window.innerHeight)
            if (containerRef.current) {
                let chatH = null
                let messageH = null
                const headerH = headerRef.current ? headerRef.current.offsetHeight : 0
                // console.log("videoRef",videoRef.current.offsetWidth)
                // console.log("window", window)
                if (window.innerWidth <= 768) {
                    const phoneFooterH = iframe ? 0 : 180
                    chatH = containerRef.current.offsetHeight - headerH - phoneFooterH;// - 46;
                    // messageH = containerRef.current.offsetHeight - videoRef.current.offsetHeight - headerRef.current.offsetHeight - 10;// - 46;
                    messageH = window.innerWidth * 0.5616
                } else {
                    // console.log("playerRef",playerRef.current)
                    // chatH = playerRef.current.offsetHeight;
                    const phoneFooterH = iframe ? 0 : 100
                    chatH = window.innerHeight - headerH - phoneFooterH;
                    // messageH = window.innerHeight - headerRef.current.offsetHeight - 10;
                    messageH = window.innerWidth * 0.5616
                }
                console.log(chatH)
                setChatHeight(chatH)
                setMessageHeight(messageH)
            }
        }

        // if (containerRef.current && videoRef.current && playerRef.current) {
        //     // console.log("REF", parentRef.current)
        //     handleResize()
        // }
        //console.log("pREF", parentRef)
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => window.removeEventListener('resize', handleResize);
    })  

  function handleSocketOnMessage(data) {
    //   console.log(data)
    if (data.type == 'status') {
        switch (data.status) {
            case 'start': {
                              
                return;
            }
            case 'restart': {
                
                return;
            }
            case 'issues': {
                return;
            }
            case 'end': {
                
                return;
            }
        }
    }

  }

  function renderVideoEnded() {
      //width:'100%', height:'100%'
    return <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: messageHeight}}>
            <div className="content">
            <img className="show-ended-isotipo" src="/img/isotipo200.png" />
            <div className="show-ended-title">La transmisión del evento ha terminado</div>
            <div className="show-ended-comment">Esperamos hayas disfrutado esta nueva experiencia de espectáculos online</div>
            </div>
        </div>
  }

  function renderInvalidSession() {
    return <div className="show-container" ref={ containerRef} style={{height: containerHeight}}>
        <div className="show-header" ref={ headerRef }>                
                <div className="social-icons">
                    <a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Vívelo Online</a> &nbsp;
                    <a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @vivelo.online</a> &nbsp;
                    #viveloonline
                </div>
                <div className="small-logo">
                    <img src="/img/vivelo-logo.png" className="small-logo" />
                </div>
        </div>
        <div className="show-content" style={{padding:20}}>          
        <FontAwesomeIcon icon={faFrownOpen} /> Los sentimos.<br />La sesión de chat que deseas iniciar es inválida. Intenta escanear el código QR de nuevo.
        </div>      
    </div>
  }

  function renderChat() {
    return <div className="show-container" ref={ containerRef} style={{height: containerHeight}}>
        { !iframe && <div className="show-header" ref={ headerRef }>
            <div>{show.fullname}</div>
            <div className="social-icons">
                <a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Vívelo Online</a> &nbsp;
                <a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @vivelo.online</a> &nbsp;
                #viveloonline
            </div>
            <div className="small-logo">
                <img src="/img/vivelo-logo.png" className="small-logo" />
            </div>
        </div> }
        <div className="show-content" >          
            <div className="show-interactions">
                <div className="chat-box">                        
                    {chatHeight && <Chat channel={id} session={chatSession} style={{height:chatHeight}} 
                        chatVisible={true}
                        socketRef={(socket) => { console.log("socket ref", socket) } } socketOnMessage={ async (data) => { return handleSocketOnMessage(data) }}
                    />}
                </div> 
            </div>
        </div>      
    </div>
  }
 
  return !isLoading && ( invalidSession ? renderInvalidSession() : renderChat() )

}