import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Button } from 'react-bootstrap'
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCircle, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
// import { faClock } from "@fortawesome/free-regular-svg-icons";
import { deviceDetect, isMobile, CustomView } from "react-device-detect";
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from 'moment';

import Loader from 'components/Loader'

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { useCart } from "libs/cartLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

import "./Main.css"
import { render } from "@testing-library/react";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const { getCart, addCartItems, removeCartItems, resetCart } = useCart()
  const [show, setShow] = useState(null);
  const [childShows, setChildShows] = useState(null);
  
  const [isLoading, setIsLoading] = useState(false);
  const [season, setSeason] = useState(2);
    
  const linkifyComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
  );

  let session = null
  
    useEffect(() => {

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow(session);
            setShow(show)

            const children = show.children.map(c => {
                const child = c.show
                child.access = c.access
                child.tickets = c.tickets
                return child
            })
            setChildShows(children)

            // const children = await loadChildShows();            
            // setChildShows(children)
            
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow(session) {
        let viewer = ''
        if (session?.viewer) {
            viewer = session.viewer._id
        }
        return API.get("vivelo", `/shows/showChannels/${id}?viewer=${viewer}`);
    }
    async function loadChildShows() {
        const children = await API.get("vivelo", `/shows/?parent=${id}&filter=ALL&sort=-1&visible=-1`);        
        return children && children.items ? children.items : []
    }

    async function registerCartItem(selectedShow, ticket) {
        // trackEvent({
        //     action: "Clicked Add to Cart Button",
        //     category: "PurchaseFunnel"
        // });

        const currency = selectedShow?.settings?.currency || 'GTQ'

        const date = moment(selectedShow.show_date).format('ddd, LL - LT')

        const cartItem = {
            ticket_uid: ticket._id,
            type: 'TICKET',                
            show_uid: selectedShow._id,
            show_name: selectedShow.fullname,
            show_date: selectedShow.show_date,
            show_type: selectedShow.type,
            venue: selectedShow.venue,                
            title: `${selectedShow.fullname} (${date})`,
            description: ticket.fullname,
            note: ticket.note,
            ticket_type: ticket.type,
            image: `${selectedShow.main_image}`,
            quantity: 1
        }
        if (ticket.location) {
            cartItem.location = ticket.location
        }

        await addCartItems({ currency: currency, items: [cartItem] })
        await updateCartCounter()
        history.push('/cart',{})
        
    }

    async function handleRegisterButton(show, ticket) {
        // trackEvent({
        //     action: "Clicked Register Now Button",
        //     category: "PurchaseFunnel"
        // });

        await resetCart()
        
        const currency = show?.settings?.currency || 'GTQ'

        const date = moment(show.show_date).format('YYYY/MM/DD LT')

        const items = [{
            ticket_uid: ticket._id,
            type: 'TICKET',
            show_uid: show._id,
            show_name: show.fullname,
            show_date: show.show_date,
            title: `${show.fullname} (${date})`,
            description: ticket.fullname,
            note: ticket.note,
            image: `${show.main_image}`,
            quantity: 1
        }]

        await addCartItems({ currency: currency, items: items })
        updateCartCounter()
        history.push('/cart/register')

        
    }


    function renderTicketBox(show) {
        if (['ACTIVE','LIVE','REPLAY','TEST'].includes(show.status)) {
            if (show.paid) {
                if (show.access) {
                    return <div><Button variant="outline-success" size="sm" href={`/shows/${show._id}/player?code=${show.access?.payment_info?.request_code}`} ><FontAwesomeIcon icon={faPlayCircle} /> Ver</Button>
                    <br />Boleto: {show.access?.payment_info?.request_code}.
                    <br />Vence: <Moment local format="ddd, LL - LT">{new Date(show.access.payment_info.expires_on)}</Moment></div>
                } else {
                    if (show?.settings?.registration) {
                        const ticket = show.tickets.filter(t => t.payment_type.includes('REGISTRATION') )
                        if (ticket && ticket.length > 0) {  
                            return <div><Button variant="outline-light" size="sm" href={`/shows/${show._id}/player`} >
                                <FontAwesomeIcon icon={faPlayCircle} /> Acceso Gratuito</Button></div>
                            // return <div><Button variant="outline-light" size="sm" onClick={
                            //     () => {
                            //         handleRegisterButton(show, ticket[0])
                            //     }
                            // } >Registrarme</Button></div>
                        }
                    } else {
                        const ticket = show.tickets.filter(t => t.payment_type.includes('CREDIT_CARD') )
                        if (ticket && ticket.length > 0) {                    
                            const price = ticket[0].price_text                            
                            return <div><Button variant="outline-light" size="sm" onClick={
                                () => {
                                    registerCartItem(show, ticket[0])
                                }
                            } >Acceso {price}</Button></div>
                        }
                    }
                    
                }
            } else {
                return <div><Button variant="outline-success" size="sm" href={`/shows/${show._id}/player`} ><FontAwesomeIcon icon={faPlayCircle} /> Acceso gratuito</Button></div>
            }
        }
    }

    function renderPlaylist(shows, season) {
        if (shows) {
            
            return <div className="show-playlist">
                { shows
                    .filter( p => p.settings.season == season)
                    .filter( p => ['ACTIVE','SOON','LIVE','REPLAY','TEST'].includes(p.status))
                    .map( p => {
                        // const playerLink = ;
                        let actionLink = `/shows/${p._id}/`
                        let accessCode = null
                        let icon = faUnlockAlt
                        
                        if( p.paid == false ) {                            
                            actionLink = `/shows/${p._id}/player`
                            icon = faPlayCircle
                        }
                        else if (p.access?.payment_info && moment(p.access?.payment_info?.expires_on).isAfter(moment.utc())) {                            
                            accessCode = p.access.payment_info.request_code
                            actionLink = `/shows/${p._id}/player?code=${accessCode}`
                            icon = faPlayCircle
                        }
                    

                    return (
                    <div key={p._id} className={`show-item`}>
                        <div className="show-item-image">
                            <img src={p.main_image} />
                            { ['ACTIVE','LIVE','REPLAY','TEST'].includes(p.status) && p.video_url && (
                            <div className="play-overlay">
                                <a 
                                href={actionLink} target="_blank"                                
                                className="d-flex justify-content-center align-items-center" 
                                // onClick={() => { trackEvent({
                                //     action: "Clicked Play Overlay",
                                //     category: "Show"
                                // }); }}
                                ><FontAwesomeIcon icon={icon} /></a>
                                {['LIVE'].includes(p.status) &&
                                <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                                }
                            </div>
                            ) }
                            { (['SOON'].includes(p.status) /*|| !p.video_url*/) && (
                            <div className="soon-overlay">
                                <div>PRÓXIMAMENTE</div>
                            </div>
                            ) }
                        </div>
                        {/* <div className="show-item-content"> */}
                            <div className="show-item-info">
                                <div className="show-item-title">
                                { ['ACTIVE','LIVE','REPLAY','TEST'].includes(p.status) && p.video_url ? (
                                    <a href={actionLink} target="_blank">{p.fullname}</a>
                                ) : <>{p.fullname}</> }
                                </div>
                                <div className="show-item-date">{p.settings.date}</div>
                            </div>
                            <div className="show-item-description">
                                <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(p.description)}</Linkify></>
                                <div className="show-item-action">{renderTicketBox(p)}</div>
                            </div>
                        {/* </div> */}
                    </div>)
    
                })
                }
            </div>
        }
    }

    return (
        !isLoading && show ? <>
        <div className="site-malasintenciones">
            <div className="header">            
                <div className="background" style={{backgroundImage:`url("${isMobile ? show.settings?.channel_image_mobile : show.settings?.channel_image}")`}}>
                    <div className="gradient"></div>
                </div>
                <div className="show-info">
                    <div className="show-title">{show.fullname}</div>
                    <div className="show-badges">
                        <div className="show-year">2021</div>
                        <div className="show-rating">18+</div>
                        <div className="show-quality">HD</div>
                    </div>
                    <div className="show-description">
                        <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(show.description)}</Linkify></>
                    </div>
                </div>
            </div>
            <div className="body">

                <div className="seasons">
                    <div className="season-title">Temporada</div>
                    <div className={`season-name ${season == 1 ? 'selected' : ''} `}><a href="#1" onClick={() => setSeason(1)}>1</a></div>
                    <div className={`season-name ${season == 2 ? 'selected' : ''} `}><a href="#2" onClick={() => setSeason(2)}>2</a></div>
                </div>
                <div>
                    <CustomView condition={season == 1} >
                    {childShows && renderPlaylist(childShows, 1)}
                    </CustomView>
                    <CustomView condition={season == 2} >
                    {childShows && renderPlaylist(childShows, 2)}
                    </CustomView>
                </div>
                
            </div>
            <div className="footer">
            <h2>Acerca de <b>Piwiz y Ricardo</b></h2>
                <div className="content">
                <div>
                <p>Piwiz Lima y Ricardo Girón son 2 rebeldes generadores de contenido; amantes de la buena plática, tragos y algunas bromas subidas de tono.  Compañeros en otra corporación radial años atrás, inician en 2017  la grata y aceptada experiencia de LOS INDOMABLES, (5-8pm) el “drive time” de 92.5fm +Música de GRUPO NUEVO MUNDO.  Un programa de radio con variedad de temas actuales y locuras diarias, donde LOS INDOMABLES exponen sus peculiares puntos de vista con participación masiva de la audiencia.  Contenido que les ha valido la aceptación del público y los ha colocado como líderes en audiencia en este horario. </p>
<p>En 2019 presentan MALAS INTENCIONES un podcast con 8 capítulos grabados entre Guatemala y México donde escapan de la censura y en formato audiovisual traspasan la barrera de la sobriedad con invitados especiales y temas candentes. El formato fue pedido por la audiencia radial y fue aceptado de inmediato con comentarios positivos y creando polémica por algunos invitados.</p>
<p>La temporada 2 de Malas Intenciones fue lanzada el 5 de noviembre de 2020 en un formato bajo demanda en la plataforma vívelo online y la producción de BIBO - tvr. Un show extenso que busca diversión con los invitados y aprovecha al máximo la libertad del formato privado con invitados polémicos como Marlon Puente “pirulo”, el humor en versión “whisky” de Los 3 Huitecos,  la popularidad de influencers como Gaby Asturias y Brenda Hughes y un espectacular cierre que dejará a muchos con ganas de más. </p>
<p>¿Qué viene?…. sin dudarlo respondemos: Seguir siendo rebeldes creando contendido, nuestro ADN es así.</p>
                </div>
        
                </div>
            </div>
        </div>
        </> : <Loader />
  );
}