import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form"
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";
import { db } from '../libs/firebase';

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

export default function Login() {
  const [messages, setMessage] = useState([]);

  useEffect(() => {
        async function onLoad() {
          db.ref('/chats-6005e963eaf3235389ee7b20/messages/').on('value', function (snapshot) {
            let mess = []
            let messages = snapshot.val()
        //    console.log(messages)
            for (const prop in messages) {
                //console.log(prop)
              const m = messages[prop]
                console.log(m.username +','+m.message)
              mess.push({ username: m.username, message: m.message })              
              // let msgs = [...state.messages, data]
            }            
            setMessage(mess)
              
          })
           
        }
      
        onLoad();
      }, []);

  return (
    <div className="content content-dark">
      {messages.map(m => {
        return <div>{m.username},{m.message}</div>
      })}
    </div>
  );
}