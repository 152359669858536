import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormGroup, FormControl, FormLabel, FormCheck, Form } from "react-bootstrap/";
import LoaderButton from "../components/LoaderButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


import { useAppContext } from "../libs/contextLib";
import { useHistory, useLocation } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import { useAnalytics } from "../libs/analytics";

import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";
import Cookie, { set } from 'js-cookie';

import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

import "./Login.css";

export default function Activate(props) {
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const { isAuthenticated, userHasAuthenticated, getSession, setSession, login, signup } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    purchaseCode: "",
    sessionType: props.sessionType || "signup",
    email: "",
    password: "",
    fullname: "",
    confirmPassword: "",
  });
  const [curSession, setCurSession] = useState(null);

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      console.log(searchParams)
      if (searchParams.get('code')) {
        handleFieldChange({ target: { id:'purchaseCode', name: 'purchaseCode', value: searchParams.get('code') }})
      }    

      async function initSession() {
        let session = await getSession()
        if (session) {
          setCurSession(session)
        }
      }
      initSession()
  }, []);


  async function activateCode(viewer_id, payment_type, payment_info, tickets) {
    console.log('activiting code')
    const purchase = await API.post("vivelo", `/viewers/viewers/${viewer_id}/activate/${fields.purchaseCode}`, {
        body: {
            payment_type: payment_type,
            payment_info: payment_info,
            tickets: tickets,
            livestream: true,
            replay: true,
            meetngreet: false
        }
    });    
    
    return purchase;
  }


  function validatePurchaseCodeForm() {
    const codeOk = fields.purchaseCode.length > 0;
    const loginOk = fields.email.length > 0 && fields.password.length > 0;
    const signupOk = fields.fullname.length > 0 && fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword;    
    
    if (!isAuthenticated) {
      if (fields.sessionType === 'login') {
        return codeOk && loginOk
      } else if (fields.sessionType === 'signup') {  
        return codeOk && signupOk
      } 
    } else {
      return codeOk
    }
  }

  function checkedSessionType(value) {    
    return fields.sessionType == value
  }



  async function handleSubmitCode(event) {    
      event.preventDefault(); 

      trackEvent({
        action: "Clicked Activate Button",
        category: "PurchaseFunnel"
      });
            
      try {
        setIsLoading(true);

        let session = await getSession()
        console.log(isAuthenticated, session)
        if (!isAuthenticated && !session) {
            if (fields.sessionType == 'login') {
              session = await login(fields.email, fields.password)
              if (!session) {
                Notification('error', 'Usuario o contraseña inválidos')
              }
            } else {
              session = await signup(fields.fullname, fields.email, fields.password, ['Viewer'])
              if (session === false) {
                Notification('error', 'Ya existe un usuario registrado con ese correo')
              }
            }
        }        
        if (session && session.viewer) {
          setCurSession(session)
          setIsLoading(true)
          const viewer_id = session.viewer._id
          const payment_info = {
              request_code: fields.purchaseCode.toUpperCase()
          }
          const purchase = await activateCode(viewer_id, 'CODE', payment_info, [])
          
          if (purchase) {
            console.info("Activation complete.  Redirecting to Show")
            setIsLoading(false);
            Notification('success', 'Tu boleto ha sido activado exitosamente')
            if (props.modal && props.handleModalSuccess) {
              props.handleModalSuccess()
            }
            history.push(`/shows/${purchase.show_uid}/player?activated=1`)
          } else {
            setIsLoading(false);
          }

          
        }

      } catch (err) {
        onError(err)
        if (err.response) {
          console.error("activation error status", err.response)
          switch(err.response.status) {
            case 401: {
              Notification('error','Usuario o contraseña inválida')
              break;
            }
            case 409: {
              Notification('info','Ya tienes un boleto activado para este show')
              history.push(`/shows/${err.response.data.show_uid}/player`)
              break;
            }
            default: {                            
              Notification('error','No fue posible activar el código ingresado')
              break;
            }
          }
        } else {
          console.error("activation exception", err)
          Notification('error','Ocurrió un error al intentar activar el código ingresado')
        }
      } finally {
        setIsLoading(false);
      }


      
  }
  

  function renderSignupFields() {
    return <>
    <FormGroup controlId="fullname" size="lg">
      <FormLabel>Nombre</FormLabel>
      <FormControl
        autoFocus
        type="fullname"
        value={fields.fullname}
        onChange={handleFieldChange}
        className={fields.fullname == "" ? "field-error" : "field-ok"}
      />
    </FormGroup>
    <FormGroup controlId="email" size="lg">
      <FormLabel>Email</FormLabel>
      <FormControl        
        type="email"
        value={fields.email}
        onChange={handleFieldChange}
        className={fields.email == "" ? "field-error" : "field-ok"}
      />
    </FormGroup>
    <FormGroup controlId="password" size="lg">
      <FormLabel>Contrase&ntilde;a</FormLabel>
      <FormControl
        type="password"
        value={fields.password}
        className={fields.password == "" ? "field-error" : "field-ok"}
        onChange={handleFieldChange}
      />
    </FormGroup>
    <FormGroup controlId="confirmPassword" size="lg">
      <FormLabel>Confirma tu Contrase&ntilde;a</FormLabel>
      <FormControl
        type="password"
        onChange={handleFieldChange}
        value={fields.confirmPassword}
        autoComplete="off"
        placeholder="Confirma tu contraseña"
        className={fields.password != fields.confirmPassword ? "field-error" : "field-ok"}
      />
    </FormGroup>
    </>
  }

  function renderLoginFields() {
    return <><Form.Group controlId="email" size="lg">
    <Form.Label>Email</Form.Label>
    <Form.Control      
      type="email"
      value={fields.email}
      onChange={handleFieldChange}
      className={fields.email == "" ? "field-error" : "field-ok"}
    />
  </Form.Group>
  <Form.Group controlId="password" size="lg">
    <Form.Label>Contraseña</Form.Label>
    <Form.Control
      value={fields.password}
      autoComplete="off"      
      className={fields.password == "" ? "field-error" : "field-ok"}
      onChange={handleFieldChange}
      type="password"
    />
    <div>
      <a href={`/password/recover`} className="password-recover">Olvid&eacute; mi contrase&ntilde;a</a>
    </div>
  </Form.Group>
  {/* <Form.Group controlId="forceLogout" size="lg">
    <Form.Check 
      type="checkbox"            
      id={`forceLogout`}
      name="forceLogout"
      value={true}
      checked={true}
      // onChange={handleFieldChange}
      label={`Cerrar sesiones abiertas en otros dispositivos`}
      readOnly
    /> 
  </Form.Group>   */}
  </>
  }
   

  function renderPurchaseCodeForm() {    
    return <><Container>
    <Row>
      <Col>
        { !props.modal && <h1>Activa tu boleto</h1> }
        <div className="description">Activa tu boleto para poder ver el show</div>
      </Col>
    </Row>
    <Row style={{ }}>
      <Col md={props.modal ? "8" : "4"}>
   
    <form onSubmit={handleSubmitCode}>
        <FormGroup controlId="purchaseCode" size="lg">
          <FormLabel>C&oacute;digo de Boleto</FormLabel>          
          <FormControl
                autoFocus
                type="text"
                onChange={handleFieldChange}
                value={fields.purchaseCode}
                className={fields.purchaseCode == "" ? "field-error" : "field-ok"}
                placeholder="Ingresa tu código"
                size="6"
                maxLength="6"
                style={{textTransform:'uppercase'}}
            /> 
            <div className="note">Ingresa el c&oacute;digo de boleto que enviamos a tu correo</div>
        </FormGroup>
        
        { !isAuthenticated ? <>
          <Form.Switch  size="lg" style={{paddingLeft:0, paddingBottom: 10}}>
          <FormCheck 
            inline
              type="radio"
              id={`sessionTypeLogin`}
              name="sessionType"
              value="login"
              checked={checkedSessionType('login')}
              onChange={handleFieldChange}
              label={`Ya tengo usuario`}
            />
          <FormCheck 
          inline
              type="radio"
              id={`sessionTypeRegister`}            
              name="sessionType"
              value='signup'
              checked={checkedSessionType('signup')}
              onChange={handleFieldChange}
              label={`Crear usuario`}
            />
          </Form.Switch>
          
          { fields.sessionType === "signup" ? renderSignupFields() : renderLoginFields() }

          </> : curSession && <>          
        <div style={{padding: '10px 10px', border: 'solid 1px', marginBottom: 10}}>Activar boleto para: <br />{curSession.user.fullname} / {curSession.user.username} </div>
          </> }

        <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validatePurchaseCodeForm()}
          variant={validatePurchaseCodeForm() ? 'primary' : 'secondary'}
        >
          Continuar
        </LoaderButton>
        { !validatePurchaseCodeForm() && <span style={{color:'red'}}>* Por favor llena todos los campos con borde rojo</span>

        }
      </form>

      </Col>
      </Row>
    </Container></>
  }


  return (
    <div className="content content-dark">
      {renderPurchaseCodeForm()}
    </div>
  );
}