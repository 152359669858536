import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      //console.log(event.target.type, event.target.name)      
      if (event.target.type === "radio") {
        console.log(event.target.name + '('+event.target.id+')' + '->' + event.target.value)
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          [event.target.id]: event.target.value
        });
      } else if (event.target.type === "checkbox") {
        console.log(event.target.name + '('+event.target.id+')' + '->' + event.target.checked)
        setValues({
          ...fields,
          [event.target.name]: event.target.checked,
          [event.target.id]: event.target.checked
        });
      }else {
        // console.log(event.target.id + '->' + event.target.value)
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      }
    }
  ];
}