import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';
import { API, Storage } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Accordion, Card } from "react-bootstrap";
import { Form, FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap/";
import CreditCardInput from 'react-credit-card-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTicketAlt, faPlayCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import OrderSummaryBox from "components/cart/OrderSummaryBox"
import LoaderButton from "../components/LoaderButton";
import { onError, Notification } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import Loader from '../components/Loader'

import "./Show.css";
import "./Order.css";

export default function Order() {  
  const { id } = useParams();
  const history = useHistory();
  const { isAuthenticated, session } = useAppContext();
  const [order, setOrder] = useState(null);
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [extras, setExtras] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        async function onLoad() {
            try {         
                const order = await loadOrder(id); 
                setOrder(order)
                if (order) {
                    /*const show = await loadShow(order.show_uid);
                    setShow(show)
                    if (session && session.viewer) {
                        loadViewerShow(session.viewer._id, order.show_uid)
                    }*/
                }
                
            } catch (e) {
                onError(e);
            }
        }

    onLoad();
    }, [id]);

    async function loadOrder(order_id) {
        return API.get("vivelo", `/viewers/orders/${order_id}`);
    }
    async function loadShow(show_id) {
        return API.get("vivelo", `/shows/${show_id}`);
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    async function resendTickets() {        
        const result = await API.post("vivelo", `/viewers/orders/${id}/resendTickets`);        
        Notification('success','Tus boletos fueron reenviados al correo registrado en la orden')
    }


    function renderTicketsSummary() {
        // <Col md="2">{!viewerShow ? <Button variant="primary" data-ticket-id={t.id} onClick="">Comprar</Button> : null }</Col>

        let items = [];
        if (order.tickets) {
            items = order.tickets.filter(t => t.type == 'TICKET').map( t => {                
                return <>
                <Row key={t._id}>
                    <Col md="6">{`${t.show_name} `}
                    {/* <Moment local format="LLLL">{new Date(show.show_date)}</Moment> */}
                    <br />{t.description}</Col>
                    <Col md="6" className="price">{t.quantity} x {t.price}</Col>
                </Row>
                { order.status == 'APPROVED' && order.codes.filter( c => c.ticket_uid == t._id).map( c => {
                    const code = c.code.split('-')[1]
                    return <Row key={`${c._id}`}>
                        <Col className="order-ticketCode">Código {code} <a href={`/activate?code=${code}`} target="_blank">ACTIVAR</a></Col>                        
                    </Row>
                }) }
                
                </>
            })
        }
        return <>
            { order.tickets ? <>{items}
                <Row style={{borderTop: 'solid 1px', paddingTop: 10}}>
                    <Col md="6">TOTAL</Col>
                    <Col md="6" className="price">Q{order.total}</Col>                    
                </Row>
                <Row className="note"><Col>**Cada boleto tiene un código único y personal</Col></Row>
                </>
               : <Row><Col>Sin Orden</Col></Row> }
            </>

    }

    function renderStatus() {
        switch(order.status) {
            case 'APPROVED': return <Badge variant="success" size="lg" >APROBADA</Badge>;
            case 'REJECTED': return <Badge variant="danger" >DENEGADA</Badge>;
            case 'CANCELLED': return <Badge variant="warning" >ANULADA</Badge>;
            case 'REFUND': return <Badge variant="secondary" >REEMBOLSO</Badge>;
            case 'WAITING_CONFIRMATION': return <Badge variant="info" >ESPERANDO APROBACIÓN</Badge>;
            default: return <Badge variant="primary" >{order.status}</Badge>;
        }
    }

    function renderStatusMessages(order) {
        switch (order.status) {
            case 'APPROVED': {
                return <>
                    <Row className="order-subtitle"><Col>{order.payment_type == 'REGISTRATION' ? 'Tu registro ha sido procesado exitosamente.' : 'La compra ha sido procesada exitosamente.'}</Col></Row>
                    <Row className="order-subtitle"><Col>Tus boletos serán enviados al correo registrado en la orden: <b><i>{order.payment_info.email}</i></b></Col></Row>
                    <Row className="order-subtitle"><Col>Si compraste más de un boleto debes reenviarlo a tus invitados</Col></Row>
                    <Row className="order-subtitle"><Col><br />
                        <ul>
                            <li><b>Si tu boleto es virtual</b>, recuerda activar tu boleto en tu cuenta para poder ver el evento.</li>
                            <li><b>Si tu boleto es presencial</b>, recuerda presentar el código QR en la admisión del evento de manera digital o impreso.  Un código por persona.</li>
                        </ul>                    
                    </Col></Row>
                </>
            }
            case 'WAITING_CONFIRMATION': {
                return <>
                    <Row className="order-subtitle"><Col>{order.payment_type == 'REGISTRATION' ? 'Tu registro ha sido procesado exitosamente.' : 'La orden ha sido registrada exitosamente.'} </Col></Row>
                    <Row className="order-subtitle"><Col><b>Estamos esperando la confirmación de tu depósito/transferencia.</b>  Al ser confirmada la orden, tus boletos serán enviados al correo registrado en la orden: <b><i>{order.payment_info.email}</i></b></Col></Row>                    
                    <Row className="order-subtitle"><Col>Si compraste más de un boleto debes reenviarlo a tus invitados</Col></Row>
                    <Row className="order-subtitle"><Col><br />
                        <ul>
                            <li><b>Si tu boleto es virtual</b>, recuerda activar tu boleto en tu cuenta para poder ver el evento.</li>
                            <li><b>Si tu boleto es presencial</b>, recuerda presentar el código QR en la admisión del evento de manera digital o impreso.  Un código por persona.</li>
                        </ul>                    
                    </Col></Row>
                </>
            }
            default: {
                return <Row className="order-subtitle"><Col>{order.payment_type == 'REGISTRATION' ? 'Tu registro no ha sido exitoso.' : 'La compra no ha sido exitosa.'}</Col></Row>
            }
        }
    }
 
    return (
        order ?    
        <div className="Order content content-dark">
            <Container>
            <Row className="order-title">
                <Col>Gracias {order.payment_info.firstname}</Col>
                <Col style={{textAlign:'right'}}>
                    { order.status == 'APPROVED' && <><Button variant="info" onClick={resendTickets}>Reenviar Correo</Button>&nbsp;</> }
                    <Button variant="secondary" onClick={() => window.print()}>Imprimir</Button>
                </Col>
            </Row>
            <Row className="order-subtitle"><Col>{renderStatus()}</Col></Row>
            { renderStatusMessages(order) }
            
                        
            <Row>
                
            </Row>
            <Row style={{marginTop:20, paddingTop:20, borderTop: 'solid 1px white'}} >
                <Col className="data-table">
                    {<OrderSummaryBox order={order} />}
                </Col>
            </Row>

            <Row style={{marginTop:30}} >
                <Col className="order-info">
                    <>ID: {order._id}</>
                </Col>
                <Col>
                    {order.payment_response && <><span className="order-info">Transacci&oacute;n: {order.payment_response.transaction_id}</span> / {renderStatus()}</> }
                </Col>
            </Row>
            <Row>
                <Col className="order-info">
                    <>Fecha: <Moment local format="YYYY-MM-DD HH:mm:ss" locale="es">{new Date(order.createdAt)}</Moment></>
                </Col>
                <Col className="order-info">
                    { order.payment_response && <>Autorizaci&oacute;n: {order.payment_response.authorization}</> }
                </Col>
            </Row>
            </Container>
        </div>
        : <Loader />
  );
}