import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button } from "react-bootstrap";
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faExpandArrowsAlt, faCircle } from "@fortawesome/free-solid-svg-icons";
import Chat from '../components/Chat'

import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";



import "./ShowView.css";

export default function Notes() {
  //const file = useRef(null);
  const { isAuthenticated, getSession } = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const [session, setSession] = useState(null);
  const [chatSession, setChatSession] = useState(null);
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [played, setPlayed] = useState(0);
  const [playing, setPlaying] = useState(true);
  const [loaded, setLoaded] = useState(0);
  const [ended, setEnded] = useState(true);
  const [muted, setMuted] = useState(true);


//   let session = null

    useEffect(() => {

    async function loadShow() {        
        const show = await API.get("vivelo", `/shows/${id}`);
        if (show) {
            setShow(show)
            console.log(['LIVE','REPLAY'].includes(show.status), show)            
            if (['LIVE','REPLAY'].includes(show.status)) {
                setEnded(false)
            }
            if (['NEW','SOON','ACTIVE','CLOSED','CANCELLED'].includes(show.status)) {
                history.push(`/shows/${id}`)
            }
        }
        return show
    }
    async function loadViewerShow(viewer_id, show_id) {
        try {
            const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
            if (vShow) {
                setViewerShow(vShow)                
            }
            return vShow
        } catch (error) {
            console.log(error)
            return null
        }
    }

    async function onLoad() {
        try {          
            setIsLoading(true)

            let sess = await getSession()
            setSession(sess)

            let show = null
            let vShow = null
            if (sess) {
                show = await loadShow();  
                              
                if (isAuthenticated) {                               
                    console.log(show)
                    console.log(sess)
                    vShow = await loadViewerShow(sess.viewer._id, show._id)
                } 
            }

            if (!vShow) {
                history.push(`/shows/${id}`)
            } 

            loadVideo(show)

            setChatSession({
                user_uid: sess.session.user_uid,
                viewer_uid: sess.viewer._id,
                show_uid: id,
                username: sess.user.fullname
            })
            

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

  function loadVideo(show) {
      console.log(`loading video ${show.video_url}`)
      //setVideoUrl(`https://vimeo.com/${show.video_id}`)
      setVideoUrl(`${show.video_url}`)
      //setVideoUrl("https://vimeo.com/417491036")
      setPlayed(0)
      setLoaded(0)
      setEnded(false)
      setMuted(false)
        //url="https://vimeo.com/417491036" 
  }
  function unloadVideo() {
    setEnded(true)
    setVideoUrl(null)
    setPlayed(0)
    setLoaded(0)
    
  }

  function handleSocketOnMessage(data) {
      console.log(data)
    if (data.type == 'status') {
        switch (data.status) {
            case 'start': {
                loadVideo(show)                
                return;
            }
            case 'restart': {
                setVideoUrl(null)
                setPlayed(0)
                setLoaded(0)
                loadVideo(show)
                return;
            }
            case 'issues': {
                return;
            }
            case 'end': {
                unloadVideo()
                return;
            }
        }
    }

  }

  function renderPlayerControls() {
      return true && <div>
          <Button size="lg"><FontAwesomeIcon icon={faPlayCircle} /></Button>
          <Button size="lg" style={{float:'right'}} onClick={() => { setMuted(!muted) }}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
          <Button size="lg" style={{float:'right'}}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
      </div>
  }

  function handleOnEnded() {
      console.log("Ended")
      unloadVideo()
  }

  function handleOnPlay() {
      setMuted(false)
  }
  function handleOnPause() { console.log("pause")};
  function handleOnReady() { console.log("ready")};
  function handleOnLoad() { console.log("load")};
  function handleOnBuffer() { console.log("buffer start")};
  function handleOnBufferEnd() { console.log("buffer end")};
  function handleOnStart() { console.log("start")};

  function renderVideoPlayer() {
    return <>
        <div className="video-player">
            <ReactPlayer
                className="react-player"
                // config={{
                //     vimeo: {
                //         playerOptions: {
                //             controls: false,
                //             loop: true
                //         }
                //     }
                // }}
                //url="https://vimeo.com/417491036" 
                //url="https://vimeo.com/event/133164"
                url={videoUrl}
                played={played}
                loaded={loaded}
                controls
                playsinline
                playing={playing}
                //volume={0.8}
                width='100%'
                height='100%'
                //pip={true}
                muted={muted}
                onEnded={handleOnEnded}
                onPlay={handleOnPlay}
                onPause={handleOnPause}
                onReady={handleOnReady}
                onLoad={handleOnLoad}
                onBuffer={handleOnBuffer}
                onBufferEnd={handleOnBufferEnd}
                onStart={handleOnStart}
            />
        </div>        
        {/* { renderPlayerControls() } */}
        </>
         
  }

  function renderVideoEnded() {
      //width:'100%', height:'100%'
    return <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div className="content">
            <img className="show-ended-isotipo" src="/img/isotipo200.png" />
            <div className="show-ended-title">La transmisión del evento ha terminado</div>
            <div className="show-ended-comment">Esperamos hayas disfrutado esta nueva experiencia de espectáculos online</div>
            </div>
        </div>
  }

  return (
/*
<div class="video-player" style="padding:56.25% 0 0 0;position:relative;">
                            <Iframe src="https://player.vimeo.com/video/415993914" frameborder="0" allow="autoplay; fullscreen" allowFullScreen style="{{position:absolute;top:0;left:0;width:100%;height:100%;}}"></Iframe>
                        </div>
*/


!isLoading && viewerShow && <div className="">
        <Container className="show-container">            
            <Row className="show-header">
                <Col>
                <div className="show-title primary">
                    {show.fullname}
                </div>
                {false && <div className="live">
                    <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
                </div>}
                <div className="small-logo">
                    <img src="/img/vivelo-logo.png" className="small-logo" />
                </div>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                <div className="show-player">
                    { !ended ? renderVideoPlayer() : renderVideoEnded() }
                    <div className="chat-box">                        
                        <Chat channel={id} session={chatSession} socketRef={(socket) => { console.log("socket ref", socket) } } socketOnMessage={ async (data) => { return handleSocketOnMessage(data) }} />
                    </div> 
                    <div className="title">
                        {show.fullname}
                    </div>
                </div>
                <div>
            <label>Recomendaciones</label>
            <ul style={{listStyleType:'circle'}}>
            <li>Si no te carga el video, intenta recargar la página</li>
            <li>Recuerda revisar los niveles de volumen</li>
            </ul>
        </div>
                </Col>
            </Row>            
        </Container>
    </div>
  );
}