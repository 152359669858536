import { API } from "aws-amplify";
import { onError } from "./errorLib";
import { useAppContext } from "./contextLib";
import { getIpGeo } from './ipgeo';

import {
  deviceDetect
} from "react-device-detect";

import config from "../config";


export const useShowStats = () => {
  const { isAuthenticated, getSession } = useAppContext();

  async function initShowStat(show_uid) {
    let sess = await getSession()
    const ipgeo = await getIpGeo();
    // const sess = null
    // const ipgeo = null

    const stats = {
      show_uid: show_uid,
      session_uid: sess && sess.session ? sess.session._id : null,
      user_uid: sess && sess.user ? sess.user._id : null,
      viewer_uid: sess && sess.viewer ? sess.viewer._id : null,
      device: deviceDetect(),
      location: { 
        country_code: ipgeo ? ipgeo.country_code2 : null,
        country_name: ipgeo ? ipgeo.country_name : null,
        city: ipgeo ? ipgeo.city : null,
        ip: ipgeo ? ipgeo.ip : null,
      } 
    }
            
    return stats
  }

  async function registerShowStat(show_uid, stats) {
    API.post("vivelo", `/stats/shows/${show_uid}/player`, {
      body: stats
    })
  }  

  return [
    initShowStat,
    registerShowStat
  ]

};