import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Form, Badge, Modal } from "react-bootstrap";
import 'moment/locale/es';
import moment from 'moment';


import Loader from 'components/Loader'
import LoaderButton from "components/LoaderButton";

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { useCart } from "libs/cartLib";
import { onError, Notification } from "libs/errorLib";
import { useFormFields } from "libs/hooksLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";
import "./seguridadvial.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { resetCart, addCartItems, createOrder } = useCart();

  const [fields, handleFieldChange] = useFormFields({
    ccSaveInfo: true,
    ccFirstname: "",
    ccLastname: "",
    ccEmail: "",
    ccEmailConfirm: "",
    ccCountry: "GT",
    ccMedio: "",
    ccPhone: "",
    ccDpi: ""
  });
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            // const tickets = await loadShowTickets()
            
            // if (isAuthenticated) {    
            //     console.log(session, show)            
            //     const vShow = await loadViewerShow(session.viewer._id, show._id)
            // }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }
    async function loadOrder(order_id) {
        return API.get("vivelo", `/viewers/orders/${order_id}`);
    }

    // function handlePaymentSuccess(purchase) {
    //     updateCartCounter()
    //     history.push(`/orders/${purchase.id}`)
    // }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >
    /* VALIDATORS */
    function validateRegistrationForm() {
        //return fields.purchaseCode.length > 0 && fields.purchaseCodeTicket;
        return fields.ccFirstname.length > 0 
            && fields.ccLastname.length > 0
            && fields.ccEmail.length > 0
            && fields.ccEmail == fields.ccEmailConfirm
            && fields.ccMedio.length > 0
            && fields.ccPhone.length > 0
            && fields.ccDpi.length > 0
            && fields.ccCountry.length > 0 ;
            // && orderTotal > 0;

    }

    async function handleSubmitRegistration(event) {    
        event.preventDefault();        
        setIsLoading(true);
        
        try {
            
            const currency = 'GTQ'            

            const payment_info = {
                    firstname: fields.ccFirstname,
                    lastname: fields.ccLastname,
                    email: fields.ccEmail,
                    country: fields.ccCountry,
                    currency: 'GTQ',
                    medio: fields.ccMedio,
                    phone: fields.ccPhone,
                    dpi: fields.ccDpi,
                    total: 0
            }
                        
            const order = {
                user_uid: session?.user?._id,
                payment_type: 'REGISTRATION',
                currency: 'GTQ',
                payment_info: payment_info,                
            }
            try {                
                await resetCart()                
            } catch(e) {console.log('error resetting')}

            const date = moment(show.show_date).format('ddd, LL - LT')
            const cartItem = {
                ticket_uid: "663513e0a3c41ba544676467",
                code: "663513e0a3c41ba544676467",
                type: 'TICKET',
                show_uid: show._id,
                show_name: show.fullname,
                show_date: show.show_date,
                show_type: show.type,
                venue: show.venue,
                title: `${show.fullname} (${date})`,
                description: "Acceso Virtual",
                note: "",
                ticket_type: "ONLINE",
                image: `${show.main_image}`,
                quantity: 1
            }
            await addCartItems({ payment_type: 'REGISTRATION', currency: currency, items: [cartItem] })


            const purchase = await createOrder(order)
    
            if (purchase) {
                console.log(purchase)
                console.log("redirecting to player")
                setTimeout(()=>redirectToPlayer(purchase.id), 5000)
                // setIsLoading(false);                
                // history.push(`/orders/${purchase.id}`)
                return true
            } else {
                Notification('error', 'No fue posible realizar la compra', null, 'top')                
                setIsLoading(false);
                return false
            }        
            
        } catch(error) {
            Notification('error', 'No fue posible realizar la compra', null, 'top')
            setIsLoading(false);
            console.error(error)
            return false
        } finally {
            
        }

        
    }  

    async function redirectToPlayer(order_uid) {
        const order = await loadOrder(order_uid)
        const code = order.codes[0].code.split("-")[1]
        const fullname = `${order.payment_info.firstname} ${order.payment_info.lastname}`

        history.push(`/seminario-seguridad-vial/player?code=${code}&fullname=${fullname}`)
    }

    function renderRegistrationForm() {
        return <Form onSubmit={handleSubmitRegistration}>
            <Row>
                <Col md="12">                                        
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccFirstname">
                        <Form.Label>Nombre*</Form.Label>
                        <Form.Control required placeholder="ej: José"  value={fields.ccFirstname} onChange={handleFieldChange} className={fields.ccFirstname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccLastname">
                        <Form.Label>Apellido*</Form.Label>
                        <Form.Control required placeholder="ej: Pérez"  value={fields.ccLastname} onChange={handleFieldChange}  className={fields.ccLastname == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccEmail">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control required type="email" placeholder="" value={fields.ccEmail} onChange={handleFieldChange}  className={fields.ccEmail == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccEmailConfirm">
                        <Form.Label>Confirmar Email*</Form.Label>
                        <Form.Control required type="email" placeholder="" value={fields.ccEmailConfirm} onChange={handleFieldChange}  className={fields.ccEmailConfirm != fields.ccEmail ? "field-error" : "field-ok"} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccPhone" className="phone">
                        <Form.Label>Teléfono*</Form.Label>
                        <Form.Control required placeholder="ej: 50001111"  value={fields.ccPhone} onChange={handleFieldChange} className={fields.ccPhone == "" ? "field-error" : "field-ok"} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="ccDpi" className="dpi">
                        <Form.Label>DPI*</Form.Label>
                        <Form.Control required placeholder="ej: 2334456778890101"  value={fields.ccDpi} onChange={handleFieldChange} className={fields.ccDpi == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="ccMedio">
                        <Form.Label>Medio / Entidad pública o privada / Comunicador independiente*</Form.Label>
                        <Form.Control required placeholder="ej: New York Times"  value={fields.ccMedio} onChange={handleFieldChange}  className={fields.ccMedio == "" ? "field-error" : "field-ok"} />
                        </Form.Group>
                        
                    </Form.Row>
                    
                    <Form.Row>
                        <LoaderButton
                            block
                            type="submit"
                            size="lg"
                            isLoading={isLoading}
                            disabled={!validateRegistrationForm()}
                            variant={validateRegistrationForm() ? 'primary' : 'secondary'}                            
                        >
                        Registrarme
                        </LoaderButton>
                        
                    </Form.Row>
                    
                </Col>
            </Row>                

                
            </Form>       
    }

    return (
        !isLoading && show ? <>
        <Container fluid className="Show content seguridadvial">        
            <div className="main-desktop">
                <img src="https://assets.viveloonline.com/sites/seguridadvial/seguridad-vial-header.png" />
                {/* <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" /> */}
            </div>
            <div className="main-mobile">
                <img src="https://assets.viveloonline.com/sites/seguridadvial/seguridad-vial-header-movil.png" />
                {/* <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" /> */}
            </div>            
            <div className="separator">                
            </div>
            <div className="info welcome">                
                    <div className="info-text">
                    Automóvil Club de Guatemala, la Federación Internacional del Automóvil (FIA), FUNDESA y el programa Movernos Seguros organizan el taller “El Rol de la Comunicación en la Seguridad Vial de Guatemala”, dirigido a profesionales de la comunicación y del periodismo. 
<br /><br />
En este evento se compartirá la información más reciente de seguridad vial en América Latina (se presentará el informe más reciente de la Organización Mundial de la Salud en torno a seguridad vial), recomendaciones y tendencias internacionales para fortalecer la comunicación de esta temática y el impacto que tiene en el público, así como una profunda revisión del concepto de riesgo. Todo ello desde un enfoque humano, aunque con profundo análisis cuantitativo, que permitirá ampliar aristas en la construcción de las noticias que todos los días consumen millones de guatemaltecos y ciudadanos en el mundo a través de las redes sociales. 
<br /><br />
<b>De ahí que los periodistas y comunicadores son vitales para este esfuerzo conjunto.</b>
                    </div>

                    <div className="separator-line"></div>
                    
                    <div className="info-boxes">
                        <div className="title">Seminario "El Rol de la Comunicación en la Seguridad Vial"</div>
                        {/* <div className="info-box"> */}
                            {/* <div className="presencial">
                                <div className="boxtitle">Presencial</div>
                                <div>
                                Lugar: Hotel Camino Real, salón Independencia/Obelisco<br />
                                Día: lunes 6 de mayo<br />
                                Hora: de 7:30 a 10:00 AM.
                                </div>
                            </div> */}
                            {/* <div className="virtual">
                                <div className="boxtitle">Virtual</div>
                                <div>
                                Día: lunes 6 de mayo.<br />
                                Hora; de 15:00 a 17:00 horas<br />
                                Lugar: se enviará el link al inscribirse en este<br />
                                <b>Habrá diploma de participación y declaraciones al final del evento.</b>
                                </div>
                            </div>
                            <div className="virtual">
                                {renderRegistrationForm()}
                            </div> */}
                        {/* </div> */}
                        <div className="video">
                            <div style={{padding:'75% 0 0 0', position:'relative'}}><iframe src="https://player.vimeo.com/video/944178402?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="Seminario &quot;El Rol de la Comunicacion en la Seguridad Vial&quot; - Automovil Club Guatemala"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                        <div className="speakers">
                            <div className="speaker">
                                <img src="https://assets.viveloonline.com/sites/seguridadvial/SeguridadVial-RicardoGavidia.png" />
                                <div className="name">Ricardo Gavidia</div>
                                <div className="role">Consultor Líder - Movernos Seguros</div>
                            </div>
                            <div className="speaker">
                                <img src="https://assets.viveloonline.com/sites/seguridadvial/SeguridadVial-HildaGomez.png" />
                                <div className="name">Hilda María Gómez</div>
                                <div className="role">Consultora Internacional en Seguridad Vial</div>
                            </div>
                            <div className="speaker">
                                <img src="https://assets.viveloonline.com/sites/seguridadvial/SeguridadVial-MariaSegui.png" />
                                <div className="name">María Seguí-Gómez</div>
                                <div className="role">Coordinadora producción Global Status Report 2023, Organización Mundial de la Salud (OMS)</div>
                            </div>
                            <div className="speaker">
                                <img src="https://assets.viveloonline.com/sites/seguridadvial/SeguridadVial-LuisDeLeon.png" />
                                <div className="name">Luis Ixbalanqué De León</div>
                                <div className="role">Periodista, comunicador y empresario</div>
                            </div>
                        </div>
                    </div>
                </div>
            
        
            <div className="separator"></div>
            
            
            
        </Container>
        </> : <Loader />
  );
}