import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./viernesverde30.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >


    return (
        !isLoading && show ? <>
        <Container fluid className="Show content content-dark viernesverde30">
        <link href='https://fonts.googleapis.com/css?family=Permanent+Marker:400' rel='stylesheet' type='text/css' />
            <link href="https://fonts.googleapis.com/css?family=Rubik+Mono+One:wght@200..900&display=swap" rel="stylesheet" />
            <div className="viernes-container">
                
                <div className="viernes-bg-body">
                    <div className="viernes-bg-header"></div>
                    <div className="viernes-content">
                        <div className="viernes-content-header">
                            <div className="viernes-titulo"><img src="https://assets.viveloonline.com/sites/viernesverde30/viernes-titulo.png" /></div>
                            <div className="viernes-hide-mobile"><img  src="https://assets.viveloonline.com/sites/viernesverde30/viernes-sol.png" /></div>                            
                        </div>
                        <div className="viernes-main-info">
                            <div className="viernes-title">
                                Concierto 30 aniversario<br />Viernes Verde
                            </div>
                            <div>
                                Sábado 1 de julio 2023 • 19:00hrs
                            </div>
                            <div>
                                Zoológico "La Aurora"
                            </div>
                            <div>
                                <p>Celebremos 30 años de carrera artistica ininterrunpida de la banda de rock Viernes Verde</p>
                            </div>
                        </div>
                        <div className="viernes-tickets">
                            <div className="viernes-planograma">
                                <img src="https://assets.viveloonline.com/sites/viernesverde30/viernes-planograma.jpg" />
                            </div>
                            <ShowTickets show={show} tickets={tickets} 
                                addToCartButtonVariant="light"
                            />
                        </div>
                        <div className="viernes-sol">
                        <img src="https://assets.viveloonline.com/sites/viernesverde30/viernes-sol.png" />
                        </div>
                    </div>
                    <div className="viernes-bg-footer"></div>    
                </div>
            </div>
                        
        </Container>
        </> : <Loader />
  );
}