import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { base64encode } from 'nodejs-base64';

import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

import "./Signup.css";


export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const { signup } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.fullname.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    try {

        const session = await signup(fields.fullname, fields.email, fields.password, ["Viewer"])
        if (session === false) {
          Notification('error', 'Ya existe un usuario registrado con ese correo')
        } else if (session) {
          history.push("/");
        } else {
          Notification('error', 'No fue posible procesar tu registro')
        }        
    } catch (e) {
        onError(e);
        setIsLoading(false);
        Notification('error', 'No fue posible procesar tu registro')
    }

    setIsLoading(false);
    
  }

  function renderForm() {
    return (<>
      <Container className="">
      <Row>
      <Col>
        <h1>Reg&iacute;strate</h1>
        <div className="description">Crea tu cuenta para poder activar y ver tu shows</div>
      </Col>
    </Row>
    <Row style={{ }}>
      <Col md="4">      
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="fullname" size="lg">
          <FormLabel>Nombre</FormLabel>
          <FormControl
            autoFocus
            type="fullname"
            value={fields.fullname}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="email" size="lg">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" size="lg">
          <FormLabel>Contrase&ntilde;a</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" size="lg">
          <FormLabel>Confirma tu Contrase&ntilde;a</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Registrarme
        </LoaderButton>
      </form>
      </Col>
      </Row>
    </Container>
    </>);
  }

  return (
    <div className="Signup content content-dark">
      {renderForm()}
    </div>
  );
}