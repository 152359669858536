import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

import { API } from "aws-amplify";


const FileUploadComponent = ({ setSelectedFile, uploadText, show_uid, session_id, ...props }) => {

    // const [selectedFile, setSelectedFile] = useState(null)

    const fileParams = async ({ file, meta }) => {
        const response = await API.get("vivelo", `/cart/orders/uploadUrl?show_uid=${show_uid}&session_id=${session_id}&filename=${meta.name}`);        
        return { url: response.signedUrl, body: file, meta: { fileUrl: response.link }, method: 'PUT' }        
    }
    const onFileChange = ({ meta, file }, status) => { 
        switch(status) {
            case 'preparing': {            
                setSelectedFile(file)
                break;
            }
            case 'removed': {
                setSelectedFile(null)
                break;
            }
            case 'done': {
                setSelectedFile(meta)
                break;
            }
        }
        // console.log(status, meta, file) 
    }
    const onSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove())
    }
    
    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
        const textMsg = files.length > 0 ? 'Subir de nuevo' : uploadText || 'Adjuntar archivo'
        return (
            // <label className="btn btn-danger mt-4">
            <label className="dzu-inputLabel" style={{textAlign: 'center', color: 'white', flexDirection:'column'}} >
                <FontAwesomeIcon icon={faPaperclip} /><br />
                {textMsg}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={e => {
                        getFilesFromEvent(e).then(chosenFiles => {
                            onFiles(chosenFiles)
                        })
                    }}
                />
            </label>
        )
    }

    const Preview = ({ meta, fileWithMeta }) => {        
        const { name, percent, status } = meta        
        
        return (
          <div style={{width:300, height:300, position:'relative', overflow: 'hidden'}}>
            <img src={meta.status=='done'?meta.fileUrl:meta.previewUrl} width="100%" style={{position:'absolute', filter: `blur(${100-percent}px)` }} />
            <div onClick={fileWithMeta.remove} style={{width:'100%', height:30, position:'absolute', bottom:0, textAlign:'center', background:'rgb(20,20,20,0.85)'}}>Eliminar</div>
          </div>            
        )
      }
      
    const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
        return (            
          <div style={{border:"solid 1px #cb0f7e", padding:10, width:320, height:320 }} key="uploader">
            {previews}
      
            {files.length == 0 && <>
            <div {...dropzoneProps}>
              {files.length < maxFiles && input}
            </div>
            </> }
      
            {/* {files.length > 0 && submitButton} */}
          </div>
        )
      }

    return (
        <Dropzone
            onSubmit={onSubmit}
            onChangeStatus={onFileChange}
            InputComponent={selectFileInput}
            getUploadParams={fileParams}
            getFilesFromEvent={getFilesFromEvent}
            LayoutComponent={Layout}
            PreviewComponent={Preview}
            accept="image/*"
            maxFiles={1}
            multiple={false}
            submitButtonDisabled={false}
            // inputContent="Drop A File"
            styles={{
                dropzone: { width: '100%', height: '100%', border: 'dashed 1px #333', overflow: 'hidden' },
                dropzoneActive: { borderColor: 'green' },
                // inputLabel: { textAlign: 'center', color: 'green' }
            }}            
        />
    );
};
export default FileUploadComponent;