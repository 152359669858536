import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button } from "react-bootstrap";
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faExpandArrowsAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Chat from '../components/FirebaseChat'
import * as Sentry from '@sentry/react';
import { deviceDetect } from "react-device-detect";

import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { getIpGeo } from '../libs/ipgeo';
import { useShowStats } from '../libs/statsLib'
import { db } from '../libs/firebase'
import config from "../config"
// import { useWindowSize } from "../libs/windowSize";


import "./ShowViewFullscreen.css";


export default function ShowViewFullscreen() {
  //const file = useRef(null);
  const { isAuthenticated, getSession } = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const [session, setSession] = useState(null);
  const [chatSession, setChatSession] = useState(null);
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [played, setPlayed] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const [ended, setEnded] = useState(false);
  const [muted, setMuted] = useState(true);
  const [ipgeo, setIpgeo] = useState(null)
  const [mobileChatUrl, setMobileChatUrl] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  
  const [ initShowStat, registerShowStat ] = useShowStats() 
  

  const containerRef = useRef(null)
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const interactionsRef = useRef(null)

//   const size = useWindowSize()
//   const childRef = useRef(null)

  const [chatHeight, setChatHeight] = useState(null);
  const [messageHeight, setMessageHeight] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [contentHeight, setContentHeight] = useState(null);  
  const [playerHeight, setPlayerHeight] = useState(null);


//   let session = null
  const stats = {
    show_uid: id,
    device: deviceDetect()            
  }


    useEffect(() => {

        

    async function loadShow() {        
        const show = await API.get("vivelo", `/shows/${id}`);
        if (show) {
            setShow(show)
            // console.log(['LIVE','REPLAY'].includes(show.status), show)            
            if (['LIVE','REPLAY','TEST'].includes(show.status)) {
                setEnded(false)
            }
            if (['NEW','SOON','ACTIVE','CLOSED','CANCELLED'].includes(show.status)) {
                history.push(`/shows/${id}`)
            }
        }
        return show
    }
    async function loadViewerShow(viewer_id, show_id) {
        try {
            const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
            if (vShow) {
                setViewerShow(vShow)                
            }
            return vShow
        } catch (error) {
            console.log(error)
            return null
        }
    }

    async function onLoad() {        
        
        // const stats = await initShowStat(id)
        // const stats = initShowStat(id)
        try {               
            setIsLoading(true)


            Sentry.addBreadcrumb({category: "player", message: "Loading session", level: Sentry.Severity.Info });

            let sess = await getSession()
            setSession(sess)        

            Sentry.addBreadcrumb({category: "player", message: "Session Loaded", data: sess, level: Sentry.Severity.Info, }); 

            let show = null
            let vShow = null
            if (sess) {
                // console.log(sess)
                stats.session_uid = sess.session._id

                const ipgeo = await getIpGeo()
                setIpgeo(ipgeo)
                stats.location = { 
                    country_code: ipgeo ? ipgeo.country_code2 : null,
                    country_name: ipgeo ? ipgeo.country_name : null,
                    city: ipgeo ? ipgeo.city : null,
                    ip: ipgeo ? ipgeo.ip : null,
                }
                
                Sentry.addBreadcrumb({ category: "player", message: "Loading Show", level: Sentry.Severity.Info, });

                show = await loadShow();

                Sentry.addBreadcrumb({ category: "player", message: "Show Loaded", level: Sentry.Severity.Info, });
                                              
                if (isAuthenticated) {                               
                    // console.log(show)
                    // console.log(sess)

                    Sentry.addBreadcrumb({ category: "player", message: "Loading Viewer Show", data: { viewer: sess.viewer._id, show: show._id }, level: Sentry.Severity.Info,});

                    stats.user_uid = sess.user._id
                    stats.viewer_uid = sess.viewer._id
                    vShow = await loadViewerShow(sess.viewer._id, show._id)

                    Sentry.addBreadcrumb({ category: "player", message: "Viewer Show Loaded", level: Sentry.Severity.Info, });

                } 

                db.ref(`show-events/${id}`).on('value', (snapshot) => {
                    handleShowEvents(show, snapshot.val())
                })
                
                
            }

            if (!vShow) {
                history.push(`/shows/${id}`)
                return;
            } 

            const unique = Math.random()            
            const code = vShow.payment_info.code
            db.ref(`show-access/${id}/codes/${code}`).on('value', (snapshot) => {
                console.log(snapshot.val(), unique)
                if (snapshot.val() != unique) {
                    // alert('Busted!')
                    setShowMessage(true)
                }
            })
            db.ref(`show-access/${id}/codes/${code}`).set(unique)

            stats.viewer_show_uid = vShow._id

            Sentry.addBreadcrumb({ category: "player", message: "Loading Video", level: Sentry.Severity.Info, });

            loadVideo(show)

            Sentry.addBreadcrumb({ category: "player", message: "Setting ChatSession", level: Sentry.Severity.Info, });

            setChatSession({
                user_uid: sess.session.user_uid,
                viewer_uid: sess.viewer._id,
                username: sess.user.fullname,
                show_uid: id,                
            })

            // if (show) {
            //     db.ref('show-events/'+id).on('value', (snapshot) => {
            //         handleShowEvents(snapshot.val())
            //     })
            // }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)

            if (stats.session_uid) {
                stats.type = 'player'
                stats.action = 'loaded'
                registerShowStat(id, stats)

                // console.log(stats)
            }
        }
    }

    onLoad();
    }, [id]);

    useEffect(() => {
        
        // function handleResize() {
        //     console.info('resize')
        //     // setContainerHeight(window.innerHeight)
        //     if (containerRef.current /*&& videoRef.current && playerRef.current*/) {
        //         let chatH = null
        //         let messageH = null
        //         let contentH = window.innerHeight - headerRef.current.offsetHeight
        //         let playerH = null
                
                
        //         // console.log("videoRef",videoRef.current.offsetWidth)
        //         // console.log("window", window)                
        //         if (window.innerWidth <= 768) {
        //             playerH = window.innerWidth * 446/758 + 10                    
        //             chatH = containerRef.current.offsetHeight - playerH - headerRef.current.offsetHeight - 100;// - 46;                    
        //             // chatH = containerRef.current.offsetHeight - videoRef.current.offsetHeight - headerRef.current.offsetHeight - 100;// - 46;
        //             // messageH = containerRef.current.offsetHeight - videoRef.current.offsetHeight - headerRef.current.offsetHeight - 10;// - 46;
        //             messageH = window.innerWidth * 0.5616

                    
        //         } else {
        //             // playerH = window.innerWidth - 500 * 446/758 + 10
        //             // console.log("playerRef",playerRef.current)
        //             // chatH = playerRef.current.offsetHeight;
        //             chatH = window.innerHeight - headerRef.current.offsetHeight - 150;
        //             // messageH = window.innerHeight - headerRef.current.offsetHeight - 10;
        //             messageH = window.innerWidth * 0.5616
        //         }
        //         setChatHeight(chatH)
        //         setMessageHeight(messageH)
        //         setContentHeight(contentH)
        //         setPlayerHeight(playerH)
        //     }
        // }
        function handleResize() {
            if ( containerRef.current && headerRef.current && interactionsRef.current ) {
                // console.log(window.innerWidth)
                //             
                if (window.innerWidth >= 900) {
                    let playerH = (window.innerWidth - 350) * 446/758 + 10
                    let chatH = playerH - 50
                    setChatHeight(chatH)
                } else {
                    // let playerH = window.innerWidth * 446/758 + 10
                    // let chatH = containerRef.current.offsetHeight - playerH - headerRef.current.offsetHeight - 100;// - 46;                    
                    let chatH = 445
                    setChatHeight(chatH)
                }
            }
        }

        // if (containerRef.current && videoRef.current && playerRef.current) {
        //     // console.log("REF", parentRef.current)
        //     handleResize()
        // }
        //console.log("pREF", parentRef)
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => window.removeEventListener('resize', handleResize);
    })

  function loadVideo(show) {
    //   console.log(`loading video ${show.video_url}`)
    console.log(`loading video player`)
      //setVideoUrl(`https://vimeo.com/${show.video_id}`)
      //setVideoUrl("https://vimeo.com/417491036")
      
      setVideoUrl(`${show.video_url}`)      
      setPlayed(0)
      setLoaded(0)
      setEnded(false)
      setMuted(false)
        //url="https://vimeo.com/417491036" 
  }
  function unloadVideo() {
    setEnded(true)
    setVideoUrl(null)
    setPlayed(0)
    setLoaded(0)
    
  }

  function handleShowEvents(show, event) {
    //   console.log('showEvent',event, show)    
    if (!event || !show) return
    
    if (event.event == 'statusChange') {        
        if (event.data.type == 'status') {
            switch (event.data.status) {
                case 'start': {
                    loadVideo(show)                
                    return;
                }
                case 'restart': {
                    setVideoUrl(null)
                    setPlayed(0)
                    setLoaded(0)
                    loadVideo(show)
                    return;
                }
                case 'issues': {
                    return;
                }
                case 'end': {
                    unloadVideo()
                    return;
                }
            }
        }
    }

  }

  function renderPlayerControls() {
      return true && <div>
          <Button size="lg"><FontAwesomeIcon icon={faPlayCircle} /></Button>
          <Button size="lg" style={{float:'right'}} onClick={() => { setMuted(!muted) }}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
          <Button size="lg" style={{float:'right'}}><FontAwesomeIcon icon={faExpandArrowsAlt} /></Button>
      </div>
  }

  function handleOnEnded() {
      console.log("Ended")
      unloadVideo()
  }

  function handleOnPlay() {
      setMuted(false)
  }
  function handleOnPause() { console.log("pause")};
  function handleOnReady() { 
    console.log("ready")
    stats.session_uid = session.session._id
    stats.user_uid = session.user._id
    stats.viewer_uid = session.viewer._id
    stats.viewer_show_uid = viewerShow._id    
    stats.location = { 
        country_code: ipgeo ? ipgeo.country_code2 : null,
        country_name: ipgeo ? ipgeo.country_name : null,
        city: ipgeo ? ipgeo.city : null,
        ip: ipgeo ? ipgeo.ip : null,
    }
    stats.type = 'player'
    stats.action = 'ready'
    registerShowStat(id, stats)
  };
  function handleOnLoad() { console.log("load")};
  function handleOnBuffer() { console.log("buffer start")};
  function handleOnBufferEnd() { console.log("buffer end")};
  function handleOnStart() { console.log("start")};

  function renderVideoPlayer() {
    return <>
        <div className="video-player" ref={ playerRef }>
            <ReactPlayer
                className="react-player"                
                // config={{
                //     vimeo: {
                //         playerOptions: {
                //             controls: false,
                //             loop: true
                //         }
                //     }
                // }}
                //url="https://vimeo.com/417491036" 
                //url="https://vimeo.com/event/133164"
                url={videoUrl}
                played={played}
                loaded={loaded}
                controls
                playsinline
                playing={playing}
                volume={0.8}
                width='100%'
                height='100%'
                //pip={true}
                // muted={muted}
                onEnded={handleOnEnded}
                onPlay={handleOnPlay}
                onPause={handleOnPause}
                onReady={handleOnReady}
                onLoad={handleOnLoad}
                onBuffer={handleOnBuffer}
                onBufferEnd={handleOnBufferEnd}
                onStart={handleOnStart}
            />
        </div>        
        {/* { renderPlayerControls() } */}
        </>
         
  }

  function renderVideoEnded() {
      //width:'100%', height:'100%'
    return <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: contentHeight}}>
            <div className="content">
            <img className="show-ended-isotipo" src="/img/isotipo200.png" />
            <div className="show-ended-title">La transmisión del evento ha terminado</div>
            <div className="show-ended-comment">Esperamos hayas disfrutado esta nueva experiencia de espectáculos online</div>
            </div>
        </div>
  }

    function isShowChat() {
        let chat_visible = ['LIVE','TEST'].includes(show.status)
        if (show.settings?.chat_visible !== undefined) {
            chat_visible = chat_visible && show.settings.chat_visible
        }
        return chat_visible
    }  

    function renderVideoContent() {
      return <div className={!isShowChat() ? 'show-content vod': 'show-content'} ref={ contentRef } style={{height: contentHeight}} >
            <div className="show-player" ref={ videoRef } style={{height: playerHeight}} >              
                { !ended ? renderVideoPlayer() : renderVideoEnded() }              
            </div>
            {isShowChat() && <div className="show-interactions" ref={ interactionsRef }>
            
                    <div className="chat-box">                        
                        {chatHeight && <>
                            <Chat channel={id} session={chatSession} style={{height:chatHeight}} 
                                chatVisible={true}
                                // socketRef={(socket) => { /*console.log("socket ref", socket)*/ } } socketOnMessage={ async (data) => { return handleSocketOnMessage(data) }}
                            />
                        
                        </>
                        }
                    </div> 
                </div> }
        </div> 
  }

  function renderMessage() {
    return <div className='show-content vod' ref={ contentRef } style={{height: contentHeight}} >
            <div className="show-ended" style={{display:'flex', justifyContent:'center', alignItems:'center', height: contentHeight}}>
                <div className="content">
                    <img className="show-ended-isotipo" src="/img/isotipo200.png" />
                    <div className="show-ended-title">Hemos detectado que has ingresado a este contenido desde otro dispositivo o navegador.</div>
                    <div className="show-ended-comment">Asegúrate de ingresar solamente en un navegador y en un dispositivo a la vez. Recarga la página para cargar el contenido de nuevo.</div>
                </div>
            </div>
        </div> 
  }

  function renderContent() {
      if (showMessage) {
          return renderMessage()
      } else {
          return renderVideoContent()
      }
  }

  return !isLoading && viewerShow && <div className="show-container" ref={ containerRef} style={{height: containerHeight}}>
      <div className="show-header" ref={ headerRef }>
            <div>{show.fullname}</div>
            <div className="social-icons">
                <a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Vívelo Online</a> &nbsp;
                <a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> @vivelo.online</a> &nbsp;
                #viveloonline
            </div>
            <div className="small-logo">
                <img src="/img/vivelo-logo.png" className="small-logo" />
            </div>
      </div>

      { renderContent() }    

      <div className="show-footer">
        <div className="help-box">
                <h3>Tips y Preguntas Frecuentes</h3>              
                <div className="faq-box">
                    <div>
                        <p>
                            <b>Tips</b><br />
                            <ul>
                                <li>Asegúrate de tener suficiente batería o conectar tu dispositivo a la corriente</li>
                                <li>Conecta tu dispositivo a unas bocinas o audífonos para disfrutar una mejor calidad de audio</li>
                            </ul>
                        </p>
                        <p>
                        <b>Tengo problemas en la transmisión,  se "congela" o se para seguido, cómo lo arreglo?</b><br />                    
Se puede ver en la parte de abajo del video player que hay un icono de una herramienta, le das click ahí y cambia la resolución. Te recomendamos bajar la resolución a 720 o 480 sí el video se está parando mucho. Este cambio de resolución ayudará a que tu internet funcione mejor con la transmisión.
<br /><img src="/img/player-quality.png" />
</p>
<p>
<b>Se puede ver el video pero no tiene sonido, cómo lo arreglo?</b><br />
Asegurate que presiones en la parte de arriba del video el ícono de "UNMUTE". También en la parte inferior del video se pueden ver unas barritas rosadas, donde puedes subir el volumen desde ahí. Debes asegurate que el volumen de tu computador o teléfono esté con el volumen correcto.
<br /><img src="/img/player-volume.png" width="320" />
</p>
                    </div>
                    <div>
                    <b>Cómo puedo ver el concierto en mi televisión?</b><br />
                    <ul>
                        <li>Si tienes <b>ChromeCast</b> y una Laptop: Usa el browser CHROME en tu laptop entra a settings y click en CAST. Ahí podrás mostrar la pantalla de tu laptop en tu televisor.</li>
                        <li>Si tienes un <b>SMART TV</b> con navegador: solamente tienes que ir a http://viveloonline.com, inicia sesión con tu usuario y dirígete a la página del show.</li>
                        <li>Si tienes <b>APPLE TV</b> y un teléfono: Entra al show desde tu teléfono y usa la opción "Duplicar pantalla" o "Screen Mirroring", y selecciona tu APPLE TV</li>
                        <li>Si tu laptop/televisor tiene <b>HDMI</b>: Conecta tu laptop al televisor usando HDMI con un cable de HDMI.</li>
                    </ul>
 
                    </div>
                </div>


            </div>
      </div>
      {/* <div className="show-footer">
        <footer className="social-footer">
            <div className="social-footer-left">
            </div>
            <div className="social-footer-icons">
              <ul className="">
                <li><a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> facebook</a></li>
                <li><a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> instagram</a></li>          
              </ul>
            </div>
          </footer>
      </div> */}
  </div>

//   return (
// /*
// <div class="video-player" style="padding:56.25% 0 0 0;position:relative;">
//                             <Iframe src="https://player.vimeo.com/video/415993914" frameborder="0" allow="autoplay; fullscreen" allowFullScreen style="{{position:absolute;top:0;left:0;width:100%;height:100%;}}"></Iframe>
//                         </div>
// */


// !isLoading && viewerShow && <div className="">
//         <Container className="show-container">            
//             <Row className="show-header">
//                 <Col>
//                 <div className="show-title primary">
//                     {show.fullname}
//                 </div>
//                 {false && <div className="live">
//                     <div className="live-overlay"><div><FontAwesomeIcon icon={faCircle} style={{color:"red"}} />&nbsp;&nbsp;EN VIVO</div></div>
//                 </div>}
//                 <div className="small-logo">
//                     <img src="/img/vivelo-logo.png" className="small-logo" />
//                 </div>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col md="12">
//                 <div className="show-player">
//                     { !ended ? renderVideoPlayer() : renderVideoEnded() }
//                     <div className="chat-box">                        
//                         <Chat channel={id} session={chatSession} socketRef={(socket) => { console.log("socket ref", socket) } } socketOnMessage={ async (data) => { return handleSocketOnMessage(data) }} />
//                     </div> 
//                     <div className="title">
//                         {show.fullname}
//                     </div>
//                 </div>
//                 <div>
//             <label>Recomendaciones</label>
//             <ul style={{listStyleType:'circle'}}>
//             <li>Si no te carga el video, intenta recargar la página</li>
//             <li>Recuerda revisar los niveles de volumen</li>
//             </ul>
//         </div>
//                 </Col>
//             </Row>            
//         </Container>
//     </div>
//   );
}