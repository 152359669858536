import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";
import "./AluxNavidad2020.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >


    return (
        !isLoading && show ? <>
        <Container fluid className="Show content content-dark aluxnavidad2020">
            <Row>
                <Col md="4" className="">
                    <img className="main_image" src="https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux-portada-vertical2.png" />
                </Col>    
                <Col md="8" className="Show">
                    <ShowTitle show={show} />
                    <ShowInfo info={show.description} />

                    { ['LIVE','REPLAY'].includes(show.status) && <div style={{marginTop: 20}}>
                        <a href="/aluxnavidad2020/player" target="_blank"><Button variant="outline-light"><FontAwesomeIcon icon={faPlay} /> Ver transmisión</Button></a>
                    </div> }
                    
                    <div className="promo">
                    <iframe src="https://player.vimeo.com/video/491466903" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>

                    <div className="gallery">
                        <div className="pic"><img src="https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux1.jpg" /></div>
                        <div className="pic"><img src="https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux2.jpg" /></div>
                        <div className="pic"><img src="https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux3.jpg" /></div>
                        <div className="pic"><img src="https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux4.jpg" /></div>
                    </div>

                    <ShowInfo info={show.description2} />
                </Col>
            </Row>
            
        </Container>
        </> : <Loader />
  );
}