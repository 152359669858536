import React, { useState, useEffect} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import Navbar from "react-bootstrap/NavBar";
import Nav from "react-bootstrap/Nav";
import { Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Loader from "./components/Loader"
import { HashLink } from 'react-router-hash-link';

import Cookie from 'js-cookie';
import { v1 as uuidv1 } from 'uuid'
import CookieConsent from "react-cookie-consent";


import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import { useAuth } from "./libs/authLib";
import { useAnalytics } from "./libs/analytics"
import { useCart } from "libs/cartLib"
import config from "./config"

import './App.css';

//const trackingId = "UA-170181956-1"; // Replace with your Google Analytics tracking ID


function App() {
  const history = useHistory();
  const location = useLocation();
  
  const { init, trackPageViewed } = useAnalytics();
  const [isAuthenticating, setIsAuthenticating, isAuthenticated, userHasAuthenticated, getSession, setSession, signup, login, logout, updateUser, validateSession] = useAuth(null, true, false);
  const { getCart, getTotalItems } = useCart();
  const [session, setSess] = useState(null)  
  const [cartTotalItems, setCartTotalItems] = useState(0)  
  const [gaIniitialized, setGaIniitialized] = useState(false)
  const [cart, setCart] = useState(null)

  const [referral, setReferral] = useState(null)
  
  useEffect(() => {
    initAnalytics();
    onLoad();

    // if (isAuthenticating) {
    // setInterval(async () => {       
    //   if (!await validateSession()) {
    //     console.log('redirecting')        
    //     history.push('/login')
    //   }
    // }, 5000)
    // }

  }, []);
  
  async function onLoad() {
    try {
      if (!Cookie.get('sessionID')) {
        Cookie.set('sessionID', uuidv1()) 
      }

      setIsAuthenticating(true);
      const s = await getSession()
      setSess(s)

      const searchParams = new URLSearchParams(location.search);      
      if (searchParams.get('referral')) {        
        Cookie.set('referral', searchParams.get('referral'))
        setReferral(searchParams.get('referral'))
      }

      await updateCartCounter()

    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function updateCartCounter() {
    let mycart;
      try {
        mycart = await getCart(true)
        setCart(mycart)
      } catch (e) {
        // no cart
      }

      const total = await getTotalItems(mycart)
      setCartTotalItems(total)

      return mycart
  }

  async function initAnalytics() {
    if (!gaIniitialized) {
      init(config.analytics.trackingId);
      trackPageViewed();
      history.listen(location => {        
        trackPageViewed(location.pathname)
      })      
      setGaIniitialized(true);
    }
  }

  

  async function handleLogout() {    
    setIsAuthenticating(true);

    try {
      await logout()
      history.push("/");
    } catch (e) {
      console.log("logout error")
      onError(e)
      console.log(e)
    } finally {
      setIsAuthenticating(false);
    } 
  }

  // function


  return (    
    !isAuthenticating ? <>
    {/* <Container fluid style={{background: 'black'}}> */}
      {/* <Container fluid style={{backgroundColor: 'black', background: 'linear-gradient(180deg, rgba(0,0,0,1) 12%, rgba(0,0,0,0) 92%)'}}> */}
      {/* <Container fluid style={{backgroundColor: 'black'}}> */}
      <div className="main-menu" >
      <Switch>
        {/* <Route exact path="/"><></></Route> */}
        <Route exact path="/shows/:id/play"><></></Route>
        <Route exact path="/shows/:id/full"><></></Route>
        <Route exact path="/shows/:id/player"><></></Route>
        <Route exact path="/shows/:id/chat"><></></Route>
        <Route exact path="/shows/:id/quicklink"><></></Route>
        <Route exact path="/shows/:showId/quicklink/success/:id"><></></Route>
        <Route exact path="/soyinvencible/evento"><></></Route>
        <Route exact path="/conviviogallo/20201128"><></></Route>
        <Route exact path="/conviviodoradaice/player"><></></Route>
        <Route exact path="/aluxnavidad2020/player"><></></Route>
        <Route exact path="/titanessummit2021/player"><></></Route>
        <Route exact path="/fafidess2021/player"><></></Route>
        <Route exact path="/seminario-seguridad-vial/player"><></></Route>
        <Route>
          <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark" className="black" >
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Brand>
                <Link to="/" className="logo">&nbsp;</Link>
              </Navbar.Brand>
              
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">                  
                  <HashLink smooth to={`/`} className="nav-link" >
                    Inicio
                  </HashLink>
                  <HashLink smooth to={`/#events`} className="nav-link" >
                    Eventos
                  </HashLink>
                  <HashLink smooth to={`/#vod`}  className="nav-link" >
                    Por Demanda
                  </HashLink>

                  <LinkContainer to="/activate">
                    <Nav.Link href="/activate">Activar Código</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/help">
                    <Nav.Link href="/help">C&oacute;mo funciona</Nav.Link>
                  </LinkContainer>

                  <Nav.Link href="http://m.me/vivelo.online" target="_blank">Ayuda</Nav.Link>

                  <Nav.Link href="http://servicios.viveloonline.com" target="_blank" className="highlight2">Contrataciones</Nav.Link>
                  
                </Nav>
                
              </Navbar.Collapse>
              
              <Nav className="icons">
                  
                  {isAuthenticated
                    ? <> 
                        <LinkContainer to="/cart">
                          <Nav.Link className="icon">{`${cartTotalItems} `}<FontAwesomeIcon icon={faShoppingCart} /></Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/user/orders">
                          <Nav.Link className="icon"><FontAwesomeIcon icon={faUserCircle} /></Nav.Link>
                        </LinkContainer>                                              
                      </>
                    : <>
                        <LinkContainer to="/cart">
                          <Nav.Link  className="icon">{`${cartTotalItems} `}<FontAwesomeIcon icon={faShoppingCart} /></Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/user/orders">
                          <Nav.Link className="highlight1 icon"><FontAwesomeIcon icon={faUserCircle} /></Nav.Link>
                        </LinkContainer>
                      </>
                  }
                </Nav>
          </Navbar>
        </Route>
      </Switch>
      </div>
    {/* </Container> */}

    {/* </Container> */}
    <AppContext.Provider value={{ isAuthenticated, setIsAuthenticating, userHasAuthenticated, getSession, setSession, login, signup, logout, updateUser, 
        cart, updateCartCounter, cartTotalItems, handleLogout,
        referral }}>
      <Routes />
    </AppContext.Provider>
    <div className="footer">
    <Switch>        
        <Route exact path="/shows/:id/play"><></></Route>
        <Route exact path="/shows/:id/full"><></></Route>
        <Route exact path="/shows/:id/player"><></></Route>
        <Route exact path="/shows/:id/chat"><></></Route>
        <Route exact path="/shows/:id/quicklink"><></></Route>
        <Route exact path="/shows/:showId/quicklink/success/:id"><></></Route>
        <Route exact path="/soyinvencible/evento"><></></Route>
        <Route exact path="/conviviogallo/20201128"><></></Route>
        <Route exact path="/conviviodoradaice/player"><></></Route>
        <Route exact path="/aluxnavidad2020/player"><></></Route>
        <Route exact path="/titanessummit2021/player"><></></Route>
        <Route exact path="/fafidess2021/player"><></></Route>
        <Route exact path="/seminario-seguridad-vial/player"><></></Route>
        <Route>
          <footer className="social-footer">
            <div className="social-footer-left">        
            </div>
            <div className="social-footer-icons">
              <ul className="">
                <li><a href="https://www.facebook.com/vivelo.online" target="_blank"><FontAwesomeIcon icon={faFacebook} /> facebook</a></li>
                <li><a href="https://www.instagram.com/vivelo.online?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} /> instagram</a></li>          
              </ul>
            </div>
          </footer>
        </Route>
        </Switch>
        <CookieConsent
          location="bottom"
          buttonText="Aceptar"
          cookieName="cookieConsent"
          style={{ background: "#2B373BDD" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Este sitio utiliza cookies para mejorar nuestros servicios. <a href="/cookies">Política de Cookies</a> 
        </CookieConsent>
        {/* Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúa navegando, consideramos que acepta su uso. Puede obtener más información, o bien conocer cómo cambiar la configuración, en nuestra <a href="/cookies">Política de Cookies</a>. */}
    </div>
    
    
    </> : <Loader />
  );
}
/*
<Container>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, getSession, setSession, login, signup }}>
        <Routes />
      </AppContext.Provider>
    </Container> 
*/
export default App;
