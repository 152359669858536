import React, { useState } from "react";
import Form from "react-bootstrap/Form"
import { API } from "aws-amplify";
import { Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import { onError, Notification } from "../libs/errorLib";

//import "./Login.css";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { LinkContainer } from "react-router-bootstrap";

export default function PasswordRequest() {   
  const { login } = useAppContext(); 
  const [isLoading, setIsLoading] = useState(false);
  const [requested, setRequested] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: ""
  });

  function validateForm() {
    return fields.email.length > 0;
  }

  async function requestPasswordReset() {
    
    try {
        const result = await API.post('vivelo',`/auth/password/request`, {
          body: { email: fields.email }           
        })

        setRequested(true)
        return true;
    } catch(e) {            
      console.log(e)                      
      return false
    }    
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);

    try {
      const result = await requestPasswordReset(fields.email) 
      if (result) {        
        Notification('success', 'Solicitud enviada')
        handleFieldChange({ target: { type: 'text', id:'email', value:'' }})
      } else {
        Notification('error', 'No fue posible hacer la solicitud en este momento')
      }

    } catch (e) {
        onError(e);
        Notification('error', 'No fue posible hacer la solicitud en este momento')
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="content content-dark">
    <Container className="">
      <Row>
      <Col>
        <h1>Recuperar contraseña</h1>
        <div className="description">Ingresa el correo registrado a tu cuenta para recuperar tu contraseña</div>
      </Col>
    </Row>
    { !requested && <Row style={{ }}>
      <Col md="4">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>        
        <LoaderButton
            block
            type="submit"
            size="lg"
            isLoading={isLoading}
            disabled={!validateForm()}
        >
            Solicitar
        </LoaderButton>
      </Form>
      </Col>
      </Row> }
      { requested && <Row style={{}}>
      <Col md="4">
        <b>Hemos enviado un correo a la dirección ingresada con las instrucciones para recuperar tu contraseña.</b>
      </Col>
    </Row> }
    </Container>
    </div>
  );
}