import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilm, faUnlock } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import Activate from 'containers/Activate'
import Login from 'containers/Login'


import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";
import { useAnalytics } from "libs/analytics";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
// import "containers/Show.css";
import "./Invencible.css"
import { LinkContainer } from "react-router-bootstrap";

export default function Site(props) {
  const { id, ...otherProps } = props
    
  const history = useHistory(); 
  const { trackEvent } = useAnalytics();
//   const { id } = useParams();  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showActivationModal, setShowActivationModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);    
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)

            const tickets = await loadShowTickets()            
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            } else {
                setViewerShow(null)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        try {
            const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);
            setViewerShow(vShow)
            
            return vShow
        } catch (e) {
            setViewerShow(null)
        }
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }   
    
    function handleModalClose() {
        setShowActivationModal(false)
        setShowLoginModal(false)
    }

    async function handleActivationModalSuccess() {
        setShowActivationModal(false);
        window.location.reload(false);

    }

    function renderActivationModal() {
        
        return <Modal size="md" centered show={showActivationModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body><Activate modal handleModalSuccess={async () => await handleActivationModalSuccess() } /></Modal.Body>        
      </Modal>
    }

    function renderLoginModal() {        
        return <Modal size="md" centered show={showLoginModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ingresa a tu cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body><Login modal /></Modal.Body>        
      </Modal>
    }
    

    return (        
        !isLoading && show ? <>
        { renderLoginModal() }
        { renderActivationModal() }
        <div className="site-invencible">
            <div className="invencible-poster">
                <img src="https://assets.viveloonline.com/shows/5fabf2947094a9f522057ae3/Poster.png" />
            </div>
            <div className="invencible-registration">
                <div className="date"><img src="https://assets.viveloonline.com/shows/5fabf2947094a9f522057ae3/Fechas.png" /></div>
                <div className="hour">8pm GUA/MEX • 9pm COL • 6pm LA</div>
                <div>
                    { !['CLOSED'].includes(show.status) && <div className="actions">                         
                        {!viewerShow ? <>
                        <ShowRegistration  show={show} tickets={tickets} onSuccess={handlePaymentSuccess}
                            buttonText="Registrarme" variant="outline-dark" style={{marginRight: 5}} className="india-tickets"
                            icon={<></>}
                        /> 
                        <Button variant="outline-dark" onClick={() => { setShowActivationModal(true); trackEvent({
                            action: "Clicked Start Activate Button",
                            category: "Show"
                        }); }} > Activar código</Button>
                        </> : <>
                        <a href={`/shows/${show._id}/full`} target="_blank" onClick={(e) => {
                            trackEvent({
                                action: "Clicked Play Button",
                                category: "Show"
                            });
                            if (!viewerShow) {
                                alert('Regístrate para obtener tu boleto para ingresar. Si ya tienes tu boleto activado, por favor inicia sesión para continuar.')
                            }
                            
                            if (!['STARTED','LIVE','REPLAY'].includes(show.status)) {
                                alert('Aún no es posible el ingreso al evento.  Por favor, recarga la página 5 minutos antes del evento y vuelve a intentar.')
                                e.preventDefault()
                                return false
                            }
                            return true                            
                        }} >
                        <Button variant="outline-dark">Ingresar</Button>
                        </a>
                        </> }  
                    </div> }
                </div>
            </div>
            <div className="invencible-description">
                <p>Todo estaba bien a principios de 2020 pero de repente todo se torno abrumador, el confinamiento, despidos masivos, negocios detenidos, el mundo sufre de la pandemia covid 19, parece que todo se perdio pero Dios nos dice en su palabra <i>JOB 14<br /> 7</i> Porque si el árbol fuere cortado, aún queda de él esperanza;<br />
Retoñará aún, y sus renuevos no faltarán.<br />
<i>8</i> Si se envejeciere en la tierra su raíz, 
Y su tronco fuere muerto en el polvo, <br />
<i>9</i> Al percibir el agua reverdecerá,
Y hará copa como planta nueva. <br /><br />
Por lo que somos invencibles en Cristo; SOY INVENCIBLE 3 noches profeticas donde renovaremos nuestras fuerzas y reverdeceremos por la fe.</p>
            </div>
            <div className="invencible-footer">
                <img src="https://assets.viveloonline.com/shows/5fabf2947094a9f522057ae3/Prelatores.png" />
            </div>
                            
        </div>
        </> : <Loader />
  );
}