import React from "react";

import './Loader.scss'

export default function Loader() {
    //const [loading, error] = useScript({ src: '/loader/js/index.js' });
 

    // return <div style={{display:'flex', justifyContent: 'center'}}><div id="animationWindow"></div></div>    
    return <div style={{display:'flex', justifyContent: 'center', position: 'relative', height: 300}}>
        <div className="preloader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
}