import React, { useState, useEffect } from "react";
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faWarehouse } from '@fortawesome/free-solid-svg-icons'

import Timer from "components/Timer"

import { useCart } from "libs/cartLib";
import { useAppContext } from "libs/contextLib";


export default function CartSummaryBox(props) {
    const [isLoading, setIsLoading] = useState(false);
    // const [cart, setCart] = useState(null)
    const [validThru, setValidThru] = useState(null)

    const { getCart, getTotalItems, getTotalPrice } = useCart()
    const { cartTotalItems, cart } = useAppContext();

    // useEffect(() => {        
    //     onLoad()         
        
    // }, [cart]);

    useEffect(() => {        
        // onLoad()         
        let valid_thru = (cart && moment(cart.valid_thru)) || null
        if (moment().utc().isAfter(valid_thru))
            valid_thru = null;         
        setValidThru(valid_thru)
    }, [cart, cartTotalItems]);

    // async function onLoad() {
    //     let cart
    //     try {
    //         cart = await getCart()
    //         setCart(cart)
    //     } catch (e) {
    //         // no cart
    //     }
    //     // console.log('cart',cart)
    // }

    function onTimerExpire() {
        window.location.reload();
    }

    function render() {
        

        return validThru && <div className="timerBanner">
            <div className="left">
                <FontAwesomeIcon icon={faClock} />&nbsp;<Timer expiryTimestamp={validThru} autoStart={true} 
            className="cartTimer" onExpire={onTimerExpire} />
            </div>
            <div className="right">
             ({getTotalItems(cart)} boletos) {getTotalPrice(cart) }
            </div>
        </div>
    }

    return render()
}