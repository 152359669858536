import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { Container, Row, Col, Button, Badge, Modal } from "react-bootstrap";


import Loader from 'components/Loader'

import ShowTitle from "components/shows/ShowTitle";
import ShowInfo from "components/shows/ShowInfo";
import ShowImage from "components/shows/ShowImage";
import ShowTicketBox from "components/shows/ShowTicketBox";
import ShowSocialBox from "components/shows/ShowSocialBox";
import ShowTickets from "components/shows/ShowTickets";
import ShowRegistration from "components/shows/ShowRegistration";

import { onError, Notification } from "libs/errorLib";
import { useAppContext } from "libs/contextLib";

import { getIpGeo } from 'libs/ipgeo';
import config from 'config';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import "containers/Show.css";
import "./Fafidess2021.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";

export default function Show(props) {
    
  const history = useHistory(); 
  const { id } = props;  
  const {isAuthenticated, getSession, updateCartCounter } = useAppContext();
  const [show, setShow] = useState(null);
  const [viewerShow, setViewerShow] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    
  let session = null
  
    useEffect(() => {

    // const searchParams = new URLSearchParams(location.search);
    // if (searchParams.get('activated') == "1") {
    //     //Notification('success','Tu boleto ha sido activado exitosamente')
    //     setShowActivationModal(false)        
    // }

    async function onLoad() {
        try {           
            setIsLoading(true)
            session = await getSession()            
            
            const show = await loadShow();
            setShow(show)
            console.log(show)
            const tickets = await loadShowTickets()
            
            if (isAuthenticated) {    
                console.log(session, show)            
                const vShow = await loadViewerShow(session.viewer._id, show._id)
            }

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false)
        }
    }

    onLoad();
    }, [id]);

    /* API CALLS  */

    async function loadShow() {
        return API.get("vivelo", `/shows/${id}`);
    }
    async function loadShowTickets() {
        const tickets = await API.get("vivelo", `/shows/${id}/tickets?paymentType=CREDIT_CARD|REGISTRATION`);
        if (tickets && tickets.items && tickets.items.length > 0) {
            tickets.items.forEach(t => { t.selected = 0 })
            setTickets(tickets.items)
        }
        return tickets
    }
    async function loadViewerShow(viewer_id, show_id) {
        const vShow = await API.get("vivelo", `/viewers/viewers/${viewer_id}/shows/${show_id}`);        
        if (vShow) {
            setViewerShow(vShow)
        }
        return vShow
    }

    function handlePaymentSuccess(purchase) {
        updateCartCounter()
        history.push(`/orders/${purchase.id}`)
    }


    // if (activated == "1") {
    //     Notification('success', "Tu boleto fue activado exitosamente")
    // }
    // <LinkContainer key={`name-${show.id}`} to={`/shows/${show.id}/play`} target="_blank" style={{marginLeft: 'auto', fontSize: '1.8rem', marginLeft:'auto'}} >


    return (
        !isLoading && show ? <>
        <Container fluid className="Show content content-dark fafidess2021">        
            <div className="main-desktop">
                <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/main-desktop3.jpg" />
                {/* <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" /> */}
            </div>
            <div className="main-mobile">
                <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/main-mobile3.jpg" />
                {/* <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" /> */}
            </div>
            <div></div>
            <div className="separator">                
            </div>
            <div className="info welcome">
                <div class="marcos"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 21.png" /></div>
                <div class="left-content">
                    <div>FAFIDESS, en conmemoración a su 35 Aniversario, te invita a compartir un Conversatorio con el exitoso empresario:</div>
                    <div>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/MA2.png" className="img-marcos" />
                    </div>
                    <div>No te pierdas este maravilloso evento y adquiere conocimientos que te llevarán a convertirte en un mejor empresario y hacer realidad tus sueños!! 
                        <br /><br />
                        Fortalece tu visión empresarial y conviértete en un empresario exitoso!!
                        <br />
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/trans.png" className="img-marcos" />
                        <br />
                        <br />
                    </div>
                    <div><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 19.png" className="img-date" /></div>
                    <div>* GRATUITO</div>
                    <div>
                        <br />
                        <ShowRegistration show={show} tickets={tickets} onSuccess={handlePaymentSuccess}
                        className="register" style={{}} buttonText="Regístrate Aquí"
                    /></div>
                    { show.status == 'LIVE' && <div>
                    <br />
                        <Button variant="success" className="play" href="/fafidess2021/player/" target="_blank" ><FontAwesomeIcon icon={faPlayCircle} />  Entrar</Button>
                    </div> }
                </div>
            </div>
            {/* <div className="separator">
            </div>
            <div className="dark-bg buttons">
                {/* <ShowTicketBox show={show} viewerShow={viewerShow} tickets={tickets} 
                        ticketsButtonVariant="outline-light"
                        loginButtonVariant="outline-light"
                        activateButtonVariant="light"
                        addToCalendarButtonVariant="outline-light"

                    /> *
                <ShowRegistration show={show} tickets={tickets} onSuccess={handlePaymentSuccess}
                    className="register" style={{}} buttonText="Regístrate Aquí"
                />
                
            </div> */}
            <div className="separator">
            </div>
            {/* <div className="video">
                <h1>Invitación de Marcos Andrés Antil</h1>
                <iframe src="https://player.vimeo.com/video/541688800" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="separator">
            </div> */}
            <div className="title">
                Conferencista
            </div>
            <div className="separator">
            </div>
            <div className="speaker">
                <div className="image"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 5.png" /></div>
                <div className="content">
                    <h1><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/MA.png" className="img-marcos2" /></h1>
                    <p>Marcos Andrés Antil, maya-Q’anjob’al, nacido en la aldea Nancultac, Huehuetenango, Guatemala. Proveniente de una familia campesina, jornaleros en fincas de café y algodón. Exiliado de su país por el Conflicto Armado Interno, se mudan a California con su familia. Trabajó los veranos en una fábrica de costuras y jardinero los fines de semana. En el 2000, se gradúa de Licenciado en Ciencias de la Computación, un diplomado en Comunicación y un certificado en Diseño de Imágenes en Computación en la Universidad Estatal de Bakersfield, California. En 2003 funda Xumak, LLC, empresa dedicada al marketing y comercio electrónico, llegando a más de 25 países en el mundo. 
                    <br /><br />
                        El Gobierno de Guatemala lo nombró Embajador de la Paz. Prensa Libre, lo reconoce como Personaje del Año 2014. Reconocido por la Asociación de Gerentes de Guatemala como el Gerente del Año en la categoría de Creatividad e Innovación. Recibió el Doctorado Honoris Causa en Tecnologías de la Información, de la Universidad Galileo de Guatemala. Pepsi, a través de la campaña Guatemorfosis, lo nombró como uno de los Agentes de Cambio. En el 2017, Marcos lanza el proyecto Café con Causa, una empresa que dona el 100% de las ganancias para impulsar la educación en el área rural del país. En 2019 escribió su autobiografía “Migrante”.
                        </p>
                </div>
                
            </div>
            <div className="separator">                
            </div>
            <div className="title">
                Moderadora
            </div>
            <div className="separator">                
            </div>
            <div className="dark-bg speaker">
                <div className="image"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 6.png" /></div>
                <div className="content">
                    <h1><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/MAC.png" className="img-mac" /></h1>                
                    <p>Guatemalteca, Licenciada en Psicología, Master en Administración y Desarrollo de Recursos Humanos. Posee varios Diplomados especializados en i) Administración y Gestión de Microfinanzas, COLCAMI, México; ii) Bases Epistemológicas de la Psicología y la Educación, México; iii) Ciencia y Tecnología, PNUD- USAC. Cuenta con varias Certificaciones Internacionales entre ellas: i) Desarrollo Personal, ii) Gestión y Desarrollo de Instituciones Microfinancieras, iii) Desarrollo del Potencial Exportador de MIPYMES y iv) Coaching Personal y Grupal.
<br /><br />
Ha trabajado desde hace más de 20 años en temas de microfinanzas para organizaciones nacionales e internacionales. Fue Directora del Centro de Investigación de Psicología de la USAC. Catedrática Universitaria por más de 35 años. Consultora Senior de la Asociación de Gerentes de Guatemala, AGG, Directora de Walter Palmieri Consultores Asociados, Coordinadora de Capacitación del proyecto 2328 apoyado con fondos del BID en el Ministerio de Salud de Guatemala.
                    </p>
                </div>
                
            </div>
            <div className="separator">                
            </div>
            <div className="header-mision">
                <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 22.png" className="img-logo" />
            </div>
            <div className="two-columns">
                <div>
                    <h1>Misión</h1>
                    <p>Institución líder en Guatemala, especializada en la prestación de servicios financieros y no financieros al sector de la micro y pequeña empresa, dirigida en forma empresarial, financieramente consolidada, brindando servicios con calidad, eficiencia y oportunidad, los cuales contribuyen al mejoramiento de las condiciones socioeconómicas de los clientes atendidos.
                    </p>
                </div>                
                <div>
                    <h1>Visión</h1>
                    <p>Somos una institución de Microfinanzas que contribuye al desarrollo económico y social del sector de la micro y pequeña empresa, a través de la prestación de servicios y productos financieros sostenibles y servicios de desarrollo empresarial, dirigidos especialmente a mujeres del área rural de Guatemala, con el fin de mejorar su nivel y calidad de vida.
                    </p>
                </div>
            </div>
            <div className="separator">
                
            </div>
            <div className="title">
                Servicios Financieros
            </div>
            <div className="servicios-financieros">
                <div className="grid-financieros">
                    <div className="grid-item">
                        <label>Bancos Comunales</label>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 31.png" />
                    </div>
                    <div className="grid-item">
                        <label>Grupos Solidarios</label>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 30.png" />
                    </div>
                    <div className="grid-item">
                        <label>Crédito Individual</label>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 29.png" />
                    </div>
                    <div className="grid-item">
                        <label>Crédito Vivienda</label>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 28.png" />
                    </div>
                    <div className="grid-item">
                        <label>Crédito Educativo</label>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 27.png" />
                    </div>
                    <div className="grid-item">
                        <label>Eco Crédito</label>
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 26.png" />
                    </div>
                </div>
                <div>
                    Para más información ingresa a nuestra página web
                </div>
                <div className="btn-fafidess">
                    <a href="http://www.fafidess.org">www.fafidess.org</a>
                </div>
        
            </div>
            <div className="separator">                
            </div>
            <div className="title">
                Servicios Empresariales
            </div>
            <div className="servicios-empresariales">
                <div className="grid-empresariales">
                    <div className="grid-item">                        
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 10.png" />
                    </div>
                    <div className="grid-item">                        
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 11.png" />
                    </div>
                    <div className="grid-item">                        
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 12.png" />
                    </div>
                    <div className="grid-item">                        
                        <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 13.png" />
                    </div>
                </div>  
            </div>
            <div className="separator">
                
            </div>
            <div className="title">
                Patrocinadores
            </div>
            <div className="partners">
                
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 16.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/redcamif-cifras.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/LogoRedimif_Especial_Blanco.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Cable Nueva Vision.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/LOGO CANAL TELEVOLCANES.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/TDS.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/TELEFRAY TRANSPARENTE.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/gggggggglogo.png" />
                    <img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/TV 5 OFICIAL.png" />
                
            </div>
            <div className="separator">
                
            </div>
            <div className="title">
                Contáctanos
            </div>
            <div className="contacto">
                5a Av. 16-68 Zona 10, Guatemala<br />
                PBX: 2294 0000<br />                
                <a href="http://www.fafidess.org">www.fafidess.org</a><br />    
                <div>
                    <a href="http://www.fafidess.org" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-www.png" /></a>
                    <a href="http://facebook.com/fundacionfafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-fb.png" /></a>
                    <a href="http://twitter.com/fafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-twitter.png" /></a>
                    <a href="http://instagram.com/fundacionfafidess" target="_blank"><img src="https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/social-ig.png" /></a>
                </div>                
            </div>
            
            
        </Container>
        </> : <Loader />
  );
}