import Noty from "noty"
import * as Sentry from '@sentry/react';

export function onError(error) {
    let message = error.toString();

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        message = error.message;
    }
    console.log(error)
    Sentry.captureException(error);

    //alert(message);
}

export function Notification(type, text, timeout, layout) {
    new Noty({
        text: text,
        layout: layout || "topRight",
        theme: "bootstrap-v4",
        type: type,
        timeout: ['info','success'].includes(type) ? timeout || 5000 : 8000
      }).show();
}