import React from "react";
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'; 
// import './Loader.scss'

export default function ShowInfo(props) {
    const { info, ...otherProps } = props

    const linkifyComponentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank">
            {text}
        </a>
    );

    return <div className="show-description">
                <><Linkify componentDecorator={linkifyComponentDecorator}>{ReactHtmlParser(info)}</Linkify></>
            </div>
}