import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Cookie from 'js-cookie';
import { base64encode, base64decode } from 'nodejs-base64';


import { onError } from "./errorLib";





export function useCart() {
    const [cart, setCart] = useState(null)

    const sessionCookie = 'sessionID'
    const sessionId = Cookie.get(sessionCookie)

    // useEffect(() => {        
    //     onLoad()         
        
    // }, [cart]);

    // async function onLoad() {
    //     if (!cart) {
    //         const mycart = await API.get("vivelo", `/cart/${sessionId}`);
    //         setCart(mycart)
    //     } 
    // }

    async function getCart(force) {
        const forceRefresh = force === undefined ? false : force
        if (!cart || force) {
            const mycart = await API.get("vivelo", `/cart/${sessionId}`);
            setCart(mycart)
            
            return mycart
        } else {
            return cart
        }
    }

    function getTotalItems(cart) {
        const items = cart ? cart.items || [] : []

        const total = items.reduce((accum, current) => (accum + current.quantity), 0)        
        return total
    }
    
    
    // { "id": "sess1" }
    // { "items": [ 
    //     { 
    //         "_id": "5edd63b1969a623f26e081a8", 
    //         "type": "TICKET", 
    //         "show_uid": "5edc79d60ee327132db0d30d", 
    //         "show_name":"Jueves de Trova (Prueba)", 
    //         "description":"Concierto + Meet n Greet", 
    //         "quantity":2 
    //     } 
    // ] }
    async function addCartItems(payload) {
        const purchase = await API.post("vivelo", `/cart/${sessionId}/items`, {
            body: payload
        });
        
        return purchase
    }
    
    // { "id": "sess1" }
    // { "items": [ 
    //     "5edd63b1969a623f26e081a8"
    // ] }
    async function removeCartItems(items) {
        const purchase = await API.del("vivelo", `/cart/${sessionId}/items`, {
            body: items
        });
        
        return purchase
    }

    async function removeCartSeats(seats) {
        const purchase = await API.del("vivelo", `/cart/${sessionId}/seats`, {
            body: seats
        });
        
        return purchase
    }

    async function resetCart() {
        try {
        const purchase = await API.del("vivelo", `/cart/${sessionId}/`);
        
        return purchase
        } catch(e) {
            if (e.response.status == 404) {
            } else {
                throw e
            }
        }
    }

    async function updatePaymentInfo(payload) {
        const result = await API.put("vivelo", `/cart/${sessionId}/payment`, {
            body: payload
        });
        
        return result
    }

    function getCurrencyValue(cart, item, property) {
        const suffix = cart?.currency == 'USD' ? '_usd' : ''
        return item[`${property}${suffix}`]
    }

    function renderPrice(cart, price) {
        const currency = cart?.currency == 'USD' ? 'US$ ' : 'Q '
        const sanitizedPrice = price || 0
        return `${currency}${sanitizedPrice.toFixed(2)}`
    }

    function getTotalPrice(cart, returnRawValue) {        
        let items = cart ? cart.items || [] : [];
        const cartTotal = items.reduce((total, current) => {
            return total + getCurrencyValue(cart, current, 'total_price')  + getCurrencyValue(cart, current, 'total_fee')
        }, 0)

        if (returnRawValue) {
            return cartTotal
        } else {
            return renderPrice(cart, cartTotal)
        }
    }

    // { "id": "sess1" }
    // { 
    //     \"session_id\":\"sess1\", 
    //     \"user_uid\": null, 
    //     \"cart_uid\": \"5f6820a2fc8561f77d1a1e32\", 
    //     \"payment_type\":\"CREDIT_CARD\", 
    //     \"currency\": \"GTQ\", 
    //     \"payment_info\": { 
    //         \"cc_name\": \"Sergei Walter\", 
    //         \"cc_number\":\"4111111111111111\", 
    //         \"cc_exp\":\"01/21\", 
    //         \"cc_cvv2\":\"4567\", 
    //         \"firstname\":\"Sergei\", 
    //         \"lastname\":\"Walter\", 
    //         \"email\":\"admin@viveloonline.com\", 
    //         \"phone\":\"52017507\", 
    //         \"address\":\"Address\", 
    //         \"city\":\"city\", 
    //         \"state\":\"state\",
    //         \"country\":\"GT\", 
    //         \"nit\":\"123\",
    //         \"currency\":\"GTQ\",
    //         \"total\":1.00,
    //         \"sessionID\":\"$sessionID\" 
    //     } 
    // }
    async function createOrder(order) {
        const cart = await getCart()

        order.session_id = sessionId
        order.cart_uid = cart._id

        const purchase = await API.post("vivelo", `/cart/orders`, {
            body: order
        });
        
        return purchase
    }


    return { cart, getCart, addCartItems, removeCartItems, removeCartSeats, getTotalItems, getTotalPrice, resetCart, updatePaymentInfo, createOrder }
}