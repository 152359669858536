import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, Container } from "react-bootstrap";
import moment from 'moment';

import Loader from 'components/Loader'
import CartSummaryBox from "components/cart/CartSummaryBox"
import CreditCardForm from "components/cart/CreditCardForm"
import BankDepositForm from "components/cart/BankDepositForm"
import Timer from "components/Timer"

import { onError } from "libs/errorLib";
import { useCart } from "libs/cartLib"
import { useAuth } from "libs/authLib"
import { useAppContext } from "libs/contextLib";
import { LinkContainer } from "react-router-bootstrap";
import { onLoad } from "@sentry/react";
// import './Loader.scss'

export default function CartCheckout(props) {
    const { info, ...otherProps } = props
    
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { getSession, updateUser, updateCartCounter } = useAppContext()
    const [user, setUser] = useState(null)
    const [cart, setCart] = useState(null)
    const [paymentType, setPaymentType] = useState('CREDIT_CARD')

    const { getCart } = useCart()

    useEffect(() => {

        async function onLoad() {
            const session = await getSession()
            setUser(session.user)

            let cart
            try {
                cart = await getCart()
                setCart(cart)
            } catch (e) {
                // no cart
            }

        }

        onLoad()

    }, [])

    useEffect(() => {

    }, [paymentType])

    async function handlePaymentSuccess(payment_info, purchase) {
        updateCartCounter()

        if (payment_info.save_info) {
            const userInfo = {
                firstname: payment_info.firstname,
                lastname: payment_info.lastname,
                email: payment_info.email,
                phone: payment_info.phone,
                address: payment_info.address,
                city: payment_info.city,
                state: payment_info.state,
                country: payment_info.country,
                nit: payment_info.nit || "C/F",
            }

            await updateUser(user._id, userInfo)
        }
        history.push(`/orders/${purchase.id}`)
    }

    function getCartSummary() {
        return <CartSummaryBox readOnly={true} totalsOnly={true} />
    }

    function onTimerExpire() {
        history.push(`/cart/`)
    }

    const bankDepositInfo = "Realiza tu depósito a la cuena de Banco Industrial 014-09309323-0 a nombre de Charly Garcia"

    function render() {
        const valid_thru = (cart && moment(cart.valid_thru)) || null
        if (moment().utc().isAfter(valid_thru))
            valid_thru = null;        

        return user && <div class="content content-dark">
            <Container>
            <Row><Col>
            <h1 className="title">Pagar Orden</h1>
            { valid_thru && <div className="timerBanner">Tiempo de reserva: <Timer expiryTimestamp={valid_thru} autoStart={true} 
            className="cartTimer" onExpire={onTimerExpire} /></div> }

            <div className="cart-summary-actions" style={{marginBottom:20}}>
                <LinkContainer to="/cart">
                    <Button variant="light" size="sm"  
                        ><FontAwesomeIcon icon={faChevronLeft} /> Modificar órden</Button>
                </LinkContainer>
            </div>
            
            {/* <div style={{display:'flex', gap:20, alignItems:'center', marginBottom:20}}>
                <div style={{display:'flex', gap:5, alignItems:'center'}}>
                    <input type="radio" name="payment_type" id="creditCard" value="creditCard" onChange={() => setPaymentType('CREDIT_CARD')} checked={paymentType == 'CREDIT_CARD'} />
                    <label for="creditCard" style={{margin:0}}>Tarjeta de Crédito</label>
                </div>
                <div style={{display:'flex', gap:5, alignItems:'center'}}>
                    <input type="radio" name="payment_type" id="deposit" value="deposit" onChange={() => setPaymentType('BANK_DEPOSIT')} checked={paymentType == 'BANK_DEPOSIT'} />
                    <label for="deposit" style={{margin:0}}>Depósito / Transferencia</label>
                </div>
            </div> */}

            {paymentType == 'CREDIT_CARD' && <CreditCardForm user={user} onSuccess={handlePaymentSuccess} cartSummary={getCartSummary} /> }
            {/* {paymentType == 'BANK_DEPOSIT' && <BankDepositForm user={user} bankDepositInfo={bankDepositInfo} onSuccess={handlePaymentSuccess} cartSummary={getCartSummary()} /> } */}
            </Col></Row>
            </Container>
        </div>
    }

    return render()
}